import classes from './SelectionButton.module.scss';

type Props = {
  label: string;
  selected: boolean;
  onClick: () => void;
};

const SelectionButton = ({ label, selected, onClick }: Props) => {
  return (
    <button
      className={`${classes['c-selection-button']} ${
        selected ? classes['c-selection-button--selected'] : ''
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
export default SelectionButton;
