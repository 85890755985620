import { useEffect, useState } from 'react';
import classes from './NumberInput.module.scss';
import { ReactComponent as MinusIcon } from 'assets/MinusIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/PlusIcon.svg';

type Props = {
  name: string;
  id: string;
  bufferValue: number;
  readOnly: boolean;
  allowNegative?: boolean;
  onChange: (value: number) => void;
};

export const NumberInput = ({ bufferValue, readOnly, allowNegative, onChange }: Props) => {
  const [value, setValue] = useState<number>(bufferValue);

  const decrement = () => {
    setValue((current) => {
      if (allowNegative) return current - 1;

      return current < 1 ? 0 : current - 1;
    });
    onChange(value);
  };

  const increment = () => {
    setValue((current) => current + 1);
    onChange(value);
  };

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={classes['c-number-input']}>
      {readOnly ? (
        <span className={classes['c-number-input__value']}>{bufferValue}</span>
      ) : (
        <>
          <button className={classes['c-number-input__button']} onClick={decrement}>
            <MinusIcon />
          </button>
          <input
            className={classes['c-number-input__input-field']}
            type="number"
            value={bufferValue}
            readOnly
          />
          <button className={classes['c-number-input__button']} onClick={increment}>
            <PlusIcon />
          </button>
        </>
      )}
    </div>
  );
};
