import dayjs from 'dayjs';

type DateType = Date | string;

export const getYearsFromNow = (date: string) => dayjs().diff(date, 'years');

export const isCurrentDate = (date: DateType) => dayjs(date).isSame(new Date(), 'date');

export const isWeekend = (date: DateType) => {
  return new Date(date).getDay() === 0 || new Date(date).getDay() === 6;
};

export const formatDate = (date: DateType, format: string): string => {
  if (!dayjs(date).isValid()) return String(date);

  return dayjs(date).format(format);
};

export const formatDateForInput = (date: DateType): string => {
  return formatDate(date, 'YYYY-MM-DD');
};

export const formatDateForDisplay = (date: DateType): string => {
  return formatDate(date, 'DD/MM/YYYY');
};

export const formatDateWithDots = (date: DateType): string => {
  return formatDate(date, 'DD.MM.YYYY.');
};

export const formatDateDayAndMonth = (date: DateType): string => {
  return formatDate(date, 'D MMM');
};

export const formatDateForCalendarTitle = (date: DateType): string => {
  return formatDate(date, 'MMMM YYYY');
};

export const areDatesSame = (firstDate: DateType, secondDate: DateType) => {
  return dayjs(firstDate).isSame(secondDate, 'date');
};

export const isDateBefore = (firstDate: DateType, secondDate: DateType) => {
  return dayjs(firstDate).isBefore(secondDate);
};

export const isDateAfter = (firstDate: DateType, secondDate: DateType) => {
  return dayjs(firstDate).isAfter(secondDate);
};

export const getDatesDayDifference = (firstDate: DateType, secondDate: DateType) => {
  return dayjs(firstDate).diff(secondDate, 'days');
};

export const isLeapYear = (date: Date): boolean => {
  const year = date.getFullYear();

  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getHoursDifference = (firstDate: Date, secondDate: Date) => {
  const diff = Math.abs(secondDate.getTime() - firstDate.getTime()) / 36e5;
  return +diff.toFixed(1);
};

export const getHoursAndMinutesFromSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return `${formattedHours}:${formattedMinutes}`;
};
export const getMinutesDifference = (firstDate: Date, secondDate: Date) => {
  const diff = Math.abs(secondDate.getTime() - firstDate.getTime()) / 60000;
  return +diff.toFixed(0);
};

export const formatHoursAndMinutesInString = (input: string) => {
  if (/^\d{2}:\d{2}$/.test(input)) {
    const [hours, minutes] = input.split(':').map(Number);
    if (hours >= 0 && hours <= 24 && minutes >= 0 && minutes <= 59) {
      return input;
    }
  }

  if (!/^\d{1,4}$/.test(input)) {
    return null;
  }

  let hours: number;
  let minutes: number;

  if (input.length === 1 || input.length === 2) {
    hours = Number(input.padStart(2, '0'));
    minutes = 0;
    if (hours >= 0 && hours <= 24) {
      return `${input.padStart(2, '0')}:00`;
    }
  }
  if (input.length === 3) {
    hours = Number(`0${input.charAt(0)}`);
    minutes = Number(input.slice(1));
    if (hours >= 0 && hours <= 24 && minutes >= 0 && minutes <= 59) {
      return `0${input.charAt(0)}:${input.slice(1)}`;
    }
  }
  if (input.length === 4) {
    hours = Number(input.slice(0, 2));
    minutes = Number(input.slice(2));
    if (hours >= 0 && hours <= 24 && minutes >= 0 && minutes <= 59) {
      return `${input.slice(0, 2)}:${input.slice(2)}`;
    }
  }
};

export const formatDateAndStringTime = (date: Date, timeString: string) => {
  const [hours, minutes] = timeString.split(':').map(Number);

  const newDate = new Date(date);

  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(0);

  return formatDate(newDate, 'YYYY-MM-DD HH:mm:ss');
};
