import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectWorkflowTemplates } from '../redux/workflowTemplatesSlice';
import type { Workflow as WorkflowModel, WorkflowSection } from '../model/workflow.model';
import classes from '../styles/WorkflowDetails.module.scss';
import { getWorkflow } from '../redux/workflowTemplatesActions';
import { RequestState } from 'config/constants';
import { ComponentType } from 'modules/projects/models';
import { SectionActions } from 'modules/shared/components';
import {
  createWorkflowTemplate,
  deleteWorkflowTemplate,
  updateWorkflowTemplate,
} from '../api/workflow.api';
import { ActionConfirmationModal, Input, Status, toast } from 'components/core';
import SectionsColumn from './SectionsColumn/SectionsColumn';

type Props = {
  componentType?: ComponentType;
};

const WorkflowTemplateDetails = ({ componentType = ComponentType.EXISTING }: Props) => {
  const { id, name } = useParams();

  const initialWorkflowSection: WorkflowSection = {
    id: `${crypto.randomUUID()}-temp`,
    name: '',
    tasks: [],
    order: 1,
    workflowId: Number(id),
  };
  const initialWorkflowTemplate: WorkflowModel = {
    name: name || '',
    workflowSections: [initialWorkflowSection],
  };

  const workflow = useAppSelector(selectWorkflowTemplates).find((item) => item.id === Number(id));
  const { loading } = useAppSelector((state) => state.workflowTemplates);

  const [workflowData, setWorkflowData] = useState<WorkflowModel>(
    workflow || initialWorkflowTemplate,
  );
  const [isEditMode, setIsEditMode] = useState(componentType === ComponentType.NEW);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOpenConfirmationModal = useCallback(() => setIsConfirmationModalOpen(true), []);
  const handleCloseConfirmationModal = useCallback(() => setIsConfirmationModalOpen(false), []);
  const handleEdit = useCallback(() => setIsEditMode(true), []);

  const isSaveButtonDisabled = useMemo(() => {
    const { name, workflowSections } = workflowData;

    const hasValidSection = workflowSections.some(
      (section) => section.name && section.tasks.length && section.tasks.some((task) => task.name),
    );

    return !name || !hasValidSection;
  }, [workflowData]);

  const navigateToWorkflowTemplates = useCallback(
    () => navigate('/workflow-templates'),
    [navigate],
  );

  const handleSectionChange = useCallback((sectionData: WorkflowSection) => {
    setWorkflowData((prevState) => ({
      ...prevState,
      workflowSections: prevState.workflowSections.map((section) =>
        section.id !== sectionData.id ? section : sectionData,
      ),
    }));
  }, []);

  const createSection = useCallback(() => {
    const newSection: WorkflowSection = {
      id: `${crypto.randomUUID()}-temp`,
      name: '',
      tasks: [],
      order: workflowData.workflowSections.length + 1,
      workflowId: Number(id),
    };

    setWorkflowData((prevWorkflow) => ({
      ...prevWorkflow,
      workflowSections: [...prevWorkflow.workflowSections, newSection],
    }));
  }, [id, workflowData.workflowSections.length]);

  const handleNameChange = useCallback(
    (name: string) => setWorkflowData((prevState) => ({ ...prevState, name })),
    [],
  );

  const filterValidWorkflowSections = useCallback(() => {
    return workflowData.workflowSections
      .filter((section) => section.name && section.tasks.length)
      .map((section) => ({
        ...section,
        tasks: section.tasks.filter((task) => task.name),
      }));
  }, [workflowData.workflowSections]);

  const handleSave = useCallback(async () => {
    const filteredWorkflowData = {
      ...workflowData,
      workflowSections: filterValidWorkflowSections(),
    };

    try {
      setIsLoading(true);

      let data: WorkflowModel = {} as WorkflowModel;

      if (componentType === ComponentType.NEW) {
        data = (await createWorkflowTemplate(filteredWorkflowData)).data;
        navigate(`/workflow-templates/${data.id}`);
      }

      if (componentType === ComponentType.EXISTING && workflow) {
        const updatedWorkflowData = { ...workflow, name: filteredWorkflowData.name };

        data = (await updateWorkflowTemplate(updatedWorkflowData)).data;
      }

      setWorkflowData(data);
      setIsEditMode(false);
    } catch {
      toast('error', 'Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [componentType, filterValidWorkflowSections, navigate, workflow, workflowData]);

  const handleCancel = useCallback(() => {
    if (componentType === ComponentType.NEW) {
      navigateToWorkflowTemplates();
      return;
    }

    setWorkflowData((prevState) => ({ ...prevState, name: workflow?.name || '' }));
    setIsEditMode(false);
  }, [componentType, navigateToWorkflowTemplates, workflow?.name]);

  const handleDelete = useCallback(async () => {
    if (!workflowData.id) return;

    if (workflowData.workflowSections.length) {
      toast(
        'error',
        'You cannot delete workflow template with sections. Please delete all sections first.',
      );
      handleCloseConfirmationModal();
      return;
    }

    try {
      setIsLoading(true);
      await deleteWorkflowTemplate(workflowData.id);
      navigateToWorkflowTemplates();
    } catch {
      toast('error', 'Something went wrong while deleting workflow template. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [
    workflowData.id,
    workflowData.workflowSections.length,
    handleCloseConfirmationModal,
    navigateToWorkflowTemplates,
  ]);

  const renderColumn = useCallback(() => {
    const { workflowSections } = workflowData;

    const left: WorkflowSection[] = [];
    const right: WorkflowSection[] = [];

    workflowSections.forEach((object, index) =>
      index % 2 === 0 ? left.push(object) : right.push(object),
    );

    return (
      <>
        <SectionsColumn
          sections={left}
          componentType={componentType}
          isButtonIncluded={left.length === right.length}
          setWorkflowData={setWorkflowData}
          createSection={createSection}
          handleSectionChange={handleSectionChange}
        />
        <SectionsColumn
          sections={right}
          componentType={componentType}
          isButtonIncluded={left.length !== right.length}
          setWorkflowData={setWorkflowData}
          createSection={createSection}
          handleSectionChange={handleSectionChange}
        />
      </>
    );
  }, [componentType, createSection, handleSectionChange, workflowData]);

  useEffect(() => {
    if (workflow) setWorkflowData(workflow);
  }, [workflow]);

  useEffect(() => {
    if (id) dispatch(getWorkflow(id));
  }, [dispatch, id]);

  return (
    <div className={classes['c-workflow-template-details']}>
      <Status isLoading={loading === RequestState.PENDING}>
        <div className={classes['c-workflow-template-details__header']}>
          <div className={classes['c-workflow-template-details__title']}>
            <Input
              id="workflow-template-input"
              size="large"
              value={workflowData.name}
              setValue={handleNameChange}
              readOnly={!isEditMode}
            />
          </div>
          <div className={classes['c-workflow-template-details__section-actions-wrap']}>
            <SectionActions
              isEditOpen={isEditMode}
              handleEdit={handleEdit}
              handleAdd={createSection}
              handleCancel={handleCancel}
              handleDelete={handleOpenConfirmationModal}
              handleSave={handleSave}
              isSaveButtonDisabled={isSaveButtonDisabled}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className={classes['c-workflow-template-details__sections-container']}>
          {renderColumn()}
        </div>
      </Status>
      <ActionConfirmationModal
        message="you want to delete this workflow template?"
        isModalOpen={isConfirmationModalOpen}
        isLoading={isLoading}
        closeModal={handleCloseConfirmationModal}
        handleNo={handleCloseConfirmationModal}
        handleYes={handleDelete}
      />
    </div>
  );
};

export default WorkflowTemplateDetails;
