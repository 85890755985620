import { Avatar, HighlightedText } from 'components/core';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { TimeAway, TimeAwayUser } from '../models/timeAwayModels';
import classes from '../styles/AwayUser.module.scss';
import {
  checkIsPublicHoliday,
  checkIsToday,
  getAllDatesInMonth,
  getSplittedAwayClass,
} from '../utils/dates';
import AwayBar from './AwayBar';
import PublicHoliday from './PublicHoliday';

type Props = {
  isFirst: boolean;
  awayUser: TimeAwayUser;
  timeAways: TimeAway[];
  currentDate: Date | 'Today';
  hoveredDay: number | null;
  setHoveredDay: (hoveredDay: number | null) => void;
  searchValue: string;
};

const AwayUser = ({
  awayUser,
  timeAways,
  currentDate,
  hoveredDay,
  setHoveredDay,
  searchValue,
  isFirst,
}: Props) => {
  const { publicHolidays } = useAppSelector((state) => state.timeline);

  const ref = useRef<HTMLDivElement | null>(null);

  const { userId, firstName, lastName, jobTitle, profilePhotoPath } = awayUser;

  const allDates = getAllDatesInMonth(currentDate);

  return (
    <div className={classes['c-away-user']}>
      <div
        className={`${classes['c-away-user__user-info']} ${
          isFirst ? classes['c-away-user__user-info--first'] : ''
        }`}
      >
        <Link to={`/user/${userId}/about`}>
          <Avatar
            horizontal
            imageUrl={profilePhotoPath}
            title={<HighlightedText text={`${firstName} ${lastName}`} highlight={searchValue} />}
            subtitle={jobTitle}
            size="timeline"
          />
        </Link>
      </div>
      <div className={classes['c-away-user__aways']} ref={ref}>
        <div className={classes['c-away-user__splited-away']}>
          {[...allDates].map((date) => (
            <div
              key={date.getTime()}
              className={`${classes['c-away-user__day']} ${
                classes[getSplittedAwayClass(hoveredDay, date)]
              }`}
              onMouseEnter={() => setHoveredDay(date.getTime())}
              onMouseLeave={() => setHoveredDay(null)}
            >
              {checkIsToday(date, new Date()) && (
                <div className={classes['c-away-user__current-day-horizontal']} />
              )}
              {checkIsPublicHoliday(publicHolidays, date) && <PublicHoliday />}
            </div>
          ))}
        </div>
        {timeAways.map((timeAway) => (
          <AwayBar
            key={timeAway.id}
            isFirst={isFirst}
            currentWidthRef={ref}
            currentDate={currentDate}
            timeAway={timeAway}
            totalNumberOfDays={allDates.length}
          />
        ))}
      </div>
    </div>
  );
};

export default AwayUser;
