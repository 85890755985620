import { useCallback, useEffect, useRef, useState } from 'react';
import classes from './AddPeopleModal.module.scss';
import type { UserBaseInfo } from 'modules/people/model/User';
import { Button, Divider, Checkbox, Avatar, Input, toast } from 'components/core';
import { getUserBaseInfo, getUserBySearch } from 'modules/people/api/people.api';
import { UserType } from 'config/constants';
import { UserProjectPhase } from 'modules/projects/models';

export enum AddPeopleType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

type Props = {
  excludedUserIds?: number[];
  buttonContent?: string;
  type?: AddPeopleType;
  onClick: (userIds: number[], selectedUsers: UserProjectPhase[]) => void;
};

export const AddPeopleModal = ({
  excludedUserIds,
  buttonContent,
  type = AddPeopleType.MULTIPLE,
  onClick,
}: Props) => {
  const [userList, setUserList] = useState<UserBaseInfo[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserProjectPhase[]>([]);

  const timerRef = useRef<any>(null);

  const handleOnClick = useCallback(() => {
    if (selectedUserIds && selectedUser) onClick(selectedUserIds, selectedUser);
  }, [selectedUser, selectedUserIds, onClick]);

  const handleCheck = useCallback(
    async (userId: number) => {
      try {
        const response = await getUser(userId);
        const selectedUser: UserProjectPhase = {
          userId: response.data.id,
          simpleUserDto: {
            id: response.data.id,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            profilePicture: response.data.profilePhotoPath,
            jobTitle: response.data.jobTitle,
          },
        };
        if (type === AddPeopleType.SINGLE) {
          setSelectedUser([selectedUser]);

          setSelectedUserIds([userId]);
        }
        if (type === AddPeopleType.MULTIPLE) {
          setSelectedUser((prev) =>
            prev?.some((x) => x.userId == userId)
              ? prev.filter((user) => user.userId !== userId)
              : [...prev, selectedUser],
          );
          setSelectedUserIds((prev) =>
            prev?.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId],
          );
        }
      } catch {
        toast('error', 'Something went wrong. Please try again later.');
      }
    },
    [type],
  );

  const getUser = async (userId: number) => {
    const user = await getUserBaseInfo(userId);
    return user;
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(async () => {
      if (searchInput.length < 3) setUserList([]);

      if (searchInput.length >= 3) {
        try {
          setUserList([]);
          const response = await getUserBySearch(searchInput, UserType.ACTIVE_MEMBER);
          setUserList(response.data.filter((user) => !excludedUserIds?.includes(user.id)));
        } catch {
          toast('error', 'Something went wrong. Try again.');
        }
      }
    }, 500);
  }, [searchInput]);

  return (
    <div className={classes['c-add-people-modal']}>
      <div className={classes['c-add-people-modal__search']}>
        <Input
          name="search-people"
          id="search-people"
          placeholder="Search people"
          setValue={setSearchInput}
          autoFocus
        />
      </div>
      <div className={classes['c-add-people-modal__users']}>
        {userList.length ? (
          userList.map(({ id, firstName, lastName, jobTitle, profilePhotoPath }) => (
            <div key={id} className={classes['c-add-people-modal__user-list']}>
              <Checkbox
                id={id}
                label={
                  <>
                    <span className={classes['c-add-people-modal__user-list-title']}>
                      {firstName + ' ' + lastName}
                    </span>
                    <div className={classes['c-add-people-modal__user-list-subtitle']}>
                      {jobTitle}
                    </div>
                  </>
                }
                image={<Avatar size="extra-small" imageUrl={profilePhotoPath || ''} />}
                checked={selectedUserIds.includes(id)}
                checkHandler={() => handleCheck(id)}
              />
              <Divider type="light" />
            </div>
          ))
        ) : (
          <div className={classes['c-add-people-modal__no-users']}>
            There are no available users...
          </div>
        )}
      </div>
      <div className={classes['c-add-people-modal__button-container']}>
        <Button size="medium" disabled={!selectedUserIds.length} onClick={handleOnClick}>
          {buttonContent}
        </Button>
      </div>
    </div>
  );
};
