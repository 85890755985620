import { getYearsFromNow } from 'utils';

export const getEffectiveOn = (effectiveOn?: string) => {
  if (!effectiveOn) return 'N/A';

  const yearsFromNow = getYearsFromNow(effectiveOn);

  if (yearsFromNow === 0) return 'this year';

  const suffix = yearsFromNow === 1 ? 'year' : 'years';

  return `${yearsFromNow} ${suffix} ago`;
};
