import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import classes from './CreateTimesheetModal.module.scss';
import { Button, Container, DropDown, PopUpModal, toast } from 'components/core';
import { months } from 'config/constants';
import { addTimesheet, selectTimesheets } from 'modules/timesheet/redux/timesheetsSlice';
import { useNavigate } from 'react-router-dom';
import { createTimesheet } from 'modules/timesheet/api/timesheet.api';

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
};

const CreateTimesheetModal = ({ isOpen, handleCloseModal }: Props) => {
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [selectedYear, setSelectedYear] = useState<string>();

  const { timesheets } = useAppSelector(selectTimesheets);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const checkTimesheetAvailability = useCallback(
    (yearCheck: number, monthCheck: string) => {
      return !timesheets.some(
        ({ month, year }) =>
          +year === yearCheck && month.toLowerCase() === monthCheck.toLowerCase(),
      );
    },
    [timesheets],
  );

  const monthOptions = useMemo(() => {
    const allMonths = Object.keys(months);

    if (!selectedYear) return allMonths;

    return allMonths.filter((item) => checkTimesheetAvailability(+selectedYear, item));
  }, [checkTimesheetAvailability, selectedYear]);

  const yearOptions = useMemo(() => {
    const years = [];

    for (let i = -1; i <= 10; i++) {
      const newYear = new Date().getFullYear() - i;

      if (selectedMonth && !checkTimesheetAvailability(newYear, selectedMonth)) continue;

      years.push(newYear.toString());
    }

    return years;
  }, [checkTimesheetAvailability, selectedMonth]);

  const handleCreate = async () => {
    if (!selectedYear || !selectedMonth) {
      toast('error', 'Please select timesheet year and month.');
      return;
    }

    try {
      const { data } = await createTimesheet({
        year: +selectedYear,
        month: selectedMonth?.toUpperCase(),
      });

      dispatch(addTimesheet(data));
      navigate(`${data.id}`);
    } catch {
      toast('error', 'Something went wrong while creating timesheet. Try agan.');
    }
  };

  return (
    <PopUpModal title="Create timesheet" opened={isOpen} width={20} closeModal={handleCloseModal}>
      <div className={classes['c-create-timesheet-modal']}>
        <div className={classes['c-create-timesheet-modal__form']}>
          <Container direction="column" align="start" gap="xs">
            <label className={classes['c-create-timesheet-modal__label']}>Month</label>
            <DropDown
              options={monthOptions}
              selectedOption={selectedMonth}
              setSelectedOption={setSelectedMonth}
            />
          </Container>
          <Container direction="column" align="start" gap="xs">
            <label className={classes['c-create-timesheet-modal__label']}>Year</label>
            <DropDown
              options={yearOptions}
              selectedOption={selectedYear}
              setSelectedOption={setSelectedYear}
            />
          </Container>
        </div>
        <Button size="medium" onClick={handleCreate}>
          Create
        </Button>
      </div>
    </PopUpModal>
  );
};

export default CreateTimesheetModal;
