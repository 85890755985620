import type { User } from 'modules/people/model/User';
import type { PtoPolicy } from 'modules/shared/model';
import { customFieldSections } from '../utils/common';

export type LocationModel = {
  id?: number;
  address: string;
  city: string;
  postalCode: string;
  region: string;
  country: string;
  label: string;
  headquarters: boolean;
};

export type CustomFieldSection = (typeof customFieldSections)[number];

export enum CustomFieldValueType {
  NUMBER = 'NUMBER',
  TEXT_FIELD = 'TEXT_FIELD',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
}

export type CustomField = {
  id: number;
  name: string;
  organizationId: number;
  valueType: CustomFieldValueType;
  sectionName: CustomFieldSection;
  singleChoiceString: string[];
};

export type NewCustomField = Omit<CustomField, 'id' | 'organizationId'>;

export type CustomFieldSingleChoiceOption = {
  id: number;
  name: string;
};

export type OrganizationDocument = {
  id: number;
  name: string;
  type: string;
  multipartFile?: string;
  originalFileName?: string;
  organizationDocumentType?: string;
  documentUrl?: string;
};

export type TimeAwayCategory = {
  name: string;
  approvalRequired: boolean;
};

export type CompanyModel = {
  id?: number;
  name: string;
  domain: string;
  logoPath: string;
  logoDisplayName: string;
  licenseType?: string;
  users: User[];
  ptoPolicies: PtoPolicy[];
};

export type CompanySettings = {
  id: number;
  organizationId: number;
  storageCapacity: number;
  dashboardContentEntries: string[];
  mfaStatus: string;
  workingAwayApprovalRequired: boolean;
  timeOffApprovalRequired: boolean;
};
