import { api } from 'lib';
import type {
  ProjectPhase,
  Project,
  UpdateProjectPhaseData,
  NewProject,
  ProjectStatusFilter,
} from '../models';
import { getOrganizationId } from 'state/utils/organization';

export const getAllProjects = async (filter: ProjectStatusFilter = 'active') => {
  return api.get<Project[]>(`/organization/${getOrganizationId()}/project/list`, {
    params: { statusFilter: filter },
  });
};

export const getAllProjectsByUser = async (userId: number) => {
  return api.get<Project[]>(`/organization/${getOrganizationId()}/project/user/${userId}`);
};

export const getProjectById = async (id: string) => {
  return api.get<Project>(`/organization/${getOrganizationId()}/project/${id}`);
};

export const createNewProject = async (project: NewProject) => {
  const newProjectPhases = project.projectPhases.map((phase) => {
    const { id, ...newPhase } = phase;
    return newPhase;
  });

  return api.post<Project>(`/organization/${getOrganizationId()}/project`, {
    name: project.name,
    projectPhases: newProjectPhases,
  });
};

export const updateProject = async (project: Project) => {
  return api.put<Project>(`/organization/${getOrganizationId()}/project/${project.id}`, {
    ...project,
  });
};

export const createNewPhase = async (phase: ProjectPhase) => {
  const { id, ...newPhase } = phase;

  return api.post<ProjectPhase>(
    `/organization/${getOrganizationId()}/project/${phase.projectId}/project-phase`,
    {
      ...newPhase,
    },
  );
};

export const updateProjectPhase = async (updatedPhase: UpdateProjectPhaseData) => {
  return api.put<ProjectPhase>(
    `/organization/${getOrganizationId()}/project/${updatedPhase.projectId}/project-phase/${
      updatedPhase.id
    }`,
    { ...updatedPhase },
  );
};

export const updatePhaseMembers = async (projectId: number, phaseId: string, userIds: number[]) => {
  return api.post<ProjectPhase>(
    `/organization/${getOrganizationId()}/project/${projectId}/project-phase/${phaseId}/user`,
    { userIds },
  );
};

export const deleteProject = async (projectId: number) => {
  return api.delete(`/organization/${getOrganizationId()}/project/${projectId}`);
};

export const deleteProjectPhase = async (phaseId: string, projectId: number) => {
  return api.delete(
    `/organization/${getOrganizationId()}/project/${projectId}/project-phase/${phaseId}`,
  );
};

export const archiveProject = async (projectId: number) => {
  return api.patch(`/organization/${getOrganizationId()}/project/${projectId}/archive`);
};
