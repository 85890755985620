import classes from './Header.module.scss';

type Props = {
  title: string;
  subtitle?: string;

  titleModifier?: 'margin-bottom';
};

const Header = ({ title, subtitle, titleModifier }: Props) => (
  <>
    <div
      className={`${classes['c-header__title']} ${
        titleModifier ? classes[`c-header__title--${titleModifier}`] : ''
      }`}
    >
      {title}
    </div>
    {subtitle && <span className={classes['c-header__subtitle']}>{subtitle}</span>}
  </>
);

export default Header;
