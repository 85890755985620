import { useState, MouseEvent, ReactNode } from 'react';
import classes from './YearCalendar.module.scss';
import { ReactComponent as ArrowIcon } from 'assets/ArrowLeft.svg';
import { Calendar } from 'react-date-range';
import { ColorLegend } from '../ColorLegend';
import { TimeAway } from 'modules/timeline/models/timeAwayModels';
import { months } from 'config/constants';
import { Button } from '../Button';
import { MonthPicker } from '../MonthPicker';
import { Container } from '../Container';
import { isColorDark } from '../Filter/utils/checkColorDarkness';

type YearCalendarProps = {
  timeAways: TimeAway[];
  numOfMonths?: number;
  isFullYearAllowed?: boolean;
  headerItem?: ReactNode;
  showCurrentDate?: boolean;
};

export const YearCalendar = ({
  timeAways,
  numOfMonths,
  isFullYearAllowed,
  headerItem,
  showCurrentDate = true,
}: YearCalendarProps) => {
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [shownDate, setShownDate] = useState<Date>(
    numOfMonths ? new Date() : new Date(selectedYear, 0, 1),
  );
  const [selectedMonth, setSelectedMonth] = useState<number | 'Today'>(shownDate.getMonth());
  const [numberOfMonths, setNumberOfMonths] = useState<number>(
    isFullYearAllowed ? 12 : numOfMonths || 12,
  );

  const isSingleMonth = numberOfMonths === 1;

  const navigatorRenderer = (
    _currFocusedDate: Date,
    changeShownDate: (
      value: Date | number | string,
      mode?: 'set' | 'setYear' | 'setMonth' | 'monthOffset',
    ) => void,
  ): JSX.Element => {
    const handleShowFullYear = () => {
      setNumberOfMonths(12);
    };

    const handleSelectMonth = (month: number | 'Today') => {
      setSelectedMonth(month);

      if (month === 'Today') {
        changeShownDate(new Date(selectedYear, new Date().getMonth(), 1));
        return;
      }

      changeShownDate(new Date(selectedYear, month, 1));
    };

    const handleSelectDateFromCalendar = (date: Date | 'Today') => {
      const selectedDate = date === 'Today' ? new Date() : date;
      setShownDate(selectedDate);
      changeShownDate(selectedDate);
    };

    const monthsView = () => {
      if (numberOfMonths !== 1) {
        return (
          <MonthPicker
            currentDate={shownDate}
            selectDateFromCalendar={handleSelectDateFromCalendar}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={handleSelectMonth}
            setSelectedYear={setSelectedYear}
          />
        );
      }

      if (isFullYearAllowed)
        return (
          <Button
            variant="text"
            leftIcon={<ArrowIcon className={classes['c-year-calendar__arrow-icon']} />}
            onClick={handleShowFullYear}
          >
            Full year
          </Button>
        );
    };

    return (
      <div className={classes['c-year-calendar__header']}>
        <Container>
          {headerItem}
          {monthsView()}
        </Container>
        <ColorLegend />
      </div>
    );
  };

  const dayContentRenderer = (day: Date): JSX.Element => {
    const date = day.getDate();
    const timeAway = timeAways.find((timeAway) => {
      const startDate = new Date(timeAway.startDate);
      const endDate = new Date(timeAway.endDate);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      return day.getTime() >= startDate.getTime() && day.getTime() <= endDate.getTime();
    });

    let isStartDate = false;
    let isEndDate = false;
    let backgroundColor = '';
    let fontColor = '';

    if (timeAway) {
      const startDate = new Date(timeAway.startDate);
      startDate.setHours(0, 0, 0, 0);
      isStartDate = day.getTime() === startDate.getTime();

      const endDate = new Date(timeAway.endDate);
      endDate.setHours(0, 0, 0, 0);
      isEndDate = day.getTime() === endDate.getTime();

      backgroundColor = timeAway.timeAwayType.color;
      fontColor = isColorDark(backgroundColor) ? '#fff' : '#000';
    }
    const isHighlighted = timeAway || isStartDate || isEndDate;
    const style = isHighlighted ? { backgroundColor } : {};

    return (
      <span
        style={style}
        className={`${classes['c-year-calendar__day']} ${
          isStartDate && classes['c-year-calendar__day--start-date']
        } ${isEndDate && classes['c-year-calendar__day--end-date']}`}
      >
        <span style={{ color: isHighlighted ? fontColor : '#000' }}>{date}</span>
      </span>
    );
  };

  const handleOnClick = (event: MouseEvent) => {
    const monthName = (event.target as HTMLDivElement).innerText;

    if (!(monthName in months)) return;

    setNumberOfMonths(1);
    setShownDate(new Date(selectedYear, months[monthName], 1));
  };

  const handleOnChange = (date: Date) => {
    setNumberOfMonths(1);
    setShownDate(new Date(selectedYear, date.getMonth(), 1));
  };

  const showSingleMonth = (): string => {
    return showCurrentDate
      ? classes['c-year-calendar__month-name--single-month']
      : classes['c-year-calendar__month-name--hidden'];
  };

  return (
    <div className={classes['c-year-calendar']} onClick={handleOnClick}>
      <Calendar
        key={numberOfMonths}
        classNames={{
          calendarWrapper: `${classes['c-year-calendar__wrapper']}`,
          months: `${classes['c-year-calendar__months']}`,
          month: `${isSingleMonth && classes['c-year-calendar__month']}`,
          monthName: `${
            isSingleMonth ? showSingleMonth() : classes['c-year-calendar__month-name']
          }`,
          days: `${isSingleMonth && classes['c-year-calendar__days']}`,
          dayHovered: `${classes['c-year-calendar__day--hovered']}`,
          dayPassive: `${classes['c-year-calendar__day--passive']}`,
          weekDays: `${classes['c-year-calendar__week-days']}`,
          weekDay: `${classes['c-year-calendar__week-day']}`,
        }}
        months={numberOfMonths}
        shownDate={shownDate}
        navigatorRenderer={navigatorRenderer}
        direction="horizontal"
        monthDisplayFormat={isSingleMonth ? 'MMMM yyy' : 'MMMM'}
        weekdayDisplayFormat={isSingleMonth ? 'EEEE' : 'E'}
        weekStartsOn={1}
        dragSelectionEnabled={false}
        showMonthAndYearPickers={false}
        showMonthArrow={false}
        showDateDisplay={false}
        showPreview={false}
        editableDateInputs={false}
        onChange={handleOnChange}
        dayContentRenderer={(date) => dayContentRenderer(date)}
      />
    </div>
  );
};
