import { zodResolver } from '@hookform/resolvers/zod';
import { AddMemberDropDown, Button, Input, PopUpModal } from 'components/core';
import { updateTimesheetEntryHours } from 'modules/timesheet/api/timesheet.api';
import { getTimesheetProjects } from 'modules/timesheet/redux/timesheetsActions';
import {
  selectProjectOptions,
  updateTimesheetEntries,
} from 'modules/timesheet/redux/timesheetsSlice';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getUserId } from 'state/utils';
import {
  formatDate,
  formatDateAndStringTime,
  formatDateForInput,
  formatHoursAndMinutesInString,
  mapErrorToErrorData,
} from 'utils';
import classes from './EnterHoursModal.module.scss';
import { EnterHoursSchema, defaultValues, enterHoursSchema } from './schema/EnterHoursSchema';

type Props = {
  opened: boolean;
  openModal: (isOpen: boolean) => void;
};

export const EnterHoursModal = ({ opened, openModal }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitted, isSubmitting, errors },
  } = useForm<EnterHoursSchema>({
    resolver: zodResolver(enterHoursSchema),
    defaultValues,
  });
  const [validStartTime, setValidStartTime] = useState<string>('09:00');
  const dispatch = useAppDispatch();
  const projectOptions = useAppSelector(selectProjectOptions);

  const getProjectNameById = (id: number | null) => {
    return projectOptions.find((project) => project.id === id)?.value;
  };
  const userId = getUserId();

  const handleStartTime = (newTime: string) => {
    const formattedTime = formatHoursAndMinutesInString(newTime);

    if (formattedTime) {
      setValidStartTime(formattedTime);
      return formattedTime;
    }
    return validStartTime;
  };

  const handleUpdateTimesheet = async (formData: EnterHoursSchema) => {
    const startDateTime = formatDateAndStringTime(formData.selectedDate, validStartTime);

    try {
      const { data } = await updateTimesheetEntryHours({
        projectId: formData.projectId,
        startDateTime,
        regularMinutes: formData.regularHours * 60,
        overtimeMinutes: formData.overtimeHours * 60,
      });
      dispatch(updateTimesheetEntries(data));
      toast.success(
        'Timesheet successfully updated for ' + formatDate(formData.selectedDate, 'YYYY-MM-DD'),
      );
      openModal(false);
    } catch (error) {
      const errorData = mapErrorToErrorData(error);
      toast.error(errorData?.message || 'Something went wrong, please try again later.');
    }
  };

  const handleBlur = (onChange: (value: string) => void, value: string) => {
    onChange(handleStartTime(value));
  };

  const handleSelectedItemId = (onChange: (value: number) => void) => (newValue: number) => {
    onChange(newValue);
  };

  const handleSetDate = (onChange: (value: Date) => void) => (newDate: string) => {
    onChange(new Date(newDate));
  };

  const handleHoursChange =
    (onChange: (value: number) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(Number(event.target.value));
    };

  useEffect(() => {
    if (!userId) return;

    dispatch(getTimesheetProjects({ userId }));
  }, [dispatch, userId]);

  return (
    <PopUpModal title="Enter hours" opened={opened} width={30} closeModal={() => openModal(false)}>
      <form
        onSubmit={handleSubmit(handleUpdateTimesheet)}
        className={classes['c-enter-hours-modal']}
      >
        <div className={classes['c-enter-hours-modal__row']}>
          <Controller
            name="selectedDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                id="selectedDate"
                type="date"
                label="Date"
                value={formatDateForInput(new Date(value))}
                setValue={handleSetDate(onChange)}
              />
            )}
          />
          <Controller
            name="projectId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AddMemberDropDown
                items={projectOptions}
                label="Project"
                selectedValue={getProjectNameById(value)}
                setSelectedItemId={handleSelectedItemId(onChange)}
                isSubmitted={isSubmitted}
                isAddNewDisabled
                excludeSearchOption
              />
            )}
          />
        </div>
        <div className={classes['c-enter-hours-modal__row']}>
          <Controller
            name="startTime"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className={classes['c-enter-hours-modal__time-picker-wrap']}>
                <label className={classes['c-enter-hours-modal__label']}>Start time</label>
                <Input
                  handleOnChange={onChange}
                  value={value}
                  id="startTime"
                  name="startTime"
                  handleOnBlur={() => handleBlur(onChange, value)}
                  isSubmitted={isSubmitted}
                  isInvalid={!!errors.startTime?.message}
                />
              </div>
            )}
          />
          <Controller
            name="regularHours"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className={classes['c-enter-hours-modal__time-picker-wrap']}>
                <label className={classes['c-enter-hours-modal__label']}>Regular hours</label>
                <Input
                  type="number"
                  handleOnChange={handleHoursChange(onChange)}
                  value={value.toString()}
                  id="regularHours"
                  name="regularHours"
                  isSubmitted={isSubmitted}
                  isInvalid={!!errors.regularHours?.message}
                />
              </div>
            )}
          />
          <Controller
            name="overtimeHours"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className={classes['c-enter-hours-modal__time-picker-wrap']}>
                <label className={classes['c-enter-hours-modal__label']}>Overtime hours</label>
                <Input
                  type="number"
                  handleOnChange={handleHoursChange(onChange)}
                  value={value.toString()}
                  id="overtimeHours"
                  name="overtimeHours"
                  isSubmitted={isSubmitted}
                  isInvalid={!!errors.overtimeHours?.message}
                />
              </div>
            )}
          />
        </div>
        <div className={classes['c-enter-hours-modal__row']}>
          <span className={classes['c-enter-hours-modal__label--error']}>
            {errors.projectId?.message ||
              errors.overtimeHours?.message ||
              errors.regularHours?.message ||
              errors.selectedDate?.message}
          </span>
        </div>
        <div className={classes['c-enter-hours-modal__button']}>
          <Button htmlType="submit" isLoading={isSubmitting}>
            Update timesheet
          </Button>
        </div>
      </form>
    </PopUpModal>
  );
};
