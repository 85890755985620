import { TokenData } from 'utils/auth/tokenModel';
import { decodeToken, setLocalStorageTokens } from 'utils/auth/tokenService';
import { setUserData } from '../redux/authSlice';
import { setOrganization } from 'state/shared/organization';
import { store } from 'state/store';

export const signIn = (data: TokenData) => {
  const { dispatch } = store;
  const { accessToken, refreshToken } = data;

  setLocalStorageTokens(accessToken, refreshToken);

  const tokenPayload = decodeToken(accessToken);

  dispatch(setUserData({ tokenPayload, accessToken }));
  dispatch(setOrganization(tokenPayload.organization));
};
