import { useEffect, useState } from 'react';
import { DropDown } from 'components/core';
import classes from '../../../styles/DropdownSelection.module.scss';

type Props = {
  items: string[];
  selectedItem: string;
  onChange: (selectedItem: string) => void;
};

const DropdownSelection = ({ items, selectedItem, onChange }: Props) => {
  const [newSelectedItem, setNewSelectedItem] = useState<string>(selectedItem);

  useEffect(() => {
    onChange(newSelectedItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSelectedItem]);

  return (
    <div className={classes['c-dropdown-selection']}>
      <DropDown
        options={items}
        defaultValue={selectedItem}
        selectedOption={newSelectedItem}
        setSelectedOption={setNewSelectedItem}
        error={!newSelectedItem ? 'Field is required' : ''}
      />
    </div>
  );
};

export default DropdownSelection;
