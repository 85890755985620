import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'components/core';
import { getAllTimeAwayTypes } from 'modules/settings/api';

export const getTimeAwayTypes = createAsyncThunk('colorLegend/colorLegendItems', async () => {
  try {
    const response = await getAllTimeAwayTypes();
    return response?.data;
  } catch {
    toast('error', 'Something went wrong. Please try again later.');
  }
});
