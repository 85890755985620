import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import classes from '../styles/ProfileNav.module.scss';
import { ReactComponent as WarningIcon } from 'assets/WarningIcon.svg';
import { IconButton } from 'components/core';

type Props = {
  icon: ReactNode;
  url: string;
  text: string;
  error?: boolean;
  tooltip?: string;
};

const ProfileNavLink: FC<Props> = ({ icon, text, url, error, tooltip = 'Missing info' }) => {
  return (
    <NavLink
      to={url}
      className={({ isActive }) =>
        `${classes['c-profile-nav__link-holder']} ${
          isActive ? classes['c-profile-nav__link-holder--active'] : ''
        }`
      }
    >
      <div className={classes['c-profile-nav__link']}>
        {icon}
        <span className={classes['c-profile-nav__link-text']}>{text}</span>
      </div>
      {error && (
        <IconButton
          icon={<WarningIcon />}
          tooltipContent={tooltip}
          tooltipPosition="bottom"
          iconButtonType="warning"
        />
      )}
    </NavLink>
  );
};

export default ProfileNavLink;
