import { useCallback, useEffect, useRef, useState, type RefObject } from 'react';
import { TimeAway } from '../models/timeAwayModels';
import classes from '../styles/AwayUser.module.scss';
import { TimeAwayStatus } from 'modules/shared/model';
import { roundedBar, roundedLeftBar, roundedRightBar, wholeMonthBar } from '../utils/bar';
import { getAllDatesInMonth } from '../utils/dates';
import { formatDate, getDatesDayDifference, isDateAfter, isDateBefore } from 'utils/dates';
import { getTimeAwayColorByStatus } from 'utils';

export type AwayBarData = {
  width: string;
  left: string;
  borderTopLeftRadius: string;
  borderBottomLeftRadius: string;
  borderTopRightRadius: string;
  borderBottomRightRadius: string;
  backgroundColor: string;
};

type Props = {
  currentWidthRef: RefObject<HTMLDivElement>;
  currentDate: Date | 'Today';
  timeAway: TimeAway;
  totalNumberOfDays: number;
  isFirst: boolean;
};

const AwayBar = ({
  currentDate,
  currentWidthRef,
  timeAway: {
    endDate,
    startDate,
    timeAwayStatus,
    timeAwayType: { name, color },
  },
  totalNumberOfDays,
  isFirst,
}: Props) => {
  const [awayBar, setAwayBar] = useState<AwayBarData | null>(null);
  const [percentagePerDay, setPercentagePerDay] = useState<number>(0);
  const [barHolderWidth, setBarHolderWidth] = useState<number>(0);
  const [hovered, setHovered] = useState<boolean>(false);
  const [cursorX, setCursorX] = useState<number>();

  const barRef = useRef<HTMLDivElement | null>(null);

  const timeAwayColor = getTimeAwayColorByStatus(color, timeAwayStatus);

  const barDimensions = useCallback(() => {
    const currentViewDays = getAllDatesInMonth(currentDate);
    const firstViewDate = currentViewDays[0];
    const lastViewDate = currentViewDays[currentViewDays.length - 1];

    if (!isDateAfter(firstViewDate, startDate) && !isDateBefore(lastViewDate, endDate)) {
      const days = getDatesDayDifference(startDate, firstViewDate);

      return roundedBar(
        `${(getDatesDayDifference(endDate, startDate) + 1) * percentagePerDay}px`,
        `${days * percentagePerDay}px`,
        timeAwayColor,
      );
    }

    if (isDateBefore(startDate, firstViewDate) && isDateAfter(endDate, lastViewDate)) {
      return wholeMonthBar(timeAwayColor);
    }

    if (isDateBefore(startDate, firstViewDate)) {
      return roundedRightBar(
        `${(getDatesDayDifference(endDate, firstViewDate) + 1) * percentagePerDay}px`,
        '0px',
        timeAwayColor,
      );
    }

    if (isDateAfter(endDate, lastViewDate)) {
      const days = getDatesDayDifference(startDate, firstViewDate);

      return roundedLeftBar(
        `${(getDatesDayDifference(lastViewDate, startDate) + 1) * percentagePerDay}px`,
        `${days * percentagePerDay}px`,
        timeAwayColor,
      );
    }

    return null;
  }, [timeAwayColor, currentDate, endDate, percentagePerDay, startDate]);

  const barDurationHandler = useCallback(() => {
    setAwayBar(barDimensions());
  }, [barDimensions]);

  const resizeBar = useCallback(() => {
    if (currentWidthRef?.current) {
      setBarHolderWidth(currentWidthRef.current.clientWidth);
    }
  }, [currentWidthRef]);

  useEffect(() => {
    if (!currentWidthRef?.current) return;

    setPercentagePerDay(currentWidthRef.current.clientWidth / totalNumberOfDays);
    barDurationHandler();
  }, [currentDate, barHolderWidth, barDurationHandler, currentWidthRef, totalNumberOfDays]);

  useEffect(() => {
    resizeBar();

    window.addEventListener('resize', resizeBar);

    return () => {
      window.removeEventListener('resize', resizeBar);
    };
  }, [resizeBar]);

  const handleOnHover = () => {
    setHovered(true);

    const element = document.getElementById('bar');

    element?.addEventListener(
      'mouseenter',
      (event: MouseEvent) => {
        setCursorX(event.offsetX);
      },
      false,
    );
  };

  const hoverPosition = () => {
    if (barRef?.current) {
      const x = (50 / 100) * barRef?.current?.offsetWidth;

      if (cursorX && cursorX > x) {
        return { right: `${barRef?.current?.offsetWidth - cursorX}px` };
      }

      return { left: `${cursorX}px` };
    }
  };

  return (
    awayBar && (
      <div
        id="bar"
        ref={barRef}
        style={awayBar}
        className={`${classes['c-away-user__away-bar']} ${
          isFirst ? classes['c-away-user__away-bar--first'] : ''
        }`}
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={handleOnHover}
      >
        <span className={classes['c-away-user__away-reason']}>
          <span className={classes['c-away-user__away-reason-text']}>
            {new Date(startDate).getMonth() === new Date(endDate).getMonth()
              ? `${name} : ${formatDate(startDate, 'MMM DD')} - ${formatDate(endDate, 'DD')}`
              : `${name} : ${formatDate(startDate, 'MMM DD')} - ${formatDate(endDate, 'MMM DD')}`}
          </span>
        </span>
        {hovered && (
          <div className={classes['c-away-user__hovered']} style={hoverPosition()}>
            {timeAwayStatus === TimeAwayStatus.PENDING && <span>Pending approval</span>}
            <span>{name}</span>
            {new Date(startDate).getMonth() === new Date(endDate).getMonth() ? (
              <span>
                {formatDate(startDate, 'MMM DD')} &rarr; {formatDate(endDate, 'DD')}
              </span>
            ) : (
              <span>
                {formatDate(startDate, 'MMM DD')} &rarr; {formatDate(endDate, 'MMM DD')}
              </span>
            )}
          </div>
        )}
      </div>
    )
  );
};

export default AwayBar;
