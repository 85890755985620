import { useState } from 'react';
import { Button } from 'components/core';
import PhaseUserListItem from './PhaseUserListItem';
import type { ProjectPhase, UserProjectPhase } from 'modules/projects/models';
import { getShortList } from 'utils';
import classes from './PhaseUserList.module.scss';
import { getPhaseUsers } from 'modules/projects/utils/projectPhase';

type Props = {
  phase: ProjectPhase;
  users?: UserProjectPhase[];
  isEditMode: boolean;
  onRemove: (userId: number) => void;
};

const PhaseUserList = ({ phase, users, isEditMode, onRemove }: Props) => {
  const [showAllUsers, setShowAllUsers] = useState<boolean>(false);

  const phaseUsers = getPhaseUsers(phase, users);
  const { data: shortListUsers, numberOfShortListedElements: numberOfHiddenUsers } = getShortList(
    phaseUsers || [],
    5,
  );

  const handleShowAllUsers = () => setShowAllUsers(true);

  if (!phaseUsers?.length) {
    return (
      <span className={classes['c-phase-user-list__empty']}>
        There are no members in this phase.
      </span>
    );
  }

  const usersToShow = showAllUsers ? phaseUsers : shortListUsers;

  return (
    <>
      {usersToShow?.map((user) => (
        <div key={user.id} className={classes['c-phase-user-list__list-item']}>
          <PhaseUserListItem user={user} isEditMode={isEditMode} onRemove={onRemove} />
        </div>
      ))}
      {numberOfHiddenUsers > 0 && !showAllUsers && (
        <div className={classes['c-phase-user-list__list-item']}>
          <Button
            variant="text"
            leftIcon={
              <span className={classes['c-phase-user-list__more-icon']}>
                +{numberOfHiddenUsers}
              </span>
            }
            onClick={handleShowAllUsers}
          >
            more {numberOfHiddenUsers === 1 ? 'person' : 'people'}
          </Button>
        </div>
      )}
    </>
  );
};

export default PhaseUserList;
