import { ReactComponent as CheckmarkIcon } from 'assets/CheckMark.svg';
import classes from './Checkmark.module.scss';

type Props = {
  isChecked: boolean;
  isClickable?: boolean;
};

export const Checkmark = ({ isChecked, isClickable }: Props) => {
  return (
    <CheckmarkIcon
      className={`${classes['c-checkmark']} ${isChecked ? classes['c-checkmark--checked'] : ''} ${
        isClickable ? classes['c-checkmark--clickable'] : ''
      }`}
    />
  );
};
