import { api } from 'lib';
import type { NewTimeAway, TimeAwayApproveData, TimeAway } from '../models/TimeAway.model';
import type { TimeAwayType } from 'modules/shared/model';
import { getOrganizationId, getUserId } from 'state/utils';

export const getAllTimeAwayTypes = async () => {
  return api.get<TimeAwayType[]>(`/organization/${getOrganizationId()}/time-away-type`, {
    params: { enabled: true },
  });
};

export const bookTimeAway = async (timeAwayTypeId: number, timeAway: NewTimeAway) => {
  return api.post<TimeAway>(
    `/organization/${getOrganizationId()}/user/${getUserId()}/time-away-type/${timeAwayTypeId}/time-away`,
    timeAway,
  );
};

export const getAllAvailableApprovers = async () => {
  return api.get<TimeAwayApproveData>(
    `/organization/${getOrganizationId()}/user/${getUserId()}/is-approver-available`,
  );
};
