import { useState } from 'react';
import classes from './ProgressBar.module.scss';

type Props = {
  color: string;
  completed: string;
  barHeight: number;
  text?: string;
  tooltipText?: string;
};

export const ProgressBar = ({ color, completed, barHeight, text, tooltipText }: Props) => {
  const [tooltip, setTooltip] = useState<boolean>(false);

  const handleOpenTooltip = () => setTooltip(true);
  const handleCloseTooltip = () => setTooltip(false);

  const containerStyles = {
    height: barHeight,
    width: '100%',
    backgroundColor: '#DBDBDB',
    borderRadius: 50,
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: color,
    borderRadius: 'inherit',
    transition: 'width 1s ease-in-out',
  };

  const labelStyles = {
    padding: 1,
    color: 'white',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    paddingLeft: 15,
    width: '200%',
    height: '100%',
  };

  return (
    <div
      style={containerStyles}
      className={classes['progress-bar']}
      onMouseEnter={handleOpenTooltip}
      onMouseLeave={handleCloseTooltip}
    >
      <div style={fillerStyles}>{text && <div style={labelStyles}>{text}</div>}</div>
      {tooltip && tooltipText && <div className={classes.tooltip}>{tooltipText}</div>}
    </div>
  );
};
