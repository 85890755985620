import { OAuthProvider } from 'modules/auth';
import OAuthRedirect from 'modules/auth/components/OAuth/OAuthRedirect/OAuthRedirect';
import { OAuthProvider as OAuthProviderType } from 'modules/auth/config';

type Props = {
  provider: OAuthProviderType;
};

export const OAuthRedirectPage = ({ provider }: Props) => {
  return (
    <OAuthProvider provider={provider}>
      <OAuthRedirect provider={provider} />
    </OAuthProvider>
  );
};
