import { useCallback, useEffect, useState } from 'react';
import classes from './UserWorkflowTask.module.scss';
import { ReactComponent as DragSvg } from 'assets/DragIcon.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { type TaskInstance } from 'modules/workflows/model/workflowInstance.model';
import { updateTaskInstance } from 'modules/workflows/api/workflowInstances.api';
import { updateSectionTask } from 'modules/workflows/redux/workflowInstanceSlice';
import { TaskStatus } from 'config/constants';
import { Avatar, IconButton, Checkmark, toast } from 'components/core';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import { formatDateForDisplay } from 'utils/dates';

type Props = {
  handleOpenTaskDetails: (taskId: number, sectionId: number) => void;
  task: TaskInstance;
  workflowInstanceId: number;
  sectionId: number;
  isViewOnly?: boolean;
};

const UserWorkflowTask = ({
  handleOpenTaskDetails,
  task: { id, name, dueDate, assignedUserDto, taskStatus },
  workflowInstanceId,
  sectionId,
  task,
  isViewOnly,
}: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(taskStatus === TaskStatus.COMPLETED);

  const dispatch = useAppDispatch();
  const { workflowId } = useParams();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    data: task,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  };

  const handleCompleteTask = useCallback(async () => {
    if (!workflowId) return;

    const updatedCompleteness = !checked ? TaskStatus.COMPLETED : TaskStatus.TO_DO;

    try {
      await updateTaskInstance(+workflowId, workflowInstanceId, {
        ...task,
        taskStatus: updatedCompleteness,
      });
      dispatch(updateSectionTask({ ...task, taskStatus: updatedCompleteness }));
      toast('success', 'Task status updated successfully.');
      setChecked((prevState) => !prevState);
    } catch {
      toast('error', 'Something went wrong while updating task completeness.');
    }
  }, [checked, dispatch, task, workflowId, workflowInstanceId]);

  useEffect(() => {
    setChecked(taskStatus === TaskStatus.COMPLETED);
  }, [taskStatus]);

  return (
    <div
      className={classes['c-user-workflow-task']}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={style}
      ref={setNodeRef}
    >
      <span className={classes['c-user-workflow-task__main']}>
        {!isViewOnly && (
          <DragSvg
            {...attributes}
            {...listeners}
            className={`${classes['c-user-workflow-task__icon-grab']} ${
              hovered ? `${classes['c-user-workflow-task__icon-grab--hovered']}` : ''
            }`}
          />
        )}
        <IconButton
          icon={<Checkmark isChecked={checked} isClickable={!isViewOnly} />}
          aria-label="Complete task"
          onClick={!isViewOnly ? handleCompleteTask : undefined}
        />
        <span className={classes['c-user-workflow-task__name']}>
          <span>{name}</span>
        </span>
      </span>
      <span className={classes['c-user-workflow-task__date']}>
        {dueDate ? `Due date - ${formatDateForDisplay(dueDate)}` : 'No due date'}
      </span>
      <span className={classes['c-user-workflow-task__assignee']}>
        <Avatar
          imageUrl={assignedUserDto?.profilePhotoPath || ''}
          title={
            assignedUserDto
              ? `${assignedUserDto.firstName} ${assignedUserDto.lastName}`
              : 'No assigne'
          }
          size="extra-small"
          horizontal
        />
      </span>

      <div className={classes['c-user-workflow-task__details']}>
        <span
          className={classes['c-user-workflow-task__details-text']}
          onClick={() => handleOpenTaskDetails(id, sectionId)}
        >
          Details
        </span>
      </div>
    </div>
  );
};

export default UserWorkflowTask;
