import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import classes from './FilterSection.module.scss';
import { useCallback } from 'react';
import { FilterCategory } from 'config/constants';
import { selectFilters } from '../redux/filterSlice';
import { replaceDashWithCommaSpace } from 'utils/utilFunctions';

type Props = {
  title: string;
  filters?: string[];
  filterCategory: FilterCategory;
  handleOnClick: (filterValue: string, index: number) => void;
};

export const FilterSection = ({ title, filters, filterCategory, handleOnClick }: Props) => {
  const { appliedFilters } = useAppSelector(selectFilters);

  const isSelected = useCallback(
    (item: string): boolean => {
      if (filterCategory === FilterCategory.SORT)
        return appliedFilters.sortBy.id === filters?.indexOf(item);

      return !!appliedFilters[filterCategory].find((filter) => filter === item);
    },
    [appliedFilters, filterCategory, filters],
  );

  const renderFilterItem = useCallback(
    (item: string, index: number) => {
      if (
        filterCategory === FilterCategory.SORT &&
        appliedFilters.sortBy.filterValue &&
        appliedFilters.sortBy.id === index
      )
        return appliedFilters.sortBy.filterValue;
      if (filterCategory === FilterCategory.LOCATION) {
        return replaceDashWithCommaSpace(item);
      }

      return item;
    },
    [appliedFilters.sortBy.filterValue, appliedFilters.sortBy.id, filterCategory],
  );

  return (
    <div className={classes['c-filter-section']}>
      <div className={classes['c-filter-section__title']}>{title}</div>
      <div className={classes['c-filter-section__filters-container']}>
        {/* We get one null value from BE for departments, that's why we need to filter it out */}
        {filters?.filter(Boolean)?.map((item, index) => (
          <button
            key={index}
            className={`${classes['c-filter-section__item']} ${
              isSelected(item)
                ? classes['c-filter-section__item--selected']
                : classes['c-filter-section__item--default']
            }
              `}
            onClick={() => handleOnClick(item, index)}
          >
            {!!renderFilterItem && renderFilterItem(item, index)}
          </button>
        ))}
      </div>
    </div>
  );
};
