import type { Dispatch, SetStateAction } from 'react';
import classes from './DocumentUploadModal.module.scss';
import { type UserDocument as UserDocumentModel } from 'modules/profile/models/profileModels';
import { Input } from '../../Input';

type Props = {
  inputs: UserDocumentModel;
  setInputs?: Dispatch<SetStateAction<UserDocumentModel>>;
  isFormSubmitted?: boolean;
};

export const UserDocumentModal = ({ inputs, setInputs, isFormSubmitted }: Props) => {
  const handleInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputs?.((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div className={classes['c-document-upload-modal']}>
      <Input
        id="name"
        name="name"
        label="Name"
        value={inputs.name}
        handleOnChange={(event) => {
          handleInputs(event);
        }}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
      <Input
        id="type"
        name="type"
        label="Type"
        value={inputs.type}
        handleOnChange={(event) => {
          handleInputs(event);
        }}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
    </div>
  );
};
