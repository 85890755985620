import { useEffect, useState, type ChangeEvent } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { ReactComponent as DeleteIcon } from 'assets/DeleteIcon.svg';
import { ReactComponent as AddIcon } from 'assets/PersonAddIconOutline.svg';
import { ReactComponent as DragSvg } from 'assets/DragIcon.svg';
import { Input, DateRangePicker, PopUpModal, IconButton } from 'components/core';
import { AddPeopleModal } from 'modules/shared/components';

import classes from './Phase.module.scss';

import type { ProjectPhase, UserProjectPhase } from 'modules/projects/models/phase.model';
import { formatDateForDisplay, formatDateForInput } from 'utils/dates';
import { DateRangeType } from 'components/core/DateRangePicker/models/dateRangePickerModel';
import PhaseUserList from '../PhaseUserList/PhaseUserList';

type Props = {
  phase: ProjectPhase;
  users?: UserProjectPhase[];
  isEditMode: boolean;
  onPhaseChange: (updatedPhase: ProjectPhase) => void;
  handlePhaseMembers: (
    phaseId: string,
    userIds: number[],
    userProjectPhase?: UserProjectPhase[],
  ) => void;
  handleRemovePhase: (phaseId: string) => void;
};

const Phase = ({
  phase,
  users,
  isEditMode,
  onPhaseChange,
  handlePhaseMembers,
  handleRemovePhase,
}: Props) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRangeType[]>([
    {
      startDate: new Date(phase.startDate),
      endDate: new Date(phase.dueDate),
      key: 'selection',
    },
  ]);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: phase.id,
    data: phase,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  };

  const handleAddMembers = (userIds: number[], selectedUsers: UserProjectPhase[]) => {
    const newUserIds = phase.userIds ? [...phase.userIds, ...userIds] : [...userIds];
    const newUser = phase.userProjectPhases
      ? [...phase.userProjectPhases, ...selectedUsers]
      : [...selectedUsers];
    handlePhaseMembers(String(phase.id), newUserIds, newUser);
    setIsModalOpen(false);
  };

  const handleRemoveMember = (id: number) => {
    const newUserIds = phase.userIds?.filter((userId) => userId !== id);
    const removedMember = phase.userProjectPhases?.filter((user) => user.userId !== id);
    handlePhaseMembers(String(phase.id), newUserIds, removedMember);
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    onPhaseChange({ ...phase, [name]: value });
  };

  useEffect(() => {
    if (isEditMode) {
      onPhaseChange({
        ...phase,
        startDate: formatDateForInput(dateRange[0].startDate ?? ''),
        dueDate: formatDateForInput(dateRange[0].endDate ?? ''),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  return (
    <div className={classes['c-phase']} style={style} ref={setNodeRef}>
      <div className={classes['c-phase__header']}>
        <div className={classes['c-phase__header-left']}>
          {isEditMode && (
            <IconButton
              aria-label="Drag phase"
              icon={<DragSvg {...attributes} {...listeners} className={classes['c-phase__icon']} />}
            />
          )}
          <div className={classes['c-phase__input-container']}>
            <Input
              name="name"
              id="phaseName"
              placeholder="enter phase name"
              value={phase.name}
              readOnly={!isEditMode}
              handleOnChange={handleInput}
            />
          </div>
        </div>
        {isEditMode && (
          <IconButton
            aria-label="Delete phase"
            icon={<DeleteIcon />}
            tooltipContent="Delete"
            tooltipPosition="bottom"
            iconButtonType="delete"
            onClick={() => handleRemovePhase(String(phase.id))}
          />
        )}
      </div>
      <div
        className={`${classes['c-phase__input-container']} ${classes['c-phase__input-container--description']}`}
      >
        <textarea
          className={classes['c-phase__description']}
          name="description"
          id="phaseDescription"
          placeholder={isEditMode ? 'enter phase description' : 'This phase has no description.'}
          value={phase?.description ?? ''}
          readOnly={!isEditMode}
          onChange={handleInput}
        />
      </div>
      <div
        className={`${classes['c-phase__input-container']} ${classes['c-phase__input-container--date']}`}
      >
        {isEditMode ? (
          <DateRangePicker
            dateRange={dateRange}
            openCalendar={isCalendarOpen}
            rangeColor="#2495ff"
            orientation="horizontal"
            setDateRange={setDateRange}
            onClick={setIsCalendarOpen}
          />
        ) : (
          <>
            <div className={classes['c-phase__date-container']}>
              <label className={classes['c-phase__date-label']}>Start date</label>
              <span className={classes['c-phase__date']}>
                {formatDateForDisplay(phase.startDate ?? '')}
              </span>
            </div>
            <div className={classes['c-phase__date-container']}>
              <label className={classes['c-phase__date-label']}>End date</label>
              <span className={classes['c-phase__date']}>
                {formatDateForDisplay(phase.dueDate ?? '')}
              </span>
            </div>
          </>
        )}
      </div>
      <div className={classes['c-phase__list-header']}>
        People in this phase
        {isEditMode && (
          <IconButton
            aria-label="Add people to phase"
            icon={<AddIcon />}
            onClick={() => setIsModalOpen(true)}
            tooltipContent="Add"
            tooltipPosition="bottom"
          />
        )}
      </div>
      <div className={classes['c-phase__members-list']}>
        <PhaseUserList
          phase={phase}
          isEditMode={isEditMode}
          users={users}
          onRemove={handleRemoveMember}
        />
      </div>
      <PopUpModal
        title={`Add people to ${phase.name}`}
        opened={isModalOpen}
        width={50}
        closeModal={() => setIsModalOpen(false)}
      >
        <AddPeopleModal
          excludedUserIds={
            users
              ?.filter((user) => phase.userIds?.includes(user.userId!))
              .map((user) => user.userId!) || []
          }
          buttonContent="Add to phase"
          onClick={handleAddMembers}
        />
      </PopUpModal>
    </div>
  );
};

export default Phase;
