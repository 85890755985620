import { useState, KeyboardEvent } from 'react';
import { Input, Button } from 'components/core';
import classes from './CreateProjectModal.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { setIsEditing, setProject } from 'modules/projects/redux/projectSlice';
import type { ProjectPhase } from 'modules/projects/models';
import { formatDateForInput } from 'utils/dates';

const initialPhase: ProjectPhase = {
  id: `${crypto.randomUUID()}-temp`,
  name: 'Initial Phase',
  description: '',
  startDate: formatDateForInput(new Date()),
  dueDate: formatDateForInput(new Date()),
  phaseOrder: 0,
  userIds: [],
};

type Props = {
  setIsModalOpen: (value: boolean) => void;
};

const CreateProjectModal = ({ setIsModalOpen }: Props) => {
  const [error, setError] = useState<string>('');
  const [name, setName] = useState<string>('');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleAddProject = () => {
    if (!name.match(/^.{1,}$/)) {
      setError('Please enter project name');
      return;
    }

    dispatch(
      setProject({
        name: name || '',
        projectPhases: [initialPhase],
        archived: false,
      }),
    );

    navigate(`new-project/${name}`);
    dispatch(setIsEditing(true));
    setIsModalOpen(false);
    setName('');
  };

  const handleEnterKey = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleAddProject();
  };

  return (
    <div className={classes['c-create-project-modal']}>
      <Input
        size="medium"
        name="project-name"
        id="project-name"
        label="Enter new project name"
        value={name}
        setValue={setName}
        autoFocus
        handleOnKeyDown={handleEnterKey}
      />
      <div className={classes['c-create-project-modal__error-message']}>{error}</div>
      <div className={classes['c-create-project-modal__button-container']}>
        <Button size="medium" onClick={handleAddProject}>
          Continue editing
        </Button>
      </div>
    </div>
  );
};

export default CreateProjectModal;
