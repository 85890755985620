export const months: Record<string, number> = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const tasksLabel = 'My tasks';
export const timeAwaysLabel = 'This week off duty';
export const timeAwayRequestsLabel = 'Pending approvals';
export const aboutLabel = 'About';

export enum UserType {
  ALL_USERS = 'ALL_USERS',
  ACTIVE_MEMBER = 'ACTIVE_MEMBER',
  NEW_HIRE = 'NEW_HIRE',
  OFFBOARDED = 'OFFBOARDED',
}

export enum UserGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum RequestState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum Role {
  USER = 'USER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SUPER_ADMINISTRATOR = 'SUPER_ADMINISTRATOR',
}

export enum FetchingUserType {
  CURRENT_USER = 'CURRENT_USER',
  PROFILE_USER = 'PROFILE_USER',
}

export enum FilterCategory {
  WORKFLOW = 'workflowNames',
  DEPARTMENT = 'departmentNames',
  LOCATION = 'locationNames',
  PROJECT = 'projectNames',
  SORT = 'sortBy',
}

export enum TaskStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export const regularWorkingMinutes = 8 * 60;
