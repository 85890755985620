import { api } from 'lib';
import { type Dispatch } from '@reduxjs/toolkit';
import { setUser } from '../../profile/redux/userSlice';
import { BasicUser, setCurrentUser } from 'components/layout/Toolbar/redux/toolbarSlice';
import { User } from 'modules/profile/models/profileModels';
import { getOrganizationId } from 'state/utils';

export const getUserByOrganization = (id: string) => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      return api.get<User>(`/organization/${getOrganizationId()}/user/${id}`);
    };

      const user = await fetchData();
      dispatch(setUser(user.data));
    
  };
};

export const getUser = () => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      return api.get<BasicUser>(`/user`);
    };

    const user = await fetchData();
      dispatch(setCurrentUser(user.data));
  };
};
