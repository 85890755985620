import classes from './CropImage.module.scss';
import ReactCrop, { centerCrop, convertToPixelCrop, Crop, makeAspectCrop } from 'react-image-crop';

import { SyntheticEvent, useCallback, useRef, useState } from 'react';
import setCanvasPreview from './utils/setCanvasPreview';

type Props = {
  imageUrl: string;
  setCroppedPicture: React.Dispatch<React.SetStateAction<File | null>>;
};

const CropImage = ({ imageUrl, setCroppedPicture }: Props) => {
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = event.currentTarget;
    const crop = makeAspectCrop(
      {
        unit: '%',
        width: 50,
      },
      1,
      width,
      height,
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleCropChange = useCallback(
    async (pixelCrop: Crop, percentCrop: Crop) => {
      setCrop(percentCrop);
      if (imgRef.current && previewCanvasRef.current && pixelCrop.width && pixelCrop.height) {
        const pixelCrop = convertToPixelCrop(
          percentCrop,
          imgRef.current.width,
          imgRef.current.height,
        );

        setCanvasPreview(imgRef.current, previewCanvasRef.current, pixelCrop, setCroppedPicture);
      }
    },
    [imgRef, previewCanvasRef, setCroppedPicture],
  );

  return (
    <>
      <ReactCrop
        crop={crop}
        circularCrop
        keepSelection
        aspect={1}
        minWidth={150}
        onChange={handleCropChange}
      >
        <img
          ref={imgRef}
          className={classes['c-crop-image__preview']}
          src={imageUrl}
          alt="profile-picture"
          onLoad={onImageLoad}
        />
      </ReactCrop>
      {crop && <canvas ref={previewCanvasRef} className={classes['c-crop-image__canvas']} />}
    </>
  );
};

export default CropImage;
