import classes from './ColorLegend.module.scss';
import { selectColorLegend, toggleColorLegendItem } from './redux/colorLegendSlice';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { FilterButton } from '../Filter/FilterButton/FilterButton';
import type { ColorLegendItem } from './models';

export const ColorLegend = () => {
  const { colorLegendItems } = useAppSelector(selectColorLegend);

  const dispatch = useAppDispatch();

  const handleOnClick = (legendItem: ColorLegendItem) => {
    dispatch(toggleColorLegendItem(legendItem));
  };

  return (
    <div className={classes['c-color-legend']}>
      {colorLegendItems.map((legendItem) => (
        <FilterButton
          key={legendItem.label}
          label={legendItem.label}
          selected={legendItem.isSelected}
          onClick={() => handleOnClick(legendItem)}
          color={legendItem.color}
        />
      ))}
    </div>
  );
};
