import { useCallback } from 'react';
import Section from '../Section/Section';
import SectionItem from '../Section/SectionItem';

const Billing = () => {
  const handleOnCancel = useCallback(() => {
    alert('Needs to be implemented');
  }, []);

  const handleOnSave = useCallback(() => {
    alert('Needs to be implemented');
  }, []);

  return (
    <Section
      title="Manage account and subscription"
      onCancel={handleOnCancel}
      onSave={handleOnSave}
    >
      <SectionItem
        itemContent="Upgrade your plan"
        onCancel={handleOnCancel}
        onSave={handleOnSave}
      />
      <SectionItem
        itemContent="Delete workspace and cancel subscription"
        onCancel={handleOnCancel}
        onSave={handleOnSave}
      />
      <SectionItem
        itemContent="Contact support: team@asemble.io"
        onCancel={handleOnCancel}
        onSave={handleOnSave}
      />
    </Section>
  );
};

export default Billing;
