import { Button } from 'components/core';
import classes from './../SignUp.module.scss';
import { decodeToken } from 'utils/auth/tokenService';
import { sendRegistrationLink } from 'modules/auth/api/auth.api';
import { useState } from 'react';
import { toast } from 'react-toastify';
import FormLink from '../../FormLink/FormLink';

type Props = {
  token: string;
};

const ResendLink = ({ token }: Props) => {
  const [showInfoMessage, setShowInfoMessage] = useState<boolean>(true);

  const handleResetToken = async () => {
    try {
      const { email } = decodeToken(token);

      if (email) {
        await sendRegistrationLink(email);
        setShowInfoMessage(false);
      }
    } catch {
      toast.error('Something went wrong while saving. Try again.');
    }
  };

  return (
    <div className={`${classes['c-signup']} ${classes['c-signup--xl']}`}>
      {showInfoMessage ? (
        <>
          <span className={classes['c-signup__info']}>
            Looks like the verification link has expired. Not to worry, we can send the link again.
          </span>
          <Button type="primary" size="large" onClick={handleResetToken}>
            Resend verification link
          </Button>
        </>
      ) : (
        <>
          <span className={classes['c-signup__info']}>
            We sent new verification link, check your email.
          </span>
          <FormLink url="/" text="Go to login page" />
        </>
      )}
    </div>
  );
};

export default ResendLink;
