import { useState } from 'react';
import classes from '../../../styles/Section.module.scss';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { ReactComponent as CalendarIcon } from 'assets/CalendarIcon.svg';
import { ReactComponent as EmploymentIcon } from 'assets/BriefcaseIcon.svg';
import { ReactComponent as ClockIcon } from 'assets/Clock.svg';
import { ReactComponent as DocumentIcon } from 'assets/Documents.svg';
import { ReactComponent as NoteIcon } from 'assets/TextSnippet.svg';
import SectionInfoReadOnly from '../../SectionInfoReadOnly/SectionInfoReadOnly';
import { deleteOffboarding } from 'modules/profile/api/profile.api';
import toast from 'utils/notifications/CustomToast';
import { useNavigate, useParams } from 'react-router-dom';
import { selectUser, updateOffboarding, updateUserStatus } from 'modules/profile/redux/userSlice';
import { ActionConfirmationModal, Button } from 'components/core';
import { useToolbarTitle } from 'hooks';

const Offboarding = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { offboardingDto } = useAppSelector(selectUser);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useToolbarTitle({ title: ['Profile', 'Offboarding'] });

  const handleOffboardingCancel = async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      await deleteOffboarding(offboardingDto.id, id);
      dispatch(updateUserStatus('ACTIVE_MEMBER'));
      dispatch(updateOffboarding(null));
      navigate(`/user/${id}/about`);
      setIsDialogOpen(false);
      toast.success('You have successfully canceled offboarding process for this user');
    } catch {
      toast.error('Something went wrong while cancelling offboarding process. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <h2 className={classes['c-section__title']}>Offboarding</h2>
        <Button variant="outlined" onClick={() => setIsDialogOpen(true)}>
          Cancel offboarding
        </Button>
      </div>
      <div className={classes['c-section__content--columns']}>
        <div className={classes['c-section__content-column']}>
          <SectionInfoReadOnly
            icon={<EmploymentIcon />}
            name="Employment ends at"
            data={offboardingDto.employmentEndDate}
          />
          <SectionInfoReadOnly
            icon={<CalendarIcon />}
            name="Last working day"
            data={offboardingDto.lastWorkingDay}
          />
          {offboardingDto.note && (
            <SectionInfoReadOnly
              icon={<NoteIcon />}
              name="Note"
              pascalCaseState
              data={offboardingDto.note}
            />
          )}
        </div>
        <div className={classes['c-section__content-column']}>
          <SectionInfoReadOnly
            icon={<ClockIcon />}
            name="Remaining working days"
            data={String(offboardingDto.remainingWorkingDays)}
          />
          <SectionInfoReadOnly
            icon={<DocumentIcon />}
            name="Reason"
            pascalCaseState
            data={offboardingDto.reason}
          />
        </div>
      </div>
      <ActionConfirmationModal
        message="you want to cancel offboarding process for this member?"
        isModalOpen={isDialogOpen}
        closeModal={() => setIsDialogOpen(false)}
        isLoading={isLoading}
        handleYes={handleOffboardingCancel}
        handleNo={() => setIsDialogOpen(false)}
      />
    </div>
  );
};

export default Offboarding;
