import { formatDateForInput } from 'utils';
import { z } from 'zod';

export const addNewHireMemberValidationSchema = z.object({
  firstName: z.string().refine((value) => value !== '', { message: 'Please enter first name.' }),
  lastName: z.string().refine((value) => value !== '', { message: 'Please enter last name.' }),
  personalEmail: z.string().email({ message: 'Please enter a valid email address.' }),
  jobTitle: z.string().refine((value) => value !== '', { message: 'Please select job title.' }),
  startDate: z.string().refine((value) => value !== '', { message: 'Please select start date.' }),
});

export type AddNewHireMemberValidationSchema = {
  firstName: string;
  lastName: string;
  personalEmail: string;
  jobTitle: string;
  startDate: string;
};

export const addNewHireMemberDefaultValues: AddNewHireMemberValidationSchema = {
  firstName: '',
  lastName: '',
  personalEmail: '',
  jobTitle: '',
  startDate: formatDateForInput(new Date()),
};
