import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import classes from './ProjectsContainer.module.scss';
import { getProjectById, getProjectsByStatus } from 'modules/projects/redux/projectActions';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import CreateProjectModal from 'modules/projects/components/CreateProjectModal/CreateProjectModal';
import type { Project, ProjectPhase } from 'modules/projects/models';
import { RequestState } from 'config/constants';
import ProjectCard from 'modules/projects/components/ProjectCard/ProjectCard';
import { setIsEditing } from 'modules/projects/redux/projectSlice';
import {
  HighlightedText,
  Empty,
  AddButton,
  Pagination,
  PopUpModal,
  Status,
  Container,
  FilterButton,
} from 'components/core';
import {
  calculatePhaseProgress,
  getEstimatedTimeInfo,
  getNumberOfFinishedPhasesInfo,
  getProjectCardInfo,
  sortProjectPhases,
} from '../../../modules/projects/utils/projectPhase';
import ProjectProgress from '../../../modules/projects/components/ProjectProgress/ProjectProgress';
import { ReactComponent as FileIcon } from 'assets/Projects.svg';
import projectsCover from 'assets/ProjectsCover.svg';
import { useToolbarTitle } from 'hooks';
const ITEMS_PER_PAGE = 11;

const statusFilters = ['Active', 'Archived'] as const;
type StatusFilter = (typeof statusFilters)[number];

const ProjectsContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<StatusFilter>('Active');
  const [paginatedProjects, setPaginatedProjects] = useState<Project[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [currentItems, setCurrentItems] = useState<{
    itemOffset: number;
    endOffset: number;
  }>({
    itemOffset: 0,
    endOffset: ITEMS_PER_PAGE,
  });

  const { projects, loading } = useAppSelector((state) => state.projects);
  const { inputFieldValue } = useAppSelector((state) => state.toolbar);

  const { pathname } = useLocation();
  const { id } = useParams();

  useToolbarTitle({ title: ['Projects'] });

  const dispatch = useAppDispatch();

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleSelectedStatusFilter = (filter: StatusFilter) => setSelectedFilter(filter);

  const getProjects = useCallback(() => {
    if (selectedFilter === 'Archived') {
      dispatch(getProjectsByStatus({ filter: 'archived' }));
      return;
    }

    dispatch(getProjectsByStatus());
  }, [dispatch, selectedFilter]);

  const getPhasesProgress = (projectPhases: ProjectPhase[]) => {
    const sortedProjectPhases = sortProjectPhases(projectPhases);

    return sortedProjectPhases.map((phase) => (
      <ProjectProgress key={phase.id} progress={calculatePhaseProgress(phase)} />
    ));
  };

  useEffect(() => {
    const newOffset = currentPage * ITEMS_PER_PAGE;
    const endOffset = newOffset + ITEMS_PER_PAGE;
    setCurrentItems({ itemOffset: newOffset, endOffset });
  }, [currentPage]);

  useEffect(() => {
    let searchedProjects = projects;

    if (!Array.isArray(searchedProjects)) return;

    if (inputFieldValue) {
      searchedProjects = projects.filter((project) =>
        project.name.toLowerCase().includes(inputFieldValue.toLowerCase()),
      );
    }

    setTotalElements(searchedProjects.length);
    setPaginatedProjects(searchedProjects.slice(currentItems.itemOffset, currentItems.endOffset));
  }, [currentItems, projects, inputFieldValue]);

  useEffect(() => {
    if (pathname === '/projects') {
      getProjects();
    }

    if (!id) return;
    dispatch(getProjectById(id));
  }, [dispatch, getProjects, id, pathname]);

  useEffect(() => {
    const totalPages = Math.ceil(totalElements / ITEMS_PER_PAGE);
    setTotalPages(totalPages);
  }, [totalElements]);

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedFilter, inputFieldValue]);

  return (
    <div className={classes['c-projects-container']}>
      {pathname === '/projects' && (
        <Container gap="xs">
          {statusFilters.map((filter) => (
            <FilterButton
              key={filter}
              label={filter}
              selected={selectedFilter === filter}
              onClick={() => handleSelectedStatusFilter(filter)}
            />
          ))}
        </Container>
      )}
      <Status isLoading={loading === RequestState.PENDING}>
        {pathname === '/projects' ? (
          <>
            {projects.length ? (
              <div className={classes['c-projects-container__cards']}>
                <div className={classes['c-projects-container__card']}>
                  <AddButton
                    label="Add new project"
                    icon={<FileIcon className={classes['c-projects-container__icon']} />}
                    onClick={handleModalOpen}
                  />
                </div>
                {paginatedProjects.map(
                  ({ id, name, numberOfPeople, numberOfPhases, projectPhases, users }) => (
                    <div key={id} className={classes['c-projects-container__card']}>
                      <ProjectCard
                        link={`/projects/${id}`}
                        title={<HighlightedText text={name} highlight={inputFieldValue} />}
                        estimatedTime={getEstimatedTimeInfo(projectPhases)}
                        info={getProjectCardInfo(numberOfPeople ?? 0, numberOfPhases ?? 0)}
                        progress={getPhasesProgress(projectPhases)}
                        completeness={getNumberOfFinishedPhasesInfo(projectPhases)}
                        users={users ?? []}
                        onEdit={() => dispatch(setIsEditing(true))}
                      />
                    </div>
                  ),
                )}
              </div>
            ) : (
              <Empty
                title="Your team's projects in one place"
                info="Stay in the loop with current projects your team is working on."
                btnText="Create project"
                img={projectsCover}
                handleOnClick={handleModalOpen}
              />
            )}
          </>
        ) : (
          <Outlet />
        )}
        <div className={classes['c-projects-container__pagination-wrap']}>
          {pathname === '/projects' && projects?.length > ITEMS_PER_PAGE && (
            <Pagination
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              first={currentPage === 0}
              last={currentPage === totalPages - 1}
            />
          )}
        </div>
        <PopUpModal
          width={30}
          title="New project name"
          opened={isModalOpen}
          closeModal={handleModalClose}
        >
          <CreateProjectModal setIsModalOpen={handleModalClose} />
        </PopUpModal>
      </Status>
    </div>
  );
};

export default ProjectsContainer;
