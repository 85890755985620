import { useState } from 'react';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { useToolbarTitle, useUserRole } from 'hooks';
import { RequestState } from 'config/constants';
import { Status } from 'components/core';
import classes from './TimesheetsOverview.module.scss';
import { selectTimesheets } from 'modules/timesheet/redux/timesheetsSlice';
import CreateTimesheetModal from '../CreateTimesheetModal/CreateTimesheetModal';
import TimesheetsListItem from './TimesheetsListItem/TimesheetsListItem';
import { selectAuthUser } from 'modules/auth/redux/authSlice';
import { Outlet, useParams } from 'react-router-dom';
import TimesheetsOverviewHeader from './TimesheetsOverviewHeader/TimesheetsOverviewHeader';

const TimesheetsOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { user } = useAppSelector(selectAuthUser);
  const { timesheets, loading } = useAppSelector(selectTimesheets);

  const { isAdminOrSuperAdmin } = useUserRole(user?.role);
  const { id } = useParams();

  useToolbarTitle({ title: ['Timesheets'] });

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  if (id) return <Outlet />;

  return (
    <div className={classes['c-timesheets-overview']}>
      <TimesheetsOverviewHeader
        isAdminOrSuperAdmin={isAdminOrSuperAdmin}
        handleOpenModal={handleOpenModal}
      />
      <div className={classes['c-timesheets-overview__list-container']}>
        <Status isLoading={loading === RequestState.PENDING}>
          <div className={classes['c-timesheets-overview__list-header']}>
            <span></span>
            {isAdminOrSuperAdmin && (
              <>
                <span>User</span>
              </>
            )}
            <span>Month</span>
            <span>Year</span>
            <span>Projects</span>
            <span>Total regular hours</span>
            <span>Total overtime hours</span>
            <span>Project hours</span>
          </div>
          <ul className={classes['c-timesheets-overview__list']}>
            {timesheets.length ? (
              timesheets.map((timesheet) => (
                <TimesheetsListItem timesheet={timesheet} key={timesheet.id} />
              ))
            ) : (
              <span className={classes['c-timesheets-overview__missing-data-message']}>
                There are no timesheets to display.
              </span>
            )}
          </ul>
        </Status>
      </div>
      <CreateTimesheetModal isOpen={isModalOpen} handleCloseModal={handleCloseModal} />
    </div>
  );
};

export default TimesheetsOverview;
