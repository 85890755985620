import { CustomFieldWithSingleChoiceOptions } from 'modules/profile/models/profileModels';
import { SectionInfoEdit } from 'modules/shared/components';

import { ReactComponent as FileIcon } from 'assets/File.svg';
import { isSingleChoice } from './utils';
import { CustomFieldValueType } from 'modules/settings/models/settingsModels';

type Props = {
  customField: CustomFieldWithSingleChoiceOptions;
  readonly: boolean;
  selectedOption?: string;
  onChange: (value: string, customFieldId: number) => void;
};

const customFieldType: Record<CustomFieldValueType, string> = {
  NUMBER: 'number',
  TEXT_FIELD: 'text',
  SINGLE_CHOICE: '',
};

export const CustomFieldItem = ({ customField, readonly, selectedOption, onChange }: Props) => {
  const { customFieldId, customFieldName, valueType, singleChoiceString } = customField;

  return (
    <SectionInfoEdit
      icon={<FileIcon />}
      inputType={customFieldType[valueType]}
      key={customFieldId}
      name={customFieldName}
      data={selectedOption ?? ''}
      readOnly={readonly}
      showAsDropdown={isSingleChoice(valueType)}
      dropdownOptions={singleChoiceString}
      dropdownDisplayedOptions={singleChoiceString}
      selectedOption={selectedOption}
      handleDropdownChange={(option) => onChange(option, customFieldId)}
      setValue={(value) => onChange(value, customFieldId)}
    />
  );
};
