import { useCallback, useState } from 'react';
import SectionItem from 'modules/settings/components/Section/SectionItem';
import CalendarForm from '../CalendarForm/CalendarForm';
import { isDataValid } from 'utils/validation';
import { calendarSchema } from '../utils/calendarSchema';
import type { OrganizationCalendar } from 'modules/shared/model';
import { ActionConfirmationModal } from 'components/core';

type Props = {
  item: OrganizationCalendar;
  isLoading: boolean;
  onSave: (calendar: OrganizationCalendar) => void;
  onDelete: (calendarId: number) => void;
};

const CalendarItem = ({ item, isLoading, onSave, onDelete }: Props) => {
  const [updatedCalendar, setUpdatedCalendar] = useState<OrganizationCalendar>(item);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const { name, default: isDefault } = item;

  const handleOnCancel = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const handleOnUpdate = useCallback((calendar: OrganizationCalendar) => {
    setUpdatedCalendar(calendar);
  }, []);

  const handleOnSave = useCallback(() => {
    if (!isDataValid(calendarSchema, updatedCalendar)) return;

    onSave(updatedCalendar);
    setIsEditMode(false);
  }, [updatedCalendar, onSave]);

  const handleOnDelete = useCallback(() => {
    if (!updatedCalendar?.id) return;

    onDelete(updatedCalendar?.id);
    handleOnCancel();
  }, [updatedCalendar?.id, onDelete, handleOnCancel]);

  return (
    <>
      <SectionItem
        itemContent={name}
        badgeLabel={isDefault ? 'Default' : ''}
        openEdit={isEditMode}
        isLoading={isLoading}
        onSave={handleOnSave}
        onDelete={() => setIsConfirmationModalOpen(true)}
        onEdit={() => setIsEditMode(true)}
        onCancel={() => setIsEditMode(false)}
      />
      {isEditMode && (
        <CalendarForm calendar={item} onChange={handleOnUpdate} displayMode="settings-section" />
      )}
      {isConfirmationModalOpen && (
        <ActionConfirmationModal
          message={`you want to delete calendar "${item.name}"?`}
          isModalOpen={isConfirmationModalOpen}
          closeModal={handleOnCancel}
          isLoading={isLoading}
          handleNo={handleOnCancel}
          handleYes={handleOnDelete}
        />
      )}
    </>
  );
};

export default CalendarItem;
