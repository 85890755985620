import Axios from 'axios';

const basicAxios = Axios.create({
  baseURL: process.env.REACT_APP_PEOPLE_COUNTRIES_API_URL,
});

export const getAllCountries = async () => {
  return basicAxios.get(`/positions`);
};

export const getAllCitiesOfSpecifiedCountry = async (country: string) => {
  return basicAxios.post(`/cities`, {
    country,
  });
};
