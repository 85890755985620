import { Button } from '../Button';
import classes from './Empty.module.scss';

type Props = {
  img?: string;
  title: string;
  info: string;
  btnText?: string;
  handleOnClick?: () => void;
};

export const Empty = ({ img, btnText, handleOnClick, info, title }: Props) => {
  return (
    <div className={classes['c-empty']}>
      {!!img && <img src={img} alt={`${title}`} />}
      <h1 className={classes['c-empty__title']}>{title}</h1>
      <p className={classes['c-empty__info']}>{info}</p>
      {!!handleOnClick && !!btnText && (
        <Button type="secondary" size="medium" onClick={handleOnClick}>
          {btnText}
        </Button>
      )}
    </div>
  );
};
