import { useCallback, useEffect, useState } from 'react';
import classes from './ExportDataSection.module.scss';
import { Checkbox } from 'components/core';
import { ExportData } from '../../model/exportDataModels';

type Props = {
  title: string;
  titleId: string;
  data: ExportData[];
  first?: boolean;
  last?: boolean;
  handleCheck: (id: number | string) => void;
  handleAllCheck: (group: string, checked: boolean) => void;
};

const ExportDataSection = ({
  first,
  titleId,
  title,
  last,
  data,
  handleCheck,
  handleAllCheck,
}: Props) => {
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const checkHandler = () => {
    setAllChecked(!allChecked);
  };

  const getClassName = useCallback((): string => {
    if (first)
      return `${classes['c-export-data-section']} ${classes[`c-export-data-section--first`]}`;

    if (last)
      return `${classes['c-export-data-section']} ${classes[`c-export-data-section--last`]}`;

    return `${classes['c-export-data-section']}`;
  }, [first, last]);

  useEffect(() => {
    const dataChecker = data.map((checkbox) => checkbox.checked);
    setAllChecked(!dataChecker.includes(false));
  }, [data]);

  return (
    <>
      <div className={getClassName()}>
        <div className={classes['c-export-data-section__title']}>
          <Checkbox
            checked={allChecked}
            title={title}
            checkHandler={checkHandler}
            id={titleId}
            titleId={titleId}
            handleAllCheck={handleAllCheck}
            size="large"
          />
        </div>
        <div>
          {data.map((checkbox) => (
            <div key={checkbox.id}>
              <div
                key={checkbox.id}
                className={
                  checkbox.checked
                    ? `${classes['c-export-data-section__checkbox-holder']} ${
                        classes[`c-export-data-section__checkbox-holder--checked`]
                      }`
                    : `${classes['c-export-data-section__checkbox-holder']}`
                }
              >
                <Checkbox
                  id={checkbox.id}
                  checked={checkbox.checked}
                  label={checkbox.label}
                  checkHandler={handleCheck}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ExportDataSection;
