import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestState } from 'config/constants';
import { ProjectPhase, type Project, UserProjectPhase } from '../models';
import { getProjectsByStatus, getProjectById } from './projectActions';
import { State } from 'state/store';

export type ProjectState = {
  projects: Project[];
  project?: Project;
  isEditing: boolean;
  loading: RequestState;
  projectLoading: RequestState;
};

const initialState: ProjectState = {
  projects: [],
  isEditing: false,
  loading: RequestState.IDLE,
  projectLoading: RequestState.IDLE,
};

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    initializeState: () => initialState,
    initializeProject: (state) => {
      state.project = initialState.project;
    },
    setProjects: (state, action: PayloadAction<Project[]>) => {
      state.projects = action.payload;
    },
    addProject: (state, action: PayloadAction<Project>) => {
      state.projects = [...state.projects, action.payload];
    },
    setProject: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
    setProjectName: (state, action: PayloadAction<string>) => {
      if (!state.project) return;

      state.project.name = action.payload;
    },
    setProjectPhase: (state, action: PayloadAction<ProjectPhase>) => {
      if (!state.project) return;

      const { payload } = action;

      state.project.projectPhases = [
        ...state.project.projectPhases.map((phase) => {
          if (phase.id !== payload.id) return phase;
          return payload;
        }),
      ];
    },
    setProjectPhasesToProject: (state, action: PayloadAction<ProjectPhase[]>) => {
      if (!state.project) return;
      state.project.projectPhases = [...action.payload];
    },
    addNewPhase: (state, action: PayloadAction<ProjectPhase>) => {
      if (!state.project) return;
      state.project.projectPhases = [...state.project.projectPhases, action.payload];
    },
    deletePhase: (state, action: PayloadAction<number>) => {
      if (!state.project) return;
      state.project.projectPhases = [
        ...state.project.projectPhases.filter((phase) => Number(phase.id) !== action.payload),
      ];
    },
    updatePhaseMembers: (
      state,
      action: PayloadAction<{
        phaseId: number;
        userIds: number[];
        userProjectPhases?: UserProjectPhase[];
      }>,
    ) => {
      if (!state.project) return;
      state.project.projectPhases = [
        ...state.project.projectPhases.map((phase) => {
          if (Number(phase.id) === action.payload.phaseId) {
            return {
              ...phase,
              userIds: action.payload.userIds,
              userProjectPhases: action.payload.userProjectPhases,
            };
          }
          return { ...phase };
        }),
      ];
    },
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsByStatus.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getProjectsByStatus.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        if (!action.payload) return;
        state.projects = action.payload;
      })
      .addCase(getProjectsByStatus.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      })
      .addCase(getProjectById.pending, (state) => {
        state.projectLoading = RequestState.PENDING;
      })
      .addCase(getProjectById.fulfilled, (state, action) => {
        state.project = action.payload;
        state.projectLoading = RequestState.FULFILLED;
      })
      .addCase(getProjectById.rejected, (state) => {
        state.projectLoading = RequestState.REJECTED;
      });
  },
});

export const {
  initializeState,
  initializeProject,
  setProjects,
  addProject,
  setProject,
  setProjectName,
  setProjectPhasesToProject,
  setProjectPhase,
  addNewPhase,
  deletePhase,
  updatePhaseMembers,
  setIsEditing,
} = projectSlice.actions;

export const selectProjects = (state: State) => state.projects;

export default projectSlice.reducer;
