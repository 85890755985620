import { useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Spinner, toast } from 'components/core';
import { loginWithOAuth } from '../../../api/auth.api';
import { signIn } from '../../../utils';
import { mapErrorToErrorData } from 'utils';
import classes from './OAuthRedirect.module.scss';
import { OAuthProvider } from '../../../config';
import { useOAuth } from '../../../hooks';

type Props = {
  provider: OAuthProvider;
};

const OAuthRedirect = ({ provider }: Props) => {
  const { loginInProgress, idToken, error } = useOAuth();

  const handleLogin = useCallback(async () => {
    if (!idToken) return;

    const defaultErrorMessage = 'Something went wrong. Please try again.';

    if (error) {
      toast('error', defaultErrorMessage);
      return;
    }

    try {
      const { data } = await loginWithOAuth(idToken, provider);
      signIn(data);
    } catch (error) {
      const errorData = mapErrorToErrorData(error);

      if (errorData?.status === 400) {
        toast('error', errorData.message);
        return;
      }

      toast('error', defaultErrorMessage);
    }
  }, [idToken, error, provider]);

  useEffect(() => {
    if (!idToken) return;

    handleLogin();
  }, [idToken, handleLogin]);

  if (loginInProgress) {
    return (
      <div className={classes['c-oauth-redirect']}>
        <Spinner />
      </div>
    );
  }

  return <Navigate to="/" replace />;
};

export default OAuthRedirect;
