import type { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './SidebarItem.module.scss';

type Props = {
  label: string;
  path: string;
  icon: ReactNode;
  isActive: boolean;
};

const SidebarItem = ({ label, path, icon, isActive }: Props) => {
  return (
    <li className={classes['c-sidebar-item']}>
      <NavLink
        to={path}
        className={`${classes['c-sidebar-item__link']} ${
          isActive ? classes['c-sidebar-item__link--active'] : ''
        }`}
      >
        <span className={classes['c-sidebar-item__icon']}>{icon}</span>
        <span className={classes['c-sidebar-item__label']}>{label}</span>
      </NavLink>
    </li>
  );
};

export default SidebarItem;
