import { getAllCountries } from 'modules/shared/api/countries.api';
import { toast } from 'react-toastify';

export type Country = {
  name: string;
  iso2: string;
  long: string;
  lat: string;
};

export const getCountries = async () => {
  try {
    const response = await getAllCountries();
    if (response && response.data.data) {
      return response.data.data;
    }
  } catch {
    toast.error('Something went wrong. Please try again later.');
  }

  toast.error('No countries found.');
  return [];
};

export const getCountryNames = (countries: Country[]): string[] => {
  return countries.map((country) => country.name);
};
