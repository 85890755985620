import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureFlags } from '../model/featureFlags.model';
import { State } from 'state/store';
import { getFeatureFlags } from './featureFlagsActions';
import { RequestState } from 'config/constants';
import { Nullable } from 'modules/shared/model';

type FeatureFlagsState = {
  featureFlags: Nullable<Record<FeatureFlags, boolean>>;
  loading: RequestState;
};

const initialState: FeatureFlagsState = {
  featureFlags: null,
  loading: RequestState.IDLE,
};

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    initializeState: () => initialState,
    setFeatureFlags(state, action: PayloadAction<Record<FeatureFlags, boolean>>) {
      state.featureFlags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeatureFlags.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getFeatureFlags.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.featureFlags = action.payload;
      })
      .addCase(getFeatureFlags.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { setFeatureFlags } = featureFlagsSlice.actions;

export default featureFlagsSlice;

export const selectFeatureFlags = (state: State) => state.featureFlags.featureFlags;
