import { Schema, ZodError } from 'zod';
import { toast, ToastType } from 'components/core';

export const isValidationError = <T>(error: T | ZodError): error is ZodError => {
  return (error as ZodError).errors !== undefined;
};

export const isDataValid = <T>(
  schema: Schema<T>,
  data: T,
  toastType: ToastType = 'warning',
): boolean => {
  try {
    schema.parse(data);
    return true;
  } catch (error) {
    if (isValidationError(error)) toast(toastType, error.errors[0].message);
    return false;
  }
};
