import { useCallback, useMemo, useState } from 'react';
import classes from './ExportData.module.scss';
import { deleteReportTemplate as deleteReportTemplateAction } from '../../redux/reportTemplateSlice';
import { ReactComponent as ReportsIcon } from 'assets/ReportsIcon.svg';
import { ReactComponent as ExportIcon } from 'assets/ExportIcon.svg';
import { ExportModel, ReportInputParams, type ReportTemplate } from '../../model/exportDataModels';
import { ExportData as ExportDataModel } from 'pages/ReportsPage/ReportsPage';
import { createReport, deleteReportTemplate } from '../../api/reports.api';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { DeleteButton, IconButton, ActionConfirmationModal, Button, toast } from 'components/core';

type Props = {
  reportTemplates: ReportTemplate[];
  selectTemplateHandler: (isModalOpen: boolean, templateId?: number) => void;
  selectedTemplate?: number;
  checkboxFields: ExportDataModel[];
  toggleModal: (isOpen: boolean) => void;
};

const ExportData = ({
  reportTemplates,
  selectTemplateHandler,
  selectedTemplate,
  checkboxFields,
  toggleModal,
}: Props) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const getReportTemplateName = useMemo(
    () => reportTemplates.find((template) => template.id === selectedTemplate)?.name,
    [reportTemplates, selectedTemplate],
  );

  const fillQuickCustomExportHandler = async (id: number) => {
    const exportFields: ExportModel = {
      reportInputParams: {},
    };

    const foundTemplate = reportTemplates.find((template) => template.id === id);
    const templateFields = JSON.parse(foundTemplate?.inputFields || '');

    checkboxFields.forEach((checkbox) => {
      Object.keys(templateFields).forEach((key) => {
        if (checkbox.templateValue === key) {
          const group = checkbox.group as keyof ReportInputParams;
          exportFields.reportInputParams[group] = exportFields.reportInputParams[group] || [];
          exportFields.reportInputParams[group]?.push(checkbox.value || '');
        }
      });
    });

    return exportFields;
  };

  const quickExportTemplateHandler = async (id?: number) => {
    if (!id) return;
    const exportFields = await fillQuickCustomExportHandler(id);

    createReport(exportFields)
      .then((res) => {
        const href = URL.createObjectURL(res.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'export-report.csv');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        toast('success', 'You have successfully exported custom report.');
      })
      .catch(() =>
        toast('error', 'Something went wrong while exporting custom report. Try again.'),
      );
  };

  const handleDeleteTemplate = useCallback(async () => {
    if (!selectedTemplate) return;

    setIsLoading(true);
    try {
      await deleteReportTemplate(selectedTemplate);
      dispatch(deleteReportTemplateAction(selectedTemplate));
      setIsConfirmationModalOpen(false);
      toast('success', 'You have successfully deleted report template.');
    } catch {
      toast('error', 'Something went wrong while deleting report template. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [selectedTemplate, dispatch]);

  const handleOnClickBin = useCallback(
    (id?: number) => {
      selectTemplateHandler(false, id);
      setIsConfirmationModalOpen(true);
    },
    [selectTemplateHandler],
  );

  return (
    <div className={classes['c-export-data']}>
      <div className={classes['c-export-data__header']}>
        <div className={classes['c-export-data__title']}>Export your people data</div>
        <Button variant="outlined" leftIcon={<ReportsIcon />} onClick={() => toggleModal(true)}>
          Create custom report
        </Button>
      </div>
      <div className={classes['c-export-data__content']}>
        {reportTemplates.length ? (
          reportTemplates.map((template) => (
            <div key={template.id} className={classes['c-export-data__item']}>
              <div
                className={classes['c-export-data__item-name']}
                onClick={() => selectTemplateHandler(true, template?.id)}
              >
                {template.name}
              </div>
              <div className={classes['c-export-data__buttons-container']}>
                <IconButton
                  icon={<ExportIcon className={classes['c-export-data__export-icon']} />}
                  aria-label="Export"
                  tooltipContent="Export Report"
                  onClick={() => quickExportTemplateHandler(template?.id)}
                />
                <DeleteButton
                  tooltipContent="Delete Report"
                  onClick={() => handleOnClickBin(template?.id)}
                />
              </div>
            </div>
          ))
        ) : (
          <span className={classes['c-export-data__content--missing']}>
            There are no report templates available.
          </span>
        )}
      </div>
      <ActionConfirmationModal
        message={`you want to delete template "${getReportTemplateName}"?`}
        isModalOpen={isConfirmationModalOpen}
        isLoading={isLoading}
        handleNo={() => setIsConfirmationModalOpen(false)}
        handleYes={handleDeleteTemplate}
        closeModal={() => setIsConfirmationModalOpen(false)}
      />
    </div>
  );
};

export default ExportData;
