import { createSlice } from '@reduxjs/toolkit';
import { RequestState } from 'config/constants';
import { PaginationData } from 'modules/shared/model';
import { State } from 'state/store';
import { type User } from '../model/User';
import { getAllUsersFromOrganization, getUsers } from './usersActions';

export type PeopleState = {
  users: User[];
  paginationData?: PaginationData;
  loading: RequestState;
};

const initialState: PeopleState = {
  users: [],
  loading: RequestState.IDLE,
};

const usersSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    initializePeople: () => initialState,
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    addNewUser: (state, action) => {
      state.users.unshift(action.payload);
    },
    promoteUser: (state, action) => {
      state.users = [
        ...state.users.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload;
          }
          if (user.id === action.payload.userReportingToId) {
            return { ...user, directReports: [...user.directReports, action.payload] };
          }
          return user;
        }),
      ];
    },
    removeUser: (state, action) => {
      state.users = [...state.users].filter(({ id }) => id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersFromOrganization.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getAllUsersFromOrganization.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.users = action.payload;
      })
      .addCase(getAllUsersFromOrganization.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        const { content, ...paginationData } = action.payload;

        state.users = content;
        state.paginationData = paginationData;
        state.loading = RequestState.FULFILLED;
      })
      .addCase(getUsers.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { initializePeople, setUsers, addNewUser, promoteUser, removeUser } =
  usersSlice.actions;

export const selectUsers = (state: State) => state.users.users;
export const selectPaginationData = (state: State) => state.users.paginationData;

export default usersSlice;
