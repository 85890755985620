import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isWeekend } from 'utils';
import classes from './TimesheetItem.module.scss';
import TimesheetCell from './TimesheetCell/TimesheetCell';
import {
  TimesheetEntry,
  TimesheetWorkingHours,
  UserProjectTimesheet,
} from 'modules/timesheet/models/timesheet.models';
import {
  getOvertimeHours,
  getRegularHours,
  getTotalHours,
} from 'modules/timesheet/utils/timesheetHours';

type Props = {
  timesheetEntry: TimesheetEntry;
  isEditMode: boolean;
  setEntries?: Dispatch<SetStateAction<TimesheetEntry[]>>;
  index: number;
};

const TimesheetItem = ({
  timesheetEntry: {
    regularHours,
    overtimeHours,
    totalHours,
    localDate,
    dayOfWeek,
    userProjectTimesheets,
  },
  isEditMode,
  setEntries,
  index,
}: Props) => {
  const [timesheetHours, setTimesheetHours] = useState<TimesheetWorkingHours[]>([]);

  const addInitialProjectEntryData = (
    value: number | string | Date,
    updatedTimesheetEntries: TimesheetEntry[],
  ) => {
    if (typeof value !== 'number') return [];

    const initialTime = new Date(localDate);

    initialTime.setHours(0);
    initialTime.setMinutes(0);

    updatedTimesheetEntries[index] = {
      ...updatedTimesheetEntries[index],
      userProjectTimesheets: [
        ...userProjectTimesheets,
        {
          projectId: value,
          startDateTime: initialTime,
          endDateTime: initialTime,
          durationInMinutes: 0,
        },
      ],
    };

    return updatedTimesheetEntries;
  };

  const updateDuration = (
    updatedProjectEntries: UserProjectTimesheet[],
    projectEntryIndex: number,
    value: number,
  ) => {
    updatedProjectEntries[projectEntryIndex] = {
      ...updatedProjectEntries[projectEntryIndex],
      durationInMinutes: value / 60,
      endDateTime: new Date(
        new Date(updatedProjectEntries[projectEntryIndex].startDateTime).getTime() + value * 1000,
      ),
    };

    return updatedProjectEntries;
  };

  const updateStartTime = (
    updatedProjectEntries: UserProjectTimesheet[],
    projectEntryIndex: number,
    value: Date,
  ) => {
    updatedProjectEntries[projectEntryIndex] = {
      ...updatedProjectEntries[projectEntryIndex],
      startDateTime: value,
      endDateTime: new Date(
        value.getTime() + updatedProjectEntries[projectEntryIndex].durationInMinutes * 1000,
      ),
    };
  };

  const handleProjectChange = (
    projectEntryIndex: number,
    name: string,
    value: number | Date | string,
  ) => {
    if (!setEntries) return;

    setEntries((prevState) => {
      const updatedTimesheetEntries = [...prevState];
      const updatedProjectEntries = [...userProjectTimesheets];

      if (!updatedProjectEntries[projectEntryIndex]) {
        return addInitialProjectEntryData(value, updatedTimesheetEntries);
      }

      if (name === 'duration') {
        updateDuration(updatedProjectEntries, projectEntryIndex, +value);
      } else if (name === 'startDateTime') {
        updateStartTime(updatedProjectEntries, projectEntryIndex, new Date(value));
      } else {
        updatedProjectEntries[projectEntryIndex] = {
          ...updatedProjectEntries[projectEntryIndex],
          [name]: value,
        };
      }

      updatedTimesheetEntries[index] = {
        ...updatedTimesheetEntries[index],
        userProjectTimesheets: updatedProjectEntries,
      };

      return updatedTimesheetEntries;
    });
  };

  useEffect(() => {
    setTimesheetHours(
      userProjectTimesheets.map((userProjectTimesheet) => {
        const { startDateTime, durationInMinutes } = userProjectTimesheet;
        const endDateTime = new Date(
          new Date(startDateTime).getTime() + (durationInMinutes ? durationInMinutes : 0) * 1000,
        );

        return {
          startDateTime,
          endDateTime,
        };
      }),
    );
  }, [userProjectTimesheets]);

  return (
    <tr
      className={`${classes['c-timesheet-item']} ${
        isWeekend(localDate) ? classes['c-timesheet-item--weekend'] : ''
      }`}
    >
      <>
        <TimesheetCell name="localDate" value={localDate} />
        <TimesheetCell name="dayOfWeek" value={dayOfWeek} />
        <>
          <TimesheetCell
            cellType="dropdown"
            name="projectId"
            onProjectChange={(name: string, value: number | Date) =>
              handleProjectChange(0, name, value)
            }
            value={userProjectTimesheets[0]?.projectId || ''}
            isReadonly={!isEditMode}
          />
          <TimesheetCell
            cellType="time"
            name="startDateTime"
            value={userProjectTimesheets[0]?.startDateTime || ''}
            timesheetEntryDate={localDate}
            isReadonly={!isEditMode}
            onProjectChange={(name: string, value: number | Date) =>
              handleProjectChange(0, name, value)
            }
            isTimePickerDisabled={!userProjectTimesheets[0]?.projectId}
          />
          {isEditMode && (
            <TimesheetCell
              cellType="time"
              name="duration"
              value={userProjectTimesheets[0]?.durationInMinutes * 60 ?? 0}
              isReadonly={false}
              onProjectChange={(name: string, value: number | Date | string) => {
                handleProjectChange(0, name, value);
              }}
            />
          )}
          <TimesheetCell
            cellType="time"
            name="endDateTime"
            value={userProjectTimesheets[0]?.endDateTime || ''}
            timesheetEntryDate={localDate}
          />
        </>
        <TimesheetCell
          name="regularHours"
          value={regularHours || getRegularHours(timesheetHours)}
        />
        <TimesheetCell
          name="overtimeHours"
          value={overtimeHours || getOvertimeHours(timesheetHours)}
        />
        <TimesheetCell name="totalHours" value={totalHours || getTotalHours(timesheetHours)} />
      </>
    </tr>
  );
};

export default TimesheetItem;
