import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import classes from '../../../styles/Section.module.scss';
import { ReactComponent as CreditCardIcon } from 'assets/CreditCardIcon.svg';
import { SectionInfoEdit, SectionActions } from 'modules/shared/components';
import type { BankAccount } from 'modules/profile/models/profileModels';
import { deleteBankAccount, updateBankAccount } from 'modules/profile/api/profile.api';
import BankPopup from 'components/core/Popups/Profile/Banking/BankingPopup';
import { useDispatch } from 'react-redux';
import {
  updateBankAccounts,
  deleteBankAccount as deleteBankAccountAction,
} from 'modules/profile/redux/userSlice';
import toast from 'utils/notifications/CustomToast';
import { useParams } from 'react-router-dom';
import { ActionConfirmationModal, PopUpModal, Tab } from 'components/core';
import { getCustomFieldsByPosition, updateCustomFieldValue } from 'modules/profile/utils';
import { CustomFieldItem } from '../../CustomFieldItem/CustomFieldItem';
import { useUserCustomFields } from 'modules/profile/hooks';

type Props = {
  bankAccounts: BankAccount[];
};

const Banking = ({ bankAccounts }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [bankAccountData, setBankAccountData] = useState<BankAccount[]>([]);
  const [activeTab, setActiveTab] = useState(bankAccountData[0]?.id);
  const [bankAccountIdsToUpdate, setBankAccountIdsToUpdate] = useState(new Set<number>());
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { id } = useParams();

  const { customFields } = useUserCustomFields(bankAccounts[0]?.customFields ?? [], isEditMode);

  const bankAccountName = bankAccountData.find((bankAccount) => bankAccount.id === activeTab)
    ?.bankName;
  const isContentAvailable = !!bankAccountData.length;

  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);
  const handleCloseConfirmationModal = () => setIsConfirmationModalOpen(false);

  const getCustomFieldValue = useCallback(
    (customFieldId: number, bankAccountId: number) => {
      const customField = bankAccountData
        .find((account) => account.id === bankAccountId)
        ?.customFields.find((field) => field.customFieldId === customFieldId);

      return customField?.fieldValue ?? '';
    },
    [bankAccountData],
  );

  const handleOnChange = useCallback((value: string, key: keyof BankAccount, id: number) => {
    setBankAccountIdsToUpdate((prevSet) => new Set([...prevSet, id]));

    setBankAccountData((prevAccount) =>
      prevAccount.map((account) => {
        if (account.id === id) {
          return { ...account, [key]: value };
        }

        return account;
      }),
    );
  }, []);

  const handleCustomFieldChange = useCallback(
    (value: string, customFieldId: number) => {
      setBankAccountIdsToUpdate((previousSet) => new Set([...previousSet, activeTab]));

      setBankAccountData((previousData) => {
        return previousData.map((previousAccount) => {
          if (previousAccount.id !== activeTab) return previousAccount;

          return {
            ...previousAccount,
            customFields: updateCustomFieldValue(
              previousAccount.customFields,
              customFieldId,
              value,
            ),
          };
        });
      });
    },
    [activeTab],
  );

  const isSubmitDisabled = useMemo(
    (): boolean =>
      !bankAccountData[0]?.bankName ||
      !bankAccountData[0]?.accountHolderName ||
      !bankAccountData[0]?.accountNumber,
    [bankAccountData],
  );

  const submitChangesHandler = useCallback(async () => {
    for (const id of bankAccountIdsToUpdate) {
      const newAccountValues = bankAccountData.find((account) => account.id === id);

      if (
        !newAccountValues?.bankName ||
        !newAccountValues.accountHolderName ||
        !newAccountValues.accountNumber
      ) {
        return;
      }

      setIsLoading(true);

      try {
        const { data } = await updateBankAccount(newAccountValues.userId, newAccountValues);
        dispatch(updateBankAccounts(data));
        setIsEditMode(false);
        toast.success('You have successfully updated banking info.');
      } catch {
        toast.error('Something went wrong while updating banking info.');
      } finally {
        setIsLoading(false);
      }
    }

    setBankAccountIdsToUpdate(new Set<number>());
  }, [bankAccountIdsToUpdate, bankAccountData, dispatch]);

  const handleCancel = useCallback(() => {
    setBankAccountData(bankAccounts);
    setIsEditMode(false);
  }, [bankAccounts]);

  const handleOnClickDelete = useCallback(() => {
    if (bankAccountData.length) setIsConfirmationModalOpen(true);
  }, [bankAccountData.length]);

  const handleDelete = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      await deleteBankAccount(activeTab, id);
      dispatch(deleteBankAccountAction(activeTab));
      setIsConfirmationModalOpen(false);
      setIsEditMode(false);
      toast.success('You have successfully deleted bank account');
    } catch {
      toast.error('Something went wrong while deleting bank account. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [id, activeTab, dispatch]);

  const handleEdit = useCallback(() => {
    setBankAccountData(bankAccounts);
    setIsEditMode(true);
  }, [bankAccounts]);

  const renderContent = useCallback(() => {
    if (!bankAccountData?.length) {
      return (
        <span className={classes['c-section__no-content-data-message']}>
          No banking data available.
        </span>
      );
    }

    return (
      <div className={classes['c-section__content--columns']}>
        {bankAccountData.map(({ id, bankName, accountHolderName, accountNumber }) => {
          if (id !== activeTab) return;

          return (
            <Fragment key={id}>
              <div className={classes['c-section__content-column']}>
                <SectionInfoEdit
                  name="Bank name"
                  data={bankName}
                  icon={<CreditCardIcon />}
                  setValue={(value) => handleOnChange(value, 'bankName', activeTab)}
                  readOnly={!isEditMode}
                />
                <SectionInfoEdit
                  name="Account holder name"
                  data={accountHolderName}
                  icon={<CreditCardIcon />}
                  setValue={(value) => handleOnChange(value, 'accountHolderName', activeTab)}
                  readOnly={!isEditMode}
                />
                {getCustomFieldsByPosition(customFields, 'odd').map((customField) => (
                  <CustomFieldItem
                    customField={customField}
                    key={customField.customFieldId}
                    readonly={!isEditMode}
                    selectedOption={getCustomFieldValue(customField.customFieldId, activeTab)}
                    onChange={handleCustomFieldChange}
                  />
                ))}
              </div>
              <div className={classes['c-section__content-column']}>
                <SectionInfoEdit
                  name="Account number"
                  data={accountNumber}
                  icon={<CreditCardIcon />}
                  setValue={(value) => handleOnChange(value, 'accountNumber', activeTab)}
                  readOnly={!isEditMode}
                />
                {getCustomFieldsByPosition(customFields, 'even').map((customField) => (
                  <CustomFieldItem
                    customField={customField}
                    key={customField.customFieldId}
                    readonly={!isEditMode}
                    selectedOption={getCustomFieldValue(customField.customFieldId, activeTab)}
                    onChange={handleCustomFieldChange}
                  />
                ))}
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }, [
    activeTab,
    bankAccountData,
    customFields,
    getCustomFieldValue,
    handleCustomFieldChange,
    handleOnChange,
    isEditMode,
  ]);

  useEffect(() => {
    if (!bankAccounts?.length) setBankAccountData([]);
    else if (!activeTab || !bankAccounts.some((bankAccount) => bankAccount.id === activeTab))
      setActiveTab(bankAccounts[0].id);

    setBankAccountData(bankAccounts);
  }, [bankAccounts, activeTab]);

  return (
    <>
      <div className={classes['c-section']}>
        <div className={classes['c-section__header']}>
          <div className={classes['c-section__header__title-and-add']}>
            <h2 className={classes['c-section__title']}>Banking</h2>
          </div>
          <SectionActions
            isEditOpen={isEditMode}
            handleEdit={handleEdit}
            handleCancel={handleCancel}
            handleSave={submitChangesHandler}
            handleAdd={handleOpenPopup}
            handleDelete={handleOnClickDelete}
            isLoading={isLoading}
            isSaveButtonDisabled={isSubmitDisabled}
            isContentAvailable={isContentAvailable}
          />
        </div>
        <div className={classes['c-section__tabs']}>
          {bankAccountData.map(({ id, bankName }) => (
            <Tab
              key={id}
              itemId={id}
              text={bankName}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ))}
        </div>
        <div className={classes['c-section__section-content-container']}>{renderContent()}</div>
      </div>
      <PopUpModal
        title="Add a bank account"
        opened={isPopupOpen}
        closeModal={handleClosePopup}
        width={30}
      >
        <BankPopup setIsPopupOpen={setIsPopupOpen} setIsFormOpen={setIsEditMode} />
      </PopUpModal>
      <ActionConfirmationModal
        message={`you want to delete bank account "${bankAccountName}"?`}
        isModalOpen={isConfirmationModalOpen}
        isLoading={isLoading}
        closeModal={handleCloseConfirmationModal}
        handleNo={handleCloseConfirmationModal}
        handleYes={handleDelete}
      />
    </>
  );
};

export default Banking;
