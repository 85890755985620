import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWorkflowInstanceById, getWorkflowInstances } from '../api/workflowInstances.api';
import { WorkflowInstanceFilter } from '../model/workflowInstance.model';

export const getAllWorkflowInstances = createAsyncThunk(
  'workflowSlice/getAllWorkflowInstances',
  async (payload: { filter: WorkflowInstanceFilter } | undefined) => {
    const { data } = await getWorkflowInstances(payload?.filter);

    return data;
  },
);

export const getWorkflowInstance = createAsyncThunk(
  'workflowInstancesSlice/getWorkflowInstance',
  async (payload: { workflowId: number; id: string }) => {
    const { workflowId, id } = payload;

    const { data } = await getWorkflowInstanceById(workflowId, id);

    return data;
  },
);
