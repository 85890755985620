import classes from './DropDown.module.scss';

type Props = {
  text: string | JSX.Element;
  onClick?: () => void;
  modifier?: 'year' | 'selected';
};

export const DropDownOption = ({ text, onClick, modifier }: Props) => (
  <span
    className={`${classes['c-drop-down-option']} ${
      modifier ? classes[`c-drop-down-option--${modifier}`] : ''
    }`}
    onClick={onClick}
  >
    {text}
  </span>
);
