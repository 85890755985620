import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';

import { store } from 'state/store';
import { App } from './App';
import { Error, Spinner } from 'components/core';

import 'styles/index.scss';
import './global.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <Router>
      <StrictMode>
        <ErrorBoundary fallback={<Error />}>
          <Suspense fallback={<Spinner />}>
            <App />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </Router>
  </Provider>,
);
