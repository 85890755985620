import { TaskStatus } from 'config/constants';
import type { SectionInstance, WorkflowInstance } from '../model/workflowInstance.model';

export const getWorkflowProgress = (workflow: WorkflowInstance): number => {
  const { numberOfCompletedTasks, numberOfTasks } = workflow;

  return Math.round((numberOfCompletedTasks / numberOfTasks) * 100);
};

export const checkIfWorkflowIsCompleted = (workflow: SectionInstance[]): boolean => {
  return workflow.every((section) =>
    section.taskInstanceDtos.every((task) => task.taskStatus === TaskStatus.COMPLETED),
  );
};
