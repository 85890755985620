import { useState } from 'react';
import classes from '../styles/Section.module.scss';
import { DocumentDropSection } from 'components/core';
import { type UserDocument } from 'modules/profile/models/profileModels';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import { DocumentsItem } from 'modules/shared/components';
import { selectUser } from '../redux/userSlice';
import { useToolbarTitle } from 'hooks';

const UserDocuments = () => {
  const { userDocuments } = useAppSelector(selectUser);
  const { id } = useParams();

  useToolbarTitle({ title: ['Profile', 'Documents'] });

  const [inputs, setInputs] = useState<UserDocument>({
    id: 0,
    name: '',
    type: '',
  });

  return (
    <div className={classes['c-section__wrapper']}>
      <div className={classes['c-section__sections-holder']}>
        {userDocuments?.map((document, index) => (
          <DocumentsItem documentItem={document} key={index} />
        ))}
        <DocumentDropSection
          title="Upload new document"
          documentType="user"
          userId={id}
          documentInputs={inputs}
          handleUserDocumentInputs={setInputs}
        />
      </div>
    </div>
  );
};

export default UserDocuments;
