import { useState, type ChangeEvent } from 'react';
import Header from '../Header/Header';
import InputContainer from '../InputContainer/InputContainer';
import { Button, Checkbox, dismissToast } from 'components/core';
import { Link, useNavigate } from 'react-router-dom';
import { mapErrorToErrorData } from 'utils';
import { login } from '../../api/auth.api';
import classes from './SignIn.module.scss';
import { isEmailValid, signIn } from '../../utils';
import FormLink from '../FormLink/FormLink';
import TextDivider from '../TextDivider/TextDivider';
import { OAuthButtonContainer } from '../OAuth/OAuthButton/OAuthButtonContainer';

type Credentials = {
  email: string;
  password: string;
};

type FormValidity = {
  email: boolean;
  password: boolean;
};

const SignIn = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<Credentials>({
    email: '',
    password: '',
  });
  const [formValidity, setFormValidity] = useState<FormValidity>({
    email: false,
    password: false,
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [passwordMessage, setPasswordMessage] = useState<string>('Please enter your password.');
  const [emailMessage, setEmailMessage] = useState<string>('Please enter a valid email address.');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCheck = () => setIsChecked((checked) => !checked);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, validity } = event.target;

    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormValidity((prev) => ({
      ...prev,
      [name]: validity.valid,
    }));
  };

  const isFormValid =
    formValidity.email && formValidity.password && isEmailValid(credentials.email);

  const handleSignIn = async () => {
    setIsFormSubmitted(true);

    if (!isFormValid) return;

    try {
      setIsLoading(true);

      const { data } = await login(credentials.email, credentials.password);

      signIn(data);
      navigate('/people');
      dismissToast();
    } catch (error) {
      const errorData = mapErrorToErrorData(error);

      if (errorData?.status === 400) {
        setFormValidity((prev) => ({ ...prev, password: false }));
        setPasswordMessage('Wrong password. Try again.');
        return;
      }

      if (errorData?.status === 404) {
        setFormValidity((prev) => ({ ...prev, email: false }));
        setEmailMessage('This email does not exist.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes['c-signin']}>
      <Header title="Sign in to your account" titleModifier="margin-bottom" />
      <div className={classes['c-signin__form-content']}>
        <InputContainer
          label="E-mail"
          name="email"
          type="email"
          required
          handleInput={handleInput}
          invalidMessage={emailMessage}
          inputValidity={formValidity.email}
          setFormValidity={(validity) => setFormValidity((prev) => ({ ...prev, name: validity }))}
          isFormSubmitted={isFormSubmitted}
          handleOnKeyPressed={handleSignIn}
          autoFocus
        />
        <InputContainer
          label="Password"
          name="password"
          type="password"
          required
          handleInput={handleInput}
          invalidMessage={passwordMessage}
          inputValidity={formValidity.password}
          setFormValidity={(validity) =>
            setFormValidity((prev) => ({ ...prev, password: validity }))
          }
          isFormSubmitted={isFormSubmitted}
          handleOnKeyPressed={handleSignIn}
        />
        <div className={classes['c-signin__options']}>
          <Checkbox
            id="rememberMe"
            handleCheckedItem={handleCheck}
            checked={isChecked}
            label="Remember me"
          />
          <Link
            to="/reset-password"
            className={`${classes['c-signin__options-item']} ${classes['c-signin__options-item--fp']}`}
          >
            I forgot my password
          </Link>
        </div>
        <div className={classes['c-signin__button-container']}>
          <Button size="large" onClick={handleSignIn} isLoading={isLoading}>
            Sign in
          </Button>
        </div>
      </div>
      <div className={classes['c-signin__buttons-container']}>
        <OAuthButtonContainer provider="google" />
        <OAuthButtonContainer provider="microsoft" />
      </div>
      <TextDivider text="or" />
      <FormLink url="/sign-up" text="Register your organization" />
    </div>
  );
};

export default SignIn;
