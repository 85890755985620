import { createSlice } from '@reduxjs/toolkit';
import { RequestState } from 'config/constants';
import { State } from 'state/store';
import { WorkflowInstanceStatus, type WorkflowInstance } from '../model/workflowInstance.model';
import { getAllWorkflowInstances, getWorkflowInstance } from './workflowInstanceAction';

type WorkflowInstanceSliceState = {
  workflowInstances: WorkflowInstance[];
  workflowInstance: WorkflowInstance;
  loading: RequestState;
};

const initialState: WorkflowInstanceSliceState = {
  workflowInstances: [],
  workflowInstance: {
    id: 0,
    numberOfTasks: 0,
    numberOfCompletedTasks: 0,
    workflowName: '',
    workflowId: 0,
    archived: false,
    userDto: {
      id: 0,
      firstName: '',
      lastName: '',
      organizationId: 0,
      department: '',
      email: '',
      personalEmail: '',
      placeOfWork: '',
      country: '',
      jobTitle: '',
      profilePhotoPath: '',
      reportingToUser: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        organizationId: 0,
        profilePicture: '',
      },
      directReports: [],
      effectiveOn: '',
      social: undefined,
      dateOfBirth: '',
    },
    workflowInstanceStatus: WorkflowInstanceStatus.DRAFT,
    workflowSectionInstanceDtos: [],
  },
  loading: RequestState.IDLE,
};

const workflowInstanceSlice = createSlice({
  name: 'workflowInstancesSlice',
  initialState,
  reducers: {
    setWorkflowInstances: (state, action) => {
      state.workflowInstances = action.payload;
    },
    setWorkflowInstance: (state, action) => {
      state.workflowInstance = action.payload;
    },
    addNewSectionTask: (state, action) => {
      const { sectionId, newTask } = action.payload;

      state.workflowInstance = {
        ...state.workflowInstance,
        workflowSectionInstanceDtos: state.workflowInstance.workflowSectionInstanceDtos.map(
          (section) => {
            if (section.id !== sectionId) {
              return section;
            }
            return {
              ...section,
              taskInstanceDtos: [...section.taskInstanceDtos, newTask],
            };
          },
        ),
      };
    },
    updateWorkflowSection: (state, action) => {
      const { id, ...updatedSection } = action.payload;

      const updatedWorkflow = {
        ...state.workflowInstance,
        workflowSectionInstanceDtos: state.workflowInstance.workflowSectionInstanceDtos.map(
          (section) => {
            if (section.id !== id) {
              return section;
            }
            return {
              ...section,
              ...updatedSection,
            };
          },
        ),
      };

      state.workflowInstance = updatedWorkflow;
    },
    deleteSectionInstanceTask: (state, action) => {
      state.workflowInstance.workflowSectionInstanceDtos = [ ...state.workflowInstance.workflowSectionInstanceDtos.map((sectionInstance) => {
        if(sectionInstance.id !== action.payload.sectionId)
          return sectionInstance;
        
          return {...sectionInstance, taskInstanceDtos : sectionInstance.taskInstanceDtos.filter((task) => task.id !== action.payload.taskId)}
      })]
    },
    updateSectionTask: (state, action) => {
      const updatedTask = action.payload;

      const updatedWorkflow = {
        ...state.workflowInstance,
        workflowSectionInstanceDtos: state.workflowInstance.workflowSectionInstanceDtos.map(
          (section) => {
            if (section.id !== updatedTask.workflowSectionInstanceId) {
              return section;
            }
            return {
              ...section,
              taskInstanceDtos: section.taskInstanceDtos.map((task) => {
                if (task.id !== updatedTask.id) {
                  return task;
                }
                return updatedTask;
              }),
            };
          },
        ),
      };

      state.workflowInstance = updatedWorkflow;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllWorkflowInstances.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getAllWorkflowInstances.fulfilled, (state, action) => {
        state.workflowInstances = action.payload;
        state.loading = RequestState.FULFILLED;
      })
      .addCase(getAllWorkflowInstances.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      })
      .addCase(getWorkflowInstance.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getWorkflowInstance.fulfilled, (state, action) => {
        state.workflowInstance = action.payload;
        state.loading = RequestState.FULFILLED;
      })
      .addCase(getWorkflowInstance.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const {
  setWorkflowInstances,
  setWorkflowInstance,
  addNewSectionTask,
  updateWorkflowSection,
  updateSectionTask,
  deleteSectionInstanceTask,
} = workflowInstanceSlice.actions;

export const selectWorkflowInstance = (state: State) => state.workflowInstances.workflowInstance;

export default workflowInstanceSlice;
