import dayjs from 'dayjs';
import classes from '../styles/Task.module.scss';
import type { Task as TaskModel } from '../model';
import { TaskStatus } from 'config/constants';
import { Button, Checkmark, HighlightedText } from 'components/core';

type Props = {
  task: TaskModel;
  searchValue?: string;
  onComplete: (task: TaskModel) => void;
};

const TIME_FORMAT = 'DD-MM-YYYY HH:mm';

const Task = ({ task, searchValue, onComplete }: Props) => {
  const { name, taskStatus, dueDate, triggerDate, workflowName, workflowSectionInstanceName } =
    task;
  const isTaskCompleted = taskStatus === TaskStatus.COMPLETED;

  const handleComplete = () => {
    onComplete(task);
  };

  return (
    <div className={classes['c-task']}>
      <div className={classes['c-task__container']}>
        <div className={classes['c-task__column']}>
          <Checkmark isChecked={isTaskCompleted} />
        </div>
        <div className={`${classes['c-task__column']} ${classes['c-task__column--highlighted']}`}>
          <HighlightedText text={name} highlight={searchValue} />
        </div>
      </div>
      <div className={classes['c-task__column']}>
        <HighlightedText text={workflowName} highlight={searchValue} />
      </div>
      <div className={classes['c-task__column']}>
        <HighlightedText text={workflowSectionInstanceName} highlight={searchValue} />
      </div>
      <div className={classes['c-task__column']}>
        {!task.dueDate ? 'No due date' : dayjs(dueDate).format(TIME_FORMAT)}
      </div>
      <div className={classes['c-task__column']}>{dayjs(triggerDate).format(TIME_FORMAT)}</div>
      <div className={`${classes['c-task__column']} ${classes['c-task__column--centered']}`}>
        {task.order}
      </div>
      <div className={`${classes['c-task__column']} ${classes['c-task__column--centered']}`}>
        {!isTaskCompleted && (
          <Button type="success" variant="outlined" onClick={handleComplete}>
            Complete
          </Button>
        )}
      </div>
    </div>
  );
};

export default Task;
