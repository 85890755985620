import type { ReactNode, ChangeEvent } from 'react';
import classes from './Checkbox.module.scss';

type Props = {
  checked: boolean;
  label?: string | JSX.Element;
  labelColor?: string;
  image?: ReactNode;
  title?: string;
  titleId?: string;
  id: number | string;
  size?: 'small' | 'medium' | 'large';
  checkHandler?: (id: number | string) => void;
  handleAllCheck?: (group: string, checked: boolean) => void;
  handleCheckedItem?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = ({
  checked,
  id,
  label,
  labelColor,
  image,
  title,
  titleId,
  size,
  checkHandler,
  handleAllCheck,
  handleCheckedItem,
}: Props) => {
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (handleAllCheck) {
      const id = titleId || '';

      handleAllCheck(id, checked);

      if (checkHandler) {
        checkHandler(id);
      }
    } else if (handleCheckedItem) {
      handleCheckedItem(event);
    }

    if (checkHandler) {
      checkHandler(id);
    }
  };

  const checkName = () => {
    const value = title ?? label;
    return typeof value !== 'string' ? '' : value;
  };

  return (
    <div className={classes['c-checkbox']}>
      <div
        className={`${classes['c-checkbox__checkbox-holder']} ${
          classes[`c-checkbox__checkbox-holder--${size}`]
        }`}
      >
        <input
          type="checkbox"
          id={titleId ?? `${id}`}
          name={checkName()}
          checked={checked}
          onChange={handleOnChange}
        />

        <label htmlFor={titleId ?? `${id}`} className={classes['c-checkbox__label']}>
          {image}
          <span className={classes['c-checkbox__label-text']} style={{ color: `${labelColor}` }}>
            {title ?? label}
          </span>
        </label>
      </div>
    </div>
  );
};
