import {
  useState,
  useEffect,
  useCallback,
  type SetStateAction,
  type ChangeEvent,
  type DragEvent,
} from 'react';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { PopUpModal, IdentificationModal, UserDocumentModal } from '../Modal';
import classes from './DocumentDropSection.module.scss';
import { addDocument } from 'modules/settings/redux/settingsSlice';
import toast from 'utils/notifications/CustomToast';
import { addIdentification, addUserDocument } from 'modules/profile/redux/userSlice';
import type { UserDocument, Identification } from 'modules/profile/models/profileModels';
import { createIdentification, createUserDocument } from 'modules/profile/api/profile.api';
import { useParams } from 'react-router-dom';
import { DeleteButton } from '../DeleteButton';
import { OrganizationDocument } from 'modules/settings/models/settingsModels';
import { createOrganizationDocument } from 'modules/settings/api/documents.api';
import { Button } from '../Button';
import { AddButton } from '../AddButton';
import { ReactComponent as AddSquareIcon } from 'assets/AddSquareIcon.svg';

const initialUserDocumentInputs: UserDocument = {
  id: 0,
  name: '',
  type: '',
};

const initialUserIdentificationInputs: Identification = {
  id: 0,
  identificationType: '',
  number: '',
  expiryDate: new Date(),
  countryOfIssue: '',
  note: '',
  userId: 0,
};

type Props = {
  title: string;
  documentType?: 'organization' | 'user' | 'identification';
  userId?: number | string;
  identificationInputs?: Identification;
  documentInputs?: UserDocument;
  handleIdentificationInputs?: React.Dispatch<SetStateAction<Identification>>;
  handleUserDocumentInputs?: React.Dispatch<SetStateAction<UserDocument | OrganizationDocument>>;
};

export const DocumentDropSection = ({
  title,
  documentType,
  identificationInputs,
  documentInputs,
  handleIdentificationInputs,
  handleUserDocumentInputs,
}: Props) => {
  const dispatch = useAppDispatch();
  const [mouseOver, setMouseOver] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files) return;

    setFile(files[0]);
  };

  const handleUpload = () => {
    setIsFormSubmitted(true);
    if (isUploadButtonDisabled) return;
    uploadFile();
  };

  const uploadFile = async () => {
    if (!file) return;

    try {
      let response;

      setIsLoading(true);

      if (documentType === 'identification' && identificationInputs) {
        response = await createIdentification(identificationInputs, file);
        dispatch(addIdentification(response.data));
        toast.success('You have successfully added new identification.');
      } else if (documentType === 'user' && documentInputs && id) {
        response = await createUserDocument(documentInputs, id, file);
        dispatch(addUserDocument(response.data));
        toast.success('You have successfully added new document.');
      } else if (documentType === 'organization' && documentInputs) {
        response = await createOrganizationDocument(documentInputs, file);
        dispatch(addDocument(response.data));
        toast.success('You have successfully uploaded new document.');
      }

      setFile(null);
      handleCloseModal();
    } catch (error) {
      toast.error('Something went wrong. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
  };

  const checkMultipleFilesUpload = useCallback(async (files: File[]) => {
    if (files.length && files.length === 1) {
      setFile(files[0]);
      setIsModalOpen(true);
    } else {
      toast.warning(
        'Only one file can be uploaded at a time. Please select and upload only one file.',
      );
    }
  }, []);

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    if (!event.dataTransfer.files) {
      return;
    }

    checkMultipleFilesUpload([...event.dataTransfer.files]);
  };

  const handleCloseModal = () => {
    setIsFormSubmitted(false);
    setFile(null);
    setIsModalOpen(false);

    handleUserDocumentInputs && handleUserDocumentInputs(initialUserDocumentInputs);

    handleIdentificationInputs &&
      handleIdentificationInputs({ ...initialUserIdentificationInputs, userId: Number(id) });
  };

  useEffect(() => {
    setIsUploadButtonDisabled(
      !file ||
        identificationInputs?.identificationType === '' ||
        identificationInputs?.number === '' ||
        identificationInputs?.countryOfIssue === '' ||
        identificationInputs?.expiryDate === '' ||
        documentInputs?.name === '',
    );
  }, [identificationInputs, documentInputs, file]);

  return (
    <>
      <div
        className={
          mouseOver
            ? `${classes['c-document-drop-section']} ${classes['c-document-drop-section--over']}`
            : classes['c-document-drop-section']
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={() => setMouseOver(true)}
        onDragLeave={() => setMouseOver(false)}
        onMouseLeave={() => setMouseOver(false)}
        onClick={() => setIsModalOpen(true)}
      >
        <input
          className={classes['c-document-drop-section__file-input']}
          type="file"
          accept="application/*"
          multiple={false}
        />
        <AddButton
          label={title}
          info="or drop a file here to get started (up to 10MB)"
          icon={<AddSquareIcon />}
        />
      </div>
      <PopUpModal
        title="Add new document"
        opened={isModalOpen}
        closeModal={handleCloseModal}
        width={25}
      >
        <div className={classes['c-document-drop-section__modal']}>
          {documentType === 'identification' && (
            <IdentificationModal
              inputs={identificationInputs || initialUserIdentificationInputs}
              setInputs={handleIdentificationInputs}
              isFormSubmitted={isFormSubmitted}
            />
          )}
          {(documentType === 'user' || documentType === 'organization') && (
            <UserDocumentModal
              inputs={documentInputs || initialUserDocumentInputs}
              setInputs={handleUserDocumentInputs}
              isFormSubmitted={isFormSubmitted}
            />
          )}
          <div className={classes['c-document-drop-section__input-file-holder']}>
            <div className={classes['c-document-drop-section__upload-input']}>
              <label
                className={`${classes['c-document-drop-section__input-file-label']} ${
                  !file && isFormSubmitted
                    ? classes['c-document-drop-section__input-file-label--invalid']
                    : ''
                }`}
                htmlFor="file-upload"
              >
                {file ? file.name : 'Add document'}
              </label>
              <input id="file-upload" type="file" multiple={false} onChange={handleFileChange} />
            </div>
            {file && <DeleteButton onClick={() => setFile(null)} />}
          </div>
          <Button size="medium" isLoading={isLoading} onClick={handleUpload}>
            Upload
          </Button>
        </div>
      </PopUpModal>
    </>
  );
};
