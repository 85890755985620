import { TaskStatus } from 'config/constants';
import { UserBaseInfo, type User } from 'modules/people/model/User';

export enum WorkflowInstanceStatus {
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
}

export type WorkflowInstanceFilter = 'active' | 'archived';

export type WorkflowInstance = {
  id: number;
  workflowName: string;
  workflowId: number;
  numberOfTasks: number;
  numberOfCompletedTasks: number;
  userDto: User;
  workflowInstanceStatus: WorkflowInstanceStatus;
  archived: boolean;
  workflowSectionInstanceDtos: SectionInstance[];
};

export type SectionInstance = {
  id: number;
  name: string;
  order: number;
  taskInstanceDtos: TaskInstance[];
  workflowInstanceId: number;
  workflowSectionId: number;
};

export type TaskInstance = {
  id: number;
  name: string;
  description: string;
  order: number;
  taskStatus?: TaskStatus;
  triggerDate?: Date | string;
  dueDate?: Date | string;
  assignedUserDto?: UserBaseInfo;
  workflowSectionInstanceId?: number;
};

export type TaskInstanceRequest = {
  id: number;
  name: string;
  description: string;
  order: number;
  taskStatus?: TaskStatus;
  triggerDate?: Date | string;
  dueDate?: Date | string;
  assignedUserId?: number;
  workflowSectionInstanceId?: number;
};
