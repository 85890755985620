import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classes from './BankingPopup.module.scss';
import { createBankAccount } from 'modules/profile/api/profile.api';
import { addBankAccount } from 'modules/profile/redux/userSlice';
import { Input, Button } from 'components/core';
import toast from 'utils/notifications/CustomToast';
import { UserCustomField } from 'modules/profile/models/profileModels';

export type NewAccount = {
  bankName: string;
  accountHolderName: string;
  accountNumber: string;
  routingNumber: string;
  sortCode: string;
  swiftNumber: string;
  userId: string | undefined;
  customFields: UserCustomField[];
};

type Props = {
  setIsPopupOpen: (value: boolean) => void;
  setIsFormOpen: (value: boolean) => void;
};

const BankingPopup = ({ setIsPopupOpen, setIsFormOpen }: Props) => {
  const { id } = useParams();
  const [newAccount, setNewAccount] = useState<NewAccount>({
    bankName: '',
    accountHolderName: '',
    accountNumber: '',
    routingNumber: '',
    sortCode: '',
    swiftNumber: '',
    userId: id,
    customFields: [],
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateNewAccount = (value: string, prop: string) => {
    setNewAccount((prevAccount) => ({ ...prevAccount, [prop]: value }));
  };

  const isBankAccountButtonDisabled = () => {
    return !newAccount.bankName || !newAccount.accountHolderName || !newAccount.accountNumber;
  };
  const dispatch = useDispatch();

  const handleAddNewAccount = () => {
    setIsFormSubmitted(true);
    !isBankAccountButtonDisabled() && saveNewAccount();
  };

  const saveNewAccount = () => {
    setIsLoading(true);
    newAccount.userId &&
      createBankAccount(newAccount, newAccount.userId)
        .then((res) => {
          dispatch(addBankAccount(res?.data));
          setIsPopupOpen(false);
          setIsFormOpen(false);
          toast.success('You have successfully added a new bank account.');
        })
        .catch(() => {
          toast.error('Something went wrong while saving. Try again.');
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  return (
    <div className={classes['c-banking-popup']}>
      <div className={classes['c-banking-popup__form']}>
        <Input
          name="bankName"
          id="bankName"
          value={newAccount.bankName}
          placeholder=""
          label="Bank name"
          handleOnChange={(event) => {
            updateNewAccount(event.target.value, event.target.name);
          }}
          isSubmitted={isFormSubmitted}
          size="medium"
        />
        <Input
          name="accountHolderName"
          id="accountHolderName"
          value={newAccount.accountHolderName}
          placeholder=""
          label="Account holder name"
          handleOnChange={(event) => {
            updateNewAccount(event.target.value, event.target.name);
          }}
          isSubmitted={isFormSubmitted}
          size="medium"
        />
        <Input
          name="accountNumber"
          id="accountNumber"
          value={newAccount.accountNumber}
          placeholder=""
          label="Account number"
          handleOnChange={(event) => {
            updateNewAccount(event.target.value, event.target.name);
          }}
          isSubmitted={isFormSubmitted}
          size="medium"
        />
      </div>
      <Button size="medium" isLoading={isLoading} onClick={handleAddNewAccount}>
        Add new account
      </Button>
    </div>
  );
};

export default BankingPopup;
