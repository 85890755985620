import { type FC, useCallback, useEffect, useState } from 'react';
import classes from './MonthPicker.module.scss';
import { YearPicker, current } from 'components/core/YearPicker/YearPicker';
import { useOnClickOutside } from 'hooks';
import dayjs from 'dayjs';
import { ReactComponent as Minimize } from 'assets/Minimize.svg';
import { formatDate } from 'utils/dates';

type Props = {
  selectedMonth: number | 'Today';
  setSelectedYear: (value: number) => void;
  setSelectedMonth: (value: number | 'Today') => void;
  currentDate: Date | 'Today';
  selectedYear: number;
  selectDateFromCalendar: (date: Date | 'Today') => void;
  extended?: boolean;
};

export const MonthPicker: FC<Props> = ({
  selectedMonth,
  setSelectedYear,
  setSelectedMonth,
  currentDate,
  selectedYear,
  selectDateFromCalendar,
  extended,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const domRef = useOnClickOutside(() => setIsCalendarOpen(false));

  const months: string[] = dayjs.months();

  const handleMonthChange = (month: number | 'Today') => {
    setSelectedMonth(month);
    setIsCalendarOpen(false);
  };

  const handleSelect = useCallback(() => {
    selectDateFromCalendar(
      selectedMonth === current ? current : new Date(selectedYear, selectedMonth, 1),
    );
  }, [selectedMonth, selectedYear, selectDateFromCalendar]);

  const handleOnClick = useCallback(() => {
    setIsCalendarOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    handleSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedYear]);

  return (
    <div className={classes['c-month-picker__button-holder']} ref={domRef}>
      <button
        className={`${classes['c-month-picker__button']} ${
          isCalendarOpen ? classes['c-month-picker__button--expanded'] : ''
        }`}
        onClick={handleOnClick}
      >
        {selectedMonth === current ? current : formatDate(currentDate, 'MMM YYYY')}
        <Minimize className={classes['c-month-picker__button-arrow']} />
      </button>
      {isCalendarOpen && (
        <div className={classes['c-month-picker__month-year-picker']}>
          <YearPicker
            extended={extended}
            selectedMonth={selectedMonth == current ? current : months[selectedMonth]}
            handleSelectedMonth={handleMonthChange}
            selectedYear={String(selectedYear)}
            handleSelectedYear={setSelectedYear}
          />
        </div>
      )}
    </div>
  );
};
