import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { RequestState } from 'config/constants';
import {
  getTimeAwaysByOrganization,
  getPublicHolidays,
} from 'modules/timeline/redux/timelineActions';
import AwayUsers from 'modules/timeline/components/AwayUsers';
import classes from './TimelinePage.module.scss';
import { current } from 'components/core/YearPicker/YearPicker';
import { formatDateForInput } from 'utils/dates';
import { ColorLegend, Empty, Filter, Spinner, MonthPicker, DayPicker } from 'components/core';
import WelcomeTimeline from 'assets/empty/WelcomeTimeline.svg';
import BookTimeAwayModal from 'modules/timeAway/components/BookTimeAwayModal';
import { useToolbarTitle } from 'hooks';

const today = new Date();

const TimelinePage = () => {
  const [hoveredDay, setHoveredDay] = useState<number | null>(today.getTime());
  const [currentDate, setCurrentDate] = useState<Date | 'Today'>(current);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<number | 'Today'>(current);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { filterClicked } = useAppSelector((state) => state.toolbar);
  const { loading, users } = useAppSelector((state) => state.timeline);

  const dispatch = useAppDispatch();

  useToolbarTitle({ title: ['Timeline'], subtitle: `${users.length} people away` }, [users.length]);

  const checkIsCurrentSelected = () => {
    if (currentDate === current) return today;
    return currentDate;
  };

  const handleModalOpen = (value: boolean) => setIsModalOpen(value);

  const selectDateFromCalendar = (date: Date | 'Today') => {
    setCurrentDate(date);
  };

  const subtractMonthHandler = () => {
    setCurrentDate(dayjs(checkIsCurrentSelected()).subtract(1, 'M').toDate());
  };

  const increaseMonthHandler = () => {
    setCurrentDate(dayjs(checkIsCurrentSelected()).add(1, 'M').toDate());
  };

  const fetchData = useCallback(
    (_startDate: Date, _endDate: Date) => {
      const startDate = formatDateForInput(_startDate);
      const endDate = formatDateForInput(_endDate);

      dispatch(
        getTimeAwaysByOrganization({
          startDate,
          endDate,
        }),
      );

      dispatch(
        getPublicHolidays({
          startDate,
          endDate,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (currentDate != current) {
      const currentDateFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const currentDateLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      fetchData(currentDateFirstDay, currentDateLastDay);
    } else {
      const addMonth = dayjs(today).add(1, 'M').toDate();

      const nextMonthDays = dayjs(addMonth).daysInMonth();

      const daysInNextMonthToShow =
        nextMonthDays < today.getDate() ? nextMonthDays : today.getDate() - 1;

      const currentDateFirstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      const currentDateLastDay = new Date(
        addMonth.getFullYear(),
        addMonth.getMonth(),
        daysInNextMonthToShow,
      );

      fetchData(currentDateFirstDay, currentDateLastDay);
    }
  }, [currentDate, fetchData]);

  return (
    <div className={classes['c-timeline-page']}>
      {filterClicked && (
        <div className={classes['c-timeline-page__filters']}>
          <Filter />
        </div>
      )}
      <div className={classes['c-timeline-page__content']}>
        <div className={classes['c-timeline-page__color-legend']}>
          <ColorLegend />
          <div className={classes['c-timeline-page__month-picker-wrap']}>
            <MonthPicker
              extended
              currentDate={currentDate}
              selectDateFromCalendar={selectDateFromCalendar}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              setSelectedMonth={setSelectedMonth}
              setSelectedYear={setSelectedYear}
            />
          </div>
        </div>
        <DayPicker
          subtractMonthHandler={subtractMonthHandler}
          currentDate={currentDate || today}
          increaseMonthHandler={increaseMonthHandler}
          hoveredDay={hoveredDay}
          setHoveredDay={setHoveredDay}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
        {loading === RequestState.PENDING ? (
          <div className={classes['c-timeline-page__spinner-container']}>
            <Spinner />
          </div>
        ) : (
          <>
            {users.length ? (
              <AwayUsers
                currentDate={currentDate || today}
                hoveredDay={hoveredDay}
                setHoveredDay={setHoveredDay}
              />
            ) : (
              <Empty
                img={WelcomeTimeline}
                title="Welcome to your timeline"
                info="A beautifully designed timeline will keep you in the loop on who's away and why. Manage and customize your time away request 
        so you can effectively plan your work, company events and special occasions. Go to your settings and customize your time away requests."
              />
            )}
          </>
        )}
      </div>
      {isModalOpen && <BookTimeAwayModal opened={isModalOpen} openModal={handleModalOpen} />}
    </div>
  );
};

export default TimelinePage;
