import { useQuery } from 'hooks';
import OrganizationForm from './Forms/OrganizationForm';
import EmailForm from './Forms/EmailForm';

const SignUp = () => {
  const token = useQuery().get('token');

  return token ? <OrganizationForm token={token} /> : <EmailForm />;
};

export default SignUp;
