import { useCallback, useEffect, useState } from 'react';
import classes from '../../styles/DirectReports.module.scss';
import { useSelector } from 'react-redux';
import { type ReportingUser } from 'modules/profile/models/profileModels';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { updateDirectReports } from 'modules/profile/api/profile.api';
import {
  selectUser,
  updateDirectReports as updateDirectReportsAction,
} from 'modules/profile/redux/userSlice';
import { useParams } from 'react-router-dom';
import { PopUpModal, UsersOverview } from 'components/core';
import { SectionActions, UserInfo, AddPeopleModal } from 'modules/shared/components';
import toast from 'utils/notifications/CustomToast';
import { UserProjectPhase } from 'modules/projects/models';

const DirectReports = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const { directReports } = useSelector(selectUser);

  const [reportsUserList, setReportsUserList] = useState<ReportingUser[]>(directReports);

  const dispatch = useAppDispatch();
  const { id } = useParams();

  const removeUser = (id: number) => {
    setReportsUserList((prevUsers) => prevUsers.filter((user) => user.id !== id));
  };

  const handleSelectUsers = (usersId: number[], selectedUsers: UserProjectPhase[]) => {
    const newUsersWithProfilePicture = selectedUsers.map((user) => ({
      id: user.simpleUserDto.id,
      firstName: user.simpleUserDto.firstName,
      lastName: user.simpleUserDto.lastName,
      jobTitle: user.simpleUserDto.jobTitle,
      profilePicture: user.simpleUserDto.profilePicture,
    })) as ReportingUser[];
    setReportsUserList((prev) => [...prev, ...newUsersWithProfilePicture]);
    setIsModalOpen(false);
  };
  const handleSave = useCallback(async () => {
    if (!id) return;

    const reportingUserIds = reportsUserList.map((user) => user.id);
    setIsLoading(true);

    try {
      await updateDirectReports(reportingUserIds, id);
      setIsFormOpen(false);
      setIsModalOpen(false);
      dispatch(updateDirectReportsAction(reportsUserList));
      toast.success('You have successfully updated direct reports.');
    } catch {
      toast.error('Something went wrong while updating direct reports. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, id, reportsUserList]);

  const handleCancel = useCallback(() => {
    setReportsUserList(directReports);
    setIsFormOpen(!isFormOpen);
    setIsModalOpen(false);
  }, [directReports, isFormOpen]);

  useEffect(() => {
    setReportsUserList(directReports);
  }, [directReports]);

  const linkToUserHandler = useCallback(
    (id: number) => {
      setSelectedId(id);
      setIsOpen(true);
    },
    [setSelectedId],
  );

  const renderContent = useCallback(() => {
    if (directReports.length || isFormOpen) {
      return (
        <UsersOverview
          isEditMode={isFormOpen}
          usersList={isFormOpen ? reportsUserList : directReports}
          removeUser={removeUser}
          setIsModalOpen={setIsModalOpen}
          clickable={false}
        />
      );
    }

    return (
      <span className={classes['c-direct-reports__no-data']}>This user has no direct reports.</span>
    );
  }, [isFormOpen, reportsUserList, directReports, linkToUserHandler]);

  const renderUserInfo = useCallback(() => {
    if (isOpen && selectedId) {
      return (
        <UserInfo
          id={selectedId}
          setSelectedId={setSelectedId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      );
    }
  }, [selectedId, isOpen]);

  return (
    <div className={classes['c-direct-reports']}>
      <div className={classes['c-direct-reports__header']}>
        <h2 className={classes['c-direct-reports__title']}>Direct reports</h2>
        {renderUserInfo()}
        <SectionActions
          isEditOpen={isFormOpen}
          handleEdit={() => setIsFormOpen(true)}
          handleCancel={handleCancel}
          handleSave={handleSave}
          isLoading={isLoading}
        />
      </div>
      {renderContent()}
      {isModalOpen && (
        <PopUpModal
          title="Add user"
          opened={isModalOpen}
          width={40}
          closeModal={() => setIsModalOpen(false)}
        >
          <AddPeopleModal buttonContent="Add" onClick={handleSelectUsers} />
        </PopUpModal>
      )}
    </div>
  );
};

export default DirectReports;
