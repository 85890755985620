import { useState } from 'react';
import { removeUserData } from 'modules/auth/redux/authSlice';
import { logout } from 'modules/auth/api/auth.api';
import { NavLink, useNavigate } from 'react-router-dom';
import { clearLocalStorageTokens, getAccessToken } from 'utils/auth/tokenService';
import classes from './UserDropDown.module.scss';
import { useUserRole, useOnClickOutside } from 'hooks';
import { Avatar, toast } from 'components/core';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { BasicUser, initializeState } from '../redux/toolbarSlice';
import { ReactComponent as Settings } from 'assets/Settings.svg';
import { ReactComponent as Logout } from 'assets/Logout.svg';
import { removeOrganization } from 'state/shared/organization';
import { ReactComponent as UserIcon } from 'assets/ProfileSettings.svg';
import { useOAuth } from 'modules/auth/hooks';

type Props = {
  user?: BasicUser;
};

export const UserDropDown = ({ user }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const closeDropDownHook = useOnClickOutside(() => setIsDropdownOpen(false));
  const { isAdminOrSuperAdmin } = useUserRole(user?.role);

  const { handleLogout: handleOAuthLogout } = useOAuth();

  const handleUserDropDown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleLogout = async () => {
    try {
      await logout(getAccessToken() ?? '');
      handleOAuthLogout();
      dispatch(removeUserData());
      dispatch(initializeState());
      dispatch(removeOrganization());
      clearLocalStorageTokens();
      navigate('/');
    } catch {
      toast('error', 'Something went wrong while logging out. Try again.');
    }
  };

  return (
    <div className={classes['c-user-dropdown']}>
      <div
        className={classes['c-user-dropdown__user-icon']}
        onClick={handleUserDropDown}
        ref={closeDropDownHook}
      >
        <Avatar imageUrl={user?.profilePhotoPath} />
        {isDropdownOpen && (
          <div className={classes['c-user-dropdown__dropdown']}>
            <NavLink
              className={classes['c-user-dropdown__item-link']}
              to={`/user/${user?.id}/about`}
            >
              <UserIcon />
              Profile
            </NavLink>
            {isAdminOrSuperAdmin && (
              <NavLink className={classes['c-user-dropdown__item-link']} to="/settings">
                <Settings />
                Settings
              </NavLink>
            )}
            <button
              className={`${classes['c-user-dropdown__item-link']} ${classes['c-user-dropdown__item-link--logout']}`}
              onClick={handleLogout}
            >
              <Logout />
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
