import type { ProjectPhase } from '../models';

export const getHighestPhaseEndDate = (projectPhases: ProjectPhase[]) => {
  if (!projectPhases.length) return new Date();

  const maxDate = Math.max(...projectPhases.map((phase) => new Date(phase.dueDate).getTime()));

  return new Date(maxDate);
};

export const isExistingPhase = (phaseId: number | string) => !phaseId?.toString().includes('temp');
