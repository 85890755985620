import { ReactNode } from 'react';
import classes from './Button.module.scss';
import { ButtonVariant, ButtonType, IconPadding } from './Button';

type Props = {
  align?: 'left' | 'right';
  type?: ButtonType;
  variant?: ButtonVariant;
  icon?: ReactNode;
  iconPadding?: IconPadding;
};

export const ButtonIcon = ({ align, icon, type, variant, iconPadding }: Props) => (
  <span
    className={`${classes['c-button__icon']} ${classes[`c-button__icon--${align}`]} ${
      variant === 'outlined' ? classes[`c-button__icon--${type}`] : ''
    }
      ${iconPadding ? classes[`c-button__icon--${iconPadding}`] : ''}`}
  >
    {icon}
  </span>
);
