import { UserType } from 'config/constants';
import { Container, FilterButton } from 'components/core';

export const userFilterTypes: Record<UserType, string> = {
  ACTIVE_MEMBER: 'Active member',
  NEW_HIRE: 'New hire',
  ALL_USERS: 'All members',
  OFFBOARDED: 'Offboarded',
};

type Props = {
  selectedType: UserType;
  setSelectedType: (userType: UserType) => void;
};

const UserTypeFilter = ({ selectedType, setSelectedType }: Props) => {
  return (
    <Container gap="xs">
      {Object.keys(userFilterTypes).map((userType) => {
        const userTypeKey = userType as UserType;

        return (
          <FilterButton
            key={userTypeKey}
            selected={selectedType === userTypeKey}
            label={userFilterTypes[userTypeKey]}
            onClick={() => setSelectedType(userTypeKey)}
          />
        );
      })}
    </Container>
  );
};

export default UserTypeFilter;
