import { createSlice } from '@reduxjs/toolkit';
import { State } from 'state/store';
import { getTimeAwaysByUser } from './timeAwaysActions';
import { RequestState } from 'config/constants';
import { TimeAway } from 'modules/timeline/models/timeAwayModels';

type TimeAwaysState = {
  timeAways: TimeAway[];
  loading: RequestState;
};

export const selectUserTimeAways = (state: State) => state.timeAways;

const initialState: TimeAwaysState = {
  timeAways: [],
  loading: RequestState.IDLE,
};

const timeAwaysSlice = createSlice({
  name: 'timeAways',
  initialState,
  reducers: {
    initializeTimeAways: () => initialState,
    setTimeAways: (state, action) => {
      state.timeAways = action.payload;
    },
    updateTimeAways: (state, action) => {
      state.timeAways = [...state.timeAways, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimeAwaysByUser.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getTimeAwaysByUser.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.timeAways = action.payload ?? [];
      })
      .addCase(getTimeAwaysByUser.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { initializeTimeAways, setTimeAways, updateTimeAways } = timeAwaysSlice.actions;
export default timeAwaysSlice.reducer;
