import { Link } from 'react-router-dom';
import classes from './WorkflowTemplateCard.module.scss';

type Props = {
  id?: number;
  name: string;
  numberOfSections: number;
  numberOfTasks: number;
};

const WorkflowTemplateCard = ({ id, name, numberOfSections, numberOfTasks }: Props) => {
  return (
    <Link to={`${id}`} className={classes['c-workflow-template-card']}>
      <h1 className={classes['c-workflow-template-card__title']}>{name}</h1>
      <span
        className={classes['c-workflow-template-card__subtitle']}
      >{`${numberOfSections} sections with ${numberOfTasks} tasks`}</span>
    </Link>
  );
};

export default WorkflowTemplateCard;
