import { useEffect, type ReactElement, useState } from 'react';
import classes from './SectionInfoReadOnly.module.scss';
import { pascalCase } from 'utils/utilFunctions';
import { ReactComponent as WarningIcon } from 'assets/WarningIcon.svg';
import { Button } from 'components/core';

type Props = {
  icon: ReactElement;
  name: string;
  data: string;
  workingDays?: boolean;
  pascalCaseState?: boolean;
  type?: 'file';
  handlePreview?: () => void;
  handleDownload?: () => void;
  isLoading?: boolean;
};

const SectionInfoReadOnly = ({
  icon,
  name,
  data,
  pascalCaseState,
  type,
  handlePreview,
  handleDownload,
  isLoading,
}: Props) => {
  const [processedData, setProcessedData] = useState<string>(data);

  useEffect(() => {
    if (!data) return setProcessedData('');
    setProcessedData(data.replace('_', ' '));
  }, [data]);

  return (
    <div className={classes['c-section-info-read-only']}>
      <div className={classes['c-section-info-read-only__data-container']}>
        <div className={classes['c-section-info-read-only__data']}>
          {icon} <div className={classes['c-section-info-read-only__label']}>{name}</div>
        </div>

        {!processedData || !processedData.length || processedData === '' ? (
          <WarningIcon />
        ) : (
          <span
            className={`${classes['c-section-info-read-only__data']} ${classes['c-section-info-read-only__data--secondary']}`}
          >
            {pascalCaseState ? pascalCase(processedData) : processedData}
          </span>
        )}
      </div>
      {type === 'file' && data && (
        <div className={classes['c-section-info-read-only__buttons-container']}>
          <Button
            onClick={() => handleDownload?.()}
            isLoading={isLoading}
            variant="outlined"
            size="extra-small"
          >
            Download
          </Button>
          <Button
            type="tertiary"
            variant="outlined"
            size="extra-small"
            onClick={() => handlePreview?.()}
          >
            Preview
          </Button>
        </div>
      )}
    </div>
  );
};

export default SectionInfoReadOnly;
