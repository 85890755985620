import { type ChangeEvent, useState, useCallback, useEffect } from 'react';
import { DropDown, Input, Button, DeleteButton } from 'components/core';
import classes from './CustomFieldModal.module.scss';
import {
  CustomFieldValueType,
  type CustomField,
  type CustomFieldSingleChoiceOption,
  type NewCustomField,
} from 'modules/settings/models/settingsModels';
import { ReactComponent as AddIcon } from 'assets/AddIconRoundOutline.svg';
import {
  customFieldSections,
  customFieldValueType,
  initialCustomFieldSingleChoiceOption,
} from '../../../utils/common';

type Props = {
  type: 'new' | 'edit';
  customField: NewCustomField | CustomField;
  isLoading: boolean;
  onUpdate?: (customField: CustomField) => void;
  onCreate?: (customField: NewCustomField) => void;
};

const MIN_NUMBER_OF_OPTIONS = 2;

const CustomFieldModal = ({ type, customField, isLoading, onUpdate, onCreate }: Props) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [updatedCustomField, setUpdatedCustomField] = useState<NewCustomField | CustomField>(
    customField,
  );

  const singleChoiceOptions: CustomFieldSingleChoiceOption[] =
    updatedCustomField.singleChoiceString.map((option, index) => ({
      id: index,
      name: option,
    }));

  const [options, setOptions] = useState<CustomFieldSingleChoiceOption[]>(
    customField.singleChoiceString.length
      ? singleChoiceOptions
      : [initialCustomFieldSingleChoiceOption],
  );

  const { name, sectionName, valueType } = updatedCustomField;

  const isSingleChoice = valueType === CustomFieldValueType.SINGLE_CHOICE;
  const valueTypes = Object.keys(CustomFieldValueType);

  const areFieldsValid = !!name.trim() && !!sectionName && !!valueType;
  const isSingleChoiceValid =
    isSingleChoice &&
    options.length >= 2 &&
    options.some((option) => !!option.name.trim()) &&
    areFieldsValid;

  const handleOptionSelect = (key: string, value: string) => {
    setUpdatedCustomField((previous) => ({ ...previous, [key]: value }));
  };

  const handleSetSectionName = (value: string) => {
    handleOptionSelect('sectionName', value);
  };

  const handleSetValueType = (value: string) => {
    handleOptionSelect('valueType', value);
  };

  const handleAddOption = () => {
    setOptions((previous) => [
      ...previous,
      { ...initialCustomFieldSingleChoiceOption, id: previous.length + 1 },
    ]);
  };

  const handleUpdate = useCallback(() => {
    setUpdatedCustomField((previous) => ({
      ...previous,
      singleChoiceString:
        options.length && isSingleChoice ? options.map((option) => option.name) : [],
    }));
  }, [options, isSingleChoice]);

  const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setUpdatedCustomField((previous) => ({ ...previous, name: value }));
  }, []);

  const handleOptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, id: number) => {
      const { value } = event.target;

      setOptions((previous) => [
        ...previous.map((option) => (option.id === id ? { ...option, name: value } : option)),
      ]);

      setUpdatedCustomField((previous) => ({
        ...previous,
        singleChoiceString: options.map((option) => option.name),
      }));
    },
    [options],
  );

  const handleDeleteOption = (id: number) => {
    setOptions((previous) => previous.filter((option) => option.id !== id));
  };

  const handleSubmit = () => {
    setIsFormSubmitted(true);

    if (!areFieldsValid || (isSingleChoice && !isSingleChoiceValid)) return;

    setUpdatedCustomField((previous) => ({
      ...previous,
      singleChoiceString:
        options.length && isSingleChoice ? options.map((option) => option.name) : [],
    }));

    type === 'edit'
      ? onUpdate?.(updatedCustomField as CustomField)
      : onCreate?.(updatedCustomField);
  };

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate]);

  return (
    <div className={classes['c-custom-field-modal']}>
      <Input
        label="Name"
        name="name"
        id="customFieldName"
        placeholder="enter name"
        size="medium"
        value={name}
        isSubmitted={isFormSubmitted}
        handleOnChange={handleNameChange}
      />
      <label className={classes['c-custom-field-modal__dropdown-label']}>Section</label>
      <DropDown
        options={customFieldSections.map((option) => option)}
        displayedOptions={[
          <span className={classes['c-custom-field-modal__dropdown-option']} key={0}>
            {'Profile > Basics'}
          </span>,
          <span className={classes['c-custom-field-modal__dropdown-option']} key={1}>
            {'Profile > Banking'}
          </span>,
          <span className={classes['c-custom-field-modal__dropdown-option']} key={3}>
            {'Profile > Employment'}
          </span>,
          <span className={classes['c-custom-field-modal__dropdown-option']} key={2}>
            {'Profile > Equipment'}
          </span>,
        ]}
        selectedOption={sectionName}
        size="medium"
        setSelectedOption={handleSetSectionName}
        isSubmitted={isFormSubmitted}
      />
      <label className={classes['c-custom-field-modal__dropdown-label']}>Type</label>
      <DropDown
        options={valueTypes}
        displayedOptions={valueTypes.map(
          (option) => customFieldValueType[option as keyof typeof customFieldValueType],
        )}
        size="medium"
        selectedOption={valueType}
        isSubmitted={isFormSubmitted}
        setSelectedOption={handleSetValueType}
      />
      {isSingleChoice && options.length < MIN_NUMBER_OF_OPTIONS && (
        <div className={classes['c-custom-field-modal__dropdown-info']}>
          * At least two options are required
        </div>
      )}
      {isSingleChoice && (
        <>
          <div className={classes['c-custom-field-modal__single-choice-button-container']}>
            <Button size="small" variant="text" leftIcon={<AddIcon />} onClick={handleAddOption}>
              Add more options
            </Button>
          </div>
          {options.map(({ id, name }, index) => (
            <div key={id} className={classes['c-custom-field-modal__input-container']}>
              <Input
                size="medium"
                name="option"
                id="customFieldOption"
                placeholder="enter option"
                value={name}
                isSubmitted={isFormSubmitted}
                handleOnChange={(event) => handleOptionChange(event, id)}
              />
              <DeleteButton onClick={() => handleDeleteOption(id)} disabled={index === 0} />
            </div>
          ))}
        </>
      )}
      <div className={classes['c-custom-field-modal__button-container']}>
        <Button size="medium" isLoading={isLoading} onClick={handleSubmit}>
          {type === 'edit' ? 'Update custom field' : 'Add custom field'}
        </Button>
      </div>
    </div>
  );
};

export default CustomFieldModal;
