import type { ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from 'assets/PencilSquare.svg';
import classes from './ProjectCard.module.scss';
import { ProjectUserList } from '../ProjectUserList/ProjectUserList';
import { ProjectUser } from 'modules/projects/models';
import { IconButton } from 'components/core';

type Props = {
  title: ReactNode;
  link: string;
  info: string;
  estimatedTime: string;
  completeness: string;
  progress: ReactNode;
  users: ProjectUser[];
  onEdit: () => void;
};

const ProjectCard = ({
  title,
  info,
  estimatedTime,
  link,
  progress,
  completeness,
  users,
  onEdit,
}: Props) => {
  const navigate = useNavigate();

  return (
    <div role="link" onClick={() => navigate(link)} className={classes['c-project-card']}>
      <div className={classes['c-project-card__header']}>
        <span className={classes['c-project-card__title']}>{title}</span>
        <Link
          className={classes['c-project-card__edit-option-link']}
          to={link}
          aria-label="Edit project"
          onClick={onEdit}
        >
          <IconButton
            icon={<EditIcon />}
            tooltipContent="Edit"
            tooltipPosition="bottom"
            iconSize="large"
          />
        </Link>
      </div>
      <div className={classes['c-project-card__row']}>
        <ProjectUserList users={users} />
        <div className={classes['c-project-card__row-info']}>{info}</div>
      </div>
      <div className={classes['c-project-card__row']}>
        <h3 className={classes['c-project-card__row-subtitle']}>Estimated time to finish</h3>
        <p className={classes['c-project-card__row-info']}>{estimatedTime}</p>
      </div>
      <div className={classes['c-project-card__row']}>
        <div className={classes['c-project-card__progress-wrap']}>{progress}</div>
        <div className={classes['c-project-card__completeness']}>{completeness}</div>
      </div>
    </div>
  );
};

export default ProjectCard;
