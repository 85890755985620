import { useCallback, useEffect, useMemo, useState, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { FilterCategory } from 'config/constants';
import { selectUsers } from 'modules/people/redux/peopleSlice';
import { toggleFilter } from 'components/core/Filter/redux/filterSlice';
import { Avatar, Button, IconButton, Tooltip, UsersOverview, toast } from 'components/core';
import { type User } from 'modules/people/model/User';
import { ReactComponent as CloseIcon } from 'assets/Close.svg';
import { ReactComponent as LinkedInIcon } from 'assets/social/linkedin.svg';
import { ReactComponent as TwitterIcon } from 'assets/social/twitter.svg';
import { ReactComponent as InstagramIcon } from 'assets/social/instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/social/facebook.svg';
import classes from './UserInfo.module.scss';
import { useUserRole, useOnClickOutside } from 'hooks';
import { getEffectiveOn } from 'modules/people/utils/userInfo';
import { getListFromString } from 'utils';
import { ReactComponent as UserIcon } from 'assets/ProfileSettings.svg';
import { getUserBaseInfo } from 'modules/people/api/people.api';
import { replaceDashWithCommaSpace } from 'utils/utilFunctions';

type Props = {
  id: number | null;
  setSelectedId: (id: number | null) => void;
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};

export const UserInfo = ({ id, setSelectedId, isOpen, setIsOpen }: Props) => {
  const [userData, setUserData] = useState<User | null>(null);
  const [isItemHovering, setIsItemHovering] = useState<string>('');
  const [projects, setProjects] = useState<string[]>([]);
  const modalRef = useOnClickOutside(() => handleClose());

  const role = useAppSelector((state) => state.auth.user?.role);
  const users = useAppSelector(selectUsers);
  const handleItemHovering = (isHovering: string) => {
    setIsItemHovering(isHovering);
  };
  const { isAdminOrSuperAdmin } = useUserRole(role);
  const isLastProject = useCallback((index: number, length: number) => index === length - 1, []);

  const dispatch = useAppDispatch();

  const isClosable = !!setIsOpen;

  const handleClose = () => {
    setIsOpen?.(false);
    setUserData(null);
    if (isClosable) setSelectedId(null);
  };

  const reportingToUser = useMemo((): string => {
    if (!userData?.reportingToUser) return 'N/A';

    return `${userData.reportingToUser.firstName} ${userData.reportingToUser.lastName}`;
  }, [userData]);

  const handleOnClick = useCallback(
    ({
      event,
      filterCategory,
      filterValue,
    }: {
      event: MouseEvent<HTMLSpanElement>;
      filterCategory: string;
      filterValue: string;
    }) => {
      event?.stopPropagation();
      dispatch(
        toggleFilter({
          filterCategory,
          filterValue,
        }),
      );
    },
    [dispatch],
  );

  const getSelectedUser = async (id: number) => {
    try {
      const response = await getUserBaseInfo(id);
      return response.data;
    } catch {
      toast('error', 'Something went wrong. Try again');
    }
  };

  const linkToUserHandler = useCallback(
    (id: number) => {
      setSelectedId(id);
    },
    [setSelectedId],
  );

  const handleOnClickReportingUser = () => {
    if (userData?.reportingToUser?.id) linkToUserHandler(userData.reportingToUser.id);
  };
  const getUserData = async () => {
    if (!id) return;
    let user = users.find((user) => user.id === id);
    if (!user) user = await getSelectedUser(id);
    if (!user) return;
    setUserData(user);
  };

  useEffect(() => {
    getUserData();
  }, [id]);

  useEffect(() => {
    if (!userData?.projectNames) return;

    const projectsList = getListFromString(userData.projectNames);
    setProjects(projectsList);
  }, [userData?.projectNames]);

  const checkSocials = useMemo((): boolean | undefined => {
    if (!userData?.social) return true;
    {
      return Object.entries(userData.social).every(([key, value]) => {
        if (key === 'biography') {
          return true;
        }
        return value === '';
      });
    }
  }, [userData?.social]);
  if (!isOpen || !userData) return null;

  return createPortal(
    <div
      className={`${isClosable ? classes['c-user-info__overlay'] : ''}`}
      role="button"
      onClick={handleClose}
      ref={isClosable ? modalRef : null}
    >
      <div
        className={`${classes['c-user-info']} ${
          isClosable ? classes['c-user-info--closable'] : ''
        }`}
        onClick={(event) => event.stopPropagation()}
      >
        <div className={classes['c-user-info--top']}>
          {isAdminOrSuperAdmin && (
            <div className={classes['c-user-info__full-profile']}>
              <Link to={`/user/${userData.id}/about`}>
                <Button
                  variant="text"
                  leftIcon={<UserIcon className={classes['c-user-info__icon']} />}
                >
                  Full Profile
                </Button>
              </Link>
            </div>
          )}
          {isClosable && (
            <IconButton
              icon={<CloseIcon className={classes['c-user-info__close-icon']} />}
              aria-label="Close user info sidebar"
              onClick={handleClose}
            />
          )}
          <div className={classes['c-user-info__avatar']}>
            <Avatar size="medium" imageUrl={userData.profilePhotoPath} />
          </div>
        </div>
        <div className={classes['c-user-info__info-section']}>
          <span className={classes['c-user-info__data--name']}>
            {userData.firstName} {userData.lastName}
          </span>
          {userData.jobTitle ? (
            <span className={classes['c-user-info__data--job-title']}>{userData.jobTitle}</span>
          ) : (
            <span className={classes['c-user-info__data--missing-info']}>N/A</span>
          )}
        </div>
        <div className={classes['c-user-info__sections-container']}>
          <div
            className={`${classes['c-user-info__info-section']} ${classes['c-user-info__info-section--m-top']}`}
          >
            <div className={classes['c-user-info__data-row--space']}>
              <label className={classes['c-user-info__data-label']}>Department</label>
              {userData.department ? (
                <div className={classes['c-user-info__button-wrap']}>
                  <button
                    className={`${classes['c-user-info__data']} ${classes['c-user-info__data--clickable']}`}
                    onClick={(event) =>
                      handleOnClick({
                        event,
                        filterCategory: FilterCategory.DEPARTMENT,
                        filterValue: userData.department,
                      })
                    }
                    onMouseEnter={() => handleItemHovering(FilterCategory.DEPARTMENT)}
                    onMouseLeave={() => handleItemHovering('')}
                  >
                    {userData.department}
                  </button>
                  <Tooltip
                    position="top"
                    isOpen={isItemHovering === FilterCategory.DEPARTMENT}
                    color="black"
                  >
                    Filter by Department
                  </Tooltip>
                </div>
              ) : (
                <span className={classes['c-user-info__data--missing']}>N/A</span>
              )}
            </div>
            <div className={classes['c-user-info__data-row--space']}>
              <label className={classes['c-user-info__data-label']}>Project</label>
              {userData.projectNames ? (
                <div className={classes['c-user-info__button-wrap']}>
                  {projects.map((value, index) => (
                    <button
                      className={`${classes['c-user-info__data']} ${classes['c-user-info__data--clickable']}`}
                      onClick={(event) =>
                        handleOnClick({
                          event,
                          filterCategory: FilterCategory.PROJECT,
                          filterValue: value,
                        })
                      }
                      onMouseEnter={() => handleItemHovering(FilterCategory.PROJECT)}
                      onMouseLeave={() => handleItemHovering('')}
                      key={index}
                    >
                      {value}
                      {!isLastProject(index, projects.length) && <span>, </span>}
                    </button>
                  ))}
                  <Tooltip
                    position="top"
                    color="black"
                    isOpen={isItemHovering === FilterCategory.PROJECT}
                  >
                    Filter By Project
                  </Tooltip>
                </div>
              ) : (
                <span className={classes['c-user-info__data--missing']}>N/A</span>
              )}
            </div>
            <div className={classes['c-user-info__data-row--space']}>
              <label className={classes['c-user-info__data-label']}>Location</label>
              {userData.placeOfWork ? (
                <div className={classes['c-user-info__button-wrap']}>
                  <button
                    className={`${classes['c-user-info__data']} ${classes['c-user-info__data--clickable']}`}
                    onClick={(event) =>
                      handleOnClick({
                        event,
                        filterCategory: FilterCategory.LOCATION,
                        filterValue: userData.placeOfWork,
                      })
                    }
                    onMouseEnter={() => handleItemHovering(FilterCategory.LOCATION)}
                    onMouseLeave={() => handleItemHovering('')}
                  >
                    {userData.placeOfWork && replaceDashWithCommaSpace(userData.placeOfWork)}
                  </button>
                  <Tooltip
                    position="top"
                    color="black"
                    isOpen={isItemHovering == FilterCategory.LOCATION}
                  >
                    Filter By Location
                  </Tooltip>
                </div>
              ) : (
                <span className={classes['c-user-info__data--missing']}>N/A</span>
              )}
            </div>

            <div className={classes['c-user-info__data-row--space']}>
              <label className={classes['c-user-info__data-label']}>Reporting to</label>
              {userData.reportingToUser && reportingToUser !== 'N/A' ? (
                <Avatar
                  imageUrl={userData.reportingToUser.profilePicture || ''}
                  title={
                    <span className={classes['c-addmember-dropdown__option-value']}>
                      {userData.reportingToUser.firstName} {userData.reportingToUser.lastName}
                    </span>
                  }
                  size="extra-small"
                  horizontal
                  clickable
                  handleOnClick={handleOnClickReportingUser}
                />
              ) : (
                <span className={classes['c-user-info__data--missing']}>N/A</span>
              )}
            </div>
          </div>
          <div className={classes['c-user-info__info-section']}>
            <p className={classes['c-user-info__joined']}>
              Joined {getEffectiveOn(userData.effectiveOn)}
            </p>
            <div
              className={`${classes['c-user-info__data-row']} ${classes['c-user-info__data-row--bio']}`}
            >
              <label className={classes['c-user-info__data-label']}>About </label>
              {userData.social?.biography ? (
                <span
                  className={`${classes['c-user-info__data--secondary']} ${classes['c-user-info__data--bio']}`}
                >
                  {userData.social.biography}
                </span>
              ) : (
                <span className={classes['c-user-info__data--missing']}>N/A</span>
              )}
            </div>
            <div className={classes['c-user-info__data-row--space']}>
              <label className={classes['c-user-info__data-label']}>Email</label>
              {userData.email ? (
                <a
                  className={`${classes['c-user-info__data']} ${classes['c-user-info__data--email']} ${classes['c-user-info__data--clickable']}`}
                  href={`mailto:${userData?.email}`}
                >
                  {userData?.email}
                </a>
              ) : (
                <span className={classes['c-user-info__data--missing']}>N/A</span>
              )}
            </div>
            <div className={classes['c-user-info__data-row--space']}>
              <label className={classes['c-user-info__data-label']}>Socials</label>
              {checkSocials === false ? (
                <div className={classes['c-user-info__social-media-icons']}>
                  {userData.social?.linkedinLink && (
                    <a href={userData.social?.linkedinLink} target="_blank" rel="noreferrer">
                      <LinkedInIcon className={classes['c-user-info__social-media-icon']} />
                    </a>
                  )}
                  {userData.social?.facebookLink && (
                    <a href={userData.social?.facebookLink} target="_blank" rel="noreferrer">
                      <FacebookIcon className={classes['c-user-info__social-media-icon']} />
                    </a>
                  )}
                  {userData.social?.instagramLink && (
                    <a href={userData.social?.instagramLink} target="_blank" rel="noreferrer">
                      <InstagramIcon className={classes['c-user-info__social-media-icon']} />
                    </a>
                  )}
                  {userData.social?.twitterLink && (
                    <a href={userData.social?.twitterLink} target="_blank" rel="noreferrer">
                      <TwitterIcon className={classes['c-user-info__social-media-icon']} />
                    </a>
                  )}
                </div>
              ) : (
                <span className={classes['c-user-info__data--missing']}>N/A</span>
              )}
            </div>
          </div>

          <div className={classes['c-user-info__reports']}>
            <span className={classes['c-user-info__reports--label']}>Direct reports</span>
            {userData.directReports?.length ? (
              <UsersOverview
                linkToUserHandler={linkToUserHandler}
                size="small-plus"
                usersList={userData.directReports?.map((el) => ({
                  id: el.id,
                  firstName: el.firstName,
                  lastName: el.lastName,
                  profilePicture: el.profilePicture || '',
                }))}
                clickable={true}
              />
            ) : (
              <div
                className={`${classes['c-user-info__reports-container']} ${classes['c-user-info__reports-container--no-data']}`}
              >
                <span>This user has no direct reports.</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};
