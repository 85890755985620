import classes from './Tab.module.scss';

type Props = {
  text: string;
  itemId: number;
  activeTab: number | null;
  setActiveTab: (itemId: number) => void;
};

export const Tab = ({ text, itemId, activeTab, setActiveTab }: Props) => {
  return (
    <button
      className={`${classes['c-tab']} ${itemId === activeTab ? classes['c-tab--active'] : ''}`}
      onClick={() => setActiveTab(itemId)}
    >
      {text}
    </button>
  );
};
