import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type {
  OrganizationDocument,
  CompanyModel,
  LocationModel,
  CustomField,
} from '../models/settingsModels';
import { State } from 'state/store';
import { RequestState } from 'config/constants';
import {
  getAllOrganizationCustomFields,
  getAllOrganizationLocations,
  getOrganization,
  getOrganizationDocuments,
} from './settingsActions';

type ContentType = 'documents' | 'locations' | 'company' | 'customFields';

type SettingsSliceState = {
  documents: OrganizationDocument[];
  locations: LocationModel[];
  customFields: CustomField[];
  company?: CompanyModel;
  loading: Record<ContentType, RequestState>;
};

const initialState: SettingsSliceState = {
  documents: [],
  locations: [],
  customFields: [],
  loading: {
    documents: RequestState.IDLE,
    locations: RequestState.IDLE,
    company: RequestState.IDLE,
    customFields: RequestState.IDLE,
  },
};

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setDocuments(state, action: PayloadAction<OrganizationDocument[]>) {
      state.documents = action.payload;
    },
    addDocument(state, action: PayloadAction<OrganizationDocument>) {
      state.documents = [...state.documents, action.payload];
    },
    setCompany(state, action: PayloadAction<CompanyModel>) {
      state.company = action.payload;
    },
    setLocations(state, action: PayloadAction<LocationModel[]>) {
      state.locations = action.payload;
    },
    addLocation(state, action: PayloadAction<LocationModel>) {
      state.locations = [...state.locations, action.payload];
    },
    setCustomFields(state, action: PayloadAction<CustomField[]>) {
      state.customFields = action.payload;
    },
    addCustomField(state, action: PayloadAction<CustomField>) {
      state.customFields = [...state.customFields, action.payload];
    },
    updateCustomField(state, action: PayloadAction<CustomField>) {
      state.customFields = [
        ...state.customFields.map((customField) => {
          if (customField.id === action.payload.id) {
            return action.payload;
          }
          return customField;
        }),
      ];
    },
    deleteCustomField(state, action: PayloadAction<number>) {
      state.customFields = [
        ...state.customFields.filter((customField) => customField.id !== action.payload),
      ];
    },
    setLogo(state, action: PayloadAction<Pick<CompanyModel, 'logoDisplayName' | 'logoPath'>>) {
      if (!state.company) return;

      state.company = {
        ...state.company,
        logoPath: action.payload.logoPath,
        logoDisplayName: action.payload.logoDisplayName,
      };
    },
    updateLocation: (state, action: PayloadAction<LocationModel>) => {
      state.locations = [
        ...state.locations.map((location) => {
          if (location.id === action.payload.id) {
            return action.payload;
          }
          return location;
        }),
      ];
    },
    deleteLocation: (state, action: PayloadAction<number>) => {
      state.locations = [...state.locations.filter((location) => location.id !== action.payload)];
    },
    updateDocument: (state, action: PayloadAction<OrganizationDocument>) => {
      state.documents = [
        ...state.documents.map((document) => {
          if (document.id === action.payload.id) {
            return {
              ...document,
              name: action.payload.name,
              type: action.payload.type,
            };
          }
          return document;
        }),
      ];
    },
    deleteDocument: (state, action: PayloadAction<number>) => {
      state.documents = [...state.documents.filter((document) => document.id !== action.payload)];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationDocuments.pending, (state) => {
        state.loading = { ...state.loading, documents: RequestState.PENDING };
      })
      .addCase(getOrganizationDocuments.fulfilled, (state, action) => {
        state.loading = { ...state.loading, documents: RequestState.FULFILLED };
        state.documents = action.payload ?? [];
      })
      .addCase(getOrganizationDocuments.rejected, (state) => {
        state.loading = { ...state.loading, documents: RequestState.REJECTED };
      })
      .addCase(getOrganization.pending, (state) => {
        state.loading = { ...state.loading, company: RequestState.PENDING };
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.loading = { ...state.loading, company: RequestState.FULFILLED };
        state.company = action.payload;
      })
      .addCase(getOrganization.rejected, (state) => {
        state.loading = { ...state.loading, company: RequestState.REJECTED };
      })
      .addCase(getAllOrganizationLocations.pending, (state) => {
        state.loading = { ...state.loading, locations: RequestState.PENDING };
      })
      .addCase(getAllOrganizationLocations.fulfilled, (state, action) => {
        state.loading = { ...state.loading, locations: RequestState.FULFILLED };
        state.locations = action.payload ?? [];
      })
      .addCase(getAllOrganizationLocations.rejected, (state) => {
        state.loading = { ...state.loading, locations: RequestState.REJECTED };
      })
      .addCase(getAllOrganizationCustomFields.pending, (state) => {
        state.loading = { ...state.loading, customFields: RequestState.PENDING };
      })
      .addCase(getAllOrganizationCustomFields.rejected, (state) => {
        state.loading = { ...state.loading, customFields: RequestState.REJECTED };
      })
      .addCase(getAllOrganizationCustomFields.fulfilled, (state, action) => {
        state.loading = { ...state.loading, customFields: RequestState.FULFILLED };
        state.customFields = action.payload ?? [];
      });
  },
});

export const {
  setDocuments,
  addDocument,
  setCompany,
  setLogo,
  setLocations,
  addLocation,
  updateLocation,
  deleteLocation,
  updateDocument,
  deleteDocument,
  addCustomField,
  updateCustomField,
  deleteCustomField,
} = settingsSlice.actions;

export const selectSettings = (state: State) => state.settings;

export default settingsSlice.reducer;
