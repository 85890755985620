import { CustomFieldValueType } from 'modules/settings/models/settingsModels';
import type { CustomFieldWithSingleChoiceOptions, UserCustomField } from '../models/profileModels';

export const getCustomFieldsByPosition = (
  customFields: CustomFieldWithSingleChoiceOptions[],
  position: 'odd' | 'even',
): CustomFieldWithSingleChoiceOptions[] => {
  return customFields?.filter((_customField, index) => {
    if (position === 'even') return index % 2 === 0;

    return index % 2 !== 0;
  });
};

export const updateCustomFieldValue = (
  customFields: UserCustomField[],
  customFieldId: number,
  value: string,
): UserCustomField[] => {
  return customFields.map((customField) => {
    if (customField.customFieldId !== customFieldId) return customField;

    return {
      ...customField,
      fieldValue: value,
    };
  });
};

export const getCustomFieldValue = (
  customFields: UserCustomField[],
  customFieldId: number,
): string => {
  return (
    customFields.find((customField) => customField.customFieldId === customFieldId)?.fieldValue ??
    ''
  );
};

export const getSingleChoiceCustomFields = (userCustomFields: UserCustomField[]) => {
  return userCustomFields.filter(
    (customField) => customField.valueType === CustomFieldValueType.SINGLE_CHOICE,
  );
};
