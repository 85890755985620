import { useState } from 'react';
import { ActiveElement, ChartEvent, TooltipItem } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  barConfiguration,
  borderConfiguration,
  disabledChartColors,
  gridConfiguration,
  responsiveConfig,
  ticksConfiguration,
  titleConfiguration,
  tooltipConfiguration,
} from './chartsConfigurations';
import { AverageSalary } from 'modules/insights/models/insights.model';

type Props = {
  title: string;
  chartData: AverageSalary[];
  chartColors: string[];
};

export const AvgSalaryChart = ({ title, chartData, chartColors }: Props) => {
  const [hoveringElement, setHoveringElement] = useState<number>();

  const chartLabels = chartData.map((item) => item.name);

  const salaryChartData = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData.map((item) => item.averageSalary.toFixed(1)),
        backgroundColor: hoveringElement !== undefined ? disabledChartColors : chartColors,
        ...barConfiguration,
      },
    ],
  };

  const salaryChartOptions = {
    ...responsiveConfig,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: title,
        ...titleConfiguration,
        padding: {
          bottom: 35,
        },
      },
      tooltip: {
        ...tooltipConfiguration,
        callbacks: {
          title(tooltipItems: TooltipItem<'bar'>[]) {
            const itemIndex: number = tooltipItems[0].dataIndex;
            return `${chartLabels[itemIndex]}`;
          },
          label(tooltipItem: TooltipItem<'bar'>) {
            return `${tooltipItem.raw}$`;
          },
          labelColor(tooltipItem: TooltipItem<'bar'>) {
            return {
              borderColor: chartColors[tooltipItem.dataIndex],
              backgroundColor: chartColors[tooltipItem.dataIndex],
            };
          },
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max: Math.max(...chartData.map((item) => item.averageSalary)) + 3000,
        border: borderConfiguration,
        grid: gridConfiguration,
        ticks: { padding: 10, ...ticksConfiguration },
      },
      x: {
        ticks: {
          padding: 15,
          ...ticksConfiguration,
        },
        border: borderConfiguration,
        grid: gridConfiguration,
      },
    },
    hoverBackgroundColor: chartColors,
    hoverBorderColor: chartColors,
    onHover(e: ChartEvent, item: ActiveElement[]) {
      if (!item.length) {
        setHoveringElement(undefined);
        return;
      }
      setHoveringElement(item[0].index);
    },
  };

  return <Bar options={salaryChartOptions} data={salaryChartData} />;
};
