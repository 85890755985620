import { ButtonVariant } from './Button';

export const getSpinnerColor = (variant: ButtonVariant) => {
  switch (variant) {
    case 'contained':
      return '#fff';
    case 'outlined':
    case 'text':
      return '#6727e2';
    default:
      return '#fff';
  }
};
