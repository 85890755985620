import classes from './SectionItem.module.scss';
import { Badge } from 'components/core';
import { SectionActions } from 'modules/shared/components';

interface SectionItemProps {
  itemContent: string | JSX.Element;
  itemOption?: string;
  badgeLabel?: string;
  openEdit?: boolean;
  setOpenEdit?: (open: boolean) => void;
  onEdit?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
  isLoading?: boolean;
}

const SectionItem = ({
  itemContent,
  itemOption,
  openEdit = false,
  badgeLabel,
  setOpenEdit,
  onEdit,
  onCancel,
  onSave,
  onDelete,
  isLoading = false,
}: SectionItemProps) => {
  const handleEdit = () => {
    setOpenEdit?.(true);
    onEdit?.();
  };

  return (
    <div
      className={`${classes['c-section-item']} ${openEdit ? classes['c-section-item--edit'] : ''}`}
    >
      <div className={classes['c-section-item__left-container']}>
        <span className={classes['c-section-item__content']}>{itemContent}</span>
        {itemOption && (
          <span className={classes['c-section-item__item-option']}>- {itemOption}</span>
        )}
        {!!badgeLabel && (
          <div className={classes['c-section-item__item-badge']}>
            <Badge color="orange" size="small">
              {badgeLabel}
            </Badge>
          </div>
        )}
      </div>
      <div className={classes['c-section-item__right-container']}>
        <SectionActions
          isEditOpen={openEdit}
          handleEdit={handleEdit}
          handleCancel={onCancel}
          handleSave={onSave}
          handleDelete={onDelete}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default SectionItem;
