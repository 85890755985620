import type { ReactNode } from 'react';

import Recovery from 'modules/auth/components/Recovery/Recovery';
import Registration from 'modules/auth/components/Registration/Registration';
import RegistrationContainer from 'components/layout/Registration/RegistrationContainer';
import SignUp from 'modules/auth/components/SignUp/SignUp';
import Insights from 'modules/insights/components/Insights';
import OrganizationChart from 'modules/organizationChart/components/OrganizationChart';
import UserDocuments from 'modules/profile/components/Documents';
import EmergencyContacts from 'modules/profile/components/EmergencyContacts';
import Employment from 'modules/profile/components/Employment';
import Identification from 'modules/profile/components/Identification';
import Profile from 'modules/profile/components/Profile';
import ProfileAbout from 'modules/profile/components/ProfileAbout';
import Offboarding from 'modules/profile/components/Sections/SectionClassic/Offboarding';
import ManageTimeAway from 'modules/profile/components/TimeAways/ManageTimeAway/ManageTimeAway';
import Project from 'modules/projects/components/Project/Project';
import ProjectsContainer from 'components/layout/Projects/ProjectsContainer';
import Billing from 'modules/settings/components/Billing/Billing';
import Company from 'modules/settings/components/Company/Company';
import CustomFields from 'modules/settings/components/CustomFields/CustomFields';
import Documents from 'modules/settings/components/Documents/Documents';
import Permissions from 'modules/settings/components/Permissions/Permissions';
import SettingsContainer from 'components/layout/Settings/SettingsContainer';
import TimeOff from 'modules/settings/components/TimeOff/TimeOff';
import Tasks from 'modules/tasks/components/Tasks';
import UserWorkflowOverviewPage from 'modules/workflows/components/UserWorkflowOverview/UserWorkflowOverviewPage';
import WorkflowTemplateDetails from 'modules/workflows/components/WorkflowTemplateDetails';
import WorkflowTemplates from 'modules/workflows/components/WorkflowTemplates';
import Workflows from 'modules/workflows/components/Workflows';
import ReportsPage from 'pages/ReportsPage/ReportsPage';
import TimelinePage from 'pages/TimelinePage/TimelinePage';
import People from 'pages/Users';
import WorkflowPage from 'pages/WorkflowPage/WorkflowPage';
import TimesheetsOverview from 'modules/timesheet/components/TimesheetsOverview/TimesheetsOverview';
import Timesheet from 'modules/timesheet/components/Timesheet/Timesheet';

import { NotFound } from 'pages/NotFound/NotFound';

import { Role } from 'config/constants';
import { ComponentType } from 'modules/projects/models';

import ProtectedRoute from './ProtectedRoute';
import RootElement from './RootElement';
import { OAuthRedirectPage } from 'pages/OAuthRedirectPage';
import TimeAwayRequests from 'modules/timeAwayRequests/components/TimeAwayRequests/TimeAwayRequests';
import TimeAwayConfirmation from 'modules/timeAwayRequests/components/TimeAwayConfirmation/TimeAwayConfirmation';

const ADMIN_ROLES = [Role.ADMINISTRATOR, Role.SUPER_ADMINISTRATOR];
const ALL_ROLES = [...ADMIN_ROLES, Role.USER];

type Route = {
  path: string;
  element: ReactNode;
  children?: Route[];
};

export const routes: Route[] = [
  {
    path: '/',
    element: <RootElement />,
    children: [
      {
        path: 'sign-up/',
        element: <SignUp />,
      },
      {
        path: 'reset-password/*',
        element: <Recovery />,
      },
    ],
  },
  {
    path: '/oauth2/redirect/microsoft',
    element: <OAuthRedirectPage provider="microsoft" />,
  },
  {
    path: '/oauth2/redirect/google',
    element: <OAuthRedirectPage provider="google" />,
  },
  {
    path: '/register',
    element: <RegistrationContainer />,
    children: [
      {
        path: 'first-time-login/*',
        element: <Registration />,
      },
    ],
  },
  {
    path: '/people',
    element: (
      <ProtectedRoute allowedRoles={ALL_ROLES}>
        <People />
      </ProtectedRoute>
    ),
  },
  {
    path: '/timeline',
    element: (
      <ProtectedRoute allowedRoles={ALL_ROLES}>
        <TimelinePage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/time-away-approvals',
    element: (
      <ProtectedRoute allowedRoles={ADMIN_ROLES}>
        <TimeAwayRequests />
      </ProtectedRoute>
    ),
  },
  {
    path: '/timeaway-confirmation',
    element: (
      <ProtectedRoute allowedRoles={ADMIN_ROLES}>
        <TimeAwayConfirmation />
      </ProtectedRoute>
    ),
  },
  {
    path: '/workflows',
    element: (
      <ProtectedRoute allowedRoles={ADMIN_ROLES}>
        <WorkflowPage />
      </ProtectedRoute>
    ),
    children: [
      { path: '', element: <Workflows /> },
      { path: ':workflowId/workflow-instance/:id', element: <UserWorkflowOverviewPage /> },
    ],
  },
  {
    path: '/workflow-templates',
    element: (
      <ProtectedRoute allowedRoles={ADMIN_ROLES}>
        <WorkflowPage />
      </ProtectedRoute>
    ),
    children: [
      { path: '', element: <WorkflowTemplates /> },
      {
        path: 'new-template/:name',
        element: <WorkflowTemplateDetails componentType={ComponentType.NEW} />,
      },
      { path: ':id', element: <WorkflowTemplateDetails /> },
    ],
  },

  {
    path: '/my-tasks',
    element: (
      <ProtectedRoute allowedRoles={ALL_ROLES}>
        <Tasks />
      </ProtectedRoute>
    ),
  },
  {
    path: '/projects',
    element: (
      <ProtectedRoute allowedRoles={ADMIN_ROLES}>
        <ProjectsContainer />
      </ProtectedRoute>
    ),
    children: [
      { path: 'new-project/:name', element: <Project componentType={ComponentType.NEW} /> },
      { path: ':id', element: <Project /> },
    ],
  },
  {
    path: '/reports',
    element: (
      <ProtectedRoute allowedRoles={ADMIN_ROLES}>
        <ReportsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/insights',
    element: (
      <ProtectedRoute allowedRoles={ADMIN_ROLES}>
        <Insights />
      </ProtectedRoute>
    ),
  },
  {
    path: '/settings',
    element: (
      <ProtectedRoute allowedRoles={ADMIN_ROLES}>
        <SettingsContainer />
      </ProtectedRoute>
    ),
    children: [
      { path: 'permissions', element: <Permissions /> },
      { path: 'company', element: <Company /> },
      { path: 'documents', element: <Documents /> },
      { path: 'time-off', element: <TimeOff /> },
      { path: 'custom-fields', element: <CustomFields /> },
      { path: 'billing', element: <Billing /> },
    ],
  },
  {
    path: '/user/:id',
    element: (
      <ProtectedRoute allowedRoles={ALL_ROLES}>
        <Profile />
      </ProtectedRoute>
    ),
    children: [
      { path: 'identification', element: <Identification /> },
      { path: 'documents', element: <UserDocuments /> },
      { path: 'emergency-contacts', element: <EmergencyContacts /> },
      { path: 'employment', element: <Employment /> },
      { path: 'about', element: <ProfileAbout /> },
      { path: 'offboarding', element: <Offboarding /> },
      { path: 'time-away', element: <ManageTimeAway /> },
    ],
  },
  {
    path: '/organization-chart',
    element: (
      <ProtectedRoute allowedRoles={ALL_ROLES}>
        <OrganizationChart />
      </ProtectedRoute>
    ),
  },
  {
    path: '/timesheets',
    element: (
      <ProtectedRoute allowedRoles={ALL_ROLES}>
        <TimesheetsOverview />
      </ProtectedRoute>
    ),
    children: [{ path: ':id', element: <Timesheet /> }],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
