import Axios from 'axios';
import { api } from 'lib';
import { TokenData } from 'utils/auth/tokenModel';
import { OAuthProvider } from '../config';

const basicAxios = Axios.create({
  baseURL: process.env.REACT_APP_PEOPLE_API_URL,
});

export const login = async (email: string, password: string) => {
  return basicAxios.post<TokenData>('/authenticate', {
    email,
    password,
  });
};

export const loginWithOAuth = async (idToken: string, provider: OAuthProvider) => {
  return basicAxios.post<TokenData>(`/authenticate/${provider}`, {
    token: idToken,
  });
};

export const logout = async (accessToken: string) => {
  return api.post('/signout', {}, { headers: { Authorization: accessToken } });
};

export const sendRecoveryLink = async (email: string) => {
  return basicAxios.post('/send-reset-password-link', {
    email,
  });
};

export const resetPassword = async (token: string, password: string) => {
  return basicAxios.post(
    '/reset-password',
    {
      password,
    },
    {
      params: {
        token,
      },
    },
  );
};

export const sendRegistrationLink = async (email: string) => {
  return basicAxios.post('/send-registration-link', {
    email,
  });
};

export const setPassword = async (data: {
  password: string;
  repeatPassword: string;
  token: string;
}) => {
  const { password, repeatPassword, token } = data;

  return basicAxios.post(
    '/first-time-login',
    {
      password,
      repeatPassword,
    },
    {
      params: {
        token,
      },
    },
  );
};

export const signup = async (
  token: string,
  firstName: string,
  lastName: string,
  jobTitle: string,
  organizationName: string,
  city: string,
  country: string,
  address: string,
  password: string,
  startDate: string,
  firstWorkingDay: string,
  licenseType: string,
) => {
  return basicAxios.post(
    '/sign-up',
    {
      password,
      firstName,
      lastName,
      jobTitle,
      organizationName,
      city,
      country,
      address,
      startDate,
      firstWorkingDay,
      licenseType,
    },
    {
      params: {
        token,
      },
    },
  );
};

export const checkIsTokenValid = async (token: string) => {
  return basicAxios.get('/validate-verification-token', {
    params: {
      token,
    },
  });
};
