import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type KeyboardEvent,
} from 'react';
import classes from './DropDown.module.scss';
import { ReactComponent as DropdownArrowIcon } from 'assets/DropdownArrowIcon.svg';
import { useOnClickOutside } from 'hooks';
import { ReactComponent as WarningIcon } from 'assets/WarningIcon.svg';
import { IconButton } from '../IconButton';
import { ErrorMessage } from '../ErrorMessage';

type Size = 'xs' | 'small' | 'medium' | 'large';

type Props = {
  options: string[];
  displayedOptions?: JSX.Element[] | string[];
  selectedOption?: string;
  setSelectedOption?: (option: string) => void;
  fetchOptions?: () => void;
  inputFieldIncluded?: boolean;
  defaultValue?: string;
  selectedValueAlign?: 'left' | 'right';
  size?: Size;
  currency?: boolean;
  isSubmitted?: boolean;
  readOnly?: boolean;
  error?: string;
  placeholder?: string;
  showSearchBar?: boolean;
  errorBorder?: boolean;
};

export const DropDown = ({
  options,
  displayedOptions,
  selectedOption,
  setSelectedOption,
  fetchOptions,
  inputFieldIncluded,
  defaultValue,
  selectedValueAlign,
  size,
  currency,
  isSubmitted,
  readOnly,
  error,
  placeholder,
  showSearchBar,
  errorBorder,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [overflow, setOverflow] = useState<boolean>(false);
  const [selected, setSelected] = useState<JSX.Element | string>('');
  const [inputField, setInputField] = useState<string>(defaultValue || '');
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const optionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const dropdownRef = useOnClickOutside(() => {
    setIsDropdownOpen(false);
  });

  const isFieldInvalid = isSubmitted && !selectedOption;
  const dropdownOptions = displayedOptions || options;

  let filtereDropDown: string[] = [];

  if (inputFieldIncluded) {
    filtereDropDown = (dropdownOptions as string[]).filter((option: string) =>
      option.toLowerCase().includes(inputField.toLowerCase()),
    );
  }

  const handleToggleDropdown = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      event.preventDefault();
      if (readOnly) return;

      if (!isDropdownOpen && !options.length) {
        fetchOptions?.();
      }

      if (isDropdownOpen) {
        inputRef.current?.blur();
      } else {
        inputRef.current?.focus();
      }

      setIsDropdownOpen((prevState) => !prevState);
    },
    [fetchOptions, isDropdownOpen, options.length, readOnly],
  );

  const handleTabKey = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      setIsDropdownOpen(false);
    }
  }, []);

  const handleClick = (index: number) => {
    setSelected(dropdownOptions[index]);
    setSelectedOption?.(options[index]);
    onChangeInputField(dropdownOptions[index] as string);
    setIsDropdownOpen(false);
  };

  const handleClickSearchedItem = useCallback(
    (item: string) => {
      setSelected(item);
      const selectedOption = options.find((option) => option === item);
      if (selectedOption) {
        setSelectedOption?.(selectedOption);
      }
      onChangeInputField(item);
      setIsDropdownOpen(false);
      setSearchInputValue('');
    },
    [options, setSelected, setSelectedOption, setIsDropdownOpen],
  );

  const filteredOptions = useMemo(() => {
    return dropdownOptions.filter((item) =>
      item.toString().toLowerCase().includes(searchInputValue.toLowerCase()),
    );
  }, [dropdownOptions, searchInputValue]);

  const onChangeInputField = (text: string) => {
    setInputField(text);
  };

  const formatSelected = (selected: string | JSX.Element, currency?: boolean) => {
    if (typeof selected === 'string') {
      return currency ? selected : selected.replace('_', ' ');
    }
    return selected;
  };

  useEffect(() => {
    const optionsRect = optionsRef.current?.getBoundingClientRect();
    const screenHeight = window.innerHeight;

    if (optionsRect && optionsRect.bottom > screenHeight) {
      setOverflow(true);
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (displayedOptions?.length && selectedOption) {
      setSelected(displayedOptions[options.indexOf(selectedOption)]);
      return;
    }

    if (selectedOption) {
      setSelected(selectedOption);
      return;
    }

    setSelected('');
  }, [selectedOption, displayedOptions, options]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };

  if (readOnly && !selectedOption)
    return (
      <div
        className={`${classes['c-dropdown__selected-option']} ${
          classes[`c-dropdown__selected-option--${size}`]
        } ${classes['c-dropdown__selected-option--readonly']} ${
          classes['c-dropdown__selected-option--missing']
        }`}
      >
        <WarningIcon />
      </div>
    );

  return (
    <div className={`${classes['c-dropdown']}`} ref={dropdownRef}>
      {inputFieldIncluded ? (
        <div
          className={`${classes['c-dropdown__selected-option']} ${
            classes[`c-dropdown__selected-option--${size}`]
          } ${classes['c-dropdown__selected-option--input-included']} ${
            classes['c-dropdown__icon--input-included']
          } ${
            selectedValueAlign === 'right'
              ? `${classes['c-dropdown__selected-option--right-align']}
                ${classes['c-dropdown__dropdown-input-field--right-align']}`
              : ''
          } ${
            isDropdownOpen
              ? `${classes['c-dropdown__selected-option--opened']}
                ${classes['c-dropdown__dropdown-input-field--opened']}`
              : ''
          } ${readOnly ? classes['c-dropdown__selected-option--readonly'] : ''} ${
            isFieldInvalid ? classes['c-dropdown__selected-option--invalid'] : ''
          } `}
        >
          <input
            ref={inputRef}
            className={classes['c-dropdown__dropdown-input-field']}
            value={inputField}
            onChange={(event) => onChangeInputField(event.target.value)}
            onKeyDown={handleTabKey}
            readOnly={readOnly}
            onClick={handleToggleDropdown}
            placeholder={placeholder}
          />
          {!readOnly && (
            <IconButton
              icon={
                <DropdownArrowIcon
                  className={`${classes['c-dropdown__icon']} ${
                    isDropdownOpen ? classes['c-dropdown__icon--opened'] : ''
                  }`}
                />
              }
              onClick={handleToggleDropdown}
            />
          )}
        </div>
      ) : (
        <button
          className={`${classes['c-dropdown__selected-option']} ${
            classes[`c-dropdown__selected-option--${size}`]
          } ${
            isDropdownOpen
              ? `${classes['c-dropdown__selected-option--opened']}
                ${classes['c-dropdown__dropdown-input-field--opened']}`
              : ''
          } ${isFieldInvalid ? classes['c-dropdown__selected-option--invalid'] : ''} 
          ${
            selectedValueAlign === 'right'
              ? `${classes['c-dropdown__selected-option--right-align']}
                ${classes['c-dropdown__dropdown-input-field--right-align']}`
              : ''
          } ${readOnly ? classes['c-dropdown__selected-option--readonly'] : ''} ${
            errorBorder ? classes['c-dropdown__selected-option--error'] : ''
          }`}
          onClick={handleToggleDropdown}
        >
          <span
            className={`${classes['c-dropdown__selected-option-value']} ${
              classes[`c-dropdown__selected-option-value--${size}`]
            } ${placeholder && !selectedOption && classes['c-dropdown__placeholder']}`}
          >
            {selected ? formatSelected(selected, currency) : placeholder}
          </span>
          {!readOnly && (
            <DropdownArrowIcon
              className={`${classes['c-dropdown__icon']} ${
                isDropdownOpen ? classes['c-dropdown__icon--opened'] : ''
              }`}
            />
          )}
        </button>
      )}
      {isDropdownOpen && (
        <div
          ref={optionsRef}
          className={`${classes['c-dropdown__options']} ${
            overflow ? classes['c-dropdown__options--overflow'] : ''
          }`}
        >
          {(() => {
            if (filtereDropDown.length === 0 && inputFieldIncluded) {
              return 'There is no option';
            } else if (filtereDropDown.length && inputFieldIncluded) {
              return filtereDropDown.map((item, index) => (
                <span
                  className={classes['c-dropdown__option']}
                  key={index}
                  onClick={() => {
                    handleClick(
                      (dropdownOptions as string[]).findIndex((value: string) =>
                        value.includes(item),
                      ),
                    );
                  }}
                >
                  {formatSelected(item, currency)}
                </span>
              ));
            }
            if (showSearchBar && options.length) {
              return (
                <>
                  <input
                    value={searchInputValue || ''}
                    className={classes['c-dropdown__search']}
                    placeholder="Search..."
                    onChange={handleOnChange}
                    autoFocus
                  />
                  {filteredOptions.map((item, index) => (
                    <span
                      className={classes['c-dropdown__option']}
                      key={index}
                      onClick={() => handleClickSearchedItem(item.toString())}
                    >
                      {(typeof item === 'string' &&
                        !displayedOptions &&
                        formatSelected(item, currency)) ||
                        item}
                    </span>
                  ))}
                </>
              );
            }
            return dropdownOptions.map((item, index) => (
              <span
                className={classes['c-dropdown__option']}
                key={index}
                onClick={() => {
                  handleClick(index);
                }}
              >
                {(typeof item === 'string' &&
                  !displayedOptions &&
                  formatSelected(item, currency)) ||
                  item}
              </span>
            ));
          })()}
        </div>
      )}
      <ErrorMessage isInputInvalid={!!error} invalidMessage={error} />
    </div>
  );
};
