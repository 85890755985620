import classes from '../styles/WorkflowsTableHead.module.scss';

const WorkflowsTableHead = () => {
  const fields = [
    'Person',
    'Workflow',
    'Job title',
    'Department',
    'First working day',
    'Place of work',
    'Reporting to',
    'Tasks',
  ];
  return (
    <div className={classes['c-workflows-table-head']}>
      {fields.map((option, index) => (
        <span key={index} className={classes['c-workflows-table-head__span']}>
          {option}
        </span>
      ))}
    </div>
  );
};

export default WorkflowsTableHead;
