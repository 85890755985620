import { ReactNode } from 'react';
import classes from './AddIconButton.module.scss';
import { ReactComponent as AddIcon } from 'assets/AddIconRoundOutline.svg';

type Props = {
  disabled?: boolean;
  onClick: () => void;
  children?: ReactNode;
};

export const AddIconButton = ({ disabled, onClick, children }: Props) => {
  return (
    <button
      className={`${classes['c-add-icon-button']} ${
        disabled ? classes['c-add-icon-button--disabled'] : ''
      }`}
      disabled={disabled}
      onClick={onClick}
      aria-label="Add"
    >
      <AddIcon /> {children}
    </button>
  );
};
