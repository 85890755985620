import type { ReactNode } from 'react';

// import { ReactComponent as Dashboard } from 'assets/Dashboard.svg';
import { ReactComponent as People } from 'assets/People.svg';
import { ReactComponent as Timeline } from 'assets/Timeline.svg';
import { ReactComponent as TimeAwayApprovals } from 'assets/CalendarIcon.svg';
import { ReactComponent as Tasks } from 'assets/Tasks.svg';
import { ReactComponent as Workflows } from 'assets/Workflows.svg';
import { ReactComponent as WorkflowTemplates } from 'assets/WorkflowTemplates.svg';
import { ReactComponent as Projects } from 'assets/Projects.svg';
import { ReactComponent as Stats } from 'assets/Statistics.svg';
import { ReactComponent as Reports } from 'assets/Reports.svg';
import { ReactComponent as OrganizationChartIcon } from 'assets/OrganizationChartIcon.svg';
import { ReactComponent as Permissions } from 'assets/LockIcon.svg';
import { ReactComponent as Company } from 'assets/Briefcase.svg';
import { ReactComponent as TimeOff } from 'assets/Clock.svg';
import { ReactComponent as CustomFields } from 'assets/CustomFieldsIcon.svg';
import { ReactComponent as Documents } from 'assets/File.svg';
import { ReactComponent as Timesheet } from 'assets/Timesheet.svg';

export type NavigationItem = {
  path?: string;
  label: string;
  icon?: ReactNode;
  submenu?: NavigationItem[];
};

export const navigationItems: NavigationItem[] = [
  // { path: '/', icon: <Dashboard />, label: 'Home' },
  { path: '/people', icon: <People />, label: 'People' },
  { path: '/timeline', icon: <Timeline />, label: 'Timeline' },
  {
    path: '/time-away-approvals',
    icon: <TimeAwayApprovals />,
    label: 'Time away requests',
  },
  { path: '/my-tasks', icon: <Tasks />, label: 'My tasks' },
  {
    icon: <Workflows />,
    label: 'Workflows',
    submenu: [
      {
        path: '/workflows',
        icon: <Workflows />,
        label: 'Instances',
      },
      { path: '/workflow-templates', icon: <WorkflowTemplates />, label: 'Templates' },
      { path: '/my-tasks', icon: <Tasks />, label: 'My tasks' },
    ],
  },
  { path: '/projects', icon: <Projects />, label: 'Projects' },
  {
    icon: <Stats />,
    label: 'Stats',
    submenu: [
      { path: '/reports', icon: <Reports />, label: 'Reports' },
      { path: '/insights', icon: <Reports />, label: 'Insights' },
    ],
  },
  {
    path: '/organization-chart',
    icon: <OrganizationChartIcon />,
    label: 'Organization chart',
  },
  { path: '/timesheets', icon: <Timesheet />, label: 'Timesheets' },
];

export const settingsSidebarNavigationItems: NavigationItem[] = [
  { path: 'company', icon: <Company />, label: 'Company' },
  { path: 'time-off', icon: <TimeOff />, label: 'Time off' },
  { path: 'permissions', icon: <Permissions />, label: 'Permissions' },
  { path: 'documents', icon: <Documents />, label: 'Documents' },
  { path: 'custom-fields', icon: <CustomFields />, label: 'Custom fields' },
];
