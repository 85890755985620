export const colors = [
  '#ad1357',
  '#f4511e',
  '#e74c3c',
  '#f6b93b',
  '#2ecc71',
  '#16a085',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#95a5a6',
  '#78350f',
  '#1a2e05',
  '#450a0a',
  '#bef264',
  '#67e8f9',
  '#083344',
  '#818cf8',
  '#6366f1',
  '#d946ef',
  '#be123c',
];
