import { zodResolver } from '@hookform/resolvers/zod';
import {
  emailSignUpDefaultValues,
  emailSignUpValidationSchema,
  EmailSignUpValidationSchema,
} from 'modules/auth/validations/EmailSignUpValidation.schema';
import classes from '../SignUp.module.scss';
import { Controller, useForm } from 'react-hook-form';
import Header from '../../Header/Header';
import InputContainer from '../../InputContainer/InputContainer';
import { Button } from 'components/core';
import TextDivider from '../../TextDivider/TextDivider';
import FormLink from '../../FormLink/FormLink';
import { ChangeEvent, useState } from 'react';
import { sendRegistrationLink } from 'modules/auth/api/auth.api';
import { toast } from 'react-toastify';

const EmailForm = () => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitted, isSubmitting, errors },
  } = useForm<EmailSignUpValidationSchema>({
    resolver: zodResolver(emailSignUpValidationSchema),
    defaultValues: emailSignUpDefaultValues,
  });
  const [showInfoMessage, setShowInfoMessage] = useState(false);

  const handleOnChange =
    (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };

  const handleSignUp = async (formData: EmailSignUpValidationSchema) => {
    const toastMessage = 'Something went wrong, please try again.';
    sendRegistrationLink(formData.email)
      .then(() => setShowInfoMessage(true))
      .catch((e) => toast.error(e.response.data.message ? e.response.data.message : toastMessage));
  };

  if (showInfoMessage) {
    return (
      <div className={`${classes['c-signup']} ${classes['c-signup--xl']}`}>
        <span className={classes['c-signup__info']}>
          Registration link successfully sent, please check your email.
        </span>
        <FormLink url="/" text="Go to login page" />
      </div>
    );
  }

  return (
    <form className={classes['c-signup']} onSubmit={handleSubmit(handleSignUp)}>
      <div className={classes['c-signup__title-content']}>
        <Header title="Signing up" subtitle="We're glad you choose us!" />
      </div>
      <div className={classes['c-signup__form-content']}>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange } }) => (
            <InputContainer
              label="E-mail"
              name="email"
              handleInput={handleOnChange(onChange)}
              isFormSubmitted={isSubmitted}
              inputValidity={!errors.email?.message}
              invalidMessage={errors.email?.message}
              autoFocus
            />
          )}
        />
      </div>
      <div className={classes['c-signup__button-container']}>
        <Button size="large" htmlType="submit" isLoading={isSubmitting}>
          Sign up
        </Button>
        <TextDivider text="or" />
        <FormLink url="/" text="Sign in" />
      </div>
    </form>
  );
};

export default EmailForm;
