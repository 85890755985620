import { createSlice } from '@reduxjs/toolkit';
import { RequestState } from 'config/constants';
import { TimeAwayUser, PublicHoliday } from '../models/timeAwayModels';
import { getTimeAwaysByOrganization, getPublicHolidays } from './timelineActions';

interface TimelineState {
  users: TimeAwayUser[];
  filteredUsers: TimeAwayUser[];
  publicHolidays: PublicHoliday[];
  loading: RequestState;
}

const initialState: TimelineState = {
  users: [],
  filteredUsers: [],
  publicHolidays: [],
  loading: RequestState.IDLE,
};

const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setTimeAways(state, action) {
      state.users = action.payload;
      state.filteredUsers = action.payload;
    },
    setFilteredUsers(state, action) {
      state.filteredUsers = action.payload;
    },
    addNewTimeAway(state, action) {
      const { timeAway, newUser } = action.payload;

      if (state.users.some((user) => user.userId === timeAway.userId)) {
        state.users = state.filteredUsers.map((user) => {
          if (user.userId === timeAway.userId) {
            return { ...user, timeAways: [...user.timeAways, timeAway] };
          }
          return user;
        });
      } else {
        state.users.push(newUser);
      }

      state.filteredUsers = state.users;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimeAwaysByOrganization.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getTimeAwaysByOrganization.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.users = action.payload;
        state.filteredUsers = action.payload;
      })
      .addCase(getTimeAwaysByOrganization.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      })

      .addCase(getPublicHolidays.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getPublicHolidays.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.publicHolidays = action.payload;
      })
      .addCase(getPublicHolidays.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { setTimeAways, setFilteredUsers, addNewTimeAway } = timelineSlice.actions;

export default timelineSlice;
