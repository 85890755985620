import { Avatar } from '../Avatar';
import { IconButton } from '../IconButton';
import classes from './UsersOverview.module.scss';
import { ReactComponent as RemoveSvg } from 'assets/Remove.svg';
import { ReactComponent as AddIcon } from 'assets/AddIconOutlineLarge.svg';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectAuthUser } from 'modules/auth/redux/authSlice';

export type Size = 'small' | 'large' | 'small-plus';
export type ListUser = {
  id: number;
  firstName: string;
  lastName: string;
  profilePicture: string;
};

type Props = {
  isEditMode?: boolean;
  usersList: ListUser[];
  removeUser?: (id: number) => void;
  setIsModalOpen?: (isOpen: boolean) => void;
  linkToUserHandler?: (id: number) => void;
  size?: Size;
  clickable?: boolean;
};

export const UsersOverview = ({
  isEditMode,
  usersList,
  size = 'large',
  removeUser,
  setIsModalOpen,
  linkToUserHandler,
  clickable = false,
}: Props) => {
  const { user: currentUser } = useAppSelector(selectAuthUser);
  return (
    <div
      className={`${classes['c-users-overview__content']}  ${
        classes[`c-users-overview__content--${size}`]
      }
        `}
    >
      {usersList.map((user) => (
        <button
          onClick={() => linkToUserHandler?.(user.id)}
          key={user.id}
          className={classes['c-users-overview__report']}
        >
          <Avatar
            size={size === 'large' ? 'medium-minus' : 'small-plus'}
            imageUrl={user.profilePicture}
            title={
              <div className={classes['c-users-overview__name']}>
                <span>{user.firstName}</span>
                {size === 'large' && <span>{user.lastName}</span>}
              </div>
            }
            tooltipContent={size === 'small-plus' ? user.firstName + ' ' + user.lastName : ''}
            icon={isEditMode && user.id !== currentUser?.id ? <RemoveSvg /> : undefined}
            onIconClick={() => removeUser?.(user.id)}
            clickable={clickable}
          />
        </button>
      ))}
      {isEditMode && (
        <IconButton
          icon={
            <div className={classes['c-users-overview__add-people']}>
              <AddIcon className={classes['c-users-overview__add-people-icon']} />
              <span>
                Add more
                <br />
                people
              </span>
            </div>
          }
          aria-label="Add people"
          onClick={() => setIsModalOpen?.(true)}
        />
      )}
    </div>
  );
};
