import dayjs from 'dayjs';
import type { ProjectPhase, ProjectPhaseUser, UserProjectPhase } from '../models';
import { getDatesDayDifference, isDateBefore } from 'utils';

export const getEstimatedTimeInfo = (projectPhases?: ProjectPhase[]): string => {
  if (!projectPhases?.length) return '-';

  const daysLeft = dayjs(projectPhases.at(-1)?.dueDate).diff(
    dayjs(projectPhases[0]?.startDate),
    'day',
  );

  const suffix = daysLeft === 1 ? 'day' : 'days';

  if (daysLeft < 0) return `${Math.abs(daysLeft)} ${suffix} overdue`;

  return `${daysLeft} ${suffix}`;
};

export const getProjectCardInfo = (numberOfPeople: number, numberOfPhases: number): string => {
  return `${numberOfPeople} ${numberOfPeople === 1 ? 'person' : 'people'}, ${numberOfPhases} ${
    numberOfPhases === 1 ? 'phase' : 'phases'
  }`;
};

const getFinishedPhases = (projectPhases: ProjectPhase[]): ProjectPhase[] => {
  return projectPhases.filter((phase) => dayjs(phase.dueDate).isBefore(new Date()));
};

export const getNumberOfFinishedPhasesInfo = (projectPhases: ProjectPhase[]): string => {
  return `Phase ${getFinishedPhases(projectPhases).length}/${projectPhases.length}`;
};

export const calculatePhaseProgress = (projectPhase: ProjectPhase): number => {
  const { startDate, dueDate } = projectPhase;

  if (isDateBefore(dueDate, new Date())) return 100;

  const remainingDays = getDatesDayDifference(new Date(), startDate);

  if (remainingDays < 0) return 0;

  return (remainingDays / getDatesDayDifference(dueDate, startDate)) * 100;
};

export const mapUserToProjectPhaseUser = (user: UserProjectPhase): ProjectPhaseUser => {
  const { id, firstName, lastName, jobTitle, profilePicture } = user.simpleUserDto;

  return {
    id,
    firstName,
    lastName,
    profilePicture,
    jobTitle,
  };
};

export const getPhaseUsers = (
  phase: ProjectPhase,
  users?: UserProjectPhase[],
): ProjectPhaseUser[] | undefined => {
  return users?.map(mapUserToProjectPhaseUser);
};

export const sortProjectPhases = (projectPhases: ProjectPhase[]) =>
  [...projectPhases].sort((a, b) => (isDateBefore(a.startDate, b.startDate) ? -1 : 1));
