import { useEffect, useRef } from 'react';

export const useOnClickOutside = (handler: () => void) => {
  const domRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const listener = (event: KeyboardEvent | MouseEvent | TouchEvent) => {
      if (
        !domRef.current ||
        domRef.current.contains(event.target as Node) ||
        (event instanceof KeyboardEvent && event.key === 'Escape') ||  (event as MouseEvent).button === 2
      ) {
        return;
      }

      handler();
      event.stopPropagation();
    };

    const keyDownListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handler();
        event.preventDefault();
        event.stopPropagation();
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('keydown', keyDownListener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('keydown', keyDownListener);
    };
  }, [domRef, handler]);

  return domRef;
};
