import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { ReactComponent as DragAndDropIcon } from 'assets/DragAndDropIcon.svg';
import classes from '../../styles/Section/SectionTask.module.scss';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { type Task } from 'modules/workflows/model/workflow.model';
import {
  createSectionTask,
  deleteSectionTask,
  updateSectionTask,
} from 'modules/workflows/api/workflow.api';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import {
  addNewSectionTask,
  updateSectionTask as updateSectionTaskAction,
  deleteSectionTask as deleteSectionTaskAction,
} from '../../redux/workflowTemplatesSlice';
import { SectionActions } from 'modules/shared/components';
import { ActionConfirmationModal, Input, toast } from 'components/core';

type Props = {
  task: Task;
  workflowId?: number;
  isNewSection: boolean;
  changeTaskName: (id: number | string, name: string) => void;
  cancelTaskChange: (id: number | string) => void;
  deleteUnsavedTask: (id: string) => void;
};

const SectionTask = ({
  task: { id, name, workflowSectionId },
  task,
  workflowId,
  isNewSection,
  changeTaskName,
  cancelTaskChange,
  deleteUnsavedTask,
}: Props) => {
  const isNewTask = String(id).includes('temp');

  const [isEditMode, setIsEditMode] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taskName, setTaskName] = useState<string>(name);

  const dispatch = useAppDispatch();

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  };

  const updateTask = useCallback(async () => {
    if (!workflowId) return;

    try {
      await updateSectionTask(workflowId, task);
      dispatch(updateSectionTaskAction({ workflowId, updatedTask: task }));

      setIsEditMode(false);
      toast('success', 'You have successfully updated task.');
    } catch {
      toast('error', 'Something went wrong while updating task.');
    }
  }, [dispatch, task, workflowId]);

  const handleSave = useCallback(async () => {
    if (!workflowId) return;

    if (isNewSection) {
      setIsEditMode(false);
      return;
    }

    if (!isNewTask) {
      updateTask();
      return;
    }

    try {
      const { data } = await createSectionTask(workflowId, task);
      dispatch(
        addNewSectionTask({
          workflowId,
          workflowSectionId,
          newTask: data,
        }),
      );

      setIsEditMode(false);
      toast('success', 'You have successfully created new task.');
    } catch {
      toast('error', 'Something went wrong while creating new task. Try again.');
    }
  }, [dispatch, isNewSection, isNewTask, task, updateTask, workflowId, workflowSectionId]);

  const handleCancel = useCallback(() => {
    if (isNewTask) {
      deleteUnsavedTask(String(id));
      return;
    }

    cancelTaskChange(id);
    setIsEditMode(false);
  }, [isNewTask, deleteUnsavedTask, cancelTaskChange, id]);

  const handleEdit = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const deleteTask = useCallback(async () => {
    if (!workflowId) return;

    try {
      setIsLoading(true);
      await deleteSectionTask(+workflowId, workflowSectionId, +id);
      dispatch(
        deleteSectionTaskAction({
          workflowId,
          sectionId: workflowSectionId,
          taskId: +id,
        }),
      );

      setIsConfirmationModalOpen(false);
      toast('success', 'You have successfully deleted section task.');
    } catch {
      toast('error', 'Something went wrong while deleting section task. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, id, workflowId, workflowSectionId]);

  const handleDelete = useCallback(() => {
    if (isNewTask) {
      deleteUnsavedTask(String(id));
      return;
    }

    setIsConfirmationModalOpen(true);
  }, [deleteUnsavedTask, isNewTask, id]);

  const handleEnterKey = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') handleSave();
    },
    [handleSave],
  );

  useEffect(() => {
    changeTaskName(id, taskName);
  }, [changeTaskName, id, taskName]);

  return (
    <div className={classes['c-section-task']} ref={setNodeRef} style={style}>
      <div className={classes['c-section-task__name']}>
        <DragAndDropIcon
          {...attributes}
          {...listeners}
          className={`${classes['c-section-task__draggable-icon']} ${
            isEditMode ? classes['c-section-task__draggable-icon--disabled'] : ''
          }`}
        />
        <Input
          name="task-name"
          id="task-name"
          placeholder="Task name"
          value={taskName}
          setValue={setTaskName}
          readOnly={!isEditMode && !isNewTask && !isNewSection}
          autoFocus
          handleOnKeyDown={handleEnterKey}
        />
      </div>
      <SectionActions
        isEditOpen={isEditMode || (isNewTask && !isNewSection)}
        handleEdit={!isNewSection ? handleEdit : undefined}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
        handleSave={handleSave}
        isLoading={false}
        isSaveButtonDisabled={!name}
      />
      <ActionConfirmationModal
        message={`you want to delete "${name}" section task?`}
        isModalOpen={isConfirmationModalOpen}
        closeModal={() => setIsConfirmationModalOpen(false)}
        handleYes={deleteTask}
        isLoading={isLoading}
        handleNo={() => setIsConfirmationModalOpen(false)}
      />
    </div>
  );
};

export default SectionTask;
