import { useCallback, useEffect, useState } from 'react';

import Tree from 'react-d3-tree';
import { RawNodeDatum } from 'react-d3-tree/lib/types/types/common';

import ChartNode from '../ChartNode/ChartNode';

import { foreignObjectProps, nodeSize, parseDataToNodes } from '../../utils/chartData';

import classes from './ChartContent.module.scss';
import { OrganizationChartData } from '../../model';
import { CompanyModel } from 'modules/settings/models/settingsModels';
import logoPlaceholder from 'assets/Building.svg';

type Props = {
  membersCount: number;
  organizationChartData: OrganizationChartData;
  company: CompanyModel;
  companyName: string;
  searchTerm: string;
  selectedUserId: number | null;
  handleSelectedUser: (id: number) => void;
};

const ChartContent = ({
  organizationChartData,
  company,
  handleSelectedUser,
  selectedUserId,
  membersCount,
  companyName,
  searchTerm,
}: Props) => {
  const [treeData, setTreeData] = useState<RawNodeDatum>();

  const parseDataForReactD3Tree = useCallback(() => {
    const children = parseDataToNodes(organizationChartData);

    setTreeData((prevData) => ({
      ...prevData,
      name: companyName,
      attributes: {
        jobTitle: 'Company',
        companyLogoPath: company?.logoPath ?? logoPlaceholder,
      },
      children,
    }));
  }, [company?.logoPath, companyName, organizationChartData]);

  useEffect(() => {
    parseDataForReactD3Tree();
  }, [parseDataForReactD3Tree]);

  return (
    <>
      {treeData && (
        <Tree
          data={treeData}
          enableLegacyTransitions
          nodeSize={nodeSize}
          orientation="vertical"
          pathFunc="elbow"
          initialDepth={1}
          pathClassFunc={() => classes['c-chart-content__custom-link']}
          separation={{ siblings: 1, nonSiblings: 1 }}
          transitionDuration={500}
          translate={{ x: 700, y: 70 }}
          renderCustomNodeElement={(chartProps) => (
            <ChartNode
              {...chartProps}
              foreignObjectProps={foreignObjectProps}
              membersCount={membersCount}
              searchTerm={searchTerm}
              selectedUserId={selectedUserId}
              setSelectedUserId={handleSelectedUser}
            />
          )}
        />
      )}
    </>
  );
};

export default ChartContent;
