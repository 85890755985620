import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Task } from '../model';
import { getTasksForUser } from './taskActions';
import { RequestState } from 'config/constants';
import { State } from 'state/store';

type TasksState = {
  tasks: Task[];
  loading: RequestState;
};

const initialState: TasksState = {
  tasks: [],
  loading: RequestState.IDLE,
};

const tasksSlice = createSlice({
  name: 'tasksSlice',
  initialState,
  reducers: {
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    updateTask: (state, action: PayloadAction<Task>) => {
      const task = state.tasks.find((task) => task.id === action.payload.id);
      const updatedTask = { ...task, ...action.payload };

      state.tasks = state.tasks.map((task) => (task.id === action.payload.id ? updatedTask : task));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasksForUser.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getTasksForUser.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.tasks = action.payload;
      })
      .addCase(getTasksForUser.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { setTasks, updateTask } = tasksSlice.actions;

export const selectTasks = (state: State) => state.tasks;

export default tasksSlice.reducer;
