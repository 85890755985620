import { useCallback, useMemo, useState } from 'react';
import Section from '../../Section/Section';
import { PopUpModal, toast } from 'components/core';
import PtoPolicyModal from './PtoPolicyModal/PtoPolicyModal';
import PtoPolicyItem from './PtoPolicyItem/PtoPolicyItem';
import {
  createPtoPolicy,
  deletePtoPolicy,
  updatePtoPolicy,
} from 'modules/settings/api/ptoPolicy.api';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { isFormValid, ptoPolicyNameExists } from './utils';
import { selectPtoPolicies, setPtoPolicies } from 'modules/shared/redux/ptoPolicy/ptoPolicySlice';
import type { PtoPolicy as PtoPolicyModel } from 'modules/shared/model';
import { isAxiosError } from 'axios';

const initialPtoPolicy: PtoPolicyModel = {
  name: '',
  yearStartDate: '',
  annualDays: 21,
  carryOverDays: 0,
  carryOverEndDate: '',
  extraDaysPerYear: 0,
  default: false,
};
const PtoPolicy = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [newPtoPolicy, setNewPtoPolicy] = useState<PtoPolicyModel>(initialPtoPolicy);

  const { ptoPolicies } = useAppSelector(selectPtoPolicies);

  const dispatch = useAppDispatch();

  const sortedPtoPolicies = useMemo(
    () =>
      [...ptoPolicies].sort((a, b) => {
        if (a.default) return -1;

        return a.name.localeCompare(b.name);
      }),
    [ptoPolicies],
  );

  const handleUpdatePtoPolicy = useCallback(
    async (updatedPtoPolicy: PtoPolicyModel) => {
      if (ptoPolicyNameExists(updatedPtoPolicy, ptoPolicies)) return;
      updatedPtoPolicy = {
        ...updatedPtoPolicy,
        replaceDefaultPolicy: updatedPtoPolicy.default,
      };
      try {
        setIsLoading(true);

        const { data } = await updatePtoPolicy(updatedPtoPolicy);
        const index = ptoPolicies.findIndex((policy) => policy.id === data.id);
        const updatedPtoPolicies = [...ptoPolicies];
        updatedPtoPolicies[index] = data;
        if (data.default === true) {
          const updatedPolicies = updatedPtoPolicies.map((policy) => {
            if (policy.id !== data.id) {
              return { ...policy, default: false };
            }
            return { ...policy, default: true };
          });
          dispatch(setPtoPolicies(updatedPolicies));
        } else dispatch(setPtoPolicies(updatedPtoPolicies));
        toast('success', `PTO policy "${updatedPtoPolicy.name}" updated successfully.`);
      } catch (error) {
        if (isAxiosError(error)) {
          toast('error', error?.response?.data.message);
          return;
        }

        toast('error', 'Something went wrong while updating PTO policy, please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [ptoPolicies, dispatch],
  );

  const handleCreatePtoPolicy = useCallback(
    async (newPtoPolicy: PtoPolicyModel) => {
      if (!isFormValid(newPtoPolicy) || ptoPolicyNameExists(newPtoPolicy, ptoPolicies)) return;

      try {
        setIsLoading(true);

        newPtoPolicy.replaceDefaultPolicy = newPtoPolicy.default;

        const { data } = await createPtoPolicy(newPtoPolicy);
        const updatedPtoPolicies = [...ptoPolicies, data];
        if (data.default === true) {
          const updatedPolicies = updatedPtoPolicies.map((policy) => {
            if (policy.id !== data.id) {
              return { ...policy, default: false };
            }
            return { ...policy, default: true };
          });

          dispatch(setPtoPolicies(updatedPolicies));
          setNewPtoPolicy(initialPtoPolicy);
        } else dispatch(setPtoPolicies(updatedPtoPolicies));
        setIsModalOpen(false);
        setNewPtoPolicy(initialPtoPolicy);
        toast('success', `PTO policy "${newPtoPolicy.name}" created successfully.`);
      } catch (error) {
        if (isAxiosError(error)) {
          toast('error', error?.response?.data.message);
          return;
        }

        toast('error', 'Something went wrong while creating PTO policy, please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [ptoPolicies, dispatch],
  );

  const onCloseModal = () => {
    setIsModalOpen(false);
    setNewPtoPolicy(initialPtoPolicy);
  };

  const handleDeletePtoPolicy = useCallback(
    async (ptoPolicyId: number) => {
      try {
        setIsLoading(true);

        await deletePtoPolicy(ptoPolicyId);

        const ptoPolicy = ptoPolicies.find((policy) => policy.id === ptoPolicyId);
        dispatch(setPtoPolicies([...ptoPolicies.filter((policy) => policy.id !== ptoPolicyId)]));
        toast('success', `PTO policy "${ptoPolicy?.name}" deleted successfully.`);
      } catch (error) {
        if (isAxiosError(error)) {
          toast('error', error?.response?.data.message);
          return;
        }

        toast('error', 'Something went wrong while deleting PTO policy, please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [ptoPolicies, dispatch],
  );

  return (
    <Section
      title="PTO policies"
      type="overview"
      iconType="add"
      onClick={() => setIsModalOpen(true)}
    >
      {!ptoPolicies.length && <div>No PTO policies available</div>}
      {sortedPtoPolicies.map((policy) => (
        <PtoPolicyItem
          key={policy.id}
          item={policy}
          isLoading={isLoading}
          onSave={handleUpdatePtoPolicy}
          onDelete={handleDeletePtoPolicy}
          setIsChangeDefaultModalOpen={setIsConfirmationModalOpen}
          isChangeDefaultModalOpen={isConfirmationModalOpen}
        />
      ))}
      <PopUpModal
        title="Add PTO policy"
        width={30}
        opened={isModalOpen}
        closeModal={onCloseModal}
        shouldClose={!isConfirmationModalOpen}
        closeModalOnClickOutside={() => setIsModalOpen(false)}
      >
        <PtoPolicyModal
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          isConfirmationModalOpen={isConfirmationModalOpen}
          setNewPtoPolicy={setNewPtoPolicy}
          newPtoPolicy={newPtoPolicy}
          onSave={handleCreatePtoPolicy}
        />
      </PopUpModal>
    </Section>
  );
};

export default PtoPolicy;
