import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'lib';
import { getOrganizationId } from 'state/utils';

export const getTimeAwaysByOrganization = createAsyncThunk(
  'timeline/getTimeAwaysByOrganization',
  async (payload: { startDate: string; endDate: string }) => {
    const { startDate, endDate } = payload;
    const response = await api.get(`/organization/${getOrganizationId()}/time-away`, {
      params: { startDate, endDate },
    });
    return response.data;
  },
);

export const getPublicHolidays = createAsyncThunk(
  `timeline/getPublicHolidaysByOrganization`,
  async (payload: { startDate: string; endDate: string }) => {
    const { startDate, endDate } = payload;
    const response = await api.get(
      `/organization/${getOrganizationId()}/public-holiday/time-period`,
      { params: { startDate, endDate } },
    );
    return response.data;
  },
);
