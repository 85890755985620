import { IconButton, Avatar } from 'components/core';
import { ReactComponent as DeleteIcon } from 'assets/DeleteIcon.svg';

import type { ProjectPhaseUser } from 'modules/projects/models';
import { getUserFullName } from 'utils/auth/common';

type Props = {
  user: ProjectPhaseUser;
  isEditMode: boolean;
  onRemove: (userId: number) => void;
};

const PhaseUserListItem = ({ user, isEditMode, onRemove }: Props) => {
  const { id, firstName, lastName, profilePicture, jobTitle } = user;

  const fullName = getUserFullName(firstName, lastName);

  return (
    <>
      {isEditMode && (
        <IconButton
          aria-label={`Remove user ${fullName}`}
          icon={<DeleteIcon />}
          iconButtonType="delete"
          tooltipContent="Delete"
          tooltipPosition="bottom"
          onClick={() => onRemove(id)}
        />
      )}
      <Avatar
        horizontal
        size="extra-small"
        title={fullName}
        subtitle={jobTitle}
        imageUrl={profilePicture}
      />
    </>
  );
};

export default PhaseUserListItem;
