import { useCallback, useState } from 'react';
import SectionItem from 'modules/settings/components/Section/SectionItem';
import PtoPolicyForm from '../PtoPolicyForm/PtoPolicyForm';
import { isFormValid } from '../utils';
import { PtoPolicy } from 'modules/shared/model';
import { ActionConfirmationModal } from 'components/core';

type Props = {
  item: PtoPolicy;
  isLoading: boolean;
  onSave: (updatedPolicy: PtoPolicy) => void;
  onDelete: (ptoPolicyId: number) => void;
  setIsChangeDefaultModalOpen?: (isChangeDefaultModalOpen: boolean) => void;
  isChangeDefaultModalOpen?: boolean;
};

const PtoPolicyItem = ({
  item,
  isLoading,
  isChangeDefaultModalOpen,
  onSave,
  onDelete,
  setIsChangeDefaultModalOpen,
}: Props) => {
  const [updatedPtoPolicy, setUpdatedPtoPolicy] = useState<PtoPolicy>(item);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const { name, annualDays, carryOverDays, default: isDefault } = item;

  const handleOnCancel = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const handleOnUpdate = useCallback((ptoPolicy: PtoPolicy) => {
    setUpdatedPtoPolicy(ptoPolicy);
  }, []);

  const handleOnSave = useCallback(() => {
    if (!isFormValid(updatedPtoPolicy)) return;

    onSave(updatedPtoPolicy);
    setIsEditMode(false);
  }, [updatedPtoPolicy, onSave]);

  const handleOnDelete = useCallback(() => {
    if (!updatedPtoPolicy?.id) return;

    onDelete(updatedPtoPolicy.id);
    handleOnCancel();
  }, [updatedPtoPolicy?.id, onDelete, handleOnCancel]);

  return (
    <>
      <SectionItem
        itemContent={name}
        itemOption={`${annualDays} days with ${carryOverDays ? carryOverDays : 0} carry over`}
        badgeLabel={isDefault ? 'Default' : ''}
        openEdit={isEditMode}
        isLoading={isLoading}
        onSave={handleOnSave}
        onDelete={() => setIsConfirmationModalOpen(true)}
        onEdit={() => setIsEditMode(true)}
        onCancel={() => setIsEditMode(false)}
      />
      {isEditMode && (
        <PtoPolicyForm
          setIsConfirmationModalOpen={setIsChangeDefaultModalOpen}
          isConfirmationModalOpen={isChangeDefaultModalOpen}
          ptoPolicy={item}
          onChange={handleOnUpdate}
          displayMode="settings-section"
        />
      )}
      {isConfirmationModalOpen && (
        <ActionConfirmationModal
          message={`you want to delete PTO policy "${item.name}"?`}
          isModalOpen={isConfirmationModalOpen}
          closeModal={handleOnCancel}
          isLoading={isLoading}
          handleNo={handleOnCancel}
          handleYes={handleOnDelete}
        />
      )}
    </>
  );
};

export default PtoPolicyItem;
