import dayjs from 'dayjs';
import { DateRangeType } from 'modules/timeAway/components/BookTimeAwayModal';

const isSelectedDate = (date: Date, dateRange: DateRangeType) => {
  const { startDate, endDate } = dateRange;
  return startDate && endDate && date >= startDate && date <= endDate;
};

export const renderDayColor = (
  day: Date,
  ranges: DateRangeType[],
  workingDays: string[] | undefined,
): string => {
  if (
    isSelectedDate(day, ranges[0]) ||
    (dayjs(day).isSame(dayjs(), 'day') &&
      dayjs(ranges[0].startDate).isSame(dayjs(), 'day') &&
      dayjs(ranges[0].endDate).isSame(dayjs(), 'day'))
  ) {
    return '#ffffff';
  }

  if (!workingDays?.includes(dayjs(day).format('dddd').toUpperCase())) {
    return '#bac2cf';
  }

  return '#000000';
};
