import {
  useEffect,
  useState,
  useCallback,
  useRef,
  type KeyboardEvent,
  type ChangeEvent,
  type MouseEvent,
  useMemo,
} from 'react';
import classes from './AddMemberDropDown.module.scss';
import { useOnClickOutside } from 'hooks';
import { ReactComponent as DropdownArrowIcon } from 'assets/DropdownArrowIcon.svg';
import { Avatar, ErrorMessage, IconButton } from 'components/core';
import avatarPlaceholder from 'assets/AvatarPlaceholder.svg';
import { UserBaseInfo } from 'modules/people/model/User';

export type DropdownItem = {
  value: string;
  id?: number;
  profilePhotoPath?: string | null;
  jobTitle?: string;
};

export type Props = {
  label?: string | React.ReactNode;
  items: DropdownItem[];
  id?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  setValue?: (value: string) => void;
  setSelectedItemId?: (id: number) => void;
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOnClick?: (prop: string) => void;
  autoFocus?: boolean;
  selectedValue?: string;
  isAddNewDisabled?: boolean;
  setOpenModal?: (isOpen: boolean) => void;
  isSubmitted?: boolean;
  isBorderVisible?: boolean;
  readOnly?: boolean;
  userMessage?: string;
  setUser?: (user: UserBaseInfo) => void;
  excludeSearchOption?: boolean;
  errorMessage?: string;
  defaultSelectedItemId?: number;
};

export const AddMemberDropDown = ({
  label,
  items,
  id,
  name,
  type = 'text',
  placeholder,
  setValue,
  setSelectedItemId,
  handleOnChange,
  autoFocus,
  selectedValue,
  isAddNewDisabled = false,
  isSubmitted,
  isBorderVisible = true,
  readOnly,
  userMessage,
  setUser,
  excludeSearchOption = false,
  errorMessage,
  defaultSelectedItemId,
}: Props) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(selectedValue || '');
  const [overflow, setOverflow] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const isReportTo =
    id === 'reportTo' || id === 'assigned-user' || id === 'add-member' || id === 'dedicatedHrDto';
  const divRef = useOnClickOutside(() => {
    if (selectedValue !== inputValue) {
      setInputValue('');
    }

    setIsOptionsOpen(false);
  });

  const isFieldInvalid = useMemo(
    () => isSubmitted && (selectedValue?.trim() === '' || !selectedValue),
    [isSubmitted, selectedValue],
  );

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChange?.(event);
    setInputValue(event.target.value);
  };

  const handleSelectItem = ({ value, id }: DropdownItem) => {
    if (!!setSelectedItemId && id) {
      setSelectedItemId(id);
    }
    if (setUser) {
      const foundUser: DropdownItem | undefined = items.find((user) => user.id === id);
      if (foundUser && foundUser?.id && foundUser.jobTitle) {
        const user: UserBaseInfo = {
          id: foundUser.id,
          firstName: foundUser.value.split(' ')[0],
          lastName: foundUser.value.split(' ')[1],
          jobTitle: foundUser.jobTitle,
          profilePhotoPath: foundUser.profilePhotoPath,
        };

        if (user) setUser(user);
      }
    }

    setValue?.(value);
    setInputValue(value);
    setIsOptionsOpen(false);
  };

  const handleOpenDropdown = (event: MouseEvent, isOpen: boolean) => {
    event.preventDefault();
    setIsOptionsOpen(isOpen);

    if (isOptionsOpen) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();
    }
  };

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Tab') {
        setIsOptionsOpen(false);
        return;
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        handleSelectItem({ value: inputValue });
        return;
      }
    },
    [inputValue],
  );

  const renderSearchResults = () => {
    let newValues;

    if (excludeSearchOption || selectedValue !== '') {
      newValues = items;
    } else {
      newValues = items.filter(({ value, jobTitle }) =>
        inputValue
          ? value.toLowerCase().includes(inputValue.toLowerCase()) ||
            jobTitle?.toLowerCase().includes(inputValue.toLowerCase())
          : value,
      );
    }

    if (newValues.length)
      return newValues.map((item) => (
        <button
          className={classes['c-addmember-dropdown__option']}
          key={item.id ?? item.value}
          onClick={() => handleSelectItem(item)}
        >
          {item.profilePhotoPath || item.profilePhotoPath === null ? (
            <Avatar
              imageUrl={item.profilePhotoPath || avatarPlaceholder}
              title={
                <span className={classes['c-addmember-dropdown__option-value']}>{item.value}</span>
              }
              subtitle={
                <span className={classes['c-addmember-dropdown__option-value-subtitle']}>
                  {item.jobTitle}
                </span>
              }
              size="extra-small"
              horizontal
            />
          ) : (
            <span className={classes['c-addmember-dropdown__option-value']}>{item.value}</span>
          )}
        </button>
      ));

    if (id === 'location') {
      return (
        <span className={classes['c-addmember-dropdown__option--missing']}>
          There is no location found
        </span>
      );
    }

    if (id === 'projectId') {
      return (
        <span className={classes['c-addmember-dropdown__option--missing']}>
          There is no project found
        </span>
      );
    }
    if (isReportTo && userMessage) {
      return (
        <span className={classes['c-addmember-dropdown__option--missing']}>{userMessage}</span>
      );
    }

    if (isAddNewDisabled && !isReportTo)
      return <span className={classes['c-addmember-dropdown__option--missing']}>No results</span>;

    return (
      !isReportTo && (
        <button
          className={classes['c-addmember-dropdown__option']}
          onClick={() => handleSelectItem({ value: inputValue })}
        >
          Add new
        </button>
      )
    );
  };

  useEffect(() => {
    if (inputValue.trim() === '') {
      setValue?.('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    setInputValue(selectedValue || '');
  }, [selectedValue]);

  useEffect(() => {
    if (defaultSelectedItemId && setSelectedItemId) {
      setSelectedItemId(defaultSelectedItemId);
    }
  }, [defaultSelectedItemId, setSelectedItemId]);

  useEffect(() => {
    const optionsRect = optionsRef.current?.getBoundingClientRect();
    const screenHeight = window.innerHeight;

    if (optionsRect && optionsRect.bottom > screenHeight) {
      setOverflow(true);
    }
  }, [isOptionsOpen]);

  return (
    <div className={classes['c-addmember-dropdown']} ref={divRef}>
      {label && <label className={classes['c-addmember-dropdown__input-label']}>{label}</label>}
      <div className={classes['c-addmember-dropdown__field-holder']}>
        <input
          id={id}
          name={name}
          type={type}
          className={`${classes['c-addmember-dropdown__input-field']}  ${
            isBorderVisible ? classes['c-addmember-dropdown__input-field--border'] : ''
          } ${isFieldInvalid ? classes['c-addmember-dropdown__input-field--invalid'] : ''} ${
            readOnly ? classes['c-addmember-dropdown__input-field--readOnly'] : ''
          }`}
          ref={inputRef}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleOnInputChange}
          onClick={(event) => handleOpenDropdown(event, !isOptionsOpen)}
          onKeyDown={handleOnKeyDown}
          autoFocus={autoFocus}
          autoComplete="off"
          disabled={readOnly}
        />
        {(isReportTo
          ? !readOnly && inputValue.length >= 3 && (items.length > 0 || userMessage)
          : !readOnly) && (
          <IconButton
            icon={
              <DropdownArrowIcon
                className={`${classes['c-addmember-dropdown__icon']} ${
                  isOptionsOpen ? classes['c-addmember-dropdown__icon--rotated'] : ''
                }`}
              />
            }
            aria-label={isOptionsOpen ? 'Close options' : 'Open options'}
            onClick={(event) => handleOpenDropdown(event, !isOptionsOpen)}
          />
        )}
      </div>
      {errorMessage && <ErrorMessage invalidMessage={errorMessage} isInputInvalid />}
      {(isReportTo
        ? isOptionsOpen && inputValue.length >= 3 && (items.length > 0 || userMessage)
        : isOptionsOpen) && (
        <div
          className={`${classes['c-addmember-dropdown__options-wrap']} ${
            overflow && label && classes['c-addmember-dropdown__options-wrap--overflow-with-label']
          }`}
          ref={optionsRef}
        >
          <div className={classes['c-addmember-dropdown__options']}>{renderSearchResults()}</div>
        </div>
      )}
    </div>
  );
};
