import type { ReactNode } from 'react';
import { Toolbar } from './Toolbar';
import classes from './Layout.module.scss';
import { Sidebar } from './Sidebar';

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => {
  return (
    <div className={classes['c-layout']}>
      <Sidebar />
      <div className={classes['c-layout__container']}>
        <Toolbar />
        <div className={classes['c-layout__content']}>{children}</div>
      </div>
    </div>
  );
};
