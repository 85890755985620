import { type CSSProperties } from 'react';
import { ReactComponent as EditIcon } from 'assets/EditIcon.svg';
import classes from './EditButton.module.scss';

type Props = {
  onClick?: () => void;
  style?: CSSProperties;
  disabled?: boolean;
};

export const EditButton = ({ onClick, style, disabled }: Props) => {
  return (
    <button
      className={classes['c-edit-button']}
      disabled={disabled}
      style={style}
      onClick={onClick}
      aria-label="Edit"
    >
      <EditIcon />
    </button>
  );
};
