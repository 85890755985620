import { useCallback, useEffect, useMemo } from 'react';
import classes from './Filter.module.scss';
import { toggleFilter, markFilterById, selectFilters } from './redux/filterSlice';
import { ReactComponent as Minimize } from 'assets/Minimize.svg';
import { toggleFilters } from 'components/layout/Toolbar/redux/toolbarSlice';
import { useLocation } from 'react-router-dom';
import { AppliedFilters } from './AppliedFilters';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getAllFilters } from './redux/filterActions';
import { FilterSection } from './FilterSection';
import { FilterCategory, RequestState, UserType } from 'config/constants';
import { Status } from '../Status';
import { getAllWorkflows } from 'modules/workflows/redux/workflowTemplatesActions';
import { Button } from '../Button';

export type FetchedFilters = {
  departmentNames: string[];
  locationNames: string[];
  projectNames: string[];
};

export const userFilterTypes: Record<UserType, string> = {
  ACTIVE_MEMBER: 'Active member',
  NEW_HIRE: 'New hire',
  ALL_USERS: 'All members',
  OFFBOARDED : 'Offboarded',

};

type FilterSectionType = {
  title: string;
  filterCategory: FilterCategory;
  path?: string[];
};

type Props = {
  filters?: string[];
};

const sortByList = ['Name A -> Z', 'Surname A -> Z', 'Oldest -> Newest'];

const filterSections: FilterSectionType[] = [
  { title: 'Workflow', filterCategory: FilterCategory.WORKFLOW, path: ['/workflows'] },
  { title: 'Department', filterCategory: FilterCategory.DEPARTMENT },
  { title: 'Location', filterCategory: FilterCategory.LOCATION },
  { title: 'Project', filterCategory: FilterCategory.PROJECT, path: ['/people', '/timeline'] },
  { title: 'Sort by', filterCategory: FilterCategory.SORT },
];

export const Filter = ({ filters }: Props) => {
  const { fetchedFilters, initialFetch, appliedFilters, loading } = useAppSelector(selectFilters);

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const areFiltersApplied = useMemo(
    () =>
      Object.values(appliedFilters).some((filterCategory) => {
        if (Array.isArray(filterCategory)) return filterCategory.length;

        return filterCategory.id !== -1;
      }),
    [appliedFilters],
  );

  const getFilters = useCallback(
    (filterCategory: FilterCategory) => {
      switch (filterCategory) {
        case FilterCategory.WORKFLOW:
          return filters;
        case FilterCategory.SORT:
          return pathname === '/timeline' ? sortByList.slice(0, 2) : sortByList;
        default:
          return fetchedFilters[filterCategory];
      }
    },
    [fetchedFilters, filters, pathname],
  );

  const handleOnClick = useCallback(
    (filterCategory: string, filterValue: string, index: number) => {
      if (filterCategory === FilterCategory.SORT) {
        dispatch(markFilterById({ id: index }));
        return;
      }

      dispatch(toggleFilter({ filterCategory, filterValue }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!initialFetch) {
      dispatch(getAllFilters());
    }
  }, [dispatch, initialFetch]);

  useEffect(() => {
    if (pathname === '/workflows') dispatch(getAllWorkflows({ page: 0, size: 9, sort: 'id' }));
  }, [pathname, dispatch]);

  return (
    <div
      className={`${classes['c-filter-overview']} ${
        areFiltersApplied ? classes['c-filter-overview--applied-filters'] : ''
      }`}
    >
      <div className={classes['c-filter-overview__top-section']}>
        <Status isLoading={loading === RequestState.PENDING} spinnerSize={30}>
          <div className={classes['c-filter-overview__data']}>
            {filterSections.map((section) => {
              if (!section.path || section.path?.includes(pathname)) {
                return (
                  <FilterSection
                    key={section.title}
                    title={section.title}
                    filters={getFilters(section.filterCategory)}
                    filterCategory={section.filterCategory}
                    handleOnClick={(filterValue, index) =>
                      handleOnClick(section.filterCategory, filterValue, index)
                    }
                  />
                );
              }
            })}
          </div>
          <div className={classes['c-filter-overview__minimize']}>
            <Button
              variant="text"
              rightIcon={<Minimize />}
              onClick={() => dispatch(toggleFilters())}
            >
              Minimize
            </Button>
          </div>
        </Status>
      </div>
      {areFiltersApplied && <AppliedFilters />}
    </div>
  );
};
