import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import type { CustomField, NewCustomField } from '../models/settingsModels';

export const getCustomFields = async () => {
  return api.get<CustomField[]>(`/organization/${getOrganizationId()}/custom-field`);
};

export const createCustomField = async (customField: NewCustomField) => {
  return api.post<CustomField>(`/organization/${getOrganizationId()}/custom-field`, customField);
};

export const updateCustomField = async (customField: CustomField) => {
  return api.put<CustomField>(
    `/organization/${getOrganizationId()}/custom-field/${customField.id}`,
    customField,
  );
};

export const deleteCustomField = async (id: number) => {
  return api.delete<void>(`/organization/${getOrganizationId()}/custom-field/${id}`);
};
