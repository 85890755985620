import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { useUserRole } from 'hooks';
import { selectAuthUser } from 'modules/auth/redux/authSlice';
import classes from './SectionActions.module.scss';
import { CancelSaveButtonGroup, IconButton } from 'components/core';
import { ReactComponent as DownloadIcon } from 'assets/Download.svg';
import { ReactComponent as PreviewIcon } from 'assets/Preview.svg';
import { ReactComponent as EditIcon } from 'assets/PencilSquare.svg';
import { ReactComponent as DeleteIcon } from 'assets/DeleteIcon.svg';
import { ReactComponent as AddIcon } from 'assets/AddIconRoundOutline.svg';

type Props = {
  isEditOpen: boolean;
  handleEdit?: () => void;
  handleCancel?: () => void;
  handleSave?: () => void;
  handleDelete?: () => void;
  handleAdd?: () => void;
  handlePreview?: () => void;
  handleDownload?: () => void;
  isLoading: boolean;
  isContentAvailable?: boolean;
  isSaveButtonDisabled?: boolean;
};

export const SectionActions = ({
  isEditOpen,
  handleEdit,
  handleCancel,
  handleSave,
  handleDelete,
  handleAdd,
  handlePreview,
  handleDownload,
  isLoading,
  isContentAvailable = true,
  isSaveButtonDisabled,
}: Props) => {
  const { user } = useAppSelector(selectAuthUser);
  const { pathname } = useLocation();
  const { isAdminOrSuperAdmin } = useUserRole(user?.role);

  const shouldAllowEdit = useMemo(() => {
    if (!pathname.includes('/employment')) return true;

    return isAdminOrSuperAdmin;
  }, [pathname, isAdminOrSuperAdmin]);

  if (isEditOpen && !!handleCancel && !!handleSave)
    return (
      <CancelSaveButtonGroup
        onCancel={handleCancel}
        onSave={handleSave}
        isLoading={isLoading}
        disabled={isSaveButtonDisabled}
      />
    );

  if (shouldAllowEdit) {
    return (
      <div className={classes['c-section-actions']}>
        {isContentAvailable && (
          <>
            {!!handlePreview && (
              <IconButton
                icon={<PreviewIcon />}
                onClick={handlePreview}
                tooltipContent="Preview"
                tooltipPosition="bottom"
              />
            )}
            {!!handleDownload && (
              <IconButton
                icon={<DownloadIcon />}
                onClick={handleDownload}
                tooltipContent="Download"
                tooltipPosition="bottom"
              />
            )}
            {!!handleEdit && (
              <IconButton
                icon={<EditIcon />}
                onClick={handleEdit}
                tooltipContent="Edit"
                tooltipPosition="bottom"
              />
            )}
            {!!handleDelete && (
              <IconButton
                icon={<DeleteIcon />}
                onClick={handleDelete}
                tooltipContent="Delete"
                tooltipPosition="bottom"
                iconButtonType="delete"
              />
            )}
          </>
        )}
        {!!handleAdd && (
          <IconButton
            icon={<AddIcon />}
            onClick={handleAdd}
            tooltipContent="New"
            tooltipPosition="bottom"
          />
        )}
      </div>
    );
  }

  return <></>;
};
