import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import type { Task as TaskModel, WorkflowSection, Workflow, Task } from '../model/workflow.model';

export const getWorkflowTemplate = (id: string | number) => {
  return api.get<Workflow>(`/organization/${getOrganizationId()}/workflow/${id}`);
};

export const createWorkflowTemplate = (workflow: Workflow) => {
  const newWorkflowSections = workflow.workflowSections.map((section) => {
    const { id, workflowId, ...sectionData } = section;
    return {
      ...sectionData,
      tasks: section.tasks.map((task) => {
        const { id, workflowSectionId, ...taskData } = task;
        return taskData;
      }),
    };
  });

  return api.post<Workflow>(`/organization/${getOrganizationId()}/workflow`, {
    name: workflow.name,
    workflowSections: newWorkflowSections,
  });
};

export const createWorkflowSection = (section: WorkflowSection) => {
  const tasks = section.tasks.map((task) => ({
    name: task.name,
    description: task.description,
    order: task.order,
  }));

  return api.post<WorkflowSection>(
    `/organization/${getOrganizationId()}/workflow/${section.workflowId}/workflow-section`,
    { ...section, tasks },
  );
};

export const createSectionTask = async (workflowId: number, task: TaskModel) => {
  const { id, ...taskData } = task;
  return api.post<Task>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-section/${task?.workflowSectionId}/task`,
    { ...taskData },
  );
};

export const updateWorkflowTemplate = (workflow: Workflow) => {
  return api.put<Workflow>(`/organization/${getOrganizationId()}/workflow/${workflow.id}`, {
    ...workflow,
  });
};

export const updateWorkflowSection = async (section: WorkflowSection) => {
  const tasks = section.tasks.map((task) => {
    if (String(task.id).includes('temp'))
      return {
        name: task.name,
        description: task.description,
        order: task.order,
      };
    return task;
  });
  return api.put<WorkflowSection>(
    `/organization/${getOrganizationId()}/workflow/${section.workflowId}/workflow-section/${
      section.id
    }`,
    { ...section, tasks },
  );
};

export const updateSectionTask = async (workflowId: number, task: TaskModel | undefined) => {
  return api.put<Task>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-section/${task?.workflowSectionId}/task/${task?.id}`,
    { ...task },
  );
};

export const deleteWorkflowTemplate = (workflowId: number) => {
  return api.delete<void>(`/organization/${getOrganizationId()}/workflow/${workflowId}`);
};

export const deleteWorkflowSection = (workflowId: number, sectionId: number) => {
  return api.delete<void>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-section/${sectionId}`,
  );
};

export const deleteSectionTask = (
  workflowId: number,
  workflowSectionId: number,
  taskId: number,
) => {
  return api.delete<void>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-section/${workflowSectionId}/task/${taskId}`,
  );
};
