import { useState } from 'react';
import classes from './ExportDataModal.module.scss';
import ExportDataSection from '../ExportDataSection/ExportDataSection';
import { ReactComponent as SaveIcon } from 'assets/SaveIcon.svg';
import { PopUpModal, Button, Tooltip } from 'components/core';
import { ExportModel, ReportInputParams, type ReportTemplate } from '../../model/exportDataModels';
import { type ExportData } from 'pages/ReportsPage/ReportsPage';
import toast from 'utils/notifications/CustomToast';
import { createReport } from '../../api/reports.api';
import SaveTemplateModal from '../SaveTemplateModal/SaveTemplateModal';

type Props = {
  opened: boolean;
  toggleModal: (isOpen?: boolean) => void;
  templateId?: number;
  findSelectedTemplateReport: () => ReportTemplate | undefined;
  setSelectedTemplate: (templateId?: number) => void;
  checkBoxFields: ExportData[];
  setCheckBoxFields: React.Dispatch<React.SetStateAction<ExportData[]>>;
};

// interface DropDownOptions {
//   option: string;
//   id: number;
// }

const ExportDataModal = ({
  opened,
  toggleModal,
  templateId,
  setSelectedTemplate,
  checkBoxFields,
  setCheckBoxFields,
}: Props) => {
  const [isSaveTemplateModalOpen, setIsSaveTemplateModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const closeModals = () => {
    toggleModal();
    setIsSaveTemplateModalOpen(false);
  };
  // Commented out for future use: This implementation of Export Range may be needed later for additional functionality

  // const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  // const [openDropDown, setOpenDropdown] = useState<boolean>(false);

  // const dropDownOptions = useMemo(
  //   () => [
  //     { id: 1, option: 'Year to date' },
  //     { id: 2, option: 'Last 30 days' },
  //     { id: 3, option: 'Last 12 days' },
  //     { id: 4, option: 'Last 2 years' },
  //     { id: 5, option: 'Last 5 years' },
  //     { id: 6, option: '2022' },
  //     { id: 7, option: '2021' },
  //     { id: 8, option: 'All time' },
  //     { id: 9, option: 'Custom' }
  //   ],
  //   []
  // );

  // const [selectedDropDownOption, setSelectedDropDownOption] = useState<DropDownOptions | null>(
  //   null
  // );

  // const [dateRange, setDateRange] = useState<DateRangeType[]>([
  //   {
  //     startDate: undefined,
  //     endDate: undefined,
  //     key: 'selection'
  //   }
  // ]);

  const getFieldsByGroup = (group: string): ExportData[] => {
    const checkboxFields = checkBoxFields.filter((checkbox) => checkbox.group === group);
    return checkboxFields;
  };

  const noneCheckboxSelectedChecker = (): boolean => {
    const userFields = getFieldsByGroup('User');
    return !userFields.some((checkbox) => checkbox.checked);
  };

  const isButtonDisabled = noneCheckboxSelectedChecker();

  const checkboxSelectHandler = (id: number | string) => {
    const newCheckedFields = [...checkBoxFields];
    newCheckedFields.forEach((checkbox) => {
      if (checkbox.id === id) {
        checkbox.checked = !checkbox.checked;
      }
    });
    setCheckBoxFields(newCheckedFields);
  };

  const selectAllCheckboxesByGroupHandler = (group: string, checked: boolean) => {
    const newCheckedFields = [...checkBoxFields];
    newCheckedFields.forEach((checkbox) => {
      if (checked && checkbox.group === group) {
        checkbox.checked = false;
      } else if (!checked && checkbox.group === group) {
        checkbox.checked = true;
      }
    });
    setCheckBoxFields(newCheckedFields);
  };

  const resetSelections = () => {
    const newCheckedFields = [...checkBoxFields];
    newCheckedFields.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setCheckBoxFields(newCheckedFields);
    // setDateRange([
    //   {
    //     startDate: undefined,
    //     endDate: undefined,
    //     key: 'selection'
    //   }
    // ]);
    // setSelectedDropDownOption(null);
  };

  // const selectDropdownHandler = (id: number) => {
  //   setSelectedDropDownOption(dropDownOptions.find((dropdown) => dropdown.id === id) || null);
  //   setOpenDropdown(false);
  // };

  const fillExportModel = async () => {
    const exportModel: ExportModel = {
      reportInputParams: {},
    };

    checkBoxFields.forEach((checkbox) => {
      if (checkbox.checked) {
        const group = checkbox.group as keyof ReportInputParams;

        if (!exportModel.reportInputParams[group]) {
          exportModel.reportInputParams[group] = [];
        }
        exportModel.reportInputParams[group]?.push(checkbox.value || '');
      }
    });

    return exportModel;
  };

  const exportReport = async () => {
    const exportReport: ExportModel = await fillExportModel();
    setIsLoading(true);
    createReport(exportReport)
      .then((res) => {
        const href = URL.createObjectURL(res.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'export-report.csv');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        resetSelections();
        toggleModal();
        toast.success('You have successfully exported custom report.');
      })
      .catch(() => toast.error('Something went wrong while exporting custom report. Try again.'))
      .finally(() => setIsLoading(false));
  };

  const handleCloseModal = () => {
    if (isSaveTemplateModalOpen) return;

    if (templateId) {
      setSelectedTemplate(undefined);
    }
    toggleModal(false);
    resetSelections();
  };

  const handleOnMouseEnter = () => {
    if (isButtonDisabled) setIsTooltipVisible(true);
  };

  return (
    <>
      <PopUpModal
        title="Export Data"
        opened={opened}
        closeModal={handleCloseModal}
        width={75}
        hidden={isSaveTemplateModalOpen}
      >
        <div className={classes['c-export-data-modal']}>
          {/* <div className={classes['c-export-data-modal__export-inputs']}>
              <ExportDropDown
                onChange={selectDropdownHandler}
                selectedOption={selectedDropDownOption}
                options={dropDownOptions}
                hidden={openDropDown}
                onClick={(dropdownState: boolean) => {
                  setOpenDropdown(dropdownState);
                }}
              />
              <DateRangePicker
                onClick={(dateRangePickerState: boolean) => {
                  setOpenCalendar(dateRangePickerState);
                }}
                dateRange={dateRange}
                setDateRange={setDateRange}
                openCalendar={openCalendar}
              />
              </div> */}
          <div className={classes['c-export-data-modal__checkbox-sections']}>
            <ExportDataSection
              handleCheck={checkboxSelectHandler}
              data={getFieldsByGroup('User')}
              first
              title="User"
              titleId="User"
              handleAllCheck={selectAllCheckboxesByGroupHandler}
            />
            <ExportDataSection
              handleCheck={checkboxSelectHandler}
              data={getFieldsByGroup('Employment')}
              title="Employment"
              titleId="Employment"
              handleAllCheck={selectAllCheckboxesByGroupHandler}
            />
            <div className={classes['c-export-data-modal__sections-column']}>
              <ExportDataSection
                handleCheck={checkboxSelectHandler}
                data={getFieldsByGroup('EmploymentRole')}
                title="Employment role"
                titleId="EmploymentRole"
                handleAllCheck={selectAllCheckboxesByGroupHandler}
              />
              <ExportDataSection
                handleCheck={checkboxSelectHandler}
                data={getFieldsByGroup('Offboarding')}
                title="Offboarding"
                titleId="Offboarding"
                handleAllCheck={selectAllCheckboxesByGroupHandler}
              />
            </div>
            <ExportDataSection
              handleCheck={checkboxSelectHandler}
              data={getFieldsByGroup('BankAccount')}
              title="Banking"
              titleId="BankAccount"
              handleAllCheck={selectAllCheckboxesByGroupHandler}
            />
            <ExportDataSection
              handleCheck={checkboxSelectHandler}
              data={getFieldsByGroup('Compensation')}
              title="Compensation"
              titleId="Compensation"
              handleAllCheck={selectAllCheckboxesByGroupHandler}
            />
            <ExportDataSection
              handleCheck={checkboxSelectHandler}
              data={getFieldsByGroup('TimeAway')}
              last
              title="Time off"
              titleId="TimeAway"
              handleAllCheck={selectAllCheckboxesByGroupHandler}
            />
          </div>
          <div className={classes['c-export-data-modal__buttons']}>
            <div
              className={classes['c-export-data-modal__button-wrap']}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={() => setIsTooltipVisible(false)}
            >
              <Tooltip isOpen={isTooltipVisible}>
                Please select at least one option from User category.
              </Tooltip>
              <Button
                variant="text"
                leftIcon={
                  <div
                    className={`${classes['c-export-data-modal__save-icon-wrap']} ${
                      isButtonDisabled
                        ? classes['c-export-data-modal__save-icon-wrap--disabled']
                        : ''
                    }`}
                  >
                    <SaveIcon className={classes['c-export-data-modal__save-icon']} />
                  </div>
                }
                disabled={isButtonDisabled}
                onClick={() => setIsSaveTemplateModalOpen(true)}
              >
                Save export template
              </Button>
            </div>

            <Button
              size="medium"
              isLoading={isLoading}
              onClick={exportReport}
              disabled={isButtonDisabled}
            >
              Export
            </Button>
          </div>
        </div>
        <SaveTemplateModal
          isModalOpen={isSaveTemplateModalOpen}
          setIsModalOpen={closeModals}
          checkBoxFields={checkBoxFields}
        />
      </PopUpModal>
    </>
  );
};

export default ExportDataModal;
