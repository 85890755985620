import type { ReactNode } from 'react';
import { Spinner } from '../Spinner';

type Props = {
  children: ReactNode;
  isLoading?: boolean;
  spinnerSize?: number;
};

export const Status = ({ isLoading, spinnerSize = 90, children }: Props) => {
  if (isLoading) return <Spinner size={spinnerSize} />;

  return <>{children}</>;
};
