import { useEffect, useState } from 'react';
import { PopUpModal, Input, Button, AddButton } from 'components/core';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { type EmergencyContact as EmergencyContactModel } from '../models/profileModels';
import Contact from './Sections/SectionClassic/Contact';
import classes from '../styles/EmergencyContacts.module.scss';
import {
  addNewEmergencyContact as addNewEmergencyContactAction,
  selectUser,
  selectUserId,
} from '../redux/userSlice';
import { emailRegex } from 'utils/regexs';
import toast from 'utils/notifications/CustomToast';
import { useParams } from 'react-router-dom';
import { useToolbarTitle, useUserRole } from 'hooks';
import { createEmergencyContact } from '../api/profile.api';
import { ReactComponent as PhoneIcon } from 'assets/PhoneIcon.svg';

const initialEmergencyContact: EmergencyContactModel = {
  fullName: '',
  email: '',
  phone: '',
  relationship: '',
  isPrimary: false,
  userId: 0,
};

const EmergencyContacts = () => {
  const { id } = useParams();
  const { emergencyContacts } = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const userId = useAppSelector(selectUserId);
  const { isAdminOrSuperAdmin } = useUserRole(user?.role);

  useToolbarTitle({ title: ['Profile', 'Emergency contacts'] });

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [newEmergencyContact, setNewEmergencyContact] = useState<EmergencyContactModel>({
    ...initialEmergencyContact,
    userId,
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const handleNewEmergencyContactChange = (value: string, changeAttribute: string) => {
    setNewEmergencyContact((prevState) => ({
      ...prevState,
      [changeAttribute]: value,
    }));
  };

  const isSubmitButtonDisabled =
    !newEmergencyContact.fullName ||
    !newEmergencyContact.phone ||
    !newEmergencyContact.relationship ||
    (newEmergencyContact.email && emailError);

  const showAddButton = isAdminOrSuperAdmin || userId === Number(id);

  const handleAddNewEmergencyContact = () => {
    setIsFormSubmitted(true);

    if (isSubmitButtonDisabled) return;
    addNewEmergencyContact();
  };

  const addNewEmergencyContact = async () => {
    try {
      setIsLoading(true);
      const res = await createEmergencyContact(newEmergencyContact, userId);

      toast.success('You have successfully added a new emergency contact.');
      dispatch(addNewEmergencyContactAction(res.data));
      handleCloseModal();
    } catch {
      toast.error('Something went wrong while saving. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setNewEmergencyContact({ ...initialEmergencyContact, userId });
    setIsFormSubmitted(false);
    setOpenModal(false);
  };

  useEffect(() => {
    if (newEmergencyContact.email && !emailRegex.test(newEmergencyContact.email)) {
      setEmailError('Email format is not valid');
      return;
    }
    setEmailError('');
  }, [newEmergencyContact.email]);

  return (
    <div className={classes['c-emergency-contacts']}>
      <div className={classes['c-emergency-contacts__container']}>
        {emergencyContacts?.map((contact) => (
          <div key={contact.id} className={classes['c-emergency-contacts__card']}>
            <Contact contact={contact} userAuthId={user?.id} userRole={user?.role} />
          </div>
        ))}
        {showAddButton && (
          <div className={classes['c-emergency-contacts__card']}>
            <AddButton
              label="Add emergency contact"
              info="Please add a friend or family member we can contact"
              icon={<PhoneIcon className={classes['c-emergency-contacts__icon']} />}
              onClick={() => setOpenModal(true)}
            />
          </div>
        )}
      </div>
      <PopUpModal
        title="Add emergency contact"
        opened={openModal}
        closeModal={handleCloseModal}
        width={30}
      >
        <div className={classes['c-emergency-contacts__modal']}>
          <div className={classes['c-emergency-contacts__modal-inputs']}>
            <Input
              name="fullName"
              id="fullName"
              label="Full name"
              value={newEmergencyContact.fullName}
              handleOnChange={(event) =>
                handleNewEmergencyContactChange(event.target.value, 'fullName')
              }
              isSubmitted={isFormSubmitted}
              size="medium"
            />
            <Input
              name="email"
              id="email"
              errorMessage={emailError}
              label="Email"
              value={newEmergencyContact.email}
              handleOnChange={(event) =>
                handleNewEmergencyContactChange(event.target.value, 'email')
              }
              size="medium"
            />
            <Input
              name="phone"
              id="phone"
              label="Phone"
              value={newEmergencyContact.phone}
              handleOnChange={(event) =>
                handleNewEmergencyContactChange(event.target.value, 'phone')
              }
              isSubmitted={isFormSubmitted}
              size="medium"
            />
            <Input
              name="relationship"
              id="relationship"
              label="Relationship"
              value={newEmergencyContact.relationship}
              handleOnChange={(event) =>
                handleNewEmergencyContactChange(event.target.value, 'relationship')
              }
              isSubmitted={isFormSubmitted}
              size="medium"
            />
          </div>
          <Button size="medium" onClick={handleAddNewEmergencyContact} isLoading={isLoading}>
            Add emergency contact
          </Button>
        </div>
      </PopUpModal>
    </div>
  );
};

export default EmergencyContacts;
