import { current } from 'components/core/YearPicker/YearPicker';
import dayjs from 'dayjs';
import { PublicHoliday } from '../models/timeAwayModels';

export const getAllDatesInMonth = (currentDate: Date | 'Today'): Date[] => {
  const dates: Date[] = [];
  const now = new Date();

  if (currentDate !== current) {
    for (let i = 1; i <= dayjs(currentDate).daysInMonth(); i++) {
      dates.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), i));
    }
  } else {
    for (let i = now.getDate(); i <= dayjs(now).daysInMonth(); i++) {
      dates.push(new Date(now.getFullYear(), now.getMonth(), i));
    }
    const addMonth = dayjs(now).add(1, 'M').toDate();

    const nextMonthDays = dayjs(addMonth).daysInMonth();

    const daysInNextMonthToShow = nextMonthDays < now.getDate() ? nextMonthDays : now.getDate() - 1;

    for (let i = 1; i <= daysInNextMonthToShow; i++) {
      dates.push(new Date(now.getFullYear(), now.getMonth() + 1, i));
    }
  }

  return dates;
};

export const checkIsToday = (_date: Date, layout: number | 'Today' | Date) =>
  layout !== current && dayjs(_date).isSame(new Date(), 'day');

export const checkIsPublicHoliday = (publicHolidays: PublicHoliday[], date: Date) =>
  publicHolidays.some((ph) => !dayjs(date).diff(ph.date, 'date'));

export const getSplittedAwayClass = (hoveredDay: number | null, date: Date) => {
  if (hoveredDay === date.getTime()) return 'c-away-user__day--hovered';

  if (date.getDay() === 6 || date.getDay() === 0) return 'c-away-user__day--weekend';

  return '';
};
