import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classes from './UserWorkflowTaskDetails.module.scss';
import { ReactComponent as CloseSvg } from 'assets/ClosePopup.svg';
import { ReactComponent as TaskUnchecked } from 'assets/TaskUnchecked.svg';
import { ReactComponent as TaskChecked } from 'assets/TaskChecked.svg';
import { ReactComponent as Checkmark } from 'assets/CheckMark.svg';
import {
  TaskInstanceRequest,
  type TaskInstance,
} from 'modules/workflows/model/workflowInstance.model';
import { UserBaseInfo, type User } from 'modules/people/model/User';
import {
  assignUserToTask,
  deleteTaskInstance,
  updateTaskInstance,
} from 'modules/workflows/api/workflowInstances.api';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import {
  deleteSectionInstanceTask,
  updateSectionTask,
} from 'modules/workflows/redux/workflowInstanceSlice';
import dayjs from 'dayjs';
import { TaskStatus } from 'config/constants';
import { formatDateForInput } from 'utils/dates';
import {
  ActionConfirmationModal,
  EditButton,
  DeleteButton,
  IconButton,
  Avatar,
  Input,
  toast,
  Container,
} from 'components/core';
import { UserSearchInputComponent } from 'components/core/Popups/People/UserSearchInputComponent/UserSearchInputComponent';

type Props = {
  task: TaskInstance;
  user: User;
  setAreTaskDetailsOpen: (isOpen: boolean) => void;
  workflowId: number;
  workflowInstanceId: number;
  isViewOnly?: boolean;
};

type InputsModel = {
  taskName: string;
  assignedUser?: UserBaseInfo;
  triggerDate?: Date | string;
  dueDate?: Date | string;
  description: string;
  taskStatus: TaskStatus;
};

const UserWorkflowTaskDetails = ({
  task,
  user: { firstName, lastName, profilePhotoPath },
  setAreTaskDetailsOpen,
  workflowId,
  workflowInstanceId,
  isViewOnly,
}: Props) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assignedUserId, setAssignedUserId] = useState<number | undefined>(
    task.assignedUserDto?.id,
  );
  const [user, setUser] = useState<UserBaseInfo | undefined>();

  const initialInputs = useMemo(
    () => ({
      taskName: task.name,
      assignedUser: task.assignedUserDto,
      triggerDate: formatDateForInput(task.triggerDate || ''),
      dueDate: formatDateForInput(task.dueDate || ''),
      description: task.description ?? '',
      taskStatus: task.taskStatus || TaskStatus.TO_DO,
    }),
    [task],
  );

  const [inputs, setInputs] = useState<InputsModel>(initialInputs);

  const dispatch = useAppDispatch();

  const handleCloseConfirmationModal = () => setIsConfirmationModalOpen(false);

  const handleAssignUser = useCallback(() => {
    if (!task.workflowSectionInstanceId || !user?.id) return;

    return assignUserToTask(
      workflowId,
      workflowInstanceId,
      task.workflowSectionInstanceId,
      task.id,
      user.id,
    );
  }, [user, task.id, task.workflowSectionInstanceId, workflowId, workflowInstanceId]);

  const handleDateChange = useCallback((value: string, name: string) => {
    setInputs((prev) => ({ ...prev, [name]: formatDateForInput(value) }));
  }, []);

  const handleInputsChange = useCallback(
    (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = event.target;

      setInputs((prev) => ({ ...prev, [name]: value }));
    },
    [],
  );

  const handleCompleteTask = useCallback(async () => {
    setInputs((prev) => ({
      ...prev,
      taskStatus:
        prev.taskStatus === TaskStatus.COMPLETED ? TaskStatus.TO_DO : TaskStatus.COMPLETED,
    }));
  }, []);

  const handleUpdateTask = useCallback(async () => {
    const newTask: TaskInstance = {
      ...task,
      name: inputs.taskName,
      assignedUserDto: user,
      triggerDate: dayjs(inputs.triggerDate).format('YYYY-MM-DD HH:mm:ss'),
      dueDate: inputs.dueDate ? dayjs(inputs.dueDate).format('YYYY-MM-DD HH:mm:ss') : undefined,
      description: inputs.description,
      taskStatus: inputs.taskStatus,
    };

    try {
      if (newTask.assignedUserDto?.id !== task.assignedUserDto?.id) {
        try {
          await handleAssignUser();
        } catch {
          toast('error', 'Something went wrong while assigning user to task. Try again.');
        }
      }
      const { assignedUserDto, ...rest } = newTask;

      const taskInstanceRequest: TaskInstanceRequest = {
        ...rest,
        assignedUserId: assignedUserDto?.id,
      };

      const { data } = await updateTaskInstance(
        workflowId,
        workflowInstanceId,
        taskInstanceRequest,
      );

      dispatch(updateSectionTask({ ...data }));
      setIsEditMode(false);
      toast('success', 'You have successfully updated task.');
    } catch {
      toast('error', 'Something went wrong while updating task. Try again.');
    }
  }, [dispatch, handleAssignUser, inputs, task, workflowId, workflowInstanceId, user]);

  const handleCancel = useCallback(() => {
    setInputs({
      taskName: task.name,
      assignedUser: task.assignedUserDto,
      triggerDate: formatDateForInput(task.triggerDate || ''),
      dueDate: formatDateForInput(task.dueDate || ''),
      description: task.description ?? '',
      taskStatus: task.taskStatus || TaskStatus.TO_DO,
    });
    setIsEditMode(false);
  }, [task]);

  const handleDeleteTask = useCallback(async () => {
    if (!task.workflowSectionInstanceId) return;

    try {
      setIsLoading(true);
      await deleteTaskInstance(
        workflowId,
        workflowInstanceId,
        task.workflowSectionInstanceId,
        task.id,
      );
      dispatch(
        deleteSectionInstanceTask({ sectionId: task.workflowSectionInstanceId, taskId: task.id }),
      );
      toast('success', 'You have successfully deleted task.');
    } catch {
      toast('error', 'Something went wrong while deleting task. Please try again.');
    } finally {
      setAreTaskDetailsOpen(false);
      setIsConfirmationModalOpen(false);
      setIsLoading(false);
    }
  }, [task.id, task.workflowSectionInstanceId, workflowId, workflowInstanceId]);

  const renderAssignedUser = useCallback(() => {
    if (isEditMode)
      return (
        <UserSearchInputComponent
          id="assigned-user"
          selectedUser={assignedUserId}
          setSelectedUser={setAssignedUserId}
          setUser={setUser}
          selectedValue={user ? `${user?.firstName} ${user?.lastName}` : ''}
        />
      );

    if (task?.assignedUserDto)
      return (
        <Avatar
          imageUrl={task?.assignedUserDto?.profilePhotoPath || ''}
          size="extra-small"
          title={`${task?.assignedUserDto.firstName} ${task?.assignedUserDto.lastName}`}
          horizontal
        />
      );

    return (
      <span className={classes['c-user-workflow-task-details__data-item--missing']}>
        No member is assigned.
      </span>
    );
  }, [user, isEditMode, task?.assignedUserDto, assignedUserId]);

  const renderCompleteTaskButton = () => (
    <IconButton
      icon={
        inputs.taskStatus === TaskStatus.COMPLETED ? (
          <TaskChecked height={24} width={24} />
        ) : (
          <TaskUnchecked height={24} width={24} />
        )
      }
      aria-label="Complete task"
      onClick={!isViewOnly && isEditMode ? handleCompleteTask : undefined}
    />
  );

  const renderCancelButton = () => (
    <IconButton
      icon={<CloseSvg className={classes['c-user-workflow-task-details__icon--header']} />}
      aria-label="Cancel changes"
      onClick={handleCancel}
    />
  );

  const renderEditButtons = () => (
    <>
      <EditButton onClick={() => setIsEditMode(true)} />
      <DeleteButton onClick={() => setIsConfirmationModalOpen(true)} />
    </>
  );

  const renderCloseButton = () => (
    <IconButton
      icon={<CloseSvg className={classes['c-user-workflow-task-details__icon--header']} />}
      aria-label="Close task details"
      onClick={() => setAreTaskDetailsOpen(false)}
    />
  );

  const renderIconContainer = () => (
    <div className={classes['c-user-workflow-task-details__icon-container']}>
      {renderCompleteTaskButton()}
      <div className={classes['c-user-workflow-task-details__icon-container-left']}>
        {!isViewOnly && renderEditButtons()}
        {renderCloseButton()}
      </div>
    </div>
  );

  const headerActions =
    isEditMode && !isViewOnly ? (
      <>
        {renderCompleteTaskButton()}
        <Container gap="xs">
          <IconButton
            icon={<Checkmark className={classes['c-user-workflow-task-details__icon-check']} />}
            aria-label="Update task"
            onClick={handleUpdateTask}
          />
          {renderCancelButton()}
        </Container>
      </>
    ) : (
      renderIconContainer()
    );

  useEffect(() => {
    setInputs((prevState) => ({ ...prevState, user }));
  }, [user]);

  useEffect(() => {
    setInputs(initialInputs);
  }, [initialInputs]);

  return (
    <div className={classes['c-user-workflow-task-details']}>
      <div className={classes['c-user-workflow-task-details__header']}>{headerActions}</div>
      <span className={classes['c-user-workflow-task-details__name']}>
        <Input
          name="taskName"
          id="taskName"
          size="medium"
          value={inputs.taskName}
          handleOnChange={handleInputsChange}
          readOnly={!isEditMode}
        />
      </span>
      <div className={classes['c-user-workflow-task-details__data-holder']}>
        <span className={classes['c-user-workflow-task-details__data-label']}>Person</span>
        <Avatar
          size="extra-small"
          imageUrl={profilePhotoPath}
          title={`${firstName} ${lastName}`}
          horizontal
        />
      </div>
      <div className={classes['c-user-workflow-task-details__data-holder']}>
        <span className={classes['c-user-workflow-task-details__data-label']}>Assignee</span>
        <span className={classes['c-user-workflow-task-details__data-item']}>
          {renderAssignedUser()}
        </span>
      </div>
      <div
        className={`${classes['c-user-workflow-task-details__data-holder-date']} ${classes['c-user-workflow-task-details__data-holder-date--border-top']}`}
      >
        <span className={classes['c-user-workflow-task-details__data-label-date']}>
          Trigger date
        </span>
        <Input
          id="triggerDate"
          name="triggerDate"
          type="date"
          value={formatDateForInput(inputs?.triggerDate || '')}
          setValue={(value) => handleDateChange(value, 'triggerDate')}
          min={new Date()}
          max={inputs.dueDate ? new Date(inputs.dueDate) : undefined}
          readOnly={!isEditMode}
        />
      </div>
      <div
        className={`${classes['c-user-workflow-task-details__data-holder-date']} ${classes['c-user-workflow-task-details__data-holder-date--border-bottom']}`}
      >
        <span className={classes['c-user-workflow-task-details__data-label-date']}>Due date</span>
        <Input
          id="dueDate"
          name="dueDate"
          type="date"
          value={formatDateForInput(inputs?.dueDate || '')}
          setValue={(value) => handleDateChange(value, 'dueDate')}
          min={inputs.triggerDate ? new Date(inputs.triggerDate) : new Date()}
          readOnly={!isEditMode}
        />
      </div>
      <div
        className={`${classes['c-user-workflow-task-details__data-holder']} ${classes['c-user-workflow-task-details__data-holder--mt']}`}
      >
        <span className={classes['c-user-workflow-task-details__data-label']}>
          Task description
        </span>
        <textarea
          name="description"
          className={classes['c-user-workflow-task-details__description']}
          placeholder={isEditMode ? 'Write a description...' : ''}
          disabled={!isEditMode}
          value={inputs.description}
          onChange={handleInputsChange}
        />
      </div>
      <ActionConfirmationModal
        message={`you want to delete "${task.name}" task?`}
        isModalOpen={isConfirmationModalOpen}
        isLoading={isLoading}
        handleNo={handleCloseConfirmationModal}
        handleYes={handleDeleteTask}
        closeModal={handleCloseConfirmationModal}
      />
    </div>
  );
};

export default UserWorkflowTaskDetails;
