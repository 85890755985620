type SearchUserData = {
  firstName: string;
  lastName: string;
  projectNames?: string;
  email?: string;
  placeOfWork?: string;
  department?: string;
  reportingTo?: string;
  workflowName?: string;
  jobTitle?: string;
  searchInput?: string;
};

export const searchUsers = ({
  firstName,
  lastName,
  projectNames,
  email,
  placeOfWork,
  department,
  reportingTo,
  jobTitle,
  workflowName,
  searchInput = '',
}: SearchUserData) => {
  const inputValue = searchInput.toLowerCase() ?? '';

  return (
    `${firstName} ${lastName}`.toLowerCase().includes(inputValue) ||
    projectNames?.toLowerCase().includes(inputValue) ||
    email?.toLowerCase().includes(inputValue) ||
    placeOfWork?.toLowerCase().includes(inputValue) ||
    department?.toLowerCase().includes(inputValue) ||
    reportingTo?.toLowerCase().includes(inputValue) ||
    jobTitle?.toLowerCase().includes(inputValue) ||
    workflowName?.toLowerCase().includes(inputValue)
  );
};

type UserData = {
  firstNameX: string;
  firstNameY: string;
  lastNameX: string;
  lastNameY: string;
  effectiveOnX?: string;
  effectiveOnY?: string;
  sortBy: string;
};

export const sortUsers = ({
  firstNameX,
  firstNameY,
  lastNameX,
  lastNameY,
  effectiveOnX,
  effectiveOnY,
  sortBy,
}: UserData) => {
  if (sortBy.includes('Name A -> Z')) {
    return firstNameX < firstNameY ? -1 : 1;
  }
  if (sortBy.includes('Name Z -> A')) {
    return firstNameX > firstNameY ? -1 : 1;
  }
  if (sortBy.includes('Surname A -> Z')) {
    return lastNameX < lastNameY ? -1 : 1;
  }
  if (sortBy.includes('Surname Z -> A')) {
    return lastNameX > lastNameY ? -1 : 1;
  }
  if (sortBy.includes('Oldest -> Newest') && effectiveOnX && effectiveOnY) {
    return effectiveOnX > effectiveOnY ? -1 : 1;
  }
  if (sortBy.includes('Newest -> Oldest') && effectiveOnX && effectiveOnY) {
    return effectiveOnX < effectiveOnY ? -1 : 1;
  }
  return firstNameX > firstNameY ? -1 : 1;
};
