import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Nullable } from 'modules/shared/model';
import { State } from 'state/store';
import { TokenPayload, UserFromJWT } from 'utils/auth/tokenModel';

export type AuthState = {
  user: Nullable<UserFromJWT>;
  accessToken: Nullable<string>;
  isAuthenticated: boolean;
};

const initialState: AuthState = {
  user: null,
  accessToken: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initializeAuth: () => initialState,
    setUserData: (
      state,
      action: PayloadAction<{ tokenPayload: TokenPayload; accessToken: string }>,
    ) => {
      const { tokenPayload, accessToken } = action.payload;
      state.user = tokenPayload.user;
      state.accessToken = accessToken;
      state.isAuthenticated = true;
    },
    removeUserData: (state) => {
      state.user = null;
      state.accessToken = null;
      state.isAuthenticated = false;
    },
  },
});

export const { initializeAuth, setUserData, removeUserData } = authSlice.actions;

export default authSlice.reducer;

export const selectAuthUser = (state: State) => state.auth;
