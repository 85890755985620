import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Search } from 'assets/Search.svg';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { UserDropDown } from './UserDropDown';
import classes from './Toolbar.module.scss';
import {
  initializeFilterAndSearch,
  selectCurrentUser,
  selectSearchValue,
  selectToolbarTitle,
  setInputValue,
} from './redux/toolbarSlice';
import { getUser } from 'modules/shared/redux/userActions';
import { initializeAppliedFilters } from 'components/core/Filter/redux/filterSlice';
import FiltersButton from 'components/core/FiltersButton/FiltersButton';
import { ReactComponent as CloseIcon } from 'assets/Close.svg';
import { pagesWithSearchBar } from './utils';

export const Toolbar = () => {
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();

  const { title, subtitle } = useAppSelector(selectToolbarTitle);
  const user = useAppSelector(selectCurrentUser);
  const searchInputValue = useAppSelector(selectSearchValue);
  const [isCloseButtonVisible, setisCloseButtonVisible] = useState<boolean>(false);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) setisCloseButtonVisible(true);

    dispatch(setInputValue(event.target.value));
  };

  const showFilters = pathname === '/timeline';

  const showSearch = pagesWithSearchBar.includes(pathname);

  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (search.length) return;

    dispatch(initializeAppliedFilters());
    dispatch(initializeFilterAndSearch());
  }, [dispatch, pathname, search]);

  const clearSearchBar = () => {
    dispatch(setInputValue(''));
    setisCloseButtonVisible(false);
  };
  return (
    <div className={classes['c-toolbar']}>
      <div className={`${classes['c-toolbar__item']} ${classes['c-toolbar__item--left']}`}>
        <div className={classes['c-toolbar__title-container']}>
          <span className={classes['c-toolbar__title']}>
            {title.map((titleItem) => (
              <span className={classes['c-toolbar__title-item']} key={titleItem}>
                {titleItem}
              </span>
            ))}
          </span>
          {subtitle && <span className={classes['c-toolbar__subtitle']}>{subtitle}</span>}
        </div>
      </div>
      <div className={`${classes['c-toolbar__item']} ${classes['c-toolbar__item--right']}`}>
        {showSearch && (
          <form
            className={`${classes['c-toolbar__search-field']} `}
            onSubmit={(e) => e.preventDefault()}
          >
            <Search className={classes['c-toolbar__search-field-icon']} />
            {isCloseButtonVisible && (
              <CloseIcon
                onClick={() => clearSearchBar()}
                className={classes['c-toolbar__search-field-icon-close']}
              />
            )}
            <input
              value={searchInputValue || ''}
              className={classes['c-toolbar__search-input']}
              placeholder={
                pathname === '/projects'
                  ? 'Search by project'
                  : 'Search by user, department, location, etc.'
              }
              onChange={handleOnChange}
            />

            {showFilters && <FiltersButton />}
          </form>
        )}
        <UserDropDown user={user} />
      </div>
    </div>
  );
};
