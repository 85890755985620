import type { ReactNode, MouseEvent, ButtonHTMLAttributes } from 'react';
import classes from './Button.module.scss';
import { Spinner } from '../Spinner';
import { getSpinnerColor } from './utils';
import { ButtonIcon } from './ButtonIcon';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | 'plain'
  | 'menu-item';

export type ButtonVariant = 'contained' | 'outlined' | 'text';
export type IconPadding = 'spacing-xs-plus';
export type ButtonJustifyContent = 'flex-start' | 'center';
export type TextAlign = 'center' | 'left';
type Size = 'extra-small' | 'small' | 'medium' | 'large';

type Props = {
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  type?: ButtonType;
  variant?: ButtonVariant;
  size?: Size;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  children: ReactNode;
  isLoading?: boolean;
  isActive?: boolean;
  disabled?: boolean;
  justifyContent?: ButtonJustifyContent;
  iconPadding?: IconPadding;
  textAlign?: TextAlign;
  onClick?: (event: MouseEvent) => void;
};

export const Button = ({
  type = 'primary',
  variant = 'contained',
  size = 'small',
  htmlType = 'button',
  justifyContent = 'center',
  textAlign = 'center',
  leftIcon,
  rightIcon,
  children,
  isLoading,
  isActive,
  disabled,
  iconPadding,
  onClick,
}: Props) => {
  const classNames = `${classes['c-button']}
   ${classes[`c-button--${type}`]} 
   ${classes[`c-button--${justifyContent}`]}
   ${classes[`c-button--${variant}`]} 
   ${classes[`c-button--${textAlign}`]}
   ${classes[`c-button--${size}`]}
   ${isActive ? classes[`c-button--active`] : ''}`;

  return (
    <button className={classNames} type={htmlType} disabled={disabled} onClick={onClick}>
      {isLoading ? (
        <Spinner size={size === 'extra-small' ? 10 : 15} color={getSpinnerColor(variant)} />
      ) : (
        <>
          {!!leftIcon && (
            <ButtonIcon
              align="left"
              icon={leftIcon}
              type={type}
              variant={variant}
              iconPadding={iconPadding}
            />
          )}
          <span className={classes['c-button__text']}>{children}</span>
          {!!rightIcon && (
            <ButtonIcon align="right" icon={rightIcon} type={type} variant={variant} />
          )}
        </>
      )}
    </button>
  );
};
