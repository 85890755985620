import { Fragment } from 'react';

export const getHighlightedText = (text: string, highlight: string, isHighligted?: boolean) => {
  const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const textParts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));

  return textParts.map((part) => (
    <Fragment key={crypto.randomUUID()}>
      {part.toLowerCase() === highlight.toLowerCase() && isHighligted ? (
        <span style={{ backgroundColor: '#C2F0C2' }}>{part}</span>
      ) : (
        part
      )}
    </Fragment>
  ));
};
