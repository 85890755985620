import { FeatureFlags } from 'modules/featureFlags/model/featureFlags.model';
import { Nullable } from 'modules/shared/model';

export const getAllowedRoutes = (
  route: JSX.Element,
  featureFlags: Nullable<Record<FeatureFlags, boolean>>,
) => {
  const { path } = route.props;

  if (path === '/*') return false;

  const featureFlagChecks: Record<string, boolean | undefined> = {
    '/workflows': featureFlags?.WORKFLOWS,
    '/my-tasks': featureFlags?.WORKFLOWS,
    '/projects': featureFlags?.PROJECTS,
    '/timesheets': featureFlags?.TIMESHEETS,
    '/reports': featureFlags?.REPORTS,
  };

  if (path in featureFlagChecks && !featureFlagChecks[path]) return false;

  return route;
};
