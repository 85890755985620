import { type ChangeEvent, useState } from 'react';
import Section from '../../Section/Section';
import classes from './YourLocations.module.scss';
import { Input, PopUpModal, Button } from 'components/core';
import LocationItem from './LocationItem/LocationItem';
import toast from 'utils/notifications/CustomToast';
import { type LocationModel } from 'modules/settings/models/settingsModels';
import { createLocation, deleteLocation, updateLocation } from 'modules/settings/api';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import {
  addLocation,
  updateLocation as updateLocationAction,
  deleteLocation as deleteLocationAction,
  selectSettings,
} from 'modules/settings/redux/settingsSlice';

const initialLocation: LocationModel = {
  address: '',
  city: '',
  postalCode: '',
  region: '',
  country: '',
  label: '',
  headquarters: false,
};

const YourLocations = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newLocation, setNewLocation] = useState<LocationModel>(initialLocation);

  const { locations } = useAppSelector(selectSettings);

  const dispatch = useAppDispatch();

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setNewLocation((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const areFieldsValid =
    !!newLocation.address &&
    !!newLocation.city &&
    !!newLocation.postalCode &&
    !!newLocation.country;

  const createNewLocation = async () => {
    setIsLoading(true);

    try {
      const response = await createLocation(newLocation);
      dispatch(addLocation(response.data));
      handleCloseModal();
      toast.success('You have successfully added new location');
    } catch {
      toast.error('Something went wrong while adding location. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSelectedLocation = async (id: number) => {
    setIsLoading(true);

    try {
      await deleteLocation(id);
      dispatch(deleteLocationAction(id));
      toast.success('You have successfully deleted location.');
    } catch {
      toast.error('Something went wrong when deleting the location. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const updateSelectedLocation = async (location: LocationModel) => {
    setIsLoading(true);

    try {
      const response = await updateLocation(location);
      dispatch(updateLocationAction(response.data));
      toast.success('You have successfully updated location.');
    } catch {
      toast.error('Something went wrong while saving. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsFormSubmitted(false);
    setOpenModal(false);
    setNewLocation(initialLocation);
  };

  const handleCreateLocation = () => {
    setIsFormSubmitted(true);
    if (areFieldsValid) createNewLocation();
  };

  return (
    <div className={classes['c-your-locations']}>
      <PopUpModal
        width={30}
        opened={openModal}
        title="Create new location"
        closeModal={handleCloseModal}
      >
        <div className={classes['c-your-locations__modal-content']}>
          <div className={classes['c-your-locations__modal-inputs-wrap']}>
            <Input
              name="address"
              id="address"
              label="Address"
              value={newLocation.address}
              handleOnChange={handleInput}
              isSubmitted={isFormSubmitted}
              size="medium"
            />
            <Input
              name="city"
              id="city"
              label="City"
              value={newLocation.city}
              handleOnChange={handleInput}
              isSubmitted={isFormSubmitted}
              size="medium"
            />
            <Input
              name="postalCode"
              id="postcode"
              label="Postcode"
              value={newLocation.postalCode}
              handleOnChange={handleInput}
              isSubmitted={isFormSubmitted}
              size="medium"
            />
            <Input
              name="region"
              id="state-region"
              label="State/Region"
              isOptional
              value={newLocation.region}
              handleOnChange={handleInput}
              size="medium"
            />
            <Input
              name="country"
              id="country"
              label="Country"
              value={newLocation.country}
              handleOnChange={handleInput}
              isSubmitted={isFormSubmitted}
              size="medium"
            />
            <Input
              name="label"
              id="label"
              type="text"
              label="Label"
              isOptional
              handleOnChange={handleInput}
              size="medium"
            />
          </div>
          <Button size="medium" onClick={handleCreateLocation} isLoading={isLoading}>
            Add location
          </Button>
        </div>
      </PopUpModal>
      <Section title="These are your locations" iconType="add" onClick={() => setOpenModal(true)}>
        {locations.map((location) => (
          <LocationItem
            key={location.id}
            location={location}
            onDelete={deleteSelectedLocation}
            onUpdate={updateSelectedLocation}
            isLoading={isLoading}
          />
        ))}
      </Section>
    </div>
  );
};

export default YourLocations;
