import classes from './ActionConfirmationModal.module.scss';
import { YesNoButtonGroup } from '../../YesNoButtonGroup';
import { PopUpModal } from '../PopUpModal';

type Props = {
  message: string;
  isModalOpen: boolean;
  isLoading?: boolean;
  handleNo: () => void;
  handleYes: () => void;
  closeModal: () => void;
  shouldClose?: boolean;
};

export const ActionConfirmationModal = ({
  message,
  isModalOpen,
  isLoading,
  handleNo,
  handleYes,
  closeModal,
  shouldClose = false,
}: Props) => {
  return (
    <PopUpModal
      opened={isModalOpen}
      width={30}
      closeModal={closeModal}
      isConfirmationModal
      shouldClose={shouldClose}
    >
      <span className={classes['c-action-confirmation-modal__message']}>{message}</span>
      <YesNoButtonGroup
        handleNo={handleNo}
        handleYes={handleYes}
        isLoading={isLoading}
        width="medium"
      />
    </PopUpModal>
  );
};
