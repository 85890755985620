import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classes from '../../styles/Section/Section.module.scss';
import SectionTask from './SectionTask';
import { ReactComponent as DropdownArrowIcon } from 'assets/DropdownArrowIcon.svg';
import {
  DndContext,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  PointerSensor,
  useSensor,
  DragOverlay,
  closestCorners,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
  Workflow,
  type Task as TaskModel,
  type WorkflowSection,
} from 'modules/workflows/model/workflow.model';
import {
  addWorkflowSection,
  updateWorkflowSection as updateWorkflowSectionAction,
  deleteWorkflowSection as deleteWorkflowSectionAction,
  selectWorkflowTemplates,
} from '../../redux/workflowTemplatesSlice';
import {
  createWorkflowSection,
  deleteWorkflowSection,
  updateSectionTask,
  updateWorkflowSection,
} from 'modules/workflows/api/workflow.api';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { SectionActions } from 'modules/shared/components';
import {
  AddIconButton,
  ActionConfirmationModal,
  Input,
  toast,
  Button,
  DeleteButton,
} from 'components/core';
import { mapErrorToErrorData } from 'utils';
import { createTempTask } from 'modules/workflows/utils/createTempTask';

type Props = {
  section: WorkflowSection;
  isNewWorkflow: boolean;
  setWorkflowData: Dispatch<SetStateAction<Workflow>>;
  handleSectionChange: (sectionData: WorkflowSection) => void;
};

const Section = ({
  section: { id, name, tasks, workflowId },
  section,
  isNewWorkflow,
  setWorkflowData,
  handleSectionChange,
}: Props) => {
  const workflowSection = useAppSelector(selectWorkflowTemplates)
    .find((workflow) => workflow.id === workflowId)
    ?.workflowSections.find((section) => section.id === id);
  const isNewSection = String(id).includes('-temp');

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);
  const [editSectionName, setEditSectionName] = useState<boolean>(isNewSection);
  const [draggingTask, setDraggingTask] = useState<TaskModel | null>(null);
  const [sectionData, setSectionData] = useState<WorkflowSection>(section);
  const [displayedTasks, setDisplayedTasks] = useState<TaskModel[]>([]);

  const sensors = [useSensor(PointerSensor)];
  const dispatch = useAppDispatch();

  const isSectionNameChanged =
    sectionData.name.trim() !== '' && sectionData.name !== workflowSection?.name;

  const isSaveButtonDisabled = useMemo(
    () => !isSectionNameChanged || sectionData.tasks.some((task) => !task.name),
    [isSectionNameChanged, sectionData.tasks],
  );

  const getTaskName = (taskId: number | string) =>
    workflowSection?.tasks.find((task) => task.id === taskId)?.name ?? '';

  const hasUnsavedTask = !!sectionData.tasks.find((task) => String(task.id).includes('temp'));

  const handleSave = useCallback(async () => {
    if (isNewSection) {
      try {
        const { id, ...updatedData } = sectionData;
        const { data } = await createWorkflowSection({ ...updatedData, workflowId });

        dispatch(addWorkflowSection(data));
        setEditSectionName(false);
        toast('success', 'You have successfully created new workflow section.');
        return;
      } catch {
        toast('error', 'Something went wrong while saving new section. Please try again.');
        return;
      }
    }

    const updatedSection = {
      name: sectionData.name,
      tasks: workflowSection?.tasks ?? [],
      order: sectionData.order,
      workflowId,
    };

    try {
      setIsLoading(true);
      await updateWorkflowSection({ id, ...updatedSection });
      dispatch(updateWorkflowSectionAction({ id, ...updatedSection }));
      setEditSectionName(false);
      toast('success', 'You have successfully updated section name.');
    } catch {
      toast('error', 'Something went wrong while updating section. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [isNewSection, sectionData, workflowSection?.tasks, workflowId, dispatch, id]);

  const handleCancel = useCallback(() => {
    if (isNewSection && id) {
      setWorkflowData((prevState) => ({
        ...prevState,
        workflowSections: prevState.workflowSections.filter((section) => section.id !== id),
      }));
      return;
    }

    setWorkflowData((prevState) => ({
      ...prevState,
      workflowSections: prevState.workflowSections.map((section) => {
        if (section.id !== id) return section;
        return { ...section, name: workflowSection?.name || '' };
      }),
    }));

    setEditSectionName(false);
  }, [isNewSection, id, setWorkflowData, workflowSection?.name]);

  const handleDelete = useCallback(async () => {
    if (!id || !workflowId) return;

    try {
      setIsLoading(true);
      await deleteWorkflowSection(workflowId, +id);
      dispatch(deleteWorkflowSectionAction({ workflowId, id: +id }));
      setIsConfirmationModalOpen(false);
      toast('success', 'You have successfully deleted workflow section.');
    } catch (error) {
      const status = mapErrorToErrorData(error)?.status;

      const message =
        status === 400
          ? 'Can not delete section which is associated with workflow.'
          : 'Something went wrong while deleting workflow section. Try again.';

      toast('error', message);
    } finally {
      setIsLoading(false);
    }
  }, [id, workflowId, dispatch]);

  const changeTaskName = useCallback((taskId: string | number, name: string) => {
    setSectionData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((task) => (task.id !== taskId ? task : { ...task, name })),
    }));
  }, []);

  const createNewTask = useCallback(() => {
    setIsSectionExpanded(true);

    setSectionData((prevState) => ({
      ...prevState,
      tasks: [...prevState.tasks, createTempTask(Number(id), tasks.length)],
    }));
  }, [id, tasks.length]);

  const deleteUnsavedTask = useCallback((taskId: string) => {
    setSectionData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.filter((task) => task.id !== taskId),
    }));
  }, []);

  const handleDragStartTask = (event: DragStartEvent) => {
    setDraggingTask(tasks.find((task) => task.id === event.active.id) ?? null);
  };

  const handleDragEndTask = async (event: DragEndEvent) => {
    setDraggingTask(null);

    if (!workflowId) return;

    const updatedTask = sectionData.tasks.find((task) => task.id === event.active.id);

    try {
      await updateSectionTask(workflowId, updatedTask);
      toast('success', 'You have successfully updated task order.');
    } catch {
      toast('error', 'Something went wrong while updating task order.');
    }
  };

  const handleTaskDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    const taskId = active.id;
    const taskOverId = over?.id;

    const tasksCopy = [...sectionData.tasks];

    if (taskId === taskOverId) return;

    const oldIndex = tasksCopy.findIndex((item) => item.id === taskId);
    const newIndex = tasksCopy.findIndex((item) => item.id === taskOverId);

    const updatedTasks = arrayMove(tasksCopy, oldIndex, newIndex).map((task, index) => {
      return {
        ...task,
        order: index + 1,
      };
    });

    setSectionData((prevState) => ({ ...prevState, tasks: updatedTasks }));
  };

  const handleInputOnChange = (name: string) => {
    setSectionData((prevState) => ({ ...prevState, name }));
  };

  const handleEnterKey = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && !hasUnsavedTask) handleSave();
    },
    [handleSave, hasUnsavedTask],
  );

  useEffect(() => {
    const { tasks } = sectionData;

    if (!tasks?.length) {
      setDisplayedTasks([]);
      return;
    }

    const sortedTasks = [...tasks].sort((x, y) => x.order - y.order);

    if (isSectionExpanded) {
      setDisplayedTasks(sortedTasks);
      return;
    }

    if (tasks.find((task) => String(task.id).includes('-temp'))) {
      setDisplayedTasks(sortedTasks.slice(0, 6));
      return;
    }

    setDisplayedTasks(sortedTasks.slice(0, 5));
  }, [isSectionExpanded, sectionData]);

  useEffect(() => {
    handleSectionChange(sectionData);
  }, [handleSectionChange, sectionData]);

  useEffect(() => {
    if (workflowSection) {
      setSectionData((prev) => {
        const hasOneTaskAndItsTemp =
          workflowSection.tasks.find((task) => task.id.toString().includes('-temp')) &&
          workflowSection.tasks.length === 1;
        const tempTask =
          prev.tasks.find((task) => task.id.toString().includes('-temp')) && !hasOneTaskAndItsTemp;

        if (tempTask) {
          return {
            ...workflowSection,
            tasks: [
              ...workflowSection.tasks,
              createTempTask(Number(id), workflowSection.tasks.length),
            ],
          };
        }

        return workflowSection;
      });
    }
  }, [id, isNewSection, workflowSection]);

  return (
    <div
      className={`${classes['c-section']} ${
        isSectionExpanded ? classes['c-section--expanded'] : ''
      }`}
    >
      <div className={classes['c-section__header']}>
        <div className={classes['c-section__header-title']}>
          <Input
            name="section-name"
            id="section-name"
            placeholder="Section name"
            value={sectionData.name}
            setValue={handleInputOnChange}
            handleOnKeyDown={handleEnterKey}
            size="large"
            readOnly={!editSectionName}
          />
        </div>
        {!isNewWorkflow ? (
          <SectionActions
            isEditOpen={editSectionName}
            handleEdit={() => setEditSectionName(true)}
            handleCancel={handleCancel}
            handleSave={handleSave}
            isSaveButtonDisabled={isSaveButtonDisabled}
            isLoading={isLoading}
            handleDelete={() => setIsConfirmationModalOpen(true)}
          />
        ) : (
          <DeleteButton onClick={handleCancel} />
        )}
      </div>
      <div className={classes['c-section__content']}>
        {!tasks.length && (
          <span className={classes['c-section__content--missing-data']}>
            There are no tasks in this section.
          </span>
        )}
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStartTask}
          onDragOver={handleTaskDragOver}
          onDragEnd={handleDragEndTask}
          modifiers={[restrictToParentElement]}
        >
          <SortableContext items={displayedTasks.map((task) => task.id)} id={String(id)}>
            {displayedTasks.map((task) => (
              <SectionTask
                key={task.id}
                task={task}
                workflowId={workflowId}
                isNewSection={isNewSection}
                changeTaskName={changeTaskName}
                cancelTaskChange={(id: number | string) => changeTaskName(id, getTaskName(id))}
                deleteUnsavedTask={deleteUnsavedTask}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {draggingTask && (
              <SectionTask
                task={draggingTask}
                workflowId={workflowId}
                isNewSection={isNewSection}
                changeTaskName={changeTaskName}
                cancelTaskChange={(id: number | string) => changeTaskName(id, getTaskName(id))}
                deleteUnsavedTask={deleteUnsavedTask}
              />
            )}
          </DragOverlay>
        </DndContext>
      </div>
      <div className={classes['c-section__bottom']}>
        <AddIconButton onClick={createNewTask} disabled={hasUnsavedTask && !isNewSection}>
          <span>Add new task</span>
        </AddIconButton>
        {!isSectionExpanded && tasks.length > 5 && (
          <Button type="plain" variant="text" onClick={() => setIsSectionExpanded(true)}>
            <span className={classes['c-section__button-content']}>
              <DropdownArrowIcon className={classes['c-section__button-content-icon']} />
              View all tasks ({tasks.length})
            </span>
          </Button>
        )}
        {isSectionExpanded && tasks.length > 5 && (
          <Button type="plain" variant="text" onClick={() => setIsSectionExpanded(false)}>
            <span className={classes['c-section__button-content']}>
              <DropdownArrowIcon
                className={`${classes['c-section__button-content-icon']} ${classes['c-section__button-content-icon--expanded']}`}
              />
              Close
            </span>
          </Button>
        )}
      </div>
      <ActionConfirmationModal
        message={`you want to delete "${name}" workflow section?`}
        isModalOpen={isConfirmationModalOpen}
        closeModal={() => setIsConfirmationModalOpen(false)}
        handleYes={handleDelete}
        isLoading={isLoading}
        handleNo={() => setIsConfirmationModalOpen(false)}
      />
    </div>
  );
};

export default Section;
