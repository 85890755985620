import Axios, {
  HttpStatusCode,
  type InternalAxiosRequestConfig,
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';
import type { TokenData } from 'utils/auth/tokenModel';
import {
  clearLocalStorageTokens,
  getAccessToken,
  getRefreshToken,
  setLocalStorageTokens,
} from 'utils/auth/tokenService';

const BASE_URL = process.env.REACT_APP_PEOPLE_API_URL;

const api = Axios.create({ baseURL: BASE_URL });

const onRequest = (config: AxiosRequestConfig): InternalAxiosRequestConfig => {
  config.headers = { Authorization: `Bearer ${getAccessToken()}` };

  return config as InternalAxiosRequestConfig;
};

const onRequestError = async (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

api.interceptors.request.use(onRequest, onRequestError);

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onResponseError = async (error: any) => {
  const originalRequest = error.config;

  if (error.response?.status === HttpStatusCode.Unauthorized && !originalRequest._retry) {
    try {
      originalRequest._retry = true;

      const { status, data } = await Axios.get<TokenData>(`${BASE_URL}/refresh-token`, {
        headers: {
          'Refresh-Token': getRefreshToken(),
        },
      });

      if (status === HttpStatusCode.Ok) {
        setLocalStorageTokens(data.accessToken, data.refreshToken);
        originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
        return api(originalRequest);
      }
    } catch (_error) {
      clearLocalStorageTokens();

      window.location.replace('/');

      return Promise.reject(_error);
    }
  }

  return Promise.reject(error);
};

api.interceptors.response.use(onResponse, onResponseError);

export { api };
