import { getOrganizationId } from 'state/utils';
import { api } from 'lib';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAllReportTemplates = createAsyncThunk(
  'reportTemplates/getAllReportTemplates',
  async () => {
    const response = await api.get(`/organization/${getOrganizationId()}/report-template`);
    return response.data;
  },
);
