import { useCallback, useState } from 'react';
import { PopUpModal } from 'components/core';
import CalendarForm from '../CalendarForm/CalendarForm';
import type { OrganizationCalendar } from 'modules/shared/model';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (calendar: OrganizationCalendar) => void;
};

const initialCalendar: OrganizationCalendar = {
  name: '',
  country: '',
  default: false,
  publicHolidays: [],
};

const CalendarModal = ({ isOpen, onClose, onSave }: Props) => {
  const [updatedCalendar, setUpdatedCalendar] = useState<OrganizationCalendar>(initialCalendar);

  const handleOnSave = useCallback(() => {
    onSave(updatedCalendar);
  }, [updatedCalendar, onSave]);

  const handleOnChange = useCallback((updatedCalendar: OrganizationCalendar) => {
    setUpdatedCalendar(updatedCalendar);
  }, []);

  return (
    <PopUpModal title="Create new calendar" width={30} opened={isOpen} closeModal={onClose}>
      <CalendarForm
        calendar={updatedCalendar}
        displayMode="modal"
        onChange={handleOnChange}
        onSave={handleOnSave}
      />
    </PopUpModal>
  );
};

export default CalendarModal;
