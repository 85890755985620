import { useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import {
  markFilterById,
  selectFilters,
  toggleFilter,
} from 'components/core/Filter/redux/filterSlice';
import { FilterCategory, UserType } from 'config/constants';
import { selectToolbar, setInputValue } from 'components/layout/Toolbar/redux/toolbarSlice';
import { getSortFilterId } from 'components/core/Filter/utils';
import { useDebounce } from './useDebounce';
import { selectAuthUser } from 'modules/auth/redux/authSlice';
import { useUserRole } from './useUserRole';

type Props = {
  page: number;
  sort: string;
  userType?: UserType;
  setCurrentPage: (page: number) => void;
  setUserType?: (userType: UserType) => void;
  fetchData: (search: string) => void;
};

export const useUrlFilters = ({
  page,
  setCurrentPage,
  sort,
  userType,
  setUserType,
  fetchData,
}: Props) => {
  const { appliedFilters } = useAppSelector(selectFilters);
  const { inputFieldValue } = useAppSelector(selectToolbar);
  const { user } = useAppSelector(selectAuthUser);
  const { isAdminOrSuperAdmin } = useUserRole(user?.role);

  const debouncedSearchTerm = useDebounce(inputFieldValue ?? '');

  const { search } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams(search);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const addFiltersFromParams = useCallback(
    (filterCategory: FilterCategory, filterValues: string[] | string) => {
      if (Array.isArray(filterValues)) {
        filterValues.forEach((filterValue) =>
          dispatch(toggleFilter({ filterCategory, filterValue, actionType: 'add' })),
        );
      }
    },
    [dispatch],
  );

  const addQueryToUrl = useCallback(
    (queryParams: URLSearchParams) => {
      const queryString = `?${queryParams}`;

      if (queryParams.size) {
        fetchData(queryString);
        navigate(queryString);
        return;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate],
  );

  const getCurrentParams = () => {
    const { departmentNames, locationNames, projectNames } = appliedFilters;
    const params = new URLSearchParams();

    if (userType === UserType.NEW_HIRE || userType === UserType.ACTIVE_MEMBER || userType === UserType.OFFBOARDED) {
      params.append('userType', userType);
    } else {
      params.delete('userType');
    }

    departmentNames.forEach((department) => {
      params.append('departmentNames', department);
    });

    locationNames.forEach((location) => {
      params.append('locationNames', location);
    });

    projectNames.forEach((project) => {
      params.append('projectNames', project);
    });

    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      params.append('term', debouncedSearchTerm);
    }

    params.append('size', '20');

    if (sort) params.append('sort', sort);

    return params;
  };

  useEffect(() => {
    if (!searchParams.size) return;

    if (!isAdminOrSuperAdmin) searchParams.set('userType', UserType.ACTIVE_MEMBER);

    const departmentNames = searchParams.getAll(FilterCategory.DEPARTMENT);
    const locationNames = searchParams.getAll(FilterCategory.LOCATION);
    const projectNames = searchParams.getAll(FilterCategory.PROJECT);
    const sort = searchParams.get('sort');
    const userType = searchParams.get('userType');
    const term = searchParams.get('term');
    const page = searchParams.get('page');

    addFiltersFromParams(FilterCategory.DEPARTMENT, departmentNames);
    addFiltersFromParams(FilterCategory.LOCATION, locationNames);
    addFiltersFromParams(FilterCategory.PROJECT, projectNames);

    if (sort) dispatch(markFilterById({ id: getSortFilterId(sort), actionType: 'add', asc : sort.includes('asc')}));

    if (userType && !!setUserType) setUserType(userType as UserType);

    if (term) dispatch(setInputValue(term));

    if (page !== null) setCurrentPage(+page);
  }, [
    addFiltersFromParams,
    dispatch,
    isAdminOrSuperAdmin,
    searchParams,
    setCurrentPage,
    setUserType,
  ]);

  useEffect(() => {
    const currentParams = getCurrentParams();

    currentParams.append('page', '0');

    setSearchParams(currentParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters, debouncedSearchTerm, setSearchParams, sort, userType]);

  useEffect(() => {
    const currentParams = getCurrentParams();

    currentParams.set('page', String(page));

    setSearchParams(currentParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchParams, setSearchParams]);

  useEffect(() => {
    addQueryToUrl(searchParams);
  }, [addQueryToUrl, searchParams, searchParams.size]);
};
