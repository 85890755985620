import { ReactComponent as PublicHolidayIcon } from 'assets/PublicHoliday.svg';
import classes from '../styles/PublicHoliday.module.scss';

type Props = {
  numberOfLines?: number;
  isPlaceHolder?: boolean;
};

const PublicHoliday = ({ isPlaceHolder, numberOfLines = 6 }: Props) => {
  const init = isPlaceHolder ? -30 : -25;
  return (
    <div className={classes['c-public-holiday']}>
      <div className={classes['c-public-holiday__content']}>
        {Array.from(Array(numberOfLines).keys()).map((number) => (
          <PublicHolidayIcon
            key={number}
            style={{ top: `${init + number * 15}px` }}
            className={classes['c-public-holiday__icon']}
          />
        ))}
      </div>
    </div>
  );
};

export default PublicHoliday;
