type SortFilterIdMap = Record<string, number>;

const sortFilterIdMap: SortFilterIdMap = {
  'firstName,asc': 0,
  'lastName,asc': 1,
  'employment.startDate,asc' : 2,
  'firstName,desc': 0,
  'lastName,desc': 1,
  'employment.startDate,desc' : 2,
};

export const getSortFilterId = (sort: string) => {
  return sortFilterIdMap[sort] !== undefined ? sortFilterIdMap[sort] : -1;
};

type FilterPropertyMap = Record<string, string>;

const filterPropertyMap: FilterPropertyMap = {
  'Name A -> Z': 'firstName,asc',
  'Surname A -> Z': 'lastName,asc',
  'Oldest -> Newest' :  'employment.startDate,asc',
  'Name Z -> A': 'firstName,desc',
  'Surname Z -> A': 'lastName,desc',
  'Newest -> Oldest' :  'employment.startDate,desc',
};

export const getSortProperty = (filterValue: string) => {
  return filterPropertyMap[filterValue] || '';
};
