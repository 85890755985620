import { formatDateForInput } from 'utils';
import { z } from 'zod';

export const addActiveMemberValidationSchema = z
  .object({
    firstName: z.string().refine((value) => value !== '', { message: 'Please enter first name.' }),
    lastName: z.string().refine((value) => value !== '', { message: 'Please enter last name.' }),
    email: z.string().email({ message: 'Please enter a valid email address.' }),
    jobTitle: z.string().refine((value) => value !== '', { message: 'Please select job title.' }),
    department: z
      .string()
      .refine((value) => value !== '', { message: 'Please select department.' }),
    reportTo: z.string().nullable(),
    startDate: z.string().refine((value) => value !== '', { message: 'Please select start date.' }),
    firstWorkingDay: z
      .string()
      .refine((value) => value !== '', { message: 'Please select first working day.' }),
    location: z.string().refine((value) => value !== '', { message: 'Please enter location.' }),
  })
  .refine((data) => new Date(data.firstWorkingDay) >= new Date(data.startDate), {
    message: 'First working day cannot be before start date.',
    path: ['firstWorkingDay'],
  });

export type AddActiveMemberValidationSchema = {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  department: string;
  reportTo?: string;
  startDate: string;
  firstWorkingDay: string;
  location: string;
};

export const addActiveMemberDefaultValues: AddActiveMemberValidationSchema = {
  firstName: '',
  lastName: '',
  email: '',
  jobTitle: '',
  department: '',
  reportTo: '',
  startDate: formatDateForInput(new Date()),
  firstWorkingDay: formatDateForInput(new Date()),
  location: '',
};
