import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestState } from 'config/constants';
import { State } from 'state/store';
import { Task, Workflow, WorkflowSection } from '../model/workflow.model';
import { getAllWorkflows, getWorkflow } from './workflowTemplatesActions';
import { createTempTask } from '../utils/createTempTask';

type WorkflowTemplatesSliceState = {
  workflowTemplates: Workflow[];
  workflowSections: WorkflowSection[];
  sectionTasks: Task[];
  loading: RequestState;
};

const initialState: WorkflowTemplatesSliceState = {
  workflowTemplates: [],
  workflowSections: [],
  sectionTasks: [],
  loading: RequestState.IDLE,
};

const workflowTemplatesSlice = createSlice({
  name: 'workflowSlice',
  initialState,
  reducers: {
    setWorkflowTemplates: (state, action: PayloadAction<Workflow[]>) => {
      state.workflowTemplates = action.payload;
    },
    setSectionTasks: (state, action: PayloadAction<Task[]>) => {
      state.sectionTasks = action.payload;
    },
    addNewSectionTask: (
      state,
      action: PayloadAction<{ workflowId: number; workflowSectionId: number; newTask: Task }>,
    ) => {
      const { workflowId, workflowSectionId, newTask } = action.payload;

      state.workflowTemplates = [
        ...state.workflowTemplates.map((workflow) => {
          if (workflow.id !== workflowId) {
            return workflow;
          }
          return {
            ...workflow,
            workflowSections: [
              ...workflow.workflowSections.map((section) => {
                if (section.id !== workflowSectionId) {
                  return section;
                }

                const filteredTasks = section.tasks.filter(
                  (task) => !task.id.toString().includes('-temp'),
                );
                return { ...section, tasks: [...filteredTasks, newTask] };
              }),
            ],
          };
        }),
      ];
    },
    addWorkflowSection: (state, action: PayloadAction<WorkflowSection>) => {
      if (state.workflowTemplates.length) {
        state.workflowTemplates = [
          ...state.workflowTemplates.map((workflow) => {
            if (workflow.id !== action.payload.workflowId) return workflow;

            return {
              ...workflow,
              workflowSections: [
                ...workflow.workflowSections,
                { ...action.payload, tasks: [createTempTask(Number(action.payload.id), 0)] },
              ],
            };
          }),
        ];
        return;
      }
    },
    updateWorkflowSection: (state, action) => {
      const { workflowId, id, ...updatedSection } = action.payload;
      const updatedWorkflows = state.workflowTemplates.map((workflow) => {
        if (workflow.id !== workflowId) {
          return workflow;
        }
        const workflowSections = workflow.workflowSections.map((section) => {
          if (section.id !== id) {
            return section;
          }
          return {
            ...section,
            ...updatedSection,
          };
        });
        return {
          ...workflow,
          workflowSections,
        };
      });
      state.workflowTemplates = updatedWorkflows;
    },
    updateSectionTask: (
      state,
      action: PayloadAction<{ workflowId: number; updatedTask: Task }>,
    ) => {
      const { workflowId, updatedTask } = action.payload;

      const workflowIndex = state.workflowTemplates.findIndex(
        (workflow) => workflow.id === workflowId,
      );
      const sectionIndex = state.workflowTemplates[workflowIndex].workflowSections.findIndex(
        (section) => section.id === updatedTask.workflowSectionId,
      );
      const taskIndex = state.workflowTemplates[workflowIndex].workflowSections[
        sectionIndex
      ].tasks.findIndex((task) => task.id === updatedTask.id);

      const updatedWorkflows = [...state.workflowTemplates];
      const updatedSection = {
        ...updatedWorkflows[workflowIndex].workflowSections[sectionIndex],
      };
      const updatedTasks = [...updatedSection.tasks];
      updatedTasks[taskIndex] = { ...updatedTasks[taskIndex], ...updatedTask };
      updatedSection.tasks = updatedTasks;
      updatedWorkflows[workflowIndex].workflowSections[sectionIndex] = updatedSection;

      state.workflowTemplates = updatedWorkflows;
    },
    deleteWorkflowSection: (state, action: PayloadAction<{ workflowId: number; id: number }>) => {
      state.workflowTemplates = [
        ...state.workflowTemplates.map((workflow) => {
          if (workflow.id !== action.payload.workflowId) return workflow;
          return {
            ...workflow,
            workflowSections: workflow.workflowSections.filter(
              (section) => section.id !== action.payload.id,
            ),
          };
        }),
      ];
    },
    deleteSectionTask: (
      state,
      action: PayloadAction<{ workflowId: number; sectionId: number; taskId: number }>,
    ) => {
      const { workflowId, sectionId, taskId } = action.payload;
      state.workflowTemplates = [
        ...state.workflowTemplates.map((workflow) => {
          if (workflow.id !== workflowId) return workflow;
          return {
            ...workflow,
            workflowSections: workflow.workflowSections.map((section) => {
              if (section.id !== sectionId) return section;
              return {
                ...section,
                tasks: section.tasks.filter((task) => task.id !== taskId),
              };
            }),
          };
        }),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWorkflows.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getAllWorkflows.fulfilled, (state, action) => {
        state.workflowTemplates = action.payload;
        state.loading = RequestState.FULFILLED;
      })
      .addCase(getAllWorkflows.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      })
      .addCase(getWorkflow.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getWorkflow.fulfilled, (state, action) => {
        state.workflowTemplates = [action.payload];
        state.loading = RequestState.FULFILLED;
      })
      .addCase(getWorkflow.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const {
  setWorkflowTemplates,
  setSectionTasks,
  addWorkflowSection,
  addNewSectionTask,
  updateWorkflowSection,
  updateSectionTask,
  deleteWorkflowSection,
  deleteSectionTask,
} = workflowTemplatesSlice.actions;

export const selectWorkflowTemplates = (state: State) => state.workflowTemplates.workflowTemplates;

export default workflowTemplatesSlice;
