import type { ReactNode } from 'react';
import classes from './Badge.module.scss';

interface BadgeProps {
  color: 'blue' | 'orange' | 'yellow' | 'orange-rounded';
  size?: 'small' | 'medium' | 'medium-plus' | 'regular';
  leftIcon?: ReactNode;
  bold?: boolean;
  children: ReactNode;
}

export const Badge = ({ color, size = 'regular', leftIcon, bold, children }: BadgeProps) => {
  return (
    <div
      className={`${classes['c-badge']} ${classes[`c-badge--${color}`]} ${
        bold ? classes['c-badge--bold'] : ''
      } ${classes[`c-badge--${size}`]}`}
    >
      {leftIcon && <span style={{ marginRight: '0.5rem' }}>{leftIcon}</span>}
      <span className={classes['c-badge__content']}>{children}</span>
    </div>
  );
};
