import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrganizationChart as getOrganizationChartFromAPI } from '../api/chart.api';
import { toast } from 'components/core';

export const getOrganizationChart = createAsyncThunk(
  'organizationChart/getOrganizationChart',
  async () => {
    try {
      const response = await getOrganizationChartFromAPI();
      return response?.data;
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  },
);
