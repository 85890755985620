import { useCallback, useEffect, useState } from 'react';
import classes from './RolesSection.module.scss';
import Section, { SectionType } from '../../Section/Section';
import { PopUpModal, UsersOverview, toast } from 'components/core';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { User } from 'modules/people/model/User';
import { AddPeopleModal, UserInfo } from 'modules/shared/components';
import { Role } from 'config/constants';
import { updateUserRole } from 'modules/settings/api/permissions.api';
import { selectAuthUser } from 'modules/auth/redux/authSlice';
import { selectUsers } from 'modules/people/redux/peopleSlice';
import { useUserRole } from 'hooks';

type Props = {
  role: Role;
  roleUsers: User[];
  getUsersByRole: (role: Role) => Promise<void>;
};

const RolesSection = ({ role, roleUsers, getUsersByRole }: Props) => {
  const [sectionType, setSectionType] = useState<SectionType>('overview');
  const [usersWithPermissions, setUsersWithPermissions] = useState<User[]>(roleUsers);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSectionLoading, setIsSectionLoading] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const organizationUsers = useAppSelector(selectUsers);
  const { user } = useAppSelector(selectAuthUser);

  const { isSuperAdmin } = useUserRole(user?.role);

  const numberOfUsers = usersWithPermissions.length;
  const isEditEnabled = sectionType === 'edit' && isSuperAdmin;

  const sectionTitle =
    role === Role.SUPER_ADMINISTRATOR
      ? `Super admins (${numberOfUsers})`
      : `Admins (${numberOfUsers})`;

  const handleEdit = () => {
    setSectionType('edit');
  };

  const handleOpenModal = (shouldOpen: boolean) => {
    setIsModalOpen(shouldOpen);
  };

  const handleAddUsers = (usersId: number[]) => {
    const newUsers = organizationUsers.filter((user) => usersId.includes(user.id));

    setUsersWithPermissions((prev) => [...prev, ...newUsers]);
  };

  const handleRemoveUser = (id: number) => {
    if (usersWithPermissions.length === 1 && role === Role.SUPER_ADMINISTRATOR) {
      toast('warning', 'There must be at least one super admin per organization.');
      return;
    }
    setUsersWithPermissions((prev) => prev.filter((user) => user.id !== id));
  };

  const handleSelectUsers = (usersId: number[]) => {
    handleAddUsers(usersId);
    setIsModalOpen(false);
  };

  const handleCancel = async () => {
    setSectionType('overview');
    setUsersWithPermissions(roleUsers);
    handleOpenModal(false);
  };

  const handleSave = useCallback(async () => {
    setIsSectionLoading(true);

    const usersToPromoteIds = usersWithPermissions
      .filter((user) => !roleUsers.includes(user))
      .map((user) => user.id);

    const usersToDemoteIds = roleUsers
      .filter((user) => !usersWithPermissions.includes(user))
      .map((user) => user.id);

    try {
      for (const userId of usersToPromoteIds) {
        await updateUserRole(userId, role);
      }

      for (const userId of usersToDemoteIds) {
        await updateUserRole(userId, Role.USER);
      }
      await getUsersByRole(role);
      toast('success', 'Permissions updated successfully.');
    } catch {
      toast('error', 'Something went wrong. Please try again.');
    } finally {
      setSectionType('overview');
      setIsSectionLoading(false);
    }
  }, [usersWithPermissions, roleUsers, getUsersByRole, role]);

  useEffect(() => {
    setUsersWithPermissions(roleUsers);
  }, [roleUsers]);

  const linkToUserHandler = useCallback(
    (id: number) => {
      setSelectedId(id);
      setIsOpen(true);
    },
    [setSelectedId],
  );

  const renderUserInfo = useCallback(() => {
    if (isOpen && selectedId) {
      return (
        <UserInfo
          id={selectedId}
          setSelectedId={setSelectedId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      );
    }
  }, [selectedId, isOpen]);
  return (
    <Section
      type={sectionType}
      iconType={isSuperAdmin ? 'edit' : undefined}
      title={sectionTitle}
      onClick={handleEdit}
      onCancel={handleCancel}
      onSave={handleSave}
      isLoading={isSectionLoading}
    >
      {renderUserInfo()}

      {usersWithPermissions.length || isEditEnabled ? (
        <UsersOverview
          isEditMode={isEditEnabled}
          usersList={usersWithPermissions.map((user) => ({
            ...user,
            profilePicture: user.profilePhotoPath,
          }))}
          removeUser={handleRemoveUser}
          setIsModalOpen={handleOpenModal}
          linkToUserHandler={linkToUserHandler}
          clickable={true}
        />
      ) : (
        <div className={classes['c-section__missing-content-container']}>
          <span className={classes['c-section__missing-content-message']}>
            There are no selected users.
          </span>
        </div>
      )}
      <PopUpModal
        title="Add user"
        opened={isModalOpen}
        closeModal={() => handleOpenModal(false)}
        width={40}
      >
        <AddPeopleModal buttonContent="Add" onClick={handleSelectUsers} />
      </PopUpModal>
    </Section>
  );
};

export default RolesSection;
