import { Divider } from 'components/core';
import { UserType } from 'config/constants';
import type { ActiveMember } from 'modules/people/model/User';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getAllDepartments, getAllJobTitles, getAllLocations } from '../redux/peoplePopupActions';
import AddActiveMemberPopup from './AddActiveMemberPopup/AddActiveMemberPopup';
import AddNewHireMemberPopup from './AddNewHireMemberPopup/AddNewHireMemberPopup';
import classes from './PeoplePopup.module.scss';
import SelectionButton from './SelectionButton/SelectionButton';

type Props = {
  userToPromoteId?: number;
  setIsPopupOpen: (value: boolean) => void;
  activeMember?: ActiveMember;
  selectedUserType: UserType;
};

export const PeoplePopup = ({ userToPromoteId, setIsPopupOpen, activeMember }: Props) => {
  const [userType, setUserType] = useState(UserType.ACTIVE_MEMBER);

  const { locations } = useAppSelector((state) => state.peoplePopup);

  const dispatch = useAppDispatch();
  const isActiveMemberActive = userType === UserType.ACTIVE_MEMBER;

  useEffect(() => {
    dispatch(getAllJobTitles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllDepartments());
  }, [dispatch]);

  useEffect(() => {
    !locations.length && dispatch(getAllLocations());
  }, [dispatch, locations.length]);

  const renderFormHeader = useCallback(() => {
    if (userToPromoteId) return;

    return (
      <div className={classes['c-people-popup__buttons']}>
        <SelectionButton
          label="Active member"
          selected={isActiveMemberActive}
          onClick={() => setUserType(UserType.ACTIVE_MEMBER)}
        />
        <span className={classes['c-people-popup__or']}>or</span>
        <SelectionButton
          label="New hire"
          selected={!isActiveMemberActive}
          onClick={() => setUserType(UserType.NEW_HIRE)}
        />
      </div>
    );
  }, [isActiveMemberActive, userToPromoteId]);

  return (
    <div className={classes['c-people-popup']}>
      <>{renderFormHeader()}</>
      <Divider type="light" />
      {userType === UserType.ACTIVE_MEMBER ? (
        <AddActiveMemberPopup
          setIsPopupOpen={setIsPopupOpen}
          memberToPromote={activeMember}
          userToPromoteId={userToPromoteId}
        />
      ) : (
        <AddNewHireMemberPopup setIsPopupOpen={setIsPopupOpen} />
      )}
    </div>
  );
};
