import { useEffect, useState } from 'react';
import classes from './ManageCustomFields.module.scss';
import Section from '../../Section/Section';
import {
  CustomFieldValueType,
  type CustomField as CustomFieldModel,
  type NewCustomField,
} from 'modules/settings/models/settingsModels';
import CustomField from '../CustomField/CustomField';
import { PopUpModal, Status, toast } from 'components/core';
import CustomFieldModal from '../CustomFieldModal/CustomFieldModal';
import {
  createCustomField,
  deleteCustomField,
  updateCustomField,
} from 'modules/settings/api/customFields.api';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getAllOrganizationCustomFields } from 'modules/settings/redux/settingsActions';
import {
  addCustomField,
  selectSettings,
  deleteCustomField as deleteField,
  updateCustomField as updateField,
} from 'modules/settings/redux/settingsSlice';
import { RequestState } from 'config/constants';
import { isAxiosError } from 'axios';

const ManageCustomFields = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { customFields, loading } = useAppSelector(selectSettings);

  const initialCustomField: NewCustomField = {
    name: '',
    sectionName: 'PROFILE_BASICS',
    valueType: CustomFieldValueType.TEXT_FIELD,
    singleChoiceString: [],
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const areSomeOptionsSame = (singleChoiceOptions: string[]) => {
    return new Set(singleChoiceOptions).size !== singleChoiceOptions.length;
  };

  const handleAddCustomField = async (customField: NewCustomField) => {
    if (areSomeOptionsSame(customField.singleChoiceString)) {
      toast(
        'error',
        'It is not allowed to have same options, please update the so they are unique.',
      );
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await createCustomField(customField);
      dispatch(addCustomField(data));
      toast('success', 'You have successfully created new custom field.');
      handleCloseModal();
    } catch {
      toast('error', 'Something went wrong while saving. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateCustomField = async (customField: CustomFieldModel) => {
    if (areSomeOptionsSame(customField.singleChoiceString)) {
      toast(
        'error',
        'It is not allowed to have same options, please update the so they are unique.',
      );
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await updateCustomField(customField);
      dispatch(updateField(data));
      toast('success', 'You have successfully updated custom field.');
      handleCloseModal();
    } catch (error) {
      if (isAxiosError(error)) {
        toast('error', error?.response?.data.message);
        return;
      }

      toast('error', 'Something went wrong while saving. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCustomField = async (id: number) => {
    setIsLoading(true);

    try {
      await deleteCustomField(id);
      dispatch(deleteField(id));
      toast('success', 'You have successfully deleted custom field');
    } catch (error) {
      if (isAxiosError(error)) {
        toast('error', error?.response?.data.message);
        return;
      }

      toast('error', 'Something went wrong while deleting. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getAllOrganizationCustomFields());
  }, [dispatch]);

  return (
    <Status isLoading={loading.customFields === RequestState.PENDING}>
      <Section title="Manage custom fields" iconType="add" onClick={handleOpenModal}>
        {customFields.length ? (
          customFields.map((item) => (
            <CustomField
              item={item}
              key={item.id}
              isLoading={isLoading}
              onUpdate={handleUpdateCustomField}
              onDelete={handleDeleteCustomField}
            />
          ))
        ) : (
          <div className={classes['c-section__missing-content-container']}>
            <span className={classes['c-section__missing-content-message']}>
              There are no custom fields.
            </span>
          </div>
        )}
      </Section>
      <PopUpModal
        title="Add custom field"
        opened={isModalOpen}
        closeModal={handleCloseModal}
        width={30}
      >
        <CustomFieldModal
          type="new"
          customField={initialCustomField}
          isLoading={isLoading}
          onCreate={handleAddCustomField}
        />
      </PopUpModal>
    </Status>
  );
};

export default ManageCustomFields;
