import { useCallback } from 'react';
import classes from './Pagination.module.scss';
import { ReactComponent as PaginationIcon } from 'assets/RightChevronDark.svg';
import { IconButton } from '../IconButton';

type Props = {
  itemsPerPage: number;
  numberOfElements?: number;
  totalFilteredElements?: number;
  totalPages?: number;
  first?: boolean;
  last?: boolean;
  currentPage: number;
  setCurrentPage: (value: number) => void;
};

export const Pagination = ({
  itemsPerPage,
  totalFilteredElements,
  totalPages,
  first,
  last,
  currentPage,
  setCurrentPage,
}: Props) => {
  const handlePreviousPage = () => {
    const newPageNumber = currentPage - 1;

    if (newPageNumber < 0) return;

    setCurrentPage(newPageNumber);
  };

  const handleNextPage = () => {
    const newPageNumber = currentPage + 1;

    if (totalPages && newPageNumber > totalPages - 1) return;

    setCurrentPage(newPageNumber);
  };

  const renderPaginationOptions = useCallback(() => {
    if (!totalFilteredElements && totalFilteredElements !== 0) return;

    const firstItemIndex = totalFilteredElements === 0 ? 0 : 20 * currentPage + 1;
    const lastIndex = (currentPage + 1) * itemsPerPage;
    const lastItemIndex = Math.min(lastIndex, totalFilteredElements);

    return (
      <span>
        {firstItemIndex} - {lastItemIndex} of {totalFilteredElements}
      </span>
    );
  }, [currentPage, itemsPerPage, totalFilteredElements]);

  return (
    <div className={classes['c-pagination']}>
      {renderPaginationOptions()}
      <div className={classes['c-pagination__options-wrap']}>
        <IconButton
          icon={
            <PaginationIcon
              className={`${classes['c-pagination__icon']} ${
                classes['c-pagination__icon--rotate']
              } ${first ? classes['c-pagination__icon--disabled'] : ''}`}
            />
          }
          onClick={handlePreviousPage}
          disabled={first}
        />
        <IconButton
          icon={
            <PaginationIcon
              className={`${classes['c-pagination__icon']}  ${
                last ? classes['c-pagination__icon--disabled'] : ''
              }`}
            />
          }
          onClick={handleNextPage}
          disabled={last}
        />
      </div>
    </div>
  );
};
