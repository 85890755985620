import type { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAuthUser } from 'modules/auth/redux/authSlice';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { Role } from 'config/constants';

type Props = {
  allowedRoles: Role[];
  children: ReactNode;
};

const ProtectedRoute = ({ allowedRoles, children }: Props) => {
  const { user, isAuthenticated } = useAppSelector(selectAuthUser);

  const location = useLocation();

  if (!user) return null;

  if (!isAuthenticated) return <Navigate to="/" />;

  const hasRequiredRole = allowedRoles.includes(user.role);

  if (!hasRequiredRole) {
    return <Navigate to="/not-found" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
