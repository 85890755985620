import type { Dispatch, SetStateAction } from 'react';
import { AddButton } from 'components/core';
import { ComponentType } from 'modules/projects/models';
import { Workflow, WorkflowSection } from 'modules/workflows/model/workflow.model';
import Section from '../Section/Section';
import classes from './SectionsColumn.module.scss';
import { ReactComponent as AddSectionIcon } from 'assets/AddSquareIcon.svg';

type Props = {
  sections: WorkflowSection[];
  componentType: ComponentType;
  isButtonIncluded: boolean;
  setWorkflowData: Dispatch<SetStateAction<Workflow>>;
  createSection: () => void;
  handleSectionChange: (sectionData: WorkflowSection) => void;
};

const SectionsColumn = ({
  sections,
  componentType,
  isButtonIncluded,
  setWorkflowData,
  createSection,
  handleSectionChange,
}: Props) => {
  return (
    <div className={classes['c-sections-column']}>
      {sections.map((section) => (
        <Section
          key={section.id}
          section={section}
          isNewWorkflow={componentType === ComponentType.NEW}
          setWorkflowData={setWorkflowData}
          handleSectionChange={handleSectionChange}
        />
      ))}
      {isButtonIncluded && (
        <div className={classes['c-sections-column__add-button-wrap']}>
          <AddButton label="Add new section" icon={<AddSectionIcon />} onClick={createSection} />
        </div>
      )}
    </div>
  );
};

export default SectionsColumn;
