import { useEffect } from 'react';
import { ToolbarTitle, setToolbarTitle } from 'components/layout/Toolbar/redux/toolbarSlice';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';

export const useToolbarTitle = <T>(title: ToolbarTitle, deps: T[] = []) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setToolbarTitle(title));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
