import { DataNode, ForeignObjectProps, OrganizationChartData } from '../model';

export const parseDataToNodes = (data: OrganizationChartData): DataNode[] => {
  const nodeMap: Record<number, DataNode> = {};
  const nodes: DataNode[] = [];

  Object.entries(data).forEach(([, nodeArray]) => {
    nodeArray.forEach((node) => {
      const dataNode: DataNode = {
        name: `${node.firstName} ${node.lastName}`,
        attributes: {
          id: node.id,
          jobTitle: node.jobTitle,
          department: node.department,
          profilePhotoPath: node.profilePhotoPath,
        },
      };

      nodeMap[node.id] = dataNode;

      if (!node.reportingToUserId) {
        nodes.push(dataNode);
      }
    });
  });

  Object.values(data).forEach((nodeArray) => {
    nodeArray.forEach((node) => {
      const parent = node.reportingToUserId && nodeMap[node.reportingToUserId];
      const child = nodeMap[node.id];

      if (parent && child) {
        parent.children = parent.children || [];
        parent.children.push(child);
      }
    });
  });

  return nodes;
};

export const nodeSize = { x: 250, y: 300 };

export const foreignObjectProps: ForeignObjectProps = {
  width: nodeSize.x,
  height: nodeSize.y,
  x: '-100',
  y: '-120',
  overflow: 'visible',
};
