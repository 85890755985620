export const sortArrayByPropertyOrder = <T extends { order?: number }>(tasks: T[]): T[] =>
  [...tasks].sort((a, b) => {
    if (a.order === undefined || b.order === undefined) return 0;

    return a.order - b.order;
  });

type ShortListResult<T> = {
  data: T[];
  numberOfShortListedElements: number;
};

export const getShortList = <T>(data: T[], numberOfElementsToShow: number): ShortListResult<T> => {
  if (!data.length) return { data, numberOfShortListedElements: 0 };

  if (data.length <= numberOfElementsToShow) {
    return {
      data,
      numberOfShortListedElements: 0,
    };
  }

  return {
    data: data.slice(0, numberOfElementsToShow),
    numberOfShortListedElements: data.length - numberOfElementsToShow,
  };
};

export const getListFromString = (stringList: string) => {
  const list = stringList.split(', ');

  return list.filter((item) => item !== '');
};
