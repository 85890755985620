import { Task } from '../model/workflow.model';

export const createTempTask = (workflowSectionId: number, order: number): Task => {
  return {
    id: `${crypto.randomUUID()}-temp`,
    name: '',
    description: '',
    order: order + 1,
    workflowSectionId,
  };
};
