import { useState } from 'react';
import dayjs from 'dayjs';
import classes from './YearPicker.module.scss';
import { MonthDropDown, DropDownOption } from '../DropDown';

export const current = 'Today';

type Props = {
  selectedYear: string;
  selectedMonth?: string;
  handleSelectedYear: (option: number) => void;
  handleSelectedMonth?: (option: number | 'Today') => void;
  extended?: boolean;
  maxDate?: Date;
};

export const YearPicker = ({
  handleSelectedYear,
  handleSelectedMonth,
  selectedYear,
  selectedMonth,
  extended = false,
}: Props) => {
  const [openMonths, setOpenMonths] = useState(false);

  const months = [...dayjs.months()];

  const selectMonthHandler = (option: string) => {
    if (handleSelectedMonth) handleSelectedMonth(months.indexOf(option));
  };

  const handleSelectCurrent = () => {
    if (handleSelectedMonth) handleSelectedMonth(current);
    handleSelectedYear(new Date().getFullYear());
  };

  const handleOpenMonths = () => {
    setOpenMonths(!openMonths);
  };

  return (
    <div className={classes['c-year-picker']}>
      {extended && (
        <>
          <DropDownOption
            text={current}
            onClick={handleSelectCurrent}
            modifier={selectedMonth === current ? 'selected' : undefined}
          />
          <DropDownOption text="Month" onClick={handleOpenMonths} />
        </>
      )}
      {(openMonths || !extended) && (
        <MonthDropDown
          selectedMonth={selectedMonth}
          options={months}
          selectMonthHandler={selectMonthHandler}
          selectedYear={selectedYear}
          handleSelectedYear={handleSelectedYear}
        />
      )}
    </div>
  );
};
