import { getAllCitiesOfSpecifiedCountry } from 'modules/shared/api/countries.api';
import { toast } from 'react-toastify';

export const getCities = async (country: string) => {
  if (!country) return [];

  try {
    const response = await getAllCitiesOfSpecifiedCountry(country);
    if (response && response.data.data) {
      return response.data.data;
    }
  } catch {
    toast.error('Something went wrong. Please try again later.');
  }

  toast.error('No cities found.');
  return [];
};
