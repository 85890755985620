import { type ReactNode, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classes from './TimeAwaysList.module.scss';
import { ReactComponent as ArrowIcon } from 'assets/ArrowRight.svg';
import { ColorLegend, Divider, Pagination } from 'components/core';
import { TimeAway } from 'modules/timeline/models/timeAwayModels';
import { formatDate } from 'utils/dates';

type Props = {
  timeAways: TimeAway[];
  headerActions: ReactNode;
};

const ITEMS_PER_PAGE = 10;

const TimeAwaysList = ({ timeAways, headerActions }: Props) => {
  const [paginatedTimeAways, setPaginatedTimeAways] = useState<TimeAway[]>(timeAways);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [currentItems] = useState<{
    itemOffset: number;
    endOffset: number;
  }>({
    itemOffset: 0,
    endOffset: ITEMS_PER_PAGE,
  });

  const sortTimeAwaysByDate = (timeAways: TimeAway[]) => {
    return [...timeAways].sort((a, b) =>
      dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? 1 : -1,
    );
  };

  const countDaysAway = (timeAway: TimeAway) => {
    const startDate = dayjs(timeAway.startDate);
    const endDate = dayjs(timeAway.endDate);
    const daysAway = endDate.diff(startDate, 'days') + 1;

    return `${daysAway} ${daysAway > 1 ? 'days' : 'day'}`;
  };

  const renderMissingDataMessage = useCallback(() => {
    if (timeAways.length) return;

    return (
      <div className={classes['c-time-away-list__missing-data-message']}>
        There are no time aways.
      </div>
    );
  }, [timeAways]);

  useEffect(() => {
    setPaginatedTimeAways(timeAways.slice(currentItems.itemOffset, currentItems.endOffset));
  }, [currentItems.endOffset, currentItems.itemOffset, timeAways]);

  return (
    <div className={classes['c-time-away-list']}>
      <div className={classes['c-time-away-list__top']}>
        <div className={classes['c-time-away-list__header']}>
          {headerActions}
          <ColorLegend />
        </div>
        <div>
          <div className={classes['c-time-away-list__list-header']}>
            <div>
              <span>Start date</span>
              <span>End date</span>
            </div>
            <span>Days off</span>
            <span>Reason</span>
            <span>Note</span>
          </div>
          <Divider type="dark" />
          {sortTimeAwaysByDate(paginatedTimeAways).map((timeAway) => (
            <div key={timeAway.id} className={classes['c-time-away-list__item']}>
              <div className={classes['c-time-away-list__date-range']}>
                <span>{formatDate(timeAway.startDate, 'D MMMM YYYY')}</span>
                <ArrowIcon />
                <span>{formatDate(timeAway.endDate, 'D MMMM YYYY')}</span>
              </div>
              <span>{countDaysAway(timeAway)}</span>
              <span>{timeAway.timeAwayType.name}</span>
              {timeAway.note !== '' && timeAway.note ? (
                <span>{timeAway.note}</span>
              ) : (
                <div className={classes['c-time-away-list__item--missing-data']}>N/A</div>
              )}
            </div>
          ))}
          {renderMissingDataMessage()}
        </div>
      </div>
      {timeAways.length > ITEMS_PER_PAGE && (
        <div className={classes['c-time-away-list__pagination']}>
          <Pagination
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default TimeAwaysList;
