import { useCallback, useEffect, useState } from 'react';
import RemainingDays from './RemainingDays';
import { PopUpModal, Button } from 'components/core';
import type { UserCalendar, UserPtoPolicy } from 'modules/profile/models/profileModels';
import type { OrganizationCalendar, PtoPolicy } from 'modules/shared/model';
import type { TimeAwayFormData } from '../TimeAwayHeader/TimeAwayHeader';
import classes from '../../../styles/TimeAwayModal.module.scss';
import { getOrganizationPtoPolicies } from 'modules/shared/redux/ptoPolicy/ptoPolicyActions';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { getAllOrganizationCalendars } from 'modules/shared/redux/calendar';
import DropdownSelection from './DropdownSelection';
import { calculateRemainingDays } from 'modules/profile/api/profile.api';

type Props = {
  isOpen: boolean;
  userPtoPolicy: UserPtoPolicy;
  ptoPolicies: PtoPolicy[];
  userCalendar: UserCalendar;
  calendars: OrganizationCalendar[];
  formData: TimeAwayFormData;
  isLoading: boolean;
  userId: number;
  setIsOpen: (isOpen: boolean) => void;
  onSave: (formData: TimeAwayFormData) => void;
};

const TimeAwayModal = ({
  ptoPolicies,
  userPtoPolicy,
  calendars,
  userCalendar,
  isOpen,
  formData,
  isLoading,
  userId,
  setIsOpen,
  onSave,
}: Props) => {
  const [data, setData] = useState<TimeAwayFormData>(formData);
  const [remainingDays, setRemainingDays] = useState<number | undefined>(
    userPtoPolicy.totalRemainingDays,
  );
  const [updatedRemainingDays, setUpdatedRemainingDays] = useState<number | undefined>(
    remainingDays,
  );
  const newPtoPolicyId = ptoPolicies.find((ptoPolicy) => ptoPolicy.name === data.ptoPolicyName)?.id;

  const dispatch = useAppDispatch();
  const handleCalculateRemainingDays = async () => {
    if (!newPtoPolicyId) return;
    const response = await calculateRemainingDays(userId, 0, 0, newPtoPolicyId);
    setRemainingDays(response);
  };

  const showRemainingDaysInfo =
    data.ptoPolicyName !== userPtoPolicy.ptoPolicyName || updatedRemainingDays !== remainingDays;
  const remainingDaysInfo =
    updatedRemainingDays === 1 ? `${updatedRemainingDays} day` : `${updatedRemainingDays} days`;

  const calendarsName = calendars.map((calendar) => calendar.name);
  const ptoPoliciesName = ptoPolicies.map((ptoPolicy) => ptoPolicy.name);

  const handleCloseModal = () => setIsOpen(false);
  const handleOnSave = () => onSave(data);

  const handleOnChange = useCallback(
    (key: string, value: string) => setData((previousData) => ({ ...previousData, [key]: value })),
    [],
  );

  const handleOnDaysChange = useCallback(
    (daysToAdd: number, daysToRemove: number) =>
      setData((previousData) => ({ ...previousData, daysToAdd, daysToRemove })),
    [],
  );

  const handleOnDaysUpdate = useCallback(() => {
    const numberOfDays = (remainingDays ?? 0) + data.daysToAdd - data.daysToRemove;

    setUpdatedRemainingDays(numberOfDays);
  }, [data, remainingDays]);

  useEffect(() => {
    if (!isOpen) return;

    dispatch(getOrganizationPtoPolicies());
    dispatch(getAllOrganizationCalendars());
  }, [dispatch, isOpen]);

  useEffect(() => {
    setData(formData);
  }, [formData]);

  useEffect(() => {
    handleOnDaysUpdate();
  }, [handleOnDaysUpdate]);

  useEffect(() => {
    handleCalculateRemainingDays();
  }, [newPtoPolicyId]);

  useEffect(() => {
    setData((previousData) => ({ ...previousData, ['totalRemainingDays']: updatedRemainingDays }));
  }, [updatedRemainingDays]);

  return (
    <PopUpModal
      title="Update user PTO policy"
      opened={isOpen}
      width={25}
      closeModal={handleCloseModal}
    >
      <div className={classes['c-time-away-modal']}>
        <RemainingDays availableDays={remainingDays ?? 0} onChange={handleOnDaysChange} />
        <div className={classes['c-time-away-modal__item']}>
          <h3 className={classes['c-time-away-modal__heading']}>PTO policy</h3>
          <DropdownSelection
            items={ptoPoliciesName}
            selectedItem={userPtoPolicy.ptoPolicyName}
            onChange={(value) => handleOnChange('ptoPolicyName', value)}
          />
        </div>
        {showRemainingDaysInfo && (
          <div className={classes['c-time-away-modal__item']}>
            <h3 className={classes['c-time-away-modal__heading']}>
              After update user will have {remainingDaysInfo}
            </h3>
          </div>
        )}
        <div className={classes['c-time-away-modal__item']}>
          <h3 className={classes['c-time-away-modal__heading']}>Calendar</h3>
          <DropdownSelection
            items={calendarsName}
            selectedItem={userCalendar.name}
            onChange={(value) => handleOnChange('calendarName', value)}
          />
        </div>
        <Button size="medium" isLoading={isLoading} onClick={handleOnSave}>
          Save
        </Button>
      </div>
    </PopUpModal>
  );
};

export default TimeAwayModal;
