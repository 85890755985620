import classes from './SideContent.module.scss';
import { ReactComponent as ZeroLogo } from 'assets/zero-logo.svg';
import landingScreen from 'assets/images/login-screen.png';

const SideContent = () => {
  return (
    <div className={classes['c-side-content']}>
      <div className={classes['c-side-content__logo-container']}>
        <ZeroLogo className={classes['c-side-content__logo']} />
        <p className={classes['c-side-content__logo-text']}>easy HRIS app</p>
      </div>
      <img className={classes['c-side-content__main-image']} src={landingScreen} alt="" />
      <p className={classes['c-side-content__main-text']}>
        Let&rsquo;s start managing staff the right way.
      </p>
    </div>
  );
};

export default SideContent;
