import { useCallback, useState } from 'react';
import { ReactComponent as DragSvg } from 'assets/DragIcon.svg';
import classes from './UserWorkflowSection.module.scss';
import UserWorkflowTask from '../UserWorkflowTask/UserWorkflowTask';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  closestCorners,
  DndContext,
  type DragOverEvent,
  PointerSensor,
  useSensor,
  type DragStartEvent,
  DragOverlay,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  type SectionInstance,
  type TaskInstance,
} from 'modules/workflows/model/workflowInstance.model';
import {
  updateSectionInstance,
  updateTaskInstance,
} from 'modules/workflows/api/workflowInstances.api';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { updateWorkflowSection } from 'modules/workflows/redux/workflowInstanceSlice';
import { SectionActions } from 'modules/shared/components';
import { useParams } from 'react-router-dom';
import { sortArrayByPropertyOrder } from 'utils/array';
import { AddIconButton, Input, CancelSaveButtonGroup, toast } from 'components/core';

type Props = {
  handleOpenTaskDetails: (taskId: number, sectionId: number) => void;
  section: SectionInstance;
  isViewOnly?: boolean;
  addNewTask: (workflowInstanceId: number, sectionId: number, taskName: string) => void;
  handleTaskDragOver?: (event: DragOverEvent) => void;
};

const UserWorkflowSection = ({
  handleOpenTaskDetails,
  section: { id, name, taskInstanceDtos, workflowInstanceId },
  section,
  isViewOnly,
  addNewTask,
  handleTaskDragOver,
}: Props) => {
  const [isAddNewTaskActive, setIsAddNewTaskActive] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [sectionName, setSectionName] = useState<string>(name);
  const [taskName, setTaskName] = useState<string>('');
  const [hoveredSection, setHoveredSection] = useState<boolean>(false);
  const [draggingTask, setDraggingTask] = useState<TaskInstance | undefined>(undefined);

  const dispatch = useAppDispatch();
  const { workflowId } = useParams();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    data: { sectionId: id },
  });

  const sensor = useSensor(PointerSensor);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  };

  const handleSaveNewTask = () => {
    if (!taskName) {
      setIsAddNewTaskActive(false);
      return;
    }

    addNewTask(workflowInstanceId, id, taskName);
    setTaskName('');
    setIsAddNewTaskActive(false);
  };

  const handleDragStartTask = (event: DragStartEvent) => {
    setDraggingTask(taskInstanceDtos.find((task) => task.id === event.active.id));
  };

  const handleDragEndTask = async (event: DragEndEvent) => {
    setDraggingTask(undefined);

    const updatedTask = taskInstanceDtos.find((task) => task.id === event.active.id);

    if (!updatedTask || !workflowId) return;

    try {
      await updateTaskInstance(+workflowId, workflowInstanceId, updatedTask);
    } catch {
      toast('error', 'Something went wrong while changing task order. Try again.');
    }
  };

  const handleUpdateSection = async () => {
    if (!workflowId) return;

    const newSection = { ...section, name: sectionName };

    try {
      const { data } = await updateSectionInstance(
        +workflowId,
        section.workflowInstanceId,
        newSection,
      );
      dispatch(updateWorkflowSection(data));
      setIsEditMode(false);
      toast('success', 'You have successfully updated section.');
    } catch {
      toast('error', 'Something went wrong while updating section. Try again.');
    }
  };

  const handleCancel = useCallback(() => {
    setSectionName(name);
    setIsEditMode(false);
  }, [name]);

  return (
    <div className={classes['c-user-workflow-section']} ref={setNodeRef} style={style}>
      <div
        className={classes['c-user-workflow-section__header']}
        onMouseEnter={() => setHoveredSection(true)}
        onMouseLeave={() => setHoveredSection(false)}
      >
        <div className={classes['c-user-workflow-section__title']}>
          {!isViewOnly && (
            <DragSvg
              {...attributes}
              {...listeners}
              className={`${classes['c-user-workflow-section__icon']}
            ${hoveredSection || isEditMode ? classes['c-user-workflow-section__icon--hovered'] : ''}
            `}
            />
          )}
          <div className={classes['c-user-workflow-section__title-input']}>
            <Input
              id="sectionName"
              name="sectionName"
              value={sectionName}
              size="medium"
              setValue={setSectionName}
              autoFocus={isEditMode}
              readOnly={!isEditMode || isViewOnly}
            />
          </div>
        </div>
        <div className={classes['c-user-workflow-section__column-header']}>Due Date</div>
        <div />
        <div className={classes['c-user-workflow-section__edit']}>
          {!isViewOnly && (
            <SectionActions
              isEditOpen={isEditMode}
              handleEdit={() => setIsEditMode(true)}
              handleCancel={handleCancel}
              handleSave={handleUpdateSection}
              isLoading={false}
            />
          )}
        </div>
      </div>
      <div className={classes['c-user-workflow-section__content']}>
        <DndContext
          sensors={[sensor]}
          collisionDetection={closestCorners}
          onDragStart={handleDragStartTask}
          onDragOver={handleTaskDragOver}
          onDragEnd={handleDragEndTask}
        >
          <SortableContext
            id={id.toString()}
            items={taskInstanceDtos}
            strategy={verticalListSortingStrategy}
          >
            <div className={classes['c-user-workflow-section__content-main']}>
              {sortArrayByPropertyOrder([...taskInstanceDtos]).map((task) => (
                <UserWorkflowTask
                  task={task}
                  workflowInstanceId={workflowInstanceId}
                  sectionId={id}
                  key={task.id}
                  handleOpenTaskDetails={handleOpenTaskDetails}
                  isViewOnly={isViewOnly}
                />
              ))}
            </div>
          </SortableContext>
          <DragOverlay>
            {!!draggingTask && (
              <UserWorkflowTask
                handleOpenTaskDetails={handleOpenTaskDetails}
                task={draggingTask}
                workflowInstanceId={workflowInstanceId}
                sectionId={id}
                isViewOnly={isViewOnly}
              />
            )}
          </DragOverlay>
        </DndContext>
        <div className={classes['c-user-workflow-section__new-task-holder']}>
          {isAddNewTaskActive && (
            <div className={classes['c-user-workflow-section__new-task-input']}>
              <Input id="new-task" placeholder="Write a task name..." setValue={setTaskName} />
              <CancelSaveButtonGroup
                onCancel={() => setIsAddNewTaskActive(false)}
                onSave={handleSaveNewTask}
              />
            </div>
          )}
          {!isViewOnly && (
            <AddIconButton
              onClick={() => setIsAddNewTaskActive(true)}
              disabled={isAddNewTaskActive}
            >
              <span>Add new task</span>
            </AddIconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserWorkflowSection;
