import { FC } from 'react';
import classes from './TextDivider.module.scss';

type Props = {
  text: string;
};

const TextDivider: FC<Props> = ({ text }) => (
  <div className={classes['c-text-divider']}>{text}</div>
);

export default TextDivider;
