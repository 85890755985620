import { type ReactNode } from 'react';
import { AuthProvider } from 'react-oauth2-code-pkce';
import { OAuthProvider as OAuthProviderType, O_AUTH_CONFIG } from '../config';

type Props = {
  provider: OAuthProviderType;
  children: ReactNode;
};

export const OAuthProvider = ({ children, provider }: Props) => {
  return <AuthProvider authConfig={O_AUTH_CONFIG[provider]}>{children}</AuthProvider>;
};
