import { type ChangeEvent, useState } from 'react';
import Header from '../Header/Header';
import InputContainer from '../InputContainer/InputContainer';
import classes from './Recovery.module.scss';
import { resetPassword, sendRecoveryLink } from '../../api/auth.api';
import { useNavigate } from 'react-router-dom';
import { type AxiosError } from 'axios';
import { EMAIL_REGEX } from '../../utils/email-regex';
import { useQuery } from 'hooks';
import TextDivider from '../TextDivider/TextDivider';
import FormLink from '../FormLink/FormLink';
import { Button, toast } from 'components/core';

interface FormData {
  email: string;
  password: string;
  repeatPassword: string;
}

interface FormValidity {
  email: boolean;
  password: boolean;
  repeatPassword: boolean;
}

const Recovery = () => {
  const [formInputs, setFormInputs] = useState<FormData>({
    email: '',
    password: '',
    repeatPassword: '',
  });
  const [formValidity, setFormValidity] = useState<FormValidity>({
    email: false,
    password: false,
    repeatPassword: false,
  });
  const [isFormSubmitted, setIsFromSubmitted] = useState<boolean>(false);
  const [emailMessage, setEmailMessage] = useState<string>('Please enter your email.');

  const navigate = useNavigate();
  const token = useQuery().get('token');

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFormInputs((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    setFormValidity((prev) => ({
      ...prev,
      [event.target.name]: event.target.validity.valid,
    }));
  };

  const isFormValid = token
    ? formValidity.password && formValidity.repeatPassword
    : formValidity.email;

  const handleSend = async () => {
    setIsFromSubmitted(true);

    try {
      if (isFormValid && !token) {
        await sendRecoveryLink(formInputs.email);
        toast('success', 'Password recovery link sent, check your email.');
        return;
      }

      if (isFormValid && token) {
        await resetPassword(token, formInputs.password);
        toast('success', 'Password successfully changed, you can now sign in.');
        navigate('/');
      }
    } catch (error) {
      const { response } = error as AxiosError;

      if (response?.status === 404) {
        setFormValidity((prev) => ({ ...prev, email: false }));
        setEmailMessage('This email does not exist.');
        toast('error', 'Something went wrong while sending recovery link. Please try again.');
        return;
      }

      toast('error', 'Something went wrong while resetting the password. Please try again.');
    }
  };

  return (
    <div className={classes['c-recovery__form-content']}>
      <div className={classes['c-recovery__title-content']}>
        <Header
          title="Password recovery"
          subtitle={
            token
              ? 'Please enter new password'
              : 'Type in your e-mail so we can send you the magic link!'
          }
        />
      </div>
      {token ? (
        <>
          <InputContainer
            label="New password"
            name="password"
            type="password"
            handleInput={handleInput}
            pattern=".{8,}"
            required={true}
            inputValidity={formValidity.password}
            invalidMessage="Password must be at least 8 characters long."
            setFormValidity={(validity) => {
              setFormValidity((prev) => ({ ...prev, password: validity }));
            }}
            isFormSubmitted={isFormSubmitted}
            handleOnKeyPressed={handleSend}
          />
          <InputContainer
            label="Repeat new password"
            name="repeatPassword"
            type="password"
            pattern={formInputs.password}
            handleInput={handleInput}
            required={true}
            inputValidity={formValidity.repeatPassword}
            invalidMessage="Passwords don't match, please try again."
            setFormValidity={(validity) => {
              setFormValidity((prev) => ({
                ...prev,
                repeatPassword: validity,
              }));
            }}
            isFormSubmitted={isFormSubmitted}
            handleOnKeyPressed={handleSend}
          />
        </>
      ) : (
        <InputContainer
          label="E-mail"
          name="email"
          type="email"
          pattern={EMAIL_REGEX}
          handleInput={handleInput}
          required={true}
          invalidMessage={emailMessage}
          inputValidity={formValidity.email}
          setFormValidity={(validity) => {
            setFormValidity((prev) => ({ ...prev, email: validity }));
          }}
          isFormSubmitted={isFormSubmitted}
          handleOnKeyPressed={handleSend}
          autoFocus={true}
        />
      )}
      <div className={classes['c-recovery__button-container']}>
        <Button size="large" onClick={handleSend}>
          {token ? 'Reset password' : 'Send'}
        </Button>
        <TextDivider text="or" />
        <FormLink url="/" text="Sign in" />
      </div>
    </div>
  );
};

export default Recovery;
