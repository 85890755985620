import { createSlice } from '@reduxjs/toolkit';

export type Location = {
  id: number;
  address: string;
  city: string;
  postalCode: string;
  region: string;
  label: string;
  country: string;
  headquarters: boolean;
  organizationId: number;
};

export interface PeoplePopupState {
  jobTitles: string[];
  locations: Location[];
  departments: string[];
}

const initialState: PeoplePopupState = {
  jobTitles: [],
  locations: [],
  departments: [],
};

export const peoplePopupSlice = createSlice({
  name: 'peoplePopup',
  initialState,
  reducers: {
    initializePeoplePopup: () => initialState,
    setJobTitles: (state, action) => {
      state.jobTitles = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
  },
});

export const { initializePeoplePopup, setJobTitles, setLocations, setDepartments } =
  peoplePopupSlice.actions;

export default peoplePopupSlice.reducer;
