import { type Dispatch } from '@reduxjs/toolkit';
import { api } from 'lib';
import { setDepartments, setJobTitles, setLocations } from './peoplePopupSlice';
import { toast } from 'components/core';
import { getOrganizationId } from 'state/utils';

export const getAllJobTitles = () => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      const res = await api.get(`/organization/${getOrganizationId()}/job-title`);
      return res;
    };
    try {
      const jobTitles = await fetchData();
      dispatch(setJobTitles(jobTitles.data));
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  };
};

export const getAllLocations = () => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      const res = await api.get(`/organization/${getOrganizationId()}/organization-location`);
      return res;
    };
    try {
      const locations = await fetchData();
      dispatch(setLocations(locations.data));
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  };
};

export const getAllDepartments = () => {
  return async (dispatch: Dispatch) => {
    const fetchData = async () => {
      const res = await api.get(`/organization/${getOrganizationId()}/department`);
      return res;
    };
    try {
      const departments = await fetchData();
      dispatch(setDepartments(departments.data));
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  };
};
