import { ChangeEvent, useCallback, useState } from 'react';
import { Calendar } from 'react-date-range';
import { ErrorMessage } from '../ErrorMessage';
import { useOnClickOutside } from 'hooks';
import classes from './DatePicker.module.scss';
import { ReactComponent as Invalid } from 'assets/InvalidData.svg';
import { formatDateWithDots } from 'utils';

type Props = {
  id: string;
  name?: string;
  value?: string;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  errorMessage?: string;
  isInvalid: boolean;
  setValue?: (value: string) => void;
  inputValuePosition?: 'left' | 'right';
  datePickerPosition?: 'left' | 'right' | 'center';
  min?: Date;
  max?: Date;
};

const DatePicker = ({
  onChange,
  onBlur,
  setValue,
  inputValuePosition,
  datePickerPosition = 'left',
  id,
  name,
  value,
  isInvalid,
  errorMessage,
  placeholder,
  min,
  max,
}: Props) => {
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  const divRef = useOnClickOutside(() => {
    setOpenCalendar(false);
  });

  const handleOnFocus = () => {
    setOpenCalendar(true);
  };

  const handleOnDateChange = useCallback(
    (date: Date) => {
      setValue?.(date.toString());
      setOpenCalendar(false);
    },
    [setValue],
  );

  return (
    <div className={classes['c-date-picker']} ref={divRef}>
      <div className={classes['c-date-picker__field-holder']}>
        <input
          className={`${classes['c-date-picker__field']}  ${
            openCalendar ? classes['c-date-picker__field--calendar-opened'] : ''
          } ${inputValuePosition === 'right' ? classes['c-date-picker__field--align-right'] : ''}
          ${errorMessage && classes['c-date-picker__field--error-border']} `}
          id={id}
          name={name}
          type="text"
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          onFocus={handleOnFocus}
          disabled={openCalendar}
          value={value && formatDateWithDots(value)}
        />
        {openCalendar && (
          <div
            className={`${classes['c-date-picker__calendar']} ${
              classes[`c-date-picker__calendar--${datePickerPosition}`]
            }`}
          >
            <Calendar
              onChange={handleOnDateChange}
              date={value ? new Date(value.toString()) : new Date()}
              minDate={min && new Date(min)}
              maxDate={max && new Date(max)}
            />
          </div>
        )}
        {errorMessage && (
          <div className={classes['c-date-picker__invalid-icon']}>
            <Invalid />
          </div>
        )}
      </div>
      <ErrorMessage isInputInvalid={isInvalid} invalidMessage={errorMessage} />
    </div>
  );
};

export default DatePicker;
