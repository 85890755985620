import { Button } from '../Button';
import classes from './CancelSaveButtonGroup.module.scss';

type Props = {
  onCancel: () => void;
  onSave: () => void;
  weight?: 'light';
  disabled?: boolean;
  isLoading?: boolean;
};

export const CancelSaveButtonGroup = ({ onCancel, onSave, disabled, isLoading }: Props) => {
  return (
    <div className={classes['c-cancel-save-button-group']}>
      <Button type="tertiary" variant="outlined" size="extra-small" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant="outlined"
        isLoading={isLoading}
        disabled={disabled}
        size="extra-small"
        onClick={onSave}
      >
        Save
      </Button>
    </div>
  );
};
