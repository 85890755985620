import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PendingTimeAway } from 'modules/timeAwayRequests/models/timeAwayRequestsModel';
import { State } from 'state/store';
import { Task, TimeAwayUser } from '../model/dashboardModels';

type DashboardState = {
  timeAways: TimeAwayUser[];
  tasks: Task[];
  timeAwayPendings: PendingTimeAway[];
};

export const selectDashboardData = (state: State) => state.dashboardCharts;

const initialState: DashboardState = {
  timeAways: [],
  tasks: [],
  timeAwayPendings: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    initializeState: () => initialState,
    setTimeAways: (state, action) => {
      state.timeAways = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setTimeAwayPendings: (state, action) => {
      state.timeAwayPendings = action.payload;
    },
    removeDashboardData: (state) => {
      state.timeAways = [];
      state.tasks = [];
      state.timeAwayPendings = [];
    },
    removeTimeAwayRequest: (state, action: PayloadAction<number>) => {
      state.timeAwayPendings = state.timeAwayPendings.filter(
        (timeAwayRequest) => timeAwayRequest.id !== action.payload,
      );
    },
  },
});

export const {
  initializeState,
  setTimeAways,
  setTasks,
  setTimeAwayPendings,
  removeDashboardData,
  removeTimeAwayRequest,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
