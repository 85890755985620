import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import { CompanySettings } from '../models/settingsModels';

export const getCompanySettings = async () => {
  return api.get<CompanySettings>(`/organization/${getOrganizationId()}/settings`);
};

export const updateCompanySettings = async (
  updatingField: string,
  value: string | number | boolean,
  param: string,
) => {
  return api.patch(
    `/organization/${getOrganizationId()}/settings/set-field-status`,
    {
      [updatingField]: value,
    },
    { params: { field: param } },
  );
};
