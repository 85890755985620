import { ChangeEvent, useState } from 'react';
import { ReactComponent as LocationIcon } from 'assets/LocationIcon.svg';
import { type LocationModel } from 'modules/settings/models/settingsModels';
import classes from './LocationItem.module.scss';
import { SectionInfoEdit } from 'modules/shared/components';
import SectionItem from 'modules/settings/components/Section/SectionItem';
import { ActionConfirmationModal } from 'components/core';

interface LocationItemProps {
  location: LocationModel;
  onDelete: (id: number) => void;
  onUpdate: (location: LocationModel) => void;
  isLoading: boolean;
}

const LocationItem = ({ location, onDelete, onUpdate, isLoading }: LocationItemProps) => {
  const { id, address, city, postalCode, region, country, label, headquarters } = location;

  const [editLocation, setEditLocation] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [inputs, setInputs] = useState<LocationModel>({
    id,
    address,
    city,
    postalCode,
    region,
    country,
    label,
    headquarters,
  });

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputs((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleCancel = () => {
    setEditLocation(false);
    setInputs({
      id,
      address,
      city,
      postalCode,
      region,
      country,
      label,
      headquarters,
    });
  };

  const handleDelete = (id: number) => {
    onDelete(id);
    setIsConfirmationModalOpen(false);
  };

  const handleUpdate = () => {
    onUpdate(inputs);
    setEditLocation(false);
  };

  return (
    <>
      <SectionItem
        itemContent={
          <span
            className={`${classes['c-location-item__text']} ${classes['c-location-item__text--title']}`}
          >
            {`${city}, ${country}${address ? `, ${address}` : ''}`}
            {label && (
              <span
                className={`${classes['c-location-item__text']} ${classes['c-location-item__label']}`}
              >{` (${label})`}</span>
            )}
          </span>
        }
        openEdit={editLocation}
        setOpenEdit={setEditLocation}
        onDelete={() => setIsConfirmationModalOpen(true)}
        onCancel={handleCancel}
        onSave={handleUpdate}
        isLoading={isLoading}
      />
      <ActionConfirmationModal
        message={`you want to delete location "${address}, ${city}"?`}
        isModalOpen={isConfirmationModalOpen}
        isLoading={isLoading}
        handleNo={() => setIsConfirmationModalOpen(false)}
        handleYes={() => handleDelete(id ?? 0)}
        closeModal={() => setIsConfirmationModalOpen(false)}
      />

      {editLocation && (
        <div className={classes['c-location-item__container']}>
          <div className={classes['c-location-item__container-column']}>
            <SectionInfoEdit
              name="address"
              data={inputs.address}
              icon={<LocationIcon />}
              handleChange={handleInput}
              displayMode="settings-section"
            />
            <SectionInfoEdit
              name="postalCode"
              label="Postal code"
              data={inputs.postalCode}
              icon={<LocationIcon />}
              handleChange={handleInput}
              displayMode="settings-section"
            />
            <SectionInfoEdit
              name="country"
              data={inputs.country}
              icon={<LocationIcon />}
              handleChange={handleInput}
              displayMode="settings-section"
            />
          </div>
          <div className={classes['c-location-item__container-column']}>
            <SectionInfoEdit
              name="city"
              data={inputs.city}
              icon={<LocationIcon />}
              handleChange={handleInput}
              displayMode="settings-section"
            />
            <SectionInfoEdit
              name="region"
              label="State/Region"
              data={inputs.region}
              icon={<LocationIcon />}
              handleChange={handleInput}
              displayMode="settings-section"
            />
            <SectionInfoEdit
              name="label"
              data={inputs.label}
              icon={<LocationIcon />}
              handleChange={handleInput}
              displayMode="settings-section"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LocationItem;
