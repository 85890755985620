import { useState, useEffect, useCallback } from 'react';
import { NumberInput } from 'components/core';
import classes from '../../../styles/RemainingDays.module.scss';

type Props = {
  availableDays: number;
  onChange: (daysToAdd: number, daysToRemove: number) => void;
};

const RemainingDays = ({ availableDays, onChange }: Props) => {
  const [input, setInput] = useState(0);

  const handleOnChange = useCallback(() => {
    let daysToAdd = 0;
    let daysToRemove = 0;
    if (input > 0) {
      daysToAdd = input;
    } else {
      daysToRemove = Math.abs(input);
    }

    onChange(daysToAdd, daysToRemove);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, availableDays]);

  useEffect(() => {
    handleOnChange();
  }, [input, handleOnChange]);

  return (
    <div className={classes['c-remaining-days']}>
      <div className={classes['c-remaining-days__content']}>
        <label className={classes['c-remaining-days__label']}>Remaining days</label>
        <div className={classes['c-remaining-days__number-of-days']}>{availableDays}</div>
      </div>
      <div className={classes['c-remaining-days__content']}>
        <label className={classes['c-remaining-days__label']}>Add or remove days</label>
        <NumberInput
          name="daysNumber"
          id="daysNumber"
          bufferValue={input}
          readOnly={false}
          allowNegative
          onChange={setInput}
        />
      </div>
    </div>
  );
};

export default RemainingDays;
