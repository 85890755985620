import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Checkbox, DropDown } from 'components/core';
import {
  organizationDefaultValues,
  OrganizationValidationSchema,
  organizationValidationSchema,
} from 'modules/auth/validations/OrganizationValidation.schema';
import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Country, formatDateForInput, getCountries, getCountryNames } from 'utils';
import { getCities } from 'utils/cities';
import { checkIsTokenValid, signup } from '../../../api/auth.api';
import FormLink from '../../FormLink/FormLink';
import Header from '../../Header/Header';
import InputContainer from '../../InputContainer/InputContainer';
import TextDivider from '../../TextDivider/TextDivider';
import classes from './../SignUp.module.scss';
import DatePicker from 'components/core/DatePicker/DatePicker';
import ResendLink from './ResendLink';

type Props = {
  token: string;
};

const OrganizationForm = ({ token }: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    clearErrors,
    formState: { isSubmitted, isSubmitting, errors },
  } = useForm<OrganizationValidationSchema>({
    resolver: zodResolver(organizationValidationSchema),
    defaultValues: organizationDefaultValues,
  });
  const [isTokenCheckFinished, setIsTokenCheckFinished] = useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isFirstWorkingDayVisible, setIsFirstWorkingDayVisible] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [cities, setCities] = useState<string[]>([]);

  const navigate = useNavigate();

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setIsChecked(true) : setIsChecked(false);
  };

  const handleOnChange =
    (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };

  const handleCountryChange = (onChange: (value: string) => void, newCountry: string) => {
    onChange(newCountry);
    setValue('city', '');
    setValue('address', '');
  };

  const handleCityChange = (onChange: (value: string) => void, newCountry: string) => {
    onChange(newCountry);
  };

  const handleSetDate = (onChange: (value: string) => void) => (newDate: string) => {
    onChange(newDate);
  };

  const handleStartDate = (onChange: (value: string) => void) => (newDate: string) => {
    onChange(newDate);
    if (!isFirstWorkingDayVisible) setValue('firstWorkingDay', newDate);
  };

  const handleFirstWorkingDayVisible = () => {
    setIsFirstWorkingDayVisible((prev) => {
      if (!prev) {
        setValue('firstWorkingDay', getValues('startDate'));
        clearErrors('firstWorkingDay');
      }
      return !prev;
    });
  };

  const handleSignUp = async (formData: OrganizationValidationSchema) => {
    const toastMessage = 'Something went wrong, please try again.';

    signup(
      token,
      formData.firstName,
      formData.lastName,
      formData.jobTitle,
      formData.organizationName,
      formData.city,
      formData.country,
      formData.address,
      formData.password.trim(),
      formatDateForInput(new Date(formData.startDate!)),
      formatDateForInput(new Date(formData.firstWorkingDay!)),
      'TRIAL',
    )
      .then(() => {
        navigate('/');
        toast.success('Successfully registred new organization.');
      })
      .catch((e) => toast.error(e.response.data.message ? e.response.data.message : toastMessage));
  };

  const checkToken = async () => {
    try {
      const response = await checkIsTokenValid(token);
      setIsTokenValid(response.data);
    } catch (e) {
      navigate('/');
      toast.error('Something went wrong while checking token. Try again.');
    } finally {
      setIsTokenCheckFinished(true);
    }
  };

  const getAllCitiesFromCountry = useCallback(() => {
    return getCities(watch('country'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('country')]);

  useEffect(() => {
    checkToken();
    getCountries().then((countries) => {
      setCountries(countries);
    });
  }, []);

  useEffect(() => {
    getAllCitiesFromCountry().then((cities) => setCities(cities));
  }, [getAllCitiesFromCountry]);

  return isTokenCheckFinished ? (
    <>
      {isTokenValid ? (
        <form className={classes['c-signup']} onSubmit={handleSubmit(handleSignUp)}>
          <div className={classes['c-signup__title-content']}>
            <Header title="Signing up" subtitle="We're glad you choose us!" />
          </div>
          <div className={classes['c-signup__form-content']}>
            <>
              <Controller
                name="firstName"
                control={control}
                render={({ field: { onChange } }) => (
                  <InputContainer
                    label="First name"
                    name="firstName"
                    handleInput={handleOnChange(onChange)}
                    inputValidity={!errors.firstName?.message}
                    invalidMessage={errors.firstName?.message}
                    isFormSubmitted={isSubmitted}
                    autoFocus
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field: { onChange } }) => (
                  <InputContainer
                    label="Last name"
                    name="lastName"
                    handleInput={handleOnChange(onChange)}
                    inputValidity={!errors.lastName?.message}
                    invalidMessage={errors.lastName?.message}
                    isFormSubmitted={isSubmitted}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field: { onChange } }) => (
                  <InputContainer
                    label="Password"
                    name="password"
                    type="password"
                    handleInput={handleOnChange(onChange)}
                    invalidMessage={errors.password?.message}
                    inputValidity={!errors.password?.message}
                    isFormSubmitted={isSubmitted}
                  />
                )}
              />
              <Controller
                name="repeatPassword"
                control={control}
                render={({ field: { onChange } }) => (
                  <InputContainer
                    label="Repeat password"
                    name="repeatPassword"
                    type="password"
                    handleInput={handleOnChange(onChange)}
                    inputValidity={!errors.repeatPassword?.message}
                    invalidMessage={errors.repeatPassword?.message}
                    isFormSubmitted={isSubmitted}
                  />
                )}
              />
              <Controller
                name="jobTitle"
                control={control}
                render={({ field: { onChange } }) => (
                  <InputContainer
                    label="Job title"
                    name="jobTitle"
                    handleInput={handleOnChange(onChange)}
                    inputValidity={!errors.jobTitle?.message}
                    invalidMessage={errors.jobTitle?.message}
                    isFormSubmitted={isSubmitted}
                  />
                )}
              />
              <Controller
                name="organizationName"
                control={control}
                render={({ field: { onChange } }) => (
                  <InputContainer
                    label="Organization name"
                    name="organizationName"
                    handleInput={handleOnChange(onChange)}
                    inputValidity={!errors.organizationName?.message}
                    invalidMessage={errors.organizationName?.message}
                    isFormSubmitted={isSubmitted}
                  />
                )}
              />
              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputContainer
                    label="Country"
                    name="country"
                    invalidMessage={errors.country?.message}
                    isFormSubmitted={isSubmitted}
                  >
                    <DropDown
                      options={getCountryNames(countries)}
                      selectedOption={value}
                      setSelectedOption={(newCountry: string) =>
                        handleCountryChange(onChange, newCountry)
                      }
                      placeholder="Please select country"
                      size="large"
                      showSearchBar
                      errorBorder={!!errors.country?.message}
                    />
                  </InputContainer>
                )}
              />
              <Controller
                name="city"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputContainer
                    label="City"
                    name="city"
                    invalidMessage={errors.city?.message}
                    isFormSubmitted={isSubmitted}
                  >
                    <DropDown
                      options={cities}
                      selectedOption={value}
                      setSelectedOption={(newCity: string) => {
                        handleCityChange(onChange, newCity);
                      }}
                      placeholder="Please select city"
                      size="large"
                      showSearchBar
                      errorBorder={!!errors.city?.message}
                    />
                  </InputContainer>
                )}
              />
              <Controller
                name="address"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputContainer
                    label="Address"
                    name="address"
                    handleInput={handleOnChange(onChange)}
                    inputValidity={!errors.address?.message}
                    invalidMessage={errors.address?.message}
                    isFormSubmitted={isSubmitted}
                    value={value}
                  />
                )}
              />
              <div className={classes['c-signup__dates-container']}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      id="startDate"
                      name="startDate"
                      placeholder="Start date"
                      value={value}
                      setValue={handleStartDate(onChange)}
                      errorMessage={errors.startDate?.message}
                      isInvalid={!!errors.startDate?.message}
                    />
                  )}
                />
                {isFirstWorkingDayVisible && (
                  <Controller
                    name="firstWorkingDay"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        id="firstWorkingDay"
                        name="firstWorkingDay"
                        placeholder="First working day"
                        value={value}
                        setValue={handleSetDate(onChange)}
                        errorMessage={errors.firstWorkingDay?.message}
                        isInvalid={!!errors.firstWorkingDay?.message}
                      />
                    )}
                  />
                )}
                <Checkbox
                  checked={!isFirstWorkingDayVisible}
                  label="First working day same as start date"
                  labelColor="#3f4c4f"
                  id={0}
                  handleCheckedItem={handleFirstWorkingDayVisible}
                />
              </div>
              <div className={classes['c-signup__checkbox-container']}>
                <Checkbox checked={isChecked} id="signup" handleCheckedItem={handleCheck} />
                <label className={classes['c-signup__checkbox-label']}>Remember me</label>
              </div>
            </>
          </div>
          <div className={classes['c-signup__button-container']}>
            <Button size="large" htmlType="submit" isLoading={isSubmitting}>
              Sign up
            </Button>
            <TextDivider text="or" />
            <FormLink url="/" text="Sign in" />
          </div>
        </form>
      ) : (
        <ResendLink token={token} />
      )}
    </>
  ) : null;
};

export default OrganizationForm;
