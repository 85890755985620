import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import { Task } from '../model';
import { getUserId } from 'state/utils';

export const getUserTasks = async () => {
  return api.get(`/organization/${getOrganizationId()}/user/${getUserId()}/tasks`);
};

export const completeTask = async (task: Task) => {
  const { id, workflowId, workflowInstanceId, workflowSectionInstanceId } = task;

  return api.put(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance/${workflowInstanceId}/workflow-section-instance/${workflowSectionInstanceId}/task-instance/${id}`,
    task,
  );
};
