import { Link } from 'react-router-dom';
import classes from '../../styles/Workflows.module.scss';
import { type WorkflowInstance } from 'modules/workflows/model/workflowInstance.model';
import { HighlightedText, Avatar, ProgressBar } from 'components/core';
import { getWorkflowProgress } from 'modules/workflows/utils';
import { colors } from 'styles/variables';
import { formatDate } from 'utils';

type Props = {
  workflow: WorkflowInstance;
  searchValue?: string;
};

const WorkflowUser = ({ workflow, searchValue }: Props) => {
  const { id, workflowId, numberOfTasks, numberOfCompletedTasks, workflowName, userDto } = workflow;

  const {
    firstName,
    lastName,
    effectiveOn,
    jobTitle,
    department,
    placeOfWork,
    reportingToUser,
    profilePhotoPath,
  } = userDto;

  const workflowProgress = getWorkflowProgress(workflow);

  return (
    <Link
      to={`/workflows/${workflowId}/workflow-instance/${id}`}
      className={classes['c-workflows__table-row']}
    >
      <span className={classes['c-workflows__user-info']}>
        <Avatar
          horizontal
          imageUrl={profilePhotoPath}
          title={<HighlightedText text={`${firstName} ${lastName}`} highlight={searchValue} />}
        />
      </span>
      <span className={classes['c-workflows__span-text']}>
        <HighlightedText text={workflowName} highlight={searchValue} />
      </span>
      <span className={classes['c-workflows__span-text']}>
        <HighlightedText text={jobTitle} highlight={searchValue} />
      </span>
      <span className={classes['c-workflows__span-text']}>
        <HighlightedText text={department} highlight={searchValue} />
      </span>
      <span className={classes['c-workflows__span-text']}>
        {formatDate(effectiveOn, 'D MMMM YYYY')}
      </span>
      <span className={classes['c-workflows__span-text']}>
        <HighlightedText text={placeOfWork} highlight={searchValue} />
      </span>
      <span className={classes['c-workflows__span-text']}>
        {reportingToUser?.firstName && reportingToUser?.lastName ? (
          <HighlightedText
            text={`${reportingToUser.firstName} ${reportingToUser.lastName}`}
            highlight={searchValue}
          />
        ) : (
          'N/A'
        )}
      </span>
      <span style={{ width: '100%' }}>
        <ProgressBar
          color={workflowProgress === 100 ? colors.success : colors.secondary}
          completed={String(workflowProgress)}
          barHeight={10}
          tooltipText={`${numberOfCompletedTasks}/${numberOfTasks} tasks`}
        />
      </span>
    </Link>
  );
};

export default WorkflowUser;
