import { ChangeEvent } from 'react';

type Props = {
  name: string;
  value?: string;
  step?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const TimeInput = ({ name, value, step, onChange }: Props) => {
  return <input name={name} type="time" value={value} onChange={onChange} step={step} />;
};

export default TimeInput;
