import { api } from 'lib';
import { NewActiveMember, User, UserBaseInfo, UserToPromote } from '../model/User';
import { getOrganizationId } from 'state/utils';
import { UserType } from 'config/constants';

export const getUserBaseInfo = (userId: number) => {
  return api.get<User>(`/organization/${getOrganizationId()}/user/${userId}/basic`);
};

export const getUserBySearch = (search?: string, userType?: UserType) => {
  let url = `/organization/${getOrganizationId()}/user/search`;

  if (search) {
    url += `?term=${search}`;
  }

  if (userType) {
    url += `${search ? '&' : '?'}userType=${userType}`;
  }
  return api.get<UserBaseInfo[]>(url);
};

export const createActiveUser = async (activeMember: NewActiveMember) => {
  return api.post(`/organization/${getOrganizationId()}/user/active-member`, {
    ...activeMember,
  });
};

export const createNewHire = async (
  firstName: string,
  lastName: string,
  personalEmail: string,
  jobTitle: string,
  startDate: string,
) => {
  return api.post(`/organization/${getOrganizationId()}/user/new-hire`, {
    firstName,
    lastName,
    personalEmail,
    jobTitle,
    startDate,
  });
};

export const promoteNewHireUser = (user: UserToPromote) => {
  return api.put<UserToPromote>(`/organization/${getOrganizationId()}/user/${user.id}/activate`, {
    ...user,
  });
};
