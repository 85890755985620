import { api } from 'lib';
import { Role } from 'config/constants';
import { User } from 'modules/people/model/User';
import { getOrganizationId } from 'state/utils/organization';

type BasicUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>;

export const getAllUsersByRole = async (role: Role) => {
  return api.get<User[]>(`/organization/${getOrganizationId()}/user/user-role`, {
    params: { role },
  });
};

export const updateUserRole = async (userId: number, role: Role) => {
  return api.patch<BasicUser>(`/organization/${getOrganizationId()}/user/update-role`, {
    id: userId,
    role,
  });
};
