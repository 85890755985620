import classes from './ProjectUserListItem.module.scss';
import avatarPlaceholder from 'assets/AvatarPlaceholder.svg';

type Props = {
  profilePictureUrl: string;
  fullName?: string;
};

export const ProjectUserListItem = ({ profilePictureUrl, fullName }: Props) => {
  return (
    <div className={classes['c-project-user-list-item']}>
      <img
        className={classes['c-project-user-list-item__image']}
        src={profilePictureUrl || avatarPlaceholder}
        alt={fullName}
      />
      {!!fullName && <span className={classes['c-project-user-list-item__text']}>{fullName}</span>}
    </div>
  );
};
