import { useToolbarTitle } from 'hooks';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectUser } from '../redux/userSlice';

import Banking from './Sections/SectionClassic/Banking';
import Basics from './Sections/SectionClassic/Basics';
import Equipment from './Sections/SectionClassic/Equipment';
import Health from './Sections/SectionClassic/Health';
import Social from './Sections/SectionClassic/Social';

const ProfileAbout = () => {
  const user = useAppSelector(selectUser);

  useToolbarTitle({ title: ['Profile', 'About'] });

  return (
    <>
      <Basics user={user} />
      <Banking bankAccounts={user.bankAccounts} />
      <Health />
      <Equipment equipments={user.equipments} />
      <Social />
    </>
  );
};

export default ProfileAbout;
