import { ReactNode, useCallback } from 'react';
import classes from './Section.module.scss';
import { CancelSaveButtonGroup, IconButton } from 'components/core';
import { ReactComponent as AddIcon } from 'assets/AddIconRoundOutline.svg';
import { ReactComponent as EditIcon } from 'assets/PencilSquare.svg';

export type SectionType = 'overview' | 'edit';

interface SectionProps {
  type?: SectionType;
  title: string;
  iconType?: 'edit' | 'add';
  onClick?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

const Section = ({
  type,
  title,
  children,
  iconType,
  onClick,
  onCancel,
  onSave,
  isLoading,
  disabled,
}: SectionProps) => {
  const renderSectionActions = useCallback(() => {
    if (!onClick) return;

    if (iconType === 'add')
      return (
        <IconButton
          icon={<AddIcon />}
          onClick={onClick}
          tooltipContent="New"
          tooltipPosition="bottom"
        />
      );

    if (iconType === 'edit')
      return (
        <IconButton
          icon={<EditIcon />}
          onClick={onClick}
          tooltipContent="Edit"
          tooltipPosition="bottom"
        />
      );
  }, [iconType, onClick]);

  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <div className={classes['c-section__title']}>{title}</div>
        {type === 'edit' && onCancel && onSave ? (
          <CancelSaveButtonGroup
            onCancel={onCancel}
            onSave={onSave}
            disabled={disabled}
            isLoading={isLoading}
          />
        ) : (
          <div className={classes['c-section__options-container']}>{renderSectionActions()}</div>
        )}
      </div>
      <div className={classes['c-section__content']}>{children}</div>
    </div>
  );
};

export default Section;
