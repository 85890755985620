import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import classes from './SettingsContainer.module.scss';
import Sidebar from 'modules/settings/components/Sidebar/Sidebar';

const SettingsContainer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/settings') {
      navigate('company');
    }
  }, [pathname, navigate]);

  return (
    <div className={classes['c-settings-container']}>
      <div className={classes['c-settings-container__sidebar-container']}>
        <Sidebar />
      </div>
      <div className={classes['c-settings-container__content']}>
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsContainer;
