import { Fragment } from 'react';
import { getHighlightedText } from './utils';

type Props = {
  text: string;
  highlight?: string;
  isHighligted?: boolean;
};

export const HighlightedText = ({ text, highlight = '', isHighligted = true }: Props) => {
  if (!text) return <Fragment>N/A</Fragment>;
  return <>{getHighlightedText(text, highlight, isHighligted)}</>;
};
