import { useEffect, useState } from 'react';
import classes from '../styles/WorkflowTemplates.module.scss';
import CreateWorkflowModal from './PopUps/CreateWorkflowModal/CreateWorkflowModal';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getAllWorkflows } from '../redux/workflowTemplatesActions';
import { WorkflowSection } from '../model/workflow.model';
import { ReactComponent as WorkflowsIcon } from 'assets/Workflows.svg';
import { RequestState } from 'config/constants';
import WorkflowTemplateCard from './WorkflowTemplateCard/WorkflowTemplateCard';
import { Empty, AddButton, PopUpModal, Status, Button } from 'components/core';
import { useToolbarTitle } from 'hooks';
import noWorkflowsImage from 'assets/images/NoWorkflows.png';

const WorkflowTemplates = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { workflowTemplates, loading } = useAppSelector((state) => state.workflowTemplates);
  const dispatch = useAppDispatch();

  const numberOfWorkflowsTemplates = workflowTemplates.length;

  useToolbarTitle(
    {
      title: ['Workflow templates'],
      subtitle: `${numberOfWorkflowsTemplates} ${
        numberOfWorkflowsTemplates === 1 ? 'template' : 'templates'
      }`,
    },
    [numberOfWorkflowsTemplates],
  );

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const getNumberOfTasks = (sections: WorkflowSection[]) => {
    return sections.reduce((totalCount, section) => totalCount + section.tasks.length, 0);
  };

  useEffect(() => {
    dispatch(getAllWorkflows({ size: 20 }));
  }, [dispatch]);

  return (
    <div className={classes['c-workflow-templates']}>
      <Status isLoading={loading === RequestState.PENDING}>
        {workflowTemplates?.length ? (
          <div className={classes['c-workflow-templates__cards-container']}>
            <AddButton
              label="Create workflow template"
              icon={<WorkflowsIcon className={classes['c-workflow-templates__add-button-icon']} />}
              onClick={handleOpenModal}
            />
            {workflowTemplates.map((workflow) => (
              <WorkflowTemplateCard
                key={workflow.id}
                id={workflow.id}
                name={workflow.name}
                numberOfSections={workflow.workflowSections.length}
                numberOfTasks={getNumberOfTasks(workflow.workflowSections)}
              />
            ))}
          </div>
        ) : (
          <>
            <div className={classes['c-workflow-templates__header']}>
              <Button leftIcon={<WorkflowsIcon />} onClick={handleOpenModal}>
                Create workflow template
              </Button>
            </div>
            <Empty
              img={noWorkflowsImage}
              title="Welcome to Workflow Module"
              info="Start adding members to your asemble. You can add members, edit data and manage your staff with ease."
            />
          </>
        )}
        <PopUpModal
          width={30}
          title="New workflow template"
          opened={openModal}
          closeModal={handleCloseModal}
        >
          <CreateWorkflowModal />
        </PopUpModal>
      </Status>
    </div>
  );
};

export default WorkflowTemplates;
