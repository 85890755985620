import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import { OrganizationDocument } from '../models/settingsModels';

export const createOrganizationDocument = async (document: OrganizationDocument, file: File) => {
  const formData = new FormData();

  formData.append('name', document.name);
  formData.append('type', document.type);
  formData.append('multipartFile', file);

  return api.post(`/organization/${getOrganizationId()}/document`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getAllOrganizationDocuments = async () => {
  return api.get<OrganizationDocument[]>(`/organization/${getOrganizationId()}/document`);
};

export const updateOrganizationDocument = async (document: OrganizationDocument, file: File) => {
  const formData = new FormData();

  Object.entries(document).forEach(([key, value]) => {
    formData.append(key, String(value));
  });

  formData.append('multipartFile', file);

  return api.put(`/organization/${getOrganizationId()}/document/${document.id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const downloadOrganizationDocument = async (documentId: number) => {
  return api.get(`/organization/${getOrganizationId()}/document/${documentId}`, {
    responseType: 'blob',
  });
};

export const deleteOrganizationDocument = async (id: number) => {
  return api.delete(`/organization/${getOrganizationId()}/document/${id}`);
};
