import { OAuthProvider as OAuthProviderType } from '../../../config';
import { OAuthProvider } from '../../../providers';
import { OAuthButton } from './OAuthButton';

type Props = {
  provider: OAuthProviderType;
};

export const OAuthButtonContainer = ({ provider }: Props) => {
  return (
    <OAuthProvider provider={provider}>
      <OAuthButton provider={provider} />
    </OAuthProvider>
  );
};
