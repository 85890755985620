import { Controller, useForm } from 'react-hook-form';
import classes from '../PeoplePopup.module.scss';

import {
  addActiveMemberDefaultValues,
  addActiveMemberValidationSchema,
  AddActiveMemberValidationSchema,
} from '../validations/AddActiveMemberValidation.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from 'components/core/Input';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { AddMemberDropDown } from '../AddMemberDropDown';
import { Button } from 'components/core/Button';
import { formatDateForInput } from 'utils';
import { Checkbox } from 'components/core/Checkbox';
import { ActiveMember, UserBaseInfo } from 'modules/people/model/User';
import { UserSearchInputComponent } from '../UserSearchInputComponent/UserSearchInputComponent';
import { useAddActiveMember } from '../hooks/useAddActiveMember';
import { usePromoteMember } from '../hooks/usePromoteMember';
import { formatLocationsForDropDown, formatSelectedLocation } from '../utils/locationDropDown';
import { useLocation } from 'react-router-dom';

type Props = {
  setIsPopupOpen: (value: boolean) => void;
  memberToPromote?: ActiveMember;
  userToPromoteId?: number;
};

const AddActiveMemberPopup = ({ setIsPopupOpen, memberToPromote, userToPromoteId }: Props) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm<AddActiveMemberValidationSchema>({
    resolver: zodResolver(addActiveMemberValidationSchema),
    defaultValues: memberToPromote?.firstName
      ? {
          firstName: memberToPromote.firstName,
          lastName: memberToPromote.lastName,
          email: '',
          jobTitle: memberToPromote.jobTitle,
          startDate: formatDateForInput(memberToPromote.startDate || new Date()),
          department: '',
          reportTo: '',
          firstWorkingDay: formatDateForInput(memberToPromote.startDate || new Date()),
          location: '',
        }
      : addActiveMemberDefaultValues,
  });

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const userTypeParam = params.get('userType');

  const { handleAddActiveMember } = useAddActiveMember();
  const { handlePromoteMember } = usePromoteMember();

  const [isFirstWorkingDayVisible, setIsFirstWorkingDayVisible] = useState<boolean>(false);
  const { jobTitles, locations, departments } = useAppSelector((state) => state.peoplePopup);
  const [selectedUser, setSelectedUser] = useState<number>();
  const [reportingToUser, setReportingToUser] = useState<UserBaseInfo | undefined>();
  const [minFirstWorkingDay, setMinFirstWorkingDay] = useState<Date>(
    new Date(getValues('startDate')),
  );

  const locationListDropDown = useMemo(() => {
    return formatLocationsForDropDown(locations);
  }, [locations]);

  const locationSelectedOption = useCallback(
    (selectedId: string) => formatSelectedLocation(selectedId, locations),
    [locations],
  );

  const handleOnChange =
    (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };

  const handleOnChangeDropdown = (onChange: (value: string) => void, item: string) =>
    onChange(item);

  const handleOnChangeLocation = (onChange: (value: string) => void, id: number) => {
    onChange(String(id));
  };

  const handleOnChangeStartDate = (onChange: (value: string) => void) => (newDate: string) => {
    setMinFirstWorkingDay(new Date(newDate));
    onChange(formatDateForInput(newDate));
  };

  const handleOnChangeFirstWorkingDayDate =
    (onChange: (value: string) => void) => (newDate: string) => {
      onChange(formatDateForInput(newDate));
    };

  const getSelectedLocation = useCallback(() => {
    return locations.find(({ id }) => id === Number(getValues('location')));
  }, [getValues, locations]);

  const handleFirstWorkingDayVisible = () => {
    setIsFirstWorkingDayVisible((prev) => {
      if (prev) {
        setValue('firstWorkingDay', getValues('startDate'));
        clearErrors('firstWorkingDay');
      }
      return !prev;
    });
  };

  const renderLocationOptions = useCallback(() => {
    return locationListDropDown.map(({ id, location }) => ({
      id,
      value: location,
    }));
  }, [locationListDropDown]);

  const renderSelectedLocation = useCallback(
    (location: string) => {
      if (location) return locationSelectedOption(location);
      if (locationListDropDown.length === 1) return locationListDropDown[0].location;
      return '';
    },
    [locationListDropDown, locationSelectedOption],
  );

  const handleAddMember = async (formData: AddActiveMemberValidationSchema) => {
    const selectedLocation = getSelectedLocation();

    let isSuccess;

    if (memberToPromote?.firstName) {
      isSuccess = await handlePromoteMember(
        formData,
        selectedLocation,
        userToPromoteId!,
        selectedUser,
        reportingToUser,
      );
    } else {
      isSuccess = await handleAddActiveMember(
        formData,
        selectedLocation,
        userTypeParam,
        selectedUser,
        reportingToUser,
      );
    }

    if (isSuccess) setIsPopupOpen(false);
  };

  const optionalText = (
    <>
      Reporting to
      <span className={classes['c-people-popup__optional-text']}> (optional)</span>
    </>
  );

  return (
    <form
      className={classes['c-people-popup__form']}
      onSubmit={handleSubmit(handleAddMember)}
      noValidate
    >
      <div className={classes['c-people-popup__multiple-inputs']}>
        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              id="firstName"
              size="medium"
              name="firstName"
              label="First name"
              value={value}
              handleOnChange={handleOnChange(onChange)}
              errorMessage={errors.firstName?.message}
              isInvalid={!!errors.firstName?.message}
              isSubmitted={isSubmitted}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              id="lastName"
              size="medium"
              name="lastName"
              label="Last name"
              value={value}
              handleOnChange={handleOnChange(onChange)}
              errorMessage={errors.lastName?.message}
              isInvalid={!!errors.lastName?.message}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </div>
      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            id="email"
            size="medium"
            name="email"
            label="Work email"
            value={value}
            handleOnChange={handleOnChange(onChange)}
            errorMessage={errors.email?.message}
            isInvalid={!!errors.email?.message}
            isSubmitted={isSubmitted}
          />
        )}
      />
      <Controller
        name="jobTitle"
        control={control}
        render={({ field: { onChange, value } }) => (
          <AddMemberDropDown
            label="Job title"
            id="jobTitle"
            selectedValue={value}
            items={jobTitles.map((value) => ({ value }))}
            setValue={(selectedItem: string) => handleOnChangeDropdown(onChange, selectedItem)}
            isSubmitted={isSubmitted}
            errorMessage={errors.jobTitle?.message}
          />
        )}
      />
      <Controller
        name="startDate"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            size="medium"
            name="startDate"
            id="startDate"
            label="Start date"
            value={value}
            type="date"
            setValue={handleOnChangeStartDate(onChange)}
            isSubmitted={isSubmitted}
            errorMessage={errors.startDate?.message}
            isInvalid={!!errors.startDate?.message}
          />
        )}
      />
      <Controller
        name="department"
        control={control}
        render={({ field: { onChange, value } }) => (
          <AddMemberDropDown
            label="Department"
            id="department"
            selectedValue={value}
            items={departments.map((value) => ({ value }))}
            setValue={(selectedItem: string) => handleOnChangeDropdown(onChange, selectedItem)}
            isSubmitted={isSubmitted}
            errorMessage={errors.department?.message}
          />
        )}
      />

      <Controller
        name="reportTo"
        control={control}
        render={({ field: { onChange } }) => (
          <UserSearchInputComponent
            label={optionalText}
            id="reportTo"
            handleOnChange={handleOnChange(onChange)}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setUser={setReportingToUser}
          />
        )}
      />

      <Controller
        name="location"
        control={control}
        render={({ field: { onChange, value } }) => (
          <AddMemberDropDown
            label="Location"
            id="location"
            selectedValue={renderSelectedLocation(value)}
            items={renderLocationOptions()}
            setSelectedItemId={(id) => handleOnChangeLocation(onChange, id)}
            defaultSelectedItemId={
              locationListDropDown.length === 1 ? locationListDropDown[0].id : undefined
            }
            isSubmitted={isSubmitted}
            errorMessage={errors.location?.message}
          />
        )}
      />
      {isFirstWorkingDayVisible && (
        <Controller
          name="firstWorkingDay"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              size="medium"
              name="firstWorkingDay"
              id="firstWorkingDay"
              label="First working day"
              value={value}
              type="date"
              setValue={handleOnChangeFirstWorkingDayDate(onChange)}
              isSubmitted={isSubmitted}
              errorMessage={errors.firstWorkingDay?.message}
              isInvalid={!!errors.firstWorkingDay?.message}
              min={minFirstWorkingDay}
            />
          )}
        />
      )}
      <Checkbox
        checked={!isFirstWorkingDayVisible}
        label="First working day same as start date"
        labelColor="#3f4c4f"
        id={0}
        handleCheckedItem={handleFirstWorkingDayVisible}
      />
      <Button size="medium" htmlType="submit" isLoading={isSubmitting}>
        {memberToPromote?.firstName ? 'Promote member' : 'Add active member'}
      </Button>
    </form>
  );
};

export default AddActiveMemberPopup;
