import { useEffect, useState } from 'react';
import { IconButton, toast } from 'components/core';
import { updateUserPtoPolicy as updateUserPolicy } from 'modules/profile/api/profile.api';
import type {
  PtoPolicyUpdateData,
  UserCalendar,
  UserPtoPolicy,
} from 'modules/profile/models/profileModels';
import { updateUserCalendar, updateUserPtoPolicy } from 'modules/profile/redux/userSlice';
import { OrganizationCalendar, PtoPolicy } from 'modules/shared/model';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import classes from '../../../styles/Section.module.scss';
import TimeAwayModal from '../TimeAwayModal/TimeAwayModal';
import { ReactComponent as Clock } from 'assets/new-clock.svg';
import { ReactComponent as Calendar } from 'assets/CalendarIcon.svg';
import { ReactComponent as CalendarCheck } from 'assets/CalendarCheck.svg';
import { ReactComponent as EditIcon } from 'assets/PencilSquare.svg';

export type TimeAwayFormData = {
  daysToAdd: number;
  daysToRemove: number;
  ptoPolicyName: string;
  calendarName: string;
  totalRemainingDays?: number;
};

type Props = {
  userId: number;
  calendars: OrganizationCalendar[];
  ptoPolicies: PtoPolicy[];
  userPtoPolicy: UserPtoPolicy;
  userCalendar: UserCalendar;
  isEditAllowed: boolean;
};

const TimeAwayHeader = ({
  userId,
  calendars,
  ptoPolicies,
  userPtoPolicy,
  userCalendar,
  isEditAllowed,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [remainingDays, setRemainingDays] = useState<number | undefined>(undefined);

  const initialData: TimeAwayFormData = {
    daysToAdd: 0,
    daysToRemove: 0,
    ptoPolicyName: userPtoPolicy.ptoPolicyName,
    calendarName: userCalendar.name,
    totalRemainingDays: userPtoPolicy.totalRemainingDays,
  };

  const dispatch = useAppDispatch();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleOnSave = async (formData: TimeAwayFormData) => {
    const newPtoPolicy = ptoPolicies.find((ptoPolicy) => ptoPolicy.name === formData.ptoPolicyName);
    const newCalendar = calendars.find((calendar) => calendar.name === formData.calendarName);

    if (!newPtoPolicy?.id || !newCalendar?.id) return;

    setRemainingDays(formData.totalRemainingDays);

    const updateData: PtoPolicyUpdateData = {
      organizationCalendarId: newCalendar.id,
      ptoPolicyId: newPtoPolicy.id,
      daysToAdd: formData.daysToAdd,
      daysToRemove: formData.daysToRemove,
    };

    setIsLoading(true);

    try {
      const { data } = await updateUserPolicy(userId, updateData);
      const { ptoPolicyId, organizationCalendarId, carryOverDays, currentYearDays, ptoPolicyName } =
        data;

      const newCalendar = calendars.find((calendar) => calendar.id === organizationCalendarId);

      const newPolicy: UserPtoPolicy = {
        userId,
        ptoPolicyId,
        ptoPolicyName,
        carryOverDays,
        currentYearDays,
      };

      dispatch(updateUserPtoPolicy(newPolicy));
      dispatch(updateUserCalendar(newCalendar));
      toast('success', 'PTO policy updated successfully');
      handleCloseModal();
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (userPtoPolicy?.totalRemainingDays) {
      setRemainingDays(userPtoPolicy.totalRemainingDays);
    }
  }, [userPtoPolicy]);
  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <div className={classes['c-section__header-item-section']}>
          <div className={classes['c-section__header-icon-wrap']}>
            <div className={classes['c-section__header-icon']}>
              <Clock />
            </div>
          </div>
          <div className={classes['c-section__header-item']}>
            <div className={classes['c-section__header-item-top']}>
              <h2 className={classes['c-section__header-heading']}>Available remaining days</h2>
            </div>
            <span className={classes['c-section__info']}>
              {remainingDays} {remainingDays === 1 ? 'day' : 'days'}
            </span>
          </div>
        </div>
        <div className={classes['c-section__header-item-section']}>
          <div className={classes['c-section__header-icon-wrap']}>
            <div className={classes['c-section__header-icon']}>
              <CalendarCheck />
            </div>
          </div>
          <div className={classes['c-section__header-item']}>
            <div className={classes['c-section__header-item-top']}>
              <h2 className={classes['c-section__header-heading']}>PTO policy</h2>
            </div>
            <span className={classes['c-section__info']}>{userPtoPolicy.ptoPolicyName}</span>
          </div>
        </div>
        <div className={classes['c-section__header-item-section']}>
          <div className={classes['c-section__header-icon-wrap']}>
            <div className={classes['c-section__header-icon']}>
              <Calendar />
            </div>
          </div>
          <div className={classes['c-section__header-item']}>
            <div className={classes['c-section__header-item-top']}>
              <h2 className={classes['c-section__header-heading']}>Calendar</h2>
            </div>
            <span className={classes['c-section__info']}>{userCalendar.name}</span>
          </div>
        </div>
        <div className={classes['c-section__header-actions']}>
          {isEditAllowed && (
            <IconButton
              icon={<EditIcon />}
              onClick={handleOpenModal}
              tooltipContent="Edit"
              tooltipPosition="bottom"
            />
          )}
        </div>
      </div>
      {isEditAllowed && (
        <TimeAwayModal
          setIsOpen={setIsModalOpen}
          isOpen={isModalOpen}
          userPtoPolicy={userPtoPolicy}
          calendars={calendars}
          userCalendar={userCalendar}
          ptoPolicies={ptoPolicies}
          formData={initialData}
          isLoading={isLoading}
          onSave={handleOnSave}
          userId={userId}
        />
      )}
    </div>
  );
};

export default TimeAwayHeader;
