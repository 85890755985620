import type { ReactNode } from 'react';
import classes from './FormContainer.module.scss';
import { Button } from 'components/core';

type Props = {
  children: ReactNode;
  displayMode?: 'settings-section' | 'modal';
  onSave?: () => void;
};

const FormContainer = ({ children, displayMode, onSave }: Props) => {
  return (
    <div
      className={`${classes['c-form-container']} ${
        onSave ? classes['c-form-container--with-actions'] : ''
      }  ${displayMode === 'modal' ? classes['c-form-container--modal'] : ''}`}
    >
      <div className={classes['c-form-container__content']}>{children}</div>
      {!!onSave && (
        <div className={classes['c-form-container__actions']}>
          <Button size="medium" onClick={onSave}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default FormContainer;
