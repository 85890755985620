import { IconButton } from 'components/core';
import { ReactComponent as CalendarIcon } from 'assets/CalendarIcon.svg';
import { ReactComponent as ListIcon } from 'assets/ListIcon.svg';
import classes from './TimeAwayActions.module.scss';

export enum OverviewType {
  CALENDAR = 'CALENDAR',
  LIST = 'LIST',
}

type Props = {
  overviewType: OverviewType;
  setOverviewType: (type: OverviewType) => void;
};

const TimeAwayActions = ({ overviewType, setOverviewType }: Props) => {
  const handleCalendarOverviewType = () => setOverviewType(OverviewType.CALENDAR);
  const handleListOverviewType = () => setOverviewType(OverviewType.LIST);

  return (
    <div className={classes['c-time-away-actions__container']}>
      <IconButton
        aria-label="Select calendar view"
        icon={<CalendarIcon />}
        tooltipContent="Select calendar view"
        tooltipPosition="right"
        onClick={handleCalendarOverviewType}
        iconButtonType="secondary"
        isSelected={overviewType === OverviewType.CALENDAR}
        iconSize="medium"
      />
      <IconButton
        aria-label="Select list view"
        icon={<ListIcon />}
        tooltipContent="Select list view"
        tooltipPosition="bottom"
        onClick={handleListOverviewType}
        iconButtonType="secondary"
        isSelected={overviewType === OverviewType.LIST}
        iconSize="medium"
      />
    </div>
  );
};

export default TimeAwayActions;
