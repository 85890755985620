/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { Status, toast } from 'components/core';
import classes from './Insights.module.scss';
import { useToolbarTitle } from 'hooks';
import { ChartsData } from '../models';
import { AvgSalaryChart, AwayDaysChart, chartColors, PeoplePerDepartmentsChart } from './charts';
import { getChartsData } from '../api/insights.api';

const Insights = () => {
  const [chartData, setChartData] = useState<ChartsData>({
    timeAwayDaysByMonth: {},
    numberOfPeoplePerDepartment: [],
    averageSalaryPerDepartment: [],
    averageSalaryPerJobTitle: [],
  });
  const [isContentLoading, setIsContentLoading] = useState<boolean>(false);

  useToolbarTitle({ title: ['Insights'] });

  const getData = useCallback(async () => {
    try {
      setIsContentLoading(true);
      const data = await getChartsData();
      setChartData(data);
    } catch {
      toast('error', 'Something went wrong. Please try again.');
    } finally {
      setIsContentLoading(false);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={classes['c-insights']}>
      <Status isLoading={isContentLoading}>
        <div className={classes['c-insights__content']}>
          <div className={classes['c-insights__chart-holder']}>
            <AwayDaysChart timeAwayDaysByMonth={chartData.timeAwayDaysByMonth} />
          </div>
          <div className={classes['c-insights__chart-holder']}>
            <PeoplePerDepartmentsChart
              numberOfPeoplePerDepartment={chartData.numberOfPeoplePerDepartment}
            />
          </div>
          <div className={classes['c-insights__chart-holder']}>
            <AvgSalaryChart
              title="Average salary per department"
              chartData={chartData.averageSalaryPerDepartment}
              chartColors={chartColors}
            />
          </div>
          <div className={classes['c-insights__chart-holder']}>
            <AvgSalaryChart
              title="Average salary per position"
              chartData={chartData.averageSalaryPerJobTitle}
              chartColors={[...chartColors].reverse()}
            />
          </div>
        </div>
      </Status>
    </div>
  );
};

export default Insights;
