import { createSlice } from '@reduxjs/toolkit';
import type { State } from 'state/store';
import type { User } from '../models/profileModels';

export type UserState = {
  user: User;
};

const initialState: UserState = {
  user: {
    id: 0,
    firstName: '',
    lastName: '',
    gender: '',
    personalEmail: '',
    userStatus: '',
    email: '',
    address: '',
    nationalities: '',
    dateOfBirth: '',
    personalPhone: '',
    role: '',
    profilePhotoPath: '',
    firstTimeLogin: false,
    profileCompleteness: 0,
    basicCustomFields: [],
    equipments: [],
    identifications: [],
    bankAccounts: [],
    emergencyContacts: [],
    userDocuments: [],
    employment: {
      id: 0,
      userId: 0,
      employeeId: '',
      taxId: '',
      taxCode: '',
      contractType: '',
      payrollProvider: '',
      placeOfWork: '',
      organizationLocationId: 0,
      startDate: '',
      probationEndsAt: '',
      workingDays: [],
      employmentRole: {
        isPrimary: false,
        id: 0,
        jobTitle: '',
        department: '',
        effectiveOn: '',
        employmentId: 0,
        reportingToUserDto: {
          id: 0,
          firstName: '',
          lastName: '',
          email: '',
          jobTitle: '',
          organizationId: 0,
          profilePicture: '',
        },
        dedicatedHrDto: {
          email: '',
          firstName: '',
          id: 0,
          jobTitle: '',
          lastName: '',
          organizationId: 0,
          profilePicture: '',
        },
      },
      compensations: [],
      customFields: [],
    },
    directReports: [],
    userSocialDto: {
      biography: '',
      linkedinLink: '',
      instagramLink: '',
      facebookLink: '',
      twitterLink: '',
    },
    healthDto: {
      foodAlergies: [],
    },
    offboardingDto: {
      id: 0,
      employmentEndDate: '',
      lastWorkingDay: '',
      reason: '',
      note: '',
    },
    userPtoPolicyDto: {
      userId: 0,
      ptoPolicyId: 0,
      ptoPolicyName: '',
      currentYearDays: 0,
      carryOverDays: 0,
    },
    organizationCalendarDto: {
      id: 0,
      default: false,
      name: '',
      country: '',
      publicHolidays: [],
    },
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initializeUser: () => initialState,
    setUser: (state, action) => {
      state.user = action.payload;
    },
    addNewEmergencyContact: (state, action) => {
      state.user.emergencyContacts = [...state.user.emergencyContacts, action.payload];
    },
    addNewEquipment: (state, action) => {
      state.user.equipments = [...state.user.equipments, action.payload];
    },
    addUserDocument: (state, action) => {
      state.user.userDocuments = [...state.user.userDocuments, action.payload];
    },
    addIdentification: (state, action) => {
      state.user.identifications = [...state.user.identifications, action.payload];
    },
    updateUserBasics: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
        customFields: action.payload.customFieldValues,
      };
    },
    updateEquipmentInSlice: (state, action) => {
      state.user.equipments = [
        ...state.user.equipments.map((equipment) => {
          if (equipment.id === action.payload.id) {
            return action.payload;
          }
          return equipment;
        }),
      ];
    },
    updateEmployment: (state, action) => {
      state.user.employment = action.payload;
    },
    updateEmploymentRole: (state, action) => {
      state.user.employment.employmentRole = action.payload;
    },
    updateEmploymentDetails: (state, action) => {
      state.user.employment = { ...state.user.employment, ...action.payload };
    },
    addNewCompensation: (state, action) => {
      state.user.employment.compensations = [
        ...state.user.employment.compensations,
        action.payload.newEmployment,
      ];
    },
    updateCompensation: (state, action) => {
      state.user.employment.compensations = state.user.employment.compensations.map(
        (compensation) => {
          if (compensation.id === action.payload.compensationToUpdate.id) {
            return action.payload.compensationToUpdate;
          }
          return compensation;
        },
      );
    },
    updateIdentification: (state, action) => {
      state.user.identifications = [
        ...state.user.identifications.map((identification) => {
          if (identification.id === action.payload.id) {
            return action.payload;
          }
          return identification;
        }),
      ];
    },
    updateUserDocument: (state, action) => {
      state.user.userDocuments = [
        ...state.user.userDocuments.map((document) => {
          if (document.id === action.payload.id) {
            return {
              ...document,
              name: action.payload.name,
              type: action.payload.type,
            };
          }
          return document;
        }),
      ];
    },
    updateUserEmergencyContacts: (state, action) => {
      state.user.emergencyContacts = [
        ...state.user.emergencyContacts.map((emergencyContact) => {
          if (emergencyContact.id === action.payload.id) {
            return action.payload;
          }
          return emergencyContact;
        }),
      ];
    },
    addBankAccount: (state, action) => {
      state.user.bankAccounts = [...state.user.bankAccounts, action.payload];
    },
    updateBankAccounts: (state, action) => {
      state.user.bankAccounts = [
        ...state.user.bankAccounts.map((account) => {
          if (account.id === action.payload.id) {
            return action.payload;
          }
          return account;
        }),
      ];
    },
    updateSocial: (state, action) => {
      state.user.userSocialDto = action.payload;
    },
    updateHealth: (state, action) => {
      state.user.healthDto = action.payload;
    },
    updateUserStatus: (state, action) => {
      state.user.userStatus = action.payload;
    },
    updateOffboarding: (state, action) => {
      state.user.offboardingDto = action.payload;
    },
    updateDirectReports: (state, action) => {
      state.user.directReports = action.payload;
    },
    updateUserPtoPolicy: (state, action) => {
      state.user.userPtoPolicyDto = action.payload;
    },
    updateUserCalendar: (state, action) => {
      state.user.organizationCalendarDto = action.payload;
    },
    setProfilePhoto: (state, action) => {
      state.user.profilePhotoPath = action.payload;
    },
    deleteEmergencyContact: (state, action) => {
      state.user.emergencyContacts = [
        ...state.user.emergencyContacts.filter((contact) => contact.id !== action.payload),
      ];
    },
    deleteBankAccount: (state, action) => {
      state.user.bankAccounts = [
        ...state.user.bankAccounts.filter((account) => account.id !== action.payload),
      ];
    },
    deleteIdentification: (state, action) => {
      state.user.identifications = [
        ...state.user.identifications.filter(
          (identification) => identification.id !== action.payload,
        ),
      ];
    },
    deleteUserDocument: (state, action) => {
      state.user.userDocuments = [
        ...state.user.userDocuments.filter((document) => document.id !== action.payload),
      ];
    },
    deleteCompensation: (state, action) => {
      state.user.employment.compensations = [
        ...state.user.employment.compensations.filter((compensation) => {
          return compensation.id !== action.payload;
        }),
      ];
    },
  },
});

export const {
  initializeUser,
  setUser,
  addNewEmergencyContact,
  addNewEquipment,
  addNewCompensation,
  addBankAccount,
  addUserDocument,
  addIdentification,
  updateUserBasics,
  updateBankAccounts,
  updateEmployment,
  updateEmploymentRole,
  updateEmploymentDetails,
  updateIdentification,
  updateUserDocument,
  updateUserEmergencyContacts,
  updateCompensation,
  updateEquipmentInSlice,
  updateSocial,
  updateHealth,
  updateUserStatus,
  updateOffboarding,
  updateDirectReports,
  updateUserPtoPolicy,
  updateUserCalendar,
  deleteEmergencyContact,
  deleteBankAccount,
  deleteIdentification,
  deleteUserDocument,
  deleteCompensation,
  setProfilePhoto,
} = userSlice.actions;

export default userSlice;

export const selectUser = (state: State) => state.profile.user;
export const selectUserEmployment = (state: State) => state.profile.user.employment;
export const selectUserId = (state: State) => state.profile.user.id;
