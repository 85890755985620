import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { State } from 'state/store';
import classes from './UserItem.module.scss';
import { toggleFilter } from 'components/core/Filter/redux/filterSlice';
import { ActiveMember, User } from 'modules/people/model/User';
import {
  PopUpModal,
  Avatar,
  PeoplePopup,
  Badge,
  HighlightedText,
  Button,
  Tooltip,
} from 'components/core';
import { FilterCategory, Role, UserType } from 'config/constants';
import { timeBasedOnCity } from 'modules/people/utils';
import { getListFromString } from 'utils';
import { ReactComponent as Arrow } from 'assets/ArrowUp.svg';

type Props = {
  user: User;
  isSelected: boolean;
  selectedType: UserType;
  searchValue?: string;
  isHighligted?: boolean;
  handleSelectedUser: (userId: number) => void;
};

const UserItem = ({
  user: {
    id,
    firstName,
    lastName,
    email,
    personalEmail,
    jobTitle,
    department,
    projectNames,
    placeOfWork,
    profilePhotoPath,
    country,
    userType,
    effectiveOn,
    newestAddedUser,
  },
  isHighligted,
  isSelected,
  selectedType,
  searchValue,
  handleSelectedUser,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [projects, setProjects] = useState<string[]>([]);
  const [activeMember, setActiveMember] = useState<ActiveMember>({
    firstName,
    lastName,
    jobTitle,
    email,
    startDate: new Date(effectiveOn),
    location: placeOfWork,
  });

  const role = useAppSelector((state: State) => state.auth.user?.role);

  const dispatch = useAppDispatch();

  const isNewHire = useMemo(
    () => userType === UserType.NEW_HIRE && role !== Role.USER,
    [userType, role],
  );

  const location = useMemo(() => {
    if (!placeOfWork) return { address: '', city: '' };

    const [address, city] = placeOfWork.split('-');
    return { address, city };
  }, [placeOfWork]);

  const isLastProject = useCallback((index: number, length: number) => index === length - 1, []);

  const handleOpenTooltip = () => setIsTooltipOpen(true);
  const handleCloseTooltip = () => setIsTooltipOpen(false);

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLSpanElement>, filterCategory: string, filterValue: string) => {
      event?.stopPropagation();
      dispatch(
        toggleFilter({
          filterCategory,
          filterValue,
        }),
      );
    },
    [dispatch],
  );

  const closePopup = () => {
    setIsModalOpen(false);
    setActiveMember({
      firstName,
      lastName,
      jobTitle,
      email,
    });
  };

  const closeModalOnClickOutside = () => {
    setIsModalOpen(false);
  };

  const handlePromote = (event: MouseEvent) => {
    event.stopPropagation();
    setIsModalOpen(true);
  };

  const renderEmailColumn = useCallback(() => {
    if (isNewHire && selectedType === UserType.ALL_USERS)
      return (
        <div className={classes['c-user-item__data-col']}>
          <Badge color="orange-rounded" size="medium-plus">
            New Hire
          </Badge>
        </div>
      );

    const userEmail = userType === UserType.ACTIVE_MEMBER ? email : personalEmail;

    if (!userEmail)
      return (
        <div className={classes['c-user-item__data-col']}>
          <span className={classes['c-user-item__data-col--missing-data']}>N/A</span>
        </div>
      );

    return (
      <div className={classes['c-user-item__data-col']}>
        <span className={classes['c-user-item__data-col--secondary']}>
          <HighlightedText isHighligted={isHighligted} text={userEmail} highlight={searchValue} />
        </span>
      </div>
    );
  }, [isNewHire, selectedType, email, searchValue, personalEmail, userType, isHighligted]);

  const renderDepartment = useCallback(
    (department?: string) => {
      if (!department) return;

      return (
        <>
          <span> in </span>
          <button
            className={classes['c-user-item__data-col--link']}
            onClick={(event) => handleOnClick(event, FilterCategory.DEPARTMENT, department)}
          >
            <HighlightedText
              isHighligted={isHighligted}
              text={department}
              highlight={searchValue}
            />
          </button>
        </>
      );
    },
    [handleOnClick, searchValue, isHighligted],
  );

  const renderProjects = useCallback(() => {
    const projectsToDisplay = projects.slice(0, 2);
    const tooltipProjects = projects.slice(2);

    return (
      <div
        className={classes['c-user-item__data-col--projects']}
        onMouseEnter={handleOpenTooltip}
        onMouseLeave={handleCloseTooltip}
      >
        {projectsToDisplay.map((project, index) => (
          <React.Fragment key={index}>
            <button
              className={classes['c-user-item__data-col--project']}
              onClick={(event) => handleOnClick(event, FilterCategory.PROJECT, project || '')}
            >
              <HighlightedText isHighligted={isHighligted} text={project} highlight={searchValue} />
            </button>
            {index === 1 && !!tooltipProjects.length && (
              <span
                className={`${classes['c-user-item__data-col--project']} ${classes['c-user-item__data-col--last-project']}`}
              >
                ...
              </span>
            )}
          </React.Fragment>
        ))}
        <Tooltip isOpen={isTooltipOpen && !!tooltipProjects.length} position="left">
          {tooltipProjects.map((project, index) => (
            <span key={index}>
              {project}
              {!isLastProject(index, tooltipProjects.length) && <span>, </span>}
            </span>
          ))}
        </Tooltip>
      </div>
    );
  }, [handleOnClick, isLastProject, isTooltipOpen, projects, searchValue, isHighligted]);

  const renderLocation = useCallback(() => {
    if (!location.city || !country)
      return <span className={classes['c-user-item__data-col--secondary']}>N/A</span>;

    return (
      <div className={classes['c-user-item__data-col--location']}>
        <button
          className={`${classes['c-user-item__data-col--primary']} ${classes['c-user-item__data-col--link']}`}
          onClick={(event) => handleOnClick(event, FilterCategory.LOCATION, placeOfWork)}
        >
          <span className={classes['c-user-item__data-col--location']}>
            <span className={classes['c-user-item__data-col--location-data']}>
              <HighlightedText
                isHighligted={isHighligted}
                text={location.address}
                highlight={searchValue}
              />
            </span>
            <span className={classes['c-user-item__data-col--location-data']}>
              <HighlightedText
                isHighligted={isHighligted}
                text={location.city}
                highlight={searchValue}
              />
            </span>
          </span>
        </button>

        <span className={classes['c-user-item__data-col--secondary']}>
          {timeBasedOnCity(location.city, country)}
        </span>
      </div>
    );
  }, [country, placeOfWork, searchValue, handleOnClick, isHighligted]);

  const renderBadgeColumn = useCallback(() => {
    if (newestAddedUser)
      return (
        <Badge size="small" color="yellow">
          Newly enlisted
        </Badge>
      );
  }, []);

  useEffect(() => {
    if (!projectNames) return;

    const projectsList = getListFromString(projectNames);
    setProjects(projectsList);
  }, [projectNames]);

  return (
    <div className={classes['c-user-item__wrap']}>
      <div
        className={`${classes['c-user-item']} ${
          isSelected ? classes['c-user-item--highlighted'] : ''
        }`}
        onClick={() => handleSelectedUser(id)}
      >
        <div className={classes['c-user-item__data-col']}>
          <div className={classes['c-user-item__data-col--avatar']}>
            <Avatar
              horizontal
              size="small-plus"
              imageUrl={profilePhotoPath}
              title={
                <HighlightedText
                  isHighligted={isHighligted}
                  text={`${firstName} ${lastName}`}
                  highlight={searchValue}
                />
              }
              subtitle={
                <div>
                  {jobTitle}
                  {renderDepartment(department)}
                </div>
              }
            />
          </div>
        </div>
        {renderEmailColumn()}
        <div className={classes['c-user-item__data-col']}>
          {!!projects.length && renderProjects()}
        </div>
        {!isNewHire ? (
          <div
            className={`${classes['c-user-item__data-col']} ${classes['c-user-item__data-col--centered']}`}
          >
            {renderLocation()}
          </div>
        ) : (
          <div
            className={`${classes['c-user-item__data-col']} ${classes['c-user-item__data-col--centered']} ${classes['c-user-item__data-col--button']}`}
          >
            <Button
              leftIcon={<Arrow className={classes['c-user-item__button-icon']} />}
              onClick={handlePromote}
            >
              Promote
            </Button>
          </div>
        )}
        <div
          className={`${classes['c-user-item__data-col']} ${classes['c-user-item__data-col--centered']}`}
        >
          {renderBadgeColumn()}
        </div>
      </div>
      <PopUpModal
        title="Promote member"
        opened={isModalOpen}
        closeModal={closePopup}
        closeModalOnClickOutside={closeModalOnClickOutside}
        width={30}
      >
        <PeoplePopup
          userToPromoteId={id}
          setIsPopupOpen={setIsModalOpen}
          setActiveMember={setActiveMember}
          selectedUserType={selectedType}
          activeMember={activeMember}
        />
      </PopUpModal>
    </div>
  );
};

export default UserItem;
