import { toast } from 'components/core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'lib';
import { TimeAway } from 'modules/timeline/models/timeAwayModels';
import { getOrganizationId } from 'state/utils';

export const getTimeAwaysByUser = createAsyncThunk(
  'timeAways/getTimeAwaysByUser',
  async (userId: string) => {
    try {
      const response = await api.get<TimeAway[]>(
        `/organization/${getOrganizationId()}/user/${userId}/time-away`,
      );
      return response?.data;
    } catch {
      toast('error', 'Something went wrong, please try again later');
    }
  },
);
