import { useState, useEffect } from 'react';
import classes from '../../../styles/Section.module.scss';
import { ReactComponent as FoodPlateIcon } from 'assets/FoodPlateIcon.svg';
import { SectionInfoEdit, SectionActions } from 'modules/shared/components';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { updateHealth } from 'modules/profile/api/profile.api';
import { updateHealth as updateHealthAction } from 'modules/profile/redux/userSlice';
import { useParams } from 'react-router-dom';
import toast from 'utils/notifications/CustomToast';

const Health = () => {
  const foodAlergies = useAppSelector((state) => state.profile.user.healthDto?.foodAlergies);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [allergiesData, setAllergiesData] = useState<string>(foodAlergies?.toString());
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const dispatch = useAppDispatch();

  const handleUpdate = async (data: string[]) => {
    if (!id) return;

    setIsLoading(true);

    try {
      const { data: responseData } = await updateHealth({ foodAlergies: data }, id);
      dispatch(updateHealthAction(responseData));
      setIsEditMode(false);
      toast.success('You have successfully updated health information.');
    } catch {
      toast.error('Something went wrong while updating health information. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setAllergiesData(foodAlergies?.toString());
    setIsEditMode(false);
  };

  useEffect(() => {
    setAllergiesData(foodAlergies?.toString());
  }, [foodAlergies]);

  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <div className={classes['c-section__header__title-and-add']}>
          <h2 className={classes['c-section__title']}>Health</h2>
        </div>
        <SectionActions
          isEditOpen={isEditMode}
          handleEdit={() => setIsEditMode(true)}
          handleCancel={handleCancel}
          handleSave={() => handleUpdate(allergiesData.split(','))}
          isLoading={isLoading}
        />
      </div>
      <div className={classes['c-section__content']}>
        <div className={classes['c-section__content-column']}>
          <SectionInfoEdit
            name="Food allergies"
            data={allergiesData?.toString()}
            icon={<FoodPlateIcon />}
            handleChange={(event) => setAllergiesData(event.target.value)}
            readOnly={!isEditMode}
          />
        </div>
      </div>
    </div>
  );
};

export default Health;
