import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { State as AppState } from 'state/store';
import type { PtoPolicy } from '../../model';
import { RequestState } from 'config/constants';
import { getOrganizationPtoPolicies } from './ptoPolicyActions';

type State = {
  ptoPolicies: PtoPolicy[];
  loading: RequestState;
};

const initialState: State = {
  ptoPolicies: [],
  loading: RequestState.IDLE,
};

const ptoPolicySlice = createSlice({
  name: 'ptoPolicy',
  initialState,
  reducers: {
    setPtoPolicies: (state, action: PayloadAction<PtoPolicy[]>) => {
      state.ptoPolicies = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationPtoPolicies.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getOrganizationPtoPolicies.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        if (action.payload) {
          state.ptoPolicies = action.payload;
        }
      })
      .addCase(getOrganizationPtoPolicies.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { setPtoPolicies } = ptoPolicySlice.actions;

export const selectPtoPolicies = (state: AppState) => state.ptoPolicies;

export default ptoPolicySlice.reducer;
