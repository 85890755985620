import { array, boolean, object, string } from 'zod';

export const calendarSchema = object({
  name: string().min(1, { message: 'Please enter a calendar name.' }),
  country: string().min(1, { message: 'Please enter a country.' }),
  default: boolean(),
  publicHolidays: array(
    object({
      name: string().min(1, { message: 'Please enter a holiday name.' }),
      date: string().min(1, { message: 'Please enter a holiday date.' }),
      enabled: boolean(),
    }),
  ),
});
