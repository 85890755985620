import { useCallback, useState } from 'react';
import classes from '../../../styles/Section.module.scss';
import { ReactComponent as IDIcon } from 'assets/IDIcon.svg';
import { ReactComponent as MailSvg } from 'assets/MailIcon.svg';
import { ReactComponent as PhoneSvg } from 'assets/PhoneIcon.svg';
import { ReactComponent as RelationshipIcon } from 'assets/RelationshipIcon.svg';
import { SectionInfoEdit, SectionActions } from 'modules/shared/components';
import { type EmergencyContact } from 'modules/profile/models/profileModels';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { emailRegex } from 'utils/regexs';
import {
  updateUserEmergencyContacts,
  deleteEmergencyContact as deleteEmergencyContactAction,
  selectUserId,
} from 'modules/profile/redux/userSlice';
import toast from 'utils/notifications/CustomToast';
import { deleteEmergencyContact, updateEmergencyContact } from 'modules/profile/api/profile.api';
import { Role } from 'config/constants';
import { ActionConfirmationModal } from 'components/core';

interface ContactProps {
  contact: EmergencyContact;
  userRole?: Role;
  userAuthId?: number;
}

const Contact = ({ contact }: ContactProps) => {
  const [openForm, setOpenForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formInputs, setFormInputs] = useState(contact);
  const [emailError, setEmailError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const userId = useAppSelector(selectUserId);
  const dispatch = useAppDispatch();

  const changeAttributeHandler = (value: string, changeAttribute: string) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [changeAttribute]: value,
    }));
  };

  const updateContactHandler = useCallback(async () => {
    setIsSubmitted(true);

    if (!formInputs.fullName.trim()) return;

    if (!!formInputs.email && !emailRegex.test(formInputs.email)) {
      setEmailError('Please enter the valid email format');
      return;
    }

    setIsLoading(true);

    const updatedContact: EmergencyContact = {
      id: formInputs.id,
      fullName: formInputs.fullName,
      email: formInputs.email,
      phone: formInputs.phone,
      relationship: formInputs.relationship,
      isPrimary: formInputs.isPrimary,
      userId,
    };

    try {
      const { data } = await updateEmergencyContact(userId, updatedContact);

      dispatch(updateUserEmergencyContacts(data));
      setOpenForm(false);
      toast.success('You have successfully updated emergency contact.');
    } catch {
      toast.error('Something went wrong while saving. Try again.');
    } finally {
      setEmailError('');
      setIsLoading(false);
    }
  }, [userId, formInputs, dispatch]);

  const handleCancel = useCallback(() => {
    setEmailError('');
    setOpenForm(false);
    setFormInputs(contact);
  }, [contact]);

  const handleDelete = useCallback(async () => {
    setIsLoading(true);

    if (!contact?.id) return;

    try {
      await deleteEmergencyContact(contact.id, userId);
      dispatch(deleteEmergencyContactAction(contact.id));
      setOpenForm(false);
      toast.success('You have successfully removed emergency contact.');
    } catch {
      toast.success('You have successfully removed emergency contact.');
    } finally {
      setIsLoading(false);
    }
  }, [contact, userId, dispatch]);

  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <h2 className={classes['c-section__title']}>{contact.fullName}</h2>
        <SectionActions
          isEditOpen={openForm}
          handleEdit={() => setOpenForm(true)}
          handleCancel={handleCancel}
          handleSave={updateContactHandler}
          handleDelete={() => setOpenDialog(true)}
          isLoading={isLoading}
        />
      </div>
      <div className={classes['c-section__content']}>
        <SectionInfoEdit
          name="Name"
          data={formInputs.fullName}
          icon={<IDIcon />}
          isSubmitted={isSubmitted}
          setValue={(event) => {
            changeAttributeHandler(event, 'fullName');
          }}
          readOnly={!openForm}
          required
        />
        <SectionInfoEdit
          name="Email"
          data={formInputs.email}
          icon={<MailSvg />}
          setValue={(event) => {
            changeAttributeHandler(event, 'email');
          }}
          errorMessage={emailError}
          readOnly={!openForm}
        />
        <SectionInfoEdit
          name="Phone"
          data={formInputs.phone}
          icon={<PhoneSvg />}
          setValue={(event) => {
            changeAttributeHandler(event, 'phone');
          }}
          readOnly={!openForm}
        />
        <SectionInfoEdit
          name="Relationship"
          data={formInputs.relationship}
          setValue={(event) => {
            changeAttributeHandler(event, 'relationship');
          }}
          icon={<RelationshipIcon className={classes['c-section__label-icon--contacts']} />}
          readOnly={!openForm}
        />
      </div>
      <ActionConfirmationModal
        message={`you want to delete emergency contact ${formInputs.fullName}?`}
        isModalOpen={openDialog}
        closeModal={() => setOpenDialog(false)}
        isLoading={isLoading}
        handleNo={() => setOpenDialog(false)}
        handleYes={handleDelete}
      />
    </div>
  );
};

export default Contact;
