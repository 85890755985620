import { useCallback } from 'react';
import PtoPolicyForm from '../PtoPolicyForm/PtoPolicyForm';
import classes from './PtoPolicyModal.module.scss';
import type { PtoPolicy } from 'modules/shared/model';

type Props = {
  onSave: (newPtoPolicy: PtoPolicy) => void;
  setIsConfirmationModalOpen: (isConfirmationModal: boolean) => void;
  setNewPtoPolicy: (newPtoPolicy: PtoPolicy) => void;
  newPtoPolicy: PtoPolicy;
  isConfirmationModalOpen: boolean;
};

const PtoPolicyModal = ({
  onSave,
  setIsConfirmationModalOpen,
  setNewPtoPolicy,
  newPtoPolicy,
  isConfirmationModalOpen,
}: Props) => {
  const handleOnSave = useCallback(() => {
    const updatedPtoPolicy = {
      ...newPtoPolicy,
      replaceDefaultPolicy: newPtoPolicy.default,
    };
    onSave(updatedPtoPolicy);
  }, [newPtoPolicy, onSave]);

  const handleOnChange = useCallback((newPtoPolicy: PtoPolicy) => {
    setNewPtoPolicy(newPtoPolicy);
  }, []);

  return (
    <div className={classes['c-pto-policy-modal']}>
      <PtoPolicyForm
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        isConfirmationModalOpen={isConfirmationModalOpen}
        ptoPolicy={newPtoPolicy}
        displayMode="modal"
        onChange={handleOnChange}
        onSave={handleOnSave}
      />
    </div>
  );
};

export default PtoPolicyModal;
