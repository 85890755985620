import type { ReactNode } from 'react';
import { Button } from 'components/core';
import { ReactComponent as MicrosoftIcon } from 'assets/MicrosoftIcon.svg';
import { useOAuth } from '../../../hooks';
import { OAuthProvider } from '../../../config';
import { ReactComponent as GoogleIcon } from 'assets/GoogleIcon.svg';
import classes from './OAuthButton.module.scss';

type Props = {
  provider: OAuthProvider;
};

const icon: Record<OAuthProvider, ReactNode> = {
  microsoft: <MicrosoftIcon />,
  google: <GoogleIcon />,
};

const text: Record<OAuthProvider, string> = {
  microsoft: 'Sign in with Microsoft',
  google: 'Sign in with Google',
};

export const OAuthButton = ({ provider }: Props) => {
  const { handleLogin } = useOAuth();

  return (
    <Button size="large" variant="outlined" type="plain" onClick={() => handleLogin()}>
      <span className={classes['c-o-auth-button']}>
        {icon[provider]}
        {text[provider]}
      </span>
    </Button>
  );
};
