import { useCallback, useEffect, useState } from 'react';
import { Role } from 'config/constants';
import RolesSection from './RolesSection/RolesSection';
import { useToolbarTitle } from 'hooks';
import { Status, toast } from 'components/core';
import { getAllUsersByRole } from 'modules/settings/api';
import { User } from 'modules/people/model/User';

const Permissions = () => {
  const [isContentLoading, setIsContentLoading] = useState<boolean>(false);
  const [superAdministratorUsers, setSuperAdministratorUsers] = useState<User[]>([]);
  const [administratorUsers, setAdministratorUsers] = useState<User[]>([]);

  useToolbarTitle({ title: ['Settings', 'Permissions'] });

  const superAdministratorRole = Role.SUPER_ADMINISTRATOR;
  const administratorRole = Role.ADMINISTRATOR;

  const getUsersByRole = useCallback(
    async (role: Role) => {
      setIsContentLoading(true);

      try {
        const { data } = await getAllUsersByRole(role);

        if (role === superAdministratorRole) {
          setSuperAdministratorUsers(data);
          return;
        }

        setAdministratorUsers(data);
      } catch {
        toast('error', 'Something went wrong. Please try again.');
      } finally {
        setIsContentLoading(false);
      }
    },
    [superAdministratorRole],
  );

  useEffect(() => {
    getUsersByRole(administratorRole);
    getUsersByRole(superAdministratorRole);
  }, [administratorRole, getUsersByRole, superAdministratorRole]);

  return (
    <Status isLoading={isContentLoading}>
      <RolesSection
        role={superAdministratorRole}
        roleUsers={superAdministratorUsers}
        getUsersByRole={getUsersByRole}
      />
      <RolesSection
        role={administratorRole}
        roleUsers={administratorUsers}
        getUsersByRole={getUsersByRole}
      />
    </Status>
  );
};

export default Permissions;
