import type { ReactNode } from 'react';
import classes from './AddButton.module.scss';

type Props = {
  label: string;
  info?: string;
  icon: ReactNode;
  onClick?: () => void;
};

export const AddButton = ({ label, info, icon, onClick }: Props) => {
  return (
    <button className={classes['c-add-button']} onClick={onClick}>
      <span className={classes['c-add-button__label-container']}>
        {icon}
        <span className={classes['c-add-button__label']}>{label}</span>
      </span>
      {info && <span className={classes['c-add-button__info']}>{info}</span>}
    </button>
  );
};
