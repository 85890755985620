import { toast as reactToast, type ToastOptions, type TypeOptions } from 'react-toastify';

const dismissToast = () => reactToast.dismiss();

const toast = (type: TypeOptions, message: string, options?: ToastOptions): void => {
  switch (type) {
    case 'success':
      reactToast.success(message, options);
      break;
    case 'info':
      reactToast.info(message, options);
      break;
    case 'warning':
      reactToast.warning(message, options);
      break;
    case 'error':
      reactToast.error(message, options);
      break;
    default:
      reactToast(message, options);
  }
};

export { toast, dismissToast };
