import { useCallback, useEffect, useState } from 'react';
import classes from '../styles/Workflows.module.scss';
import noContentImage from 'assets/images/NoWorkflows.png';
import WorkflowUser from './WorkflowUser/WorkflowUser';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getAllWorkflowInstances } from '../redux/workflowInstanceAction';
import { ReactComponent as WorkflowsIcon } from 'assets/Workflows.svg';
import { RequestState } from 'config/constants';
import { selectWorkflowTemplates } from '../redux/workflowTemplatesSlice';
import CreateWorkflowModal from './CreateWorkflowModal/CreateWorkflowModal';
import {
  Button,
  Empty,
  PopUpModal,
  Filter,
  Status,
  FilterButton,
  Container,
  AppliedFilters,
} from 'components/core';
import WorkflowsTableHead from './WorkflowsTableHead';
import { useToolbarTitle } from 'hooks';
import { workflowStatusFilters, WorkflowStatusFilter } from '../utils';
import { useFilteredWorkflows } from '../hooks';
import FiltersButton from 'components/core/FiltersButton/FiltersButton';

const Workflows = () => {
  const { workflowInstances } = useAppSelector((state) => state.workflowInstances);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<WorkflowStatusFilter>('Active');

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const workflowTemplates = useAppSelector(selectWorkflowTemplates);
  const { appliedFilters } = useAppSelector((state) => state.filters);
  const { filterClicked, inputFieldValue } = useAppSelector((state) => state.toolbar);
  const { loading } = useAppSelector((state) => state.workflowInstances);

  const { filteredWorkflows } = useFilteredWorkflows(
    workflowInstances,
    appliedFilters,
    inputFieldValue ?? '',
  );

  const numberOfWorkflows = filteredWorkflows.length;

  useToolbarTitle(
    {
      title: ['Workflows'],
      subtitle: `${numberOfWorkflows} ${numberOfWorkflows === 1 ? 'workflow' : 'workflows'}`,
    },
    [numberOfWorkflows],
  );

  const dispatch = useAppDispatch();

  const workflowFilters = workflowTemplates?.map((workflow) => workflow.name) || [];

  const areFiltersApplied =
    !!appliedFilters.workflowNames.length ||
    !!appliedFilters.departmentNames.length ||
    !!appliedFilters.locationNames.length ||
    !!appliedFilters.sortBy.filterValue;

  const showNoResults =
    !filteredWorkflows.length &&
    (!!inputFieldValue?.length || areFiltersApplied || selectedFilter === 'Archived');

  const handleSelectedStatusFilter = (filter: WorkflowStatusFilter) => {
    setSelectedFilter(filter);
  };

  const getWorkflowsByStatus = useCallback(
    (selectedFilter: WorkflowStatusFilter) => {
      if (selectedFilter === 'Archived') {
        dispatch(getAllWorkflowInstances({ filter: 'archived' }));
        return;
      }

      dispatch(getAllWorkflowInstances());
    },
    [dispatch],
  );

  useEffect(() => {
    getWorkflowsByStatus(selectedFilter);
  }, [selectedFilter, getWorkflowsByStatus]);

  return (
    <div className={classes['c-workflows']}>
      {filterClicked && <Filter filters={workflowFilters} />}
      {areFiltersApplied && !filterClicked && (
        <div className={classes['c-workflows__applied-filters']}>
          <AppliedFilters />
        </div>
      )}
      <div className={classes['c-workflows__header']}>
        <Container gap="xs">
          {workflowStatusFilters.map((filter) => (
            <FilterButton
              key={filter}
              label={filter}
              selected={selectedFilter === filter}
              onClick={() => handleSelectedStatusFilter(filter)}
            />
          ))}
        </Container>
        <Container>
          <FiltersButton />
          <Button leftIcon={<WorkflowsIcon />} onClick={handleOpenModal}>
            Create workflow
          </Button>
        </Container>
      </div>
      <div className={classes['c-workflows__table']}>
        <Status isLoading={loading === RequestState.PENDING}>
          {!!filteredWorkflows.length && (
            <>
              <WorkflowsTableHead />
              <div className={classes['c-workflows__table-content']}>
                {filteredWorkflows.map((workflow) => (
                  <WorkflowUser
                    key={workflow.id}
                    workflow={workflow}
                    searchValue={inputFieldValue}
                  />
                ))}
              </div>
            </>
          )}
          {showNoResults && (
            <Empty
              img={noContentImage}
              title="No workflows found"
              info="Try changing the filters or search term."
            />
          )}
          {!workflowInstances.length && selectedFilter === 'Active' && (
            <Empty
              img={noContentImage}
              title="Welcome to workflow module"
              info="Start adding members to your assemble. You can add members, edit data and manage your staff with ease."
            />
          )}
        </Status>
      </div>
      <PopUpModal
        width={30}
        title="Create new workflow"
        opened={isModalOpen}
        closeModal={handleCloseModal}
      >
        <CreateWorkflowModal workflowTemplates={workflowTemplates} />
      </PopUpModal>
    </div>
  );
};

export default Workflows;
