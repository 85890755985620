import { useCallback, useState, useMemo } from 'react';
import Section from '../../Section/Section';
import CalendarModal from './CalendarModal/CalendarModal';
import CalendarItem from './CalendarItem/CalendarItem';
import {
  createOrganizationCalendar,
  deleteOrganizationCalendar,
  updateOrganizationCalendar,
} from 'modules/settings/api/organizationCalendar.api';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { isDataValid } from 'utils/validation';
import { calendarSchema } from './utils/calendarSchema';
import type { OrganizationCalendar } from 'modules/shared/model';
import {
  selectCalendars,
  deleteCalendar,
  updateCalendar,
  createCalendar,
} from 'modules/shared/redux/calendar';
import toast from 'utils/notifications/CustomToast';
import { mapErrorToErrorData } from 'utils';

const Calendar = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { calendars } = useAppSelector(selectCalendars);

  const sortedCalendars = useMemo(
    () =>
      [...calendars].sort((a, b) => {
        if (a.default) return -1;

        return a.name.localeCompare(b.name);
      }),
    [calendars],
  );

  const dispatch = useAppDispatch();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleCreateCalendar = useCallback(
    async (newCalendar: OrganizationCalendar) => {
      if (calendars.some((calendar) => calendar.name === newCalendar.name)) {
        toast.warning(`Calendar "${newCalendar.name}" already exists.`);
        return;
      }

      if (!isDataValid(calendarSchema, newCalendar)) return;

      try {
        setIsLoading(true);

        const { data } = await createOrganizationCalendar(newCalendar);
        dispatch(createCalendar(data));
        setIsModalOpen(false);

        toast.success(`Calendar "${data.name}" created successfully.`);
      } catch (error) {
        const errorData = mapErrorToErrorData(error);
        toast.error(
          errorData?.message || 'Something went wrong while creating calendar, please try again.',
        );
      } finally {
        setIsLoading(false);
      }
    },
    [calendars, dispatch],
  );

  const handleUpdateCalendar = useCallback(
    async (updatedCalendar: OrganizationCalendar) => {
      try {
        setIsLoading(true);

        const { data } = await updateOrganizationCalendar(updatedCalendar);
        dispatch(updateCalendar(data));

        toast.success(`Calendar "${updatedCalendar.name}" updated successfully.`);
      } catch (error) {
        const errorData = mapErrorToErrorData(error);
        toast.error(
          errorData?.message || 'Something went wrong while updating calendar, please try again.',
        );
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  const handleDeleteCalendar = useCallback(
    async (calendarId: number) => {
      try {
        setIsLoading(true);

        const calendarName = calendars.find((calendar) => calendar.id === calendarId)?.name;

        await deleteOrganizationCalendar(calendarId);
        dispatch(deleteCalendar(calendarId));

        toast.success(`Calendar "${calendarName}" deleted successfully.`);
      } catch (error) {
        const errorData = mapErrorToErrorData(error);
        toast.error(
          errorData?.message || 'Something went wrong while deleting calendar, please try again.',
        );
      } finally {
        setIsLoading(false);
      }
    },
    [calendars, dispatch],
  );

  return (
    <Section title="Calendar" iconType="add" onClick={handleOpenModal}>
      {sortedCalendars.map((calendar) => (
        <CalendarItem
          key={calendar.id}
          item={calendar}
          isLoading={isLoading}
          onSave={handleUpdateCalendar}
          onDelete={handleDeleteCalendar}
        />
      ))}
      {isModalOpen && (
        <CalendarModal
          isOpen={isModalOpen}
          onSave={handleCreateCalendar}
          onClose={handleCloseModal}
        />
      )}
    </Section>
  );
};

export default Calendar;
