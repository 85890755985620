import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'lib';
import { getWorkflowTemplate } from '../api/workflow.api';
import { getOrganizationId } from 'state/utils';

export const getAllWorkflows = createAsyncThunk(
  'workflowSlice/getAllWorkflows',
  async (payload: { page?: number; size?: number; sort?: string }) => {
    const { page, size, sort } = payload;

    const response = await api.get(`/organization/${getOrganizationId()}/workflow`, {
      params: { page, size, sort },
    });
    return response.data.content;
  },
);

export const getWorkflow = createAsyncThunk('workflowSlice/getWorkflow', async (id: string) => {
  const { data } = await getWorkflowTemplate(id);
  return data;
});
