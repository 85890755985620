import { Routes as Switch, useLocation } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
  Filler,
} from 'chart.js';
import { Toast } from 'components/core';
import { setupDayjs } from 'dayjs.setup';
import { routes } from 'routes';
import { useAuth } from 'hooks';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectAuthUser } from 'modules/auth/redux/authSlice';
import { Layout } from 'components/layout';
import 'react-image-crop/src/ReactCrop.scss';
import { selectFeatureFlags } from 'modules/featureFlags/redux/featureFlagsSlice';
import { getAllowedRoutes } from 'utils/getAllowedRoutes';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  Filler,
);

setupDayjs();

export function App() {
  const { isLoad } = useAuth();
  const { isAuthenticated } = useAppSelector(selectAuthUser);
  const featureFlags = useAppSelector(selectFeatureFlags);

  const nonWildcardPaths = routes.filter((route) => getAllowedRoutes(route, featureFlags));

  const allRoutes = nonWildcardPaths.flatMap((route) => {
    let routePaths = [route.props.path];

    if (route.props.children) {
      routePaths = routePaths.concat(
        route.props.children.map(
          (childRoute: JSX.Element) => `${route.props.path}/${childRoute.props.path}`,
        ),
      );
    }

    return routePaths;
  });

  const checkUrlForPattern = (url: string, patterns: string[]) => {
    for (const pattern of patterns) {
      const regexPattern = pattern.replace(/:\w+/g, '([^/]+)');
      const regex = new RegExp(`^${regexPattern}$`);
      if (regex.test(url)) return true;
    }
    return false;
  };

  const location = useLocation();
  const currentPath = location.pathname;
  let isWildcard = allRoutes.some((x) => x === currentPath);

  if (!isWildcard) {
    const patternsWithDynamicParam = allRoutes.filter((route) => route.includes(':')) as string[];
    isWildcard = checkUrlForPattern(currentPath, patternsWithDynamicParam);
  }

  if (!isLoad) return null;

  if (!isAuthenticated)
    return (
      <>
        <Switch>{nonWildcardPaths}</Switch>
        <Toast />
      </>
    );

  if (!isWildcard) {
    return (
      <>
        <Switch>{nonWildcardPaths}</Switch>
        <Toast />
      </>
    );
  }

  return (
    <>
      <Layout>
        <Switch>{nonWildcardPaths}</Switch>
      </Layout>

      <Toast />
    </>
  );
}
