import { Route } from 'react-router-dom';
import { routes as appRoutes } from './routes';

export const routes = appRoutes.map((route) => (
  <Route key={route.path} path={route.path} element={route.element}>
    {route.children?.map((childRoute) => (
      <Route key={childRoute.path} path={childRoute.path} element={childRoute.element} />
    ))}
  </Route>
));
