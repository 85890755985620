import { useEffect, useState } from 'react';
import classes from './ReportsPage.module.scss';
import ExportDataModal from 'modules/reports/components/ExportDataModal/ExportDataModal';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getAllReportTemplates } from 'modules/reports/redux/reportTemplateActions';
import { type ReportTemplate } from 'modules/reports/model/exportDataModels';
import { checkboxFields } from 'modules/reports/utils';
import ExportData from 'modules/reports/components/ExportData/ExportData';
import { Spinner } from 'components/core';
import { RequestState } from 'config/constants';
import { useToolbarTitle } from 'hooks';

export type ExportData = {
  label: string;
  checked: boolean;
  id: number;
  group: string;
  value?: string;
  templateValue?: string;
};

const ReportsPage = () => {
  const [checkBoxFields, setCheckBoxFields] = useState<ExportData[]>(checkboxFields);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<number>();

  const reportTemplates = useAppSelector((state) => state.reportTemplates.reportTemplates);
  const { loading } = useAppSelector((state) => state.reportTemplates);

  const dispatch = useAppDispatch();

  useToolbarTitle({ title: ['Reports'] });

  const toggleModalHandler = (isOpen?: boolean) => {
    setSelectedTemplate(undefined);
    if (isOpen !== undefined) return setIsModalOpen(isOpen);

    setIsModalOpen((prevState) => !prevState);
  };

  const selectTemplateHandler = (isModalOpen: boolean, templateId?: number) => {
    setSelectedTemplate(templateId);
    setIsModalOpen(isModalOpen);
  };

  const findSelectedTemplateReport = (): ReportTemplate | undefined => {
    if (selectedTemplate) {
      return reportTemplates.find((template) => template.id === selectedTemplate);
    }
  };

  useEffect(() => {
    dispatch(getAllReportTemplates());
  }, [dispatch]);

  return (
    <div className={classes['c-reports-page']}>
      {loading === RequestState.PENDING ? (
        <Spinner />
      ) : (
        <ExportData
          reportTemplates={reportTemplates}
          selectTemplateHandler={selectTemplateHandler}
          selectedTemplate={selectedTemplate}
          checkboxFields={checkBoxFields}
          toggleModal={toggleModalHandler}
        />
      )}
      <ExportDataModal
        checkBoxFields={checkBoxFields}
        setCheckBoxFields={setCheckBoxFields}
        templateId={selectedTemplate}
        opened={isModalOpen}
        toggleModal={toggleModalHandler}
        findSelectedTemplateReport={findSelectedTemplateReport}
        setSelectedTemplate={setSelectedTemplate}
      />
    </div>
  );
};

export default ReportsPage;
