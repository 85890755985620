import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { ColorLegendItem } from '../models';
import { RequestState } from 'config/constants';
import { getTimeAwayTypes } from './colorLegendActions';
import type { State } from 'state/store';
import { mapTimeAwayTypesToColorLegendItems } from '../utils';

type ColorLegendState = {
  colorLegendItems: ColorLegendItem[];
  loading: RequestState;
};

const initialState: ColorLegendState = {
  colorLegendItems: [],
  loading: RequestState.IDLE,
};

export const colorLegendSlice = createSlice({
  name: 'colorLegend',
  initialState,
  reducers: {
    setColorLegendItems: (state, action: PayloadAction<ColorLegendItem[]>) => {
      state.colorLegendItems = action.payload;
    },
    toggleColorLegendItem: (state, action: PayloadAction<ColorLegendItem>) => {
      state.colorLegendItems = state.colorLegendItems.map((colorLegendItem) => {
        if (colorLegendItem.label === action.payload.label) {
          return {
            ...colorLegendItem,
            isSelected: !colorLegendItem.isSelected,
          };
        }
        return colorLegendItem;
      });
    },
    resetSelectedItems: (state) => {
      state.colorLegendItems = state.colorLegendItems.map((colorLegendItem) => ({
        ...colorLegendItem,
        isSelected: false,
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimeAwayTypes.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getTimeAwayTypes.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.colorLegendItems = mapTimeAwayTypesToColorLegendItems(action.payload ?? []);
      })
      .addCase(getTimeAwayTypes.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { setColorLegendItems, toggleColorLegendItem, resetSelectedItems } =
  colorLegendSlice.actions;

export const selectColorLegend = (state: State) => state.colorLegend;

export default colorLegendSlice.reducer;
