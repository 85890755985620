import { type ChangeEvent, type KeyboardEvent, useEffect, useState } from 'react';
import classes from './InputContainer.module.scss';
import VisibilityButton from '../VisibilityButton/VisibilityButton';
import { ReactComponent as Invalid } from 'assets/InvalidData.svg';
import { ErrorMessage } from 'components/core';

interface InputContainerProps {
  label: string;
  name?: string;
  type?: string;
  value?: string;
  inputMode?: 'text' | 'email' | 'numeric';
  autoFocus?: boolean;
  pattern?: string;
  required?: boolean;
  invalidMessage?: string;
  handleInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputValidity?: boolean;
  setFormValidity?: (validity: boolean) => void;
  isFormSubmitted: boolean;
  handleOnKeyPressed?: () => void;
  children?: JSX.Element;
}

const InputContainer = ({
  label,
  name,
  type = 'text',
  inputMode,
  autoFocus,
  pattern,
  required = false,
  invalidMessage,
  handleInput,
  inputValidity,
  setFormValidity,
  isFormSubmitted,
  handleOnKeyPressed,
  children,
  value,
}: InputContainerProps) => {
  const [input, setInput] = useState<string>('');
  const [isOnBlur, setIsOnBlur] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    checkValidity(event.target.validity.valid);

    handleInput?.(event);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    handleOnKeyPressed && event.key === 'Enter' && handleOnKeyPressed();
  };

  const checkValidity = (inputValidity: boolean) => {
    setFormValidity?.(inputValidity);
  };

  const handleOnBlur = () => {
    input !== '' && setIsOnBlur(true);
  };

  const isInputInvalid = !inputValidity && (isOnBlur || isFormSubmitted);

  useEffect(() => {
    setInput(value || '');
  }, [value]);

  return (
    <div className={classes['c-input-container']}>
      {!children ? (
        <div className={classes['c-input-container__input-wrap']}>
          <input
            className={`${classes['c-input-container__input-field']}  ${
              isInputInvalid ? classes['c-input-container__input-field--invalid'] : ''
            }`}
            type={isPasswordVisible ? 'text' : type}
            name={name}
            value={input}
            required={required}
            inputMode={inputMode}
            autoFocus={autoFocus}
            pattern={pattern}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            placeholder={label}
          />
          {isInputInvalid ? (
            <div className={classes['c-input-container__password-visibility']}>
              <Invalid />
            </div>
          ) : (
            <>
              {type === 'password' && (
                <div
                  className={`${classes['c-input-container__password-visibility']} ${classes['c-input-container__password-visibility--pw']}`}
                >
                  <VisibilityButton
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    visible={isPasswordVisible}
                  />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        children
      )}
      <ErrorMessage invalidMessage={invalidMessage} isInputInvalid={isInputInvalid} />
    </div>
  );
};

export default InputContainer;
