import { Empty } from '../Empty';
import classes from './Error.module.scss';

export const Error = () => {
  const reload = () => window.location.reload();

  return (
    <div className={classes['c-error']}>
      <Empty
        title="Oops, something went wrong."
        info='Our team has been notified, just click "Try again".'
        btnText="Try again"
        handleOnClick={reload}
      />
    </div>
  );
};
