import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { State as AppState } from 'state/store';
import type { OrganizationCalendar } from '../../model';
import { RequestState } from 'config/constants';
import { getAllOrganizationCalendars } from './calendarActions';

type State = {
  calendars: OrganizationCalendar[];
  loading: RequestState;
};

const initialState: State = {
  calendars: [],
  loading: RequestState.IDLE,
};

const ptoPolicySlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCalendars: (state, action: PayloadAction<OrganizationCalendar[]>) => {
      state.calendars = action.payload;
    },
    createCalendar: (state, action: PayloadAction<OrganizationCalendar>) => {
      state.calendars.push(action.payload);
    },
    updateCalendar: (state, action: PayloadAction<OrganizationCalendar>) => {
      const index = state.calendars.findIndex((calendar) => calendar.id === action.payload.id);
      const updatedCalendars = [...state.calendars];
      updatedCalendars[index] = action.payload;

      state.calendars = updatedCalendars;
    },
    deleteCalendar: (state, action: PayloadAction<number>) => {
      state.calendars = [...state.calendars.filter((calendar) => calendar.id !== action.payload)];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrganizationCalendars.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getAllOrganizationCalendars.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        if (action.payload) {
          state.calendars = action.payload;
        }
      })
      .addCase(getAllOrganizationCalendars.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { setCalendars, createCalendar, updateCalendar, deleteCalendar } =
  ptoPolicySlice.actions;

export const selectCalendars = (state: AppState) => state.calendars;

export default ptoPolicySlice.reducer;
