import { z } from 'zod';

export const emailSignUpValidationSchema = z.object({
  email: z.string().email({ message: 'Invalid email format.' }),
});

export type EmailSignUpValidationSchema = z.infer<typeof emailSignUpValidationSchema>;

export const emailSignUpDefaultValues: EmailSignUpValidationSchema = {
  email: '',
};
