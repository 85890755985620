import { ChangeEvent, useCallback, useMemo } from 'react';
import TimePicker from 'react-time-picker';
import { AddMemberDropDown } from 'components/core';
import { formatDate, formatDateForDisplay, getHoursAndMinutesFromSeconds } from 'utils';
import classes from './TimesheetCell.module.scss';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectProjectOptions } from 'modules/timesheet/redux/timesheetsSlice';
import TimeInput from 'components/core/TimeInput/TimeInput';

export type TimesheetCellType = 'text' | 'dropdown' | 'time';

export const tempProjects = [
  { id: 1, value: 'Project 1' },
  { id: 2, value: 'Project 2' },
];

type Props<T> = {
  cellType?: TimesheetCellType;
  value: T;
  name: string;
  timesheetEntryDate?: Date | string;
  isReadonly?: boolean;
  isLastDay?: boolean;
  isTimePickerDisabled?: boolean;
  onCellChange?: (name: string, value: string) => void;
  onProjectChange?: (name: string, value: number | Date) => void;
};

const TimesheetCell = <T,>({
  cellType = 'text',
  value,
  name,
  timesheetEntryDate,
  isReadonly = true,
  isLastDay,
  onCellChange,
  onProjectChange,
  isTimePickerDisabled,
}: Props<T>) => {
  const projectOptions = useAppSelector(selectProjectOptions);

  const selectedProject = useMemo(
    () => projectOptions.find((project) => value === project.id)?.value,
    [projectOptions, value],
  );

  const cellValue = useMemo(() => {
    if (name === 'startDateTime' || name === 'endDateTime')
      return formatDate(value as Date, 'HH:mm');
    if (value instanceof Date) return formatDateForDisplay(value);
    if (cellType === 'dropdown') return selectedProject;

    if (name === 'duration') {
      return getHoursAndMinutesFromSeconds(+value);
    }
    return `${value}`;
  }, [cellType, selectedProject, value, name]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!onCellChange) return;

      const { name, value } = event.target;
      onCellChange(name, value);
    },
    [onCellChange],
  );

  const handleDurationChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!onProjectChange) return;

      const { name, value } = event.target;
      let totalSeconds;

      const [hours, minutes] = value.split(':');

      if (+minutes > 0 && +minutes <= 30) {
        totalSeconds = +hours * 3600 + 30 * 60;
      } else if (+minutes === 0) {
        totalSeconds = +hours * 3600;
      } else {
        totalSeconds = (+hours + 1) * 3600;
      }

      onProjectChange(name, totalSeconds);
    },
    [onProjectChange],
  );

  const handleTimeChange = useCallback(
    (value: string | null) => {
      if (!onProjectChange || !timesheetEntryDate || !value) return;

      const [hours] = value.split(':');
      const newDate = new Date(timesheetEntryDate);
      newDate.setHours(parseInt(hours, 10));
      newDate.setMinutes(0);

      onProjectChange(name, newDate);
    },
    [name, onProjectChange, timesheetEntryDate],
  );

  const renderCell = useCallback(() => {
    if (cellType === 'text' || isReadonly)
      return (
        <input
          className={classes['c-timesheet-cell__cell-container']}
          value={cellValue}
          name={name}
          onChange={handleOnChange}
          readOnly={isReadonly}
        />
      );
    if (cellType === 'dropdown')
      return (
        <AddMemberDropDown
          items={projectOptions}
          setSelectedItemId={(projectId: number) => onProjectChange?.(name, projectId)}
          selectedValue={selectedProject}
          isAddNewDisabled
          isBorderVisible={false}
          readOnly={isReadonly}
        />
      );

    if (name === 'duration') {
      return (
        <TimeInput name={name} value={cellValue} step="1800" onChange={handleDurationChange} />
      );
    }

    return (
      <TimePicker
        value={cellValue}
        onChange={handleTimeChange}
        disabled={isTimePickerDisabled}
        clearIcon={null}
        clockIcon={null}
        format="HH:mm"
      />
    );
  }, [
    cellType,
    cellValue,
    handleDurationChange,
    handleOnChange,
    handleTimeChange,
    isReadonly,
    isTimePickerDisabled,
    name,
    onProjectChange,
    projectOptions,
    selectedProject,
  ]);

  return (
    <td
      className={`${classes['c-timesheet-cell']} ${classes[`c-timesheet-cell--${cellType}`]} ${
        isReadonly ? classes['c-timesheet-cell--readonly'] : ''
      } ${isLastDay ? classes['c-timesheet-cell--last-day'] : ''}`}
    >
      {renderCell()}
    </td>
  );
};

export default TimesheetCell;
