import { toast } from 'components/core';
import { getCompany, getAllOrganizationDocuments, getLocations, getCustomFields } from '../api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getOrganizationDocuments = createAsyncThunk(
  'settings/getOrganizationDocuments',
  async () => {
    try {
      const { data } = await getAllOrganizationDocuments();
      return data;
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  },
);

export const getOrganization = createAsyncThunk(
  'settings/getOrganization',
  async (organizationId: number) => {
    try {
      const { data } = await getCompany(organizationId);
      return data;
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  },
);

export const getAllOrganizationLocations = createAsyncThunk(
  'settings/getAllOrganizationLocations',
  async () => {
    try {
      const { data } = await getLocations();
      return data;
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  },
);

export const getAllOrganizationCustomFields = createAsyncThunk(
  'settings/getAllOrganizationCustomFields',
  async () => {
    try {
      const { data } = await getCustomFields();
      return data;
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  },
);
