import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Project.module.scss';
import {
  Input,
  CancelSaveButtonGroup,
  AddButton,
  ActionConfirmationModal,
  Status,
  toast,
  Empty,
  Badge,
  ArchiveActionButtons,
} from 'components/core';
import { ReactComponent as AddSquareIcon } from 'assets/AddSquareIcon.svg';
import Phase from '../Phase/Phase';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import {
  archiveProject,
  createNewPhase,
  createNewProject,
  deleteProject,
  deleteProjectPhase,
  updatePhaseMembers,
  updateProject,
  updateProjectPhase,
} from 'modules/projects/api/projects.api';
import {
  setProject,
  setProjectName as setProjectNameAction,
  addNewPhase as addNewPhaseAction,
  updatePhaseMembers as updatePhaseMembersAction,
  deletePhase,
  selectProjects,
  setIsEditing,
  setProjects,
  setProjectPhase,
} from 'modules/projects/redux/projectSlice';
import {
  ProjectPhase,
  UpdateProjectPhaseData,
  UserProjectPhase,
} from 'modules/projects/models/phase.model';
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { RequestState } from 'config/constants';
import dayjs from 'dayjs';
import { ComponentType } from 'modules/projects/models';
import { isDataValid } from 'utils/validation';
import { projectPhaseSchema } from 'modules/projects/utils/projectPhaseSchema';
import { formatDateForInput, isDateAfter } from 'utils/dates';
import projectsCover from 'assets/ProjectsCover.svg';
import { getHighestPhaseEndDate, isExistingPhase } from 'modules/projects/utils/project';

type ActionType = 'delete' | 'archive';

type Props = {
  componentType?: ComponentType;
};

const Project = ({ componentType = ComponentType.EXISTING }: Props) => {
  const { project, projectLoading, isEditing } = useAppSelector(selectProjects);

  const [projectName, setProjectName] = useState(project?.name ?? '');
  const [projectPhases, setProjectPhases] = useState<ProjectPhase[]>(project?.projectPhases ?? []);
  const [draggingPhase, setDraggingPhase] = useState<ProjectPhase | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modifiedPhaseIds, setModifiedPhaseIds] = useState<string[]>([]);
  const [modifiedMembersPhaseIds, setModifiedMembersPhaseIds] = useState<string[]>([]);
  const [deletedPhaseIds, setDeletedPhaseIds] = useState<string[]>([]);
  const [actionType, setActionType] = useState<ActionType>('delete');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const areAllPhasesFinished = useMemo(
    () => !projectPhases.some((phase) => isDateAfter(phase.dueDate, new Date())),
    [projectPhases],
  );

  const handleOpenConfirmationModal = (actionType: ActionType) => {
    setActionType(actionType);
    setIsConfirmationModalOpen(true);
  };
  const handleCloseConfirmationModal = () => setIsConfirmationModalOpen(false);

  const sensor = useSensor(PointerSensor);

  const handleEditMode = useCallback(
    (isEditing: boolean) => dispatch(setIsEditing(isEditing)),
    [dispatch],
  );

  const removePhaseFromModifiedPhaseIds = (id: number | string) => {
    setModifiedPhaseIds((prev) => [...prev.filter((phaseId) => String(phaseId) !== String(id))]);
  };

  const handleResetModifiedPhaseIds = () => setModifiedPhaseIds([]);

  const handlePhaseInputsUpdate = useCallback(
    (updatedPhase: ProjectPhase) => {
      const foundPhase = project?.projectPhases.find((phase) => phase.id === updatedPhase.id);

      if (
        foundPhase?.name === updatedPhase.name &&
        foundPhase?.description === updatedPhase.description &&
        dayjs(foundPhase?.startDate).isSame(updatedPhase.startDate, 'date') &&
        dayjs(foundPhase?.dueDate).isSame(updatedPhase.dueDate, 'date') &&
        modifiedPhaseIds.includes(String(updatedPhase.id))
      ) {
        removePhaseFromModifiedPhaseIds(updatedPhase.id);
      }

      setProjectPhases((prev) => {
        const index = prev.findIndex((phase) => phase.id === updatedPhase.id);
        const updatedPhases = [...prev];

        updatedPhases[index] = updatedPhase;
        return updatedPhases;
      });

      if (isExistingPhase(updatedPhase.id) && !modifiedPhaseIds.includes(String(updatedPhase.id))) {
        setModifiedPhaseIds((prev) => [...new Set([...prev, String(updatedPhase.id)])]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project, projectPhases],
  );

  const handlePhaseMembers = useCallback(
    (phaseId: string, userIds: number[], userProjectPhases?: UserProjectPhase[]) => {
      setProjectPhases((prev) => [
        ...prev.map((phase) => {
          if (String(phase.id) === phaseId) {
            return { ...phase, userIds, userProjectPhases };
          }
          return { ...phase };
        }),
      ]);

      if (isExistingPhase(phaseId) && !modifiedMembersPhaseIds.includes(phaseId)) {
        setModifiedMembersPhaseIds((prev) => [...prev, phaseId]);
      }
    },
    [modifiedMembersPhaseIds],
  );

  const handleCreateNewProject = async () => {
    setIsLoading(true);

    try {
      const { data } = await createNewProject({ name: projectName, projectPhases });
      dispatch(setProject(data));
      toast('success', `You have successfully created project "${data.name}".`);
      navigate(`/projects/${data.id}`, { replace: true });
    } catch {
      toast('error', 'Something went wrong while creating new project. Try again.');
    } finally {
      setIsLoading(false);
      handleEditMode(false);
    }
  };

  const handleUpdateProject = async () => {
    setIsLoading(true);

    if (!project?.id) return;

    try {
      const { data } = await updateProject({
        id: project.id,
        name: projectName,
        projectPhases: project?.projectPhases ?? [],
        archived: project.archived,
      });
      dispatch(setProjectNameAction(data.name));
      toast('success', 'You have successfully updated the project.');
      handleEditMode(false);
    } catch {
      toast('error', 'Something went wrong while updating project. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleArchiveProject = useCallback(async () => {
    if (!project?.id) return;

    if (!areAllPhasesFinished) {
      toast('warning', 'All phases must be finished before you archive project.');
      handleCloseConfirmationModal();
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await archiveProject(project.id);
      dispatch(setProjects(data));
      toast('success', 'You have successfully archived project.');
      navigate('/projects');
    } catch {
      toast('error', 'Something went wrong while archiving project. Please try again.');
    } finally {
      setIsLoading(false);
      handleCloseConfirmationModal();
    }
  }, [areAllPhasesFinished, dispatch, navigate, project?.id]);

  const handleDeleteProject = async () => {
    if (projectPhases.length && !project?.archived) {
      toast(
        'error',
        'Active projects cannot be deleted if they contain phases. Please archive the project first.',
      );
      setIsConfirmationModalOpen(false);
      return;
    }

    if (!project?.id) return;

    setIsLoading(true);

    try {
      await deleteProject(project.id);
      toast('success', 'You successfully deleted project.');
      navigate('/projects');
    } catch {
      toast('error', 'Something went wrong while deleting project. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePhase = async () => {
    const modifiedPhases = projectPhases.filter((phase) =>
      modifiedPhaseIds.includes(String(phase.id)),
    );

    setIsLoading(true);

    try {
      for (const phase of modifiedPhases) {
        const { id, name, projectId, description, phaseOrder, startDate, dueDate } = phase;

        const updatedPhase: UpdateProjectPhaseData = {
          id,
          projectId,
          name,
          description,
          phaseOrder,
          startDate: formatDateForInput(startDate ?? ''),
          dueDate: formatDateForInput(dueDate ?? ''),
        };
        const { data } = await updateProjectPhase(updatedPhase);
        dispatch(setProjectPhase(data));
      }

      modifiedPhases.length && toast('success', 'You have successfully updated phases.');
      handleEditMode(false);
    } catch {
      toast('error', 'Something went wrong while updating phases. Try again.');
    } finally {
      handleResetModifiedPhaseIds();
      setIsLoading(false);
    }
  };

  const handleRemovePhase = useCallback(
    (phaseId: string) => {
      let phaseToDeleteUserIds: number[] | undefined = [];

      if (isExistingPhase(phaseId)) {
        phaseToDeleteUserIds = project?.projectPhases.find((phase) => String(phase.id) === phaseId)
          ?.userIds;
      } else {
        phaseToDeleteUserIds = projectPhases.find((phase) => String(phase.id) === phaseId)?.userIds;
      }

      if (phaseToDeleteUserIds?.length) {
        toast('error', "Project phases with assigned phase members can't be deleted");
        return;
      }

      if (projectPhases.length === 1) {
        toast('error', 'Project must have at least one phase.');
        return;
      }

      const index = projectPhases.findIndex((phase) => String(phase.id) === phaseId);

      setProjectPhases((prev) => {
        const updatedPhases = [...prev];
        updatedPhases.splice(index, 1);

        return updatedPhases;
      });

      const phaseToRemove = projectPhases[index];

      if (!isExistingPhase(phaseToRemove.id)) return;

      setDeletedPhaseIds((prev) => [...prev, String(phaseToRemove.id)]);
    },
    [project, projectPhases],
  );

  const handleNewPhase = () => {
    const initialPhase: ProjectPhase = {
      id: `${crypto.randomUUID()}-temp`,
      name: '',
      description: '',
      startDate: formatDateForInput(getHighestPhaseEndDate(projectPhases)),
      dueDate: formatDateForInput(getHighestPhaseEndDate(projectPhases)),
      projectId: project?.id,
      phaseOrder: projectPhases.length,
      userIds: [],
    };

    setProjectPhases((prev) => [...prev, initialPhase]);
  };

  const handleCancel = () => {
    if (componentType === ComponentType.NEW) {
      navigate('/projects');
    } else {
      setProjectName(project?.name ?? '');
      setProjectPhases(project?.projectPhases ?? []);
    }

    handleResetModifiedPhaseIds();
    setModifiedMembersPhaseIds([]);
    setDeletedPhaseIds([]);
    handleEditMode(false);
  };

  const handleUpdatePhaseMembers = async () => {
    if (!project?.id) return;

    setIsLoading(true);

    try {
      for (const phaseId of modifiedMembersPhaseIds) {
        const updatedUserIds =
          projectPhases.find((phase) => String(phase.id) === phaseId)?.userIds || [];

        const { data } = await updatePhaseMembers(project.id, phaseId, updatedUserIds);

        dispatch(
          updatePhaseMembersAction({
            phaseId: Number(data.id),
            userIds: data.userIds,
            userProjectPhases: data.userProjectPhases,
          }),
        );
      }
      toast('success', 'You have successfully updated phase members.');
    } catch {
      toast('error', 'Something went wrong while updating phase members. Try again.');
    } finally {
      setIsLoading(false);
      handleEditMode(false);
      setModifiedMembersPhaseIds([]);
    }
  };

  const handleDeletePhase = async () => {
    if (!project?.id) return;

    setIsLoading(true);

    try {
      for (const phaseId of deletedPhaseIds) {
        await deleteProjectPhase(phaseId, project.id);
        dispatch(deletePhase(Number(phaseId)));
      }
      toast('success', 'You successfully deleted phase.');
    } catch {
      toast('error', 'Something went wrong while deleting phase. Try again.');
    } finally {
      setIsLoading(false);
      handleEditMode(false);
      setDeletedPhaseIds([]);
    }
  };

  const handleCreateNewPhase = async () => {
    const newPhases = projectPhases.filter((phase) => !isExistingPhase(phase.id));

    setIsLoading(true);

    try {
      for (const phase of newPhases) {
        if (!isDataValid(projectPhaseSchema, phase)) return;

        const { data } = await createNewPhase({ ...phase, projectId: project?.id });
        dispatch(addNewPhaseAction(data));
      }
      toast('success', 'You successfully added new phases.');
      handleEditMode(false);
    } catch {
      toast('error', 'Something went wrong while creating new phase. Try again.');
    } finally {
      setIsLoading(false);
      handleResetModifiedPhaseIds();
    }
  };

  const handleSave = useCallback(() => {
    if (componentType === ComponentType.NEW) {
      handleCreateNewProject();
    }
    if (modifiedMembersPhaseIds.length) {
      handleUpdatePhaseMembers();
    }

    if (project?.id && projectPhases.find((phase) => !isExistingPhase(phase.id))) {
      handleCreateNewPhase();
    }
    if (modifiedPhaseIds.length) {
      handleUpdatePhase();
    }

    if (deletedPhaseIds.length) {
      handleDeletePhase();
    }

    if (project?.name !== projectName) {
      handleUpdateProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    componentType,
    deletedPhaseIds.length,
    modifiedMembersPhaseIds.length,
    modifiedPhaseIds.length,
    project?.id,
    project?.name,
    projectName,
    projectPhases,
  ]);

  const handleDragStartPhase = useCallback(
    (event: DragStartEvent) => {
      setDraggingPhase(projectPhases.find((phase) => phase.id === event.active.id) ?? null);
    },
    [projectPhases],
  );

  const handleDragEndPhase = useCallback(
    (event: DragEndEvent) => {
      const { id } = event.active;
      setDraggingPhase(null);

      if (isExistingPhase(id) && !modifiedPhaseIds.includes(String(id))) {
        setModifiedPhaseIds((prev) => [...prev, String(id)]);
      }
    },
    [modifiedPhaseIds],
  );

  const handleDragOverPhase = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;

      if (active.id === over?.id) return;

      setProjectPhases(() => {
        const oldIndex = projectPhases.findIndex((item) => item.id === active.id);
        const newIndex = projectPhases.findIndex((item) => item.id === over?.id);

        const updatedProjectPhases = arrayMove(projectPhases, oldIndex, newIndex).map(
          (phase, index) => ({
            ...phase,
            phaseOrder: index + 1,
          }),
        );

        return updatedProjectPhases.sort((a, b) => a.phaseOrder - b.phaseOrder);
      });
    },
    [projectPhases],
  );

  const isSaveButtonDisabled = useMemo(
    () =>
      projectName === project?.name &&
      !modifiedMembersPhaseIds.length &&
      !modifiedPhaseIds.length &&
      !deletedPhaseIds.length &&
      !projectPhases.filter((phase) => !isExistingPhase(phase.id)).length,
    [
      projectName,
      project,
      modifiedMembersPhaseIds,
      modifiedPhaseIds,
      deletedPhaseIds,
      projectPhases,
    ],
  );

  const sortedProjectPhases = useMemo(
    () => [...projectPhases].sort((a, b) => a.phaseOrder - b.phaseOrder),
    [projectPhases],
  );

  const sortingItems = [...projectPhases].map((phase) => ({
    ...phase,
    id: phase.id,
  }));

  const renderHeaderActions = useCallback(() => {
    if (project?.archived)
      return (
        <div className={classes['c-project__header-buttons']}>
          <Badge size="medium" color="blue">
            ARCHIVED
          </Badge>
          <ArchiveActionButtons
            handleConfirmationModal={handleOpenConfirmationModal}
            isArchiveExist={false}
            isDeleteExist={true}
          />
        </div>
      );

    if (isEditing)
      return (
        <CancelSaveButtonGroup
          onCancel={handleCancel}
          onSave={handleSave}
          disabled={isSaveButtonDisabled}
          isLoading={isLoading}
        />
      );

    return (
      <ArchiveActionButtons
        isArchiveDisabled={!areAllPhasesFinished}
        tooltipContent="You can archive project only when all phases are finished"
        handleEdit={handleEditMode}
        handleConfirmationModal={handleOpenConfirmationModal}
        isArchiveExist={true}
        isDeleteExist={false}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areAllPhasesFinished,
    handleEditMode,
    handleSave,
    isEditing,
    isLoading,
    isSaveButtonDisabled,
    project?.archived,
  ]);

  const renderPhase = useCallback(
    (phase: ProjectPhase) => (
      <Phase
        phase={phase}
        isEditMode={isEditing}
        users={phase.userProjectPhases}
        onPhaseChange={handlePhaseInputsUpdate}
        handlePhaseMembers={handlePhaseMembers}
        handleRemovePhase={handleRemovePhase}
      />
    ),
    [isEditing, handlePhaseInputsUpdate, handlePhaseMembers, handleRemovePhase],
  );

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
    setProjectPhases(project.projectPhases);
  }, [project]);

  const confirmationModalHandler =
    actionType === 'delete' ? handleDeleteProject : handleArchiveProject;

  return (
    <Status isLoading={projectLoading === RequestState.PENDING}>
      <div className={classes['c-project']}>
        <div className={classes['c-project__header']}>
          <div className={classes['c-project__input-container']}>
            <Input
              name="projectName"
              id="projectName"
              placeholder="enter project name"
              size="medium"
              value={projectName}
              readOnly={!isEditing}
              setValue={setProjectName}
            />
          </div>
          {renderHeaderActions()}
        </div>
        <div className={classes['c-project__container']}>
          {!projectPhases.length && !isEditing && (
            <div className={classes['c-project__phases-empty']}>
              <Empty
                title="There are no phases in this project"
                info="Click on the edit button to start adding new phases."
                img={projectsCover}
              />
            </div>
          )}
          <div className={classes['c-project__phases']}>
            {!!sortedProjectPhases.length && (
              <DndContext
                sensors={[sensor]}
                collisionDetection={closestCorners}
                onDragStart={handleDragStartPhase}
                onDragOver={handleDragOverPhase}
                onDragEnd={handleDragEndPhase}
              >
                <SortableContext
                  id={String(project?.id)}
                  items={sortingItems}
                  strategy={verticalListSortingStrategy}
                >
                  {sortedProjectPhases.map((phase) => {
                    return (
                      <div key={phase.id} className={classes['c-project__card']}>
                        {renderPhase(phase)}
                      </div>
                    );
                  })}
                </SortableContext>
                <DragOverlay>{draggingPhase && renderPhase(draggingPhase)}</DragOverlay>
              </DndContext>
            )}
            {isEditing && (
              <div className={classes['c-project__card']}>
                <AddButton
                  label="Add new phase"
                  icon={<AddSquareIcon />}
                  onClick={handleNewPhase}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ActionConfirmationModal
        message={`you want to ${actionType} "${projectName}" project?`}
        isModalOpen={isConfirmationModalOpen}
        isLoading={isLoading}
        handleNo={handleCloseConfirmationModal}
        handleYes={confirmationModalHandler}
        closeModal={handleCloseConfirmationModal}
      />
    </Status>
  );
};

export default Project;
