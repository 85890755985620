export const checkboxFields = [
  // USER
  {
    label: 'First name',
    value: 'firstName',
    templateValue: 'first_name',
    checked: false,
    id: 1,
    group: 'User',
  },
  {
    label: 'Last name',
    templateValue: 'last_name',
    value: 'lastName',
    checked: false,
    id: 3,
    group: 'User',
  },
  {
    label: 'Birthday',
    templateValue: 'date_of_birth',
    value: 'dateOfBirth',
    checked: false,
    id: 4,
    group: 'User',
  },
  {
    label: 'Nationality',
    templateValue: 'nationalities',
    value: 'nationalities',
    checked: false,
    id: 5,
    group: 'User',
  },
  {
    label: 'Gender',
    value: 'gender',
    templateValue: 'gender',
    checked: false,
    id: 6,
    group: 'User',
  },
  {
    label: 'Work email',
    value: 'email',
    templateValue: 'email',
    checked: false,
    id: 7,
    group: 'User',
  },
  {
    label: 'Personal email',
    value: 'personalEmail',
    templateValue: 'personal_email',
    checked: false,
    id: 8,
    group: 'User',
  },
  {
    label: 'Personal phone',
    templateValue: 'personal_phone',
    value: 'personalPhone',
    checked: false,
    id: 10,
    group: 'User',
  },
  {
    label: 'Full address',
    templateValue: 'address',
    value: 'address',
    checked: false,
    id: 11,
    group: 'User',
  },
  // EMPLOYMENT ROLE

  {
    label: 'Job title',
    templateValue: 'job_title',
    value: 'jobTitle',
    checked: false,
    id: 18,
    group: 'EmploymentRole',
  },
  {
    label: 'Department',
    templateValue: 'department',
    value: 'department',
    checked: false,
    id: 19,
    group: 'EmploymentRole',
  },
  {
    label: 'Role effective date',
    templateValue: 'role_effective_date',
    value: 'effectiveOn',
    checked: false,
    id: 22,
    group: 'EmploymentRole',
  },

  // EMPLOYMENT
  {
    label: 'Employee ID',
    templateValue: 'employee_id',
    value: 'employeeId',
    checked: false,
    id: 27,
    group: 'Employment',
  },
  {
    label: 'Tax ID',
    templateValue: 'tax_id',
    value: 'taxId',
    checked: false,
    id: 28,
    group: 'Employment',
  },
  {
    label: 'Tax Code',
    templateValue: 'tax_code',
    value: 'taxCode',
    checked: false,
    id: 29,
    group: 'Employment',
  },
  {
    label: 'Employment start',
    value: 'startDate',
    templateValue: 'employment_start_date',
    checked: false,
    id: 30,
    group: 'Employment',
  },
  {
    label: 'First working day',
    templateValue: 'first_working_day',
    value: 'firstWorkingDay',
    checked: false,
    id: 31,
    group: 'Employment',
  },
  {
    label: 'Probation end',
    templateValue: 'provation_ends_at',
    value: 'probationEndsAt',
    checked: false,
    id: 32,
    group: 'Employment',
  },

  // OFFBOARDING
  {
    label: 'Employment end',
    templateValue: 'employment_end',
    value: 'employmentEndDate',
    checked: false,
    id: 33,
    group: 'Offboarding',
  },
  {
    label: 'Last working day',
    templateValue: 'last_working_day',
    value: 'lastWorkingDay',
    checked: false,
    id: 34,
    group: 'Offboarding',
  },
  {
    label: 'Leaving reason',
    templateValue: 'leaving_reason',
    value: 'reason',
    checked: false,
    id: 35,
    group: 'Offboarding',
  },
  {
    label: 'Leaving note',
    templateValue: 'leaving_note',
    value: 'note',
    checked: false,
    id: 36,
    group: 'Offboarding',
  },
  // BANK ACCOUNT
  {
    label: 'Bank name',
    templateValue: 'bank_name',
    value: 'bankName',
    checked: false,
    id: 37,
    group: 'BankAccount',
  },
  {
    label: 'Name on account',
    templateValue: 'account_holder_name',
    value: 'accountHolderName',
    checked: false,
    id: 38,
    group: 'BankAccount',
  },
  {
    label: 'Account number',
    templateValue: 'account_number',
    value: 'accountNumber',
    checked: false,
    id: 39,
    group: 'BankAccount',
  },
  {
    label: 'Swift code',
    templateValue: 'swift_number',
    value: 'swiftNumber',
    checked: false,
    id: 40,
    group: 'BankAccount',
  },
  {
    label: 'Sort code',
    templateValue: 'sort_code',
    value: 'sortCode',
    checked: false,
    id: 41,
    group: 'BankAccount',
  },
  {
    label: 'Routing number',
    templateValue: 'routing_number',
    value: 'routingNumber',
    checked: false,
    id: 42,
    group: 'BankAccount',
  },
  // COMPENSATION
  {
    label: 'Type',
    templateValue: 'compensation_type',
    value: 'compensationType',
    checked: false,
    id: 43,
    group: 'Compensation',
  },
  {
    label: 'Amount',
    templateValue: 'compensation_amount',
    value: 'amount',
    checked: false,
    id: 44,
    group: 'Compensation',
  },
  {
    label: 'Note',
    templateValue: 'compensation_note',
    value: 'note',
    checked: false,
    id: 45,
    group: 'Compensation',
  },
  {
    label: 'Effective on',
    value: 'effectiveOn',
    templateValue: 'effectiveOn',
    checked: false,
    id: 46,
    group: 'Compensation',
  },
  {
    label: 'Currency',
    templateValue: 'currency',
    value: 'currency',
    checked: false,
    id: 57,
    group: 'Compensation',
  },
  {
    label: 'Frequency',
    templateValue: 'frequency',
    value: 'frequency',
    checked: false,
    id: 58,
    group: 'Compensation',
  },
  // TIME OFF
  {
    label: 'Start date',
    templateValue: 'time_off_start_date',
    value: 'startDate',
    checked: false,
    id: 56,
    group: 'TimeAway',
  },
  {
    label: 'Start period',
    templateValue: 'time_away_start_period',
    value: 'timeAwayStartPeriod',
    checked: false,
    id: 59,
    group: 'TimeAway',
  },
  {
    label: 'End date',
    templateValue: 'time_off_end_date',
    value: 'endDate',
    checked: false,
    id: 60,
    group: 'TimeAway',
  },
  {
    label: 'End period',
    value: 'timeAwayEndPeriod',
    templateValue: 'time_away_end_period',
    checked: false,
    id: 61,
    group: 'TimeAway',
  },
  {
    label: 'Note',
    templateValue: 'time_off_note',
    value: 'note',
    checked: false,
    id: 62,
    group: 'TimeAway',
  },
];
