import type {
  CustomFieldSingleChoiceOption,
  CustomFieldValueType,
} from 'modules/settings/models/settingsModels';

export const initialCustomFieldSingleChoiceOption: CustomFieldSingleChoiceOption = {
  id: 1,
  name: '',
};

export const customFieldSections = [
  'PROFILE_BASICS',
  'PROFILE_BANKING',
  'PROFILE_EMPLOYMENT',
  'PROFILE_EQUIPMENT',
] as const;

export const customFieldValueType: Record<CustomFieldValueType, string> = {
  NUMBER: 'Number',
  TEXT_FIELD: 'Text Field',
  SINGLE_CHOICE: 'Single Choice',
};
