import { useCallback, useState } from 'react';
import classes from './ArchiveActionButtons.module.scss';
import { EditButton } from '../EditButton';
import { IconButton } from '../IconButton';
import { ReactComponent as FileIcon } from 'assets/File.svg';
import { DeleteButton } from '../DeleteButton';
import { Tooltip } from '../Tooltip';
import { Container } from '../Container';

type ActionType = 'delete' | 'archive';

type Props = {
  isArchiveDisabled?: boolean;
  isDeleteDisabled?: boolean;
  isArchiveExist?: boolean;
  isDeleteExist?: boolean;
  tooltipContent?: string;
  handleEdit?: (isEditMode: boolean) => void;
  handleConfirmationModal: (value: ActionType) => void;
};

export const ArchiveActionButtons = ({
  isArchiveDisabled,
  isDeleteDisabled,
  isArchiveExist = true,
  isDeleteExist = true,
  tooltipContent,
  handleEdit,
  handleConfirmationModal,
}: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const handleTooltipOpen = () => setIsTooltipOpen(true);
  const handleTooltipClose = () => setIsTooltipOpen(false);

  const renderDeleteButton = useCallback(() => {
    return (
      isDeleteExist && (
        <DeleteButton
          disabled={isDeleteDisabled}
          onClick={() => handleConfirmationModal('delete')}
        />
      )
    );
  }, [handleConfirmationModal, isDeleteDisabled]);

  return (
    <Container>
      {!!handleEdit && <EditButton onClick={() => handleEdit(true)} />}
      {isArchiveExist && (
        <div
          className={classes['c-archive-action-buttons']}
          onMouseEnter={handleTooltipOpen}
          onMouseLeave={handleTooltipClose}
        >
          <IconButton
            aria-label="Archive project"
            disabled={isArchiveDisabled}
            icon={<FileIcon className={classes['c-archive-action-buttons__archive-button']} />}
            onClick={() => handleConfirmationModal('archive')}
          />
          <Tooltip
            isOpen={isTooltipOpen && (isArchiveDisabled || isDeleteDisabled)}
            position="left"
          >
            {tooltipContent}
          </Tooltip>

          {isDeleteDisabled && renderDeleteButton()}
        </div>
      )}
      {!isDeleteDisabled && renderDeleteButton()}
    </Container>
  );
};
