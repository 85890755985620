import { useEffect, useMemo, useCallback } from 'react';
import ManageCompany from './ManageCompany/ManageCompany';
import YourLocations from './YourLocations/YourLocations';
import { useToolbarTitle } from 'hooks';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectSettings } from 'modules/settings/redux/settingsSlice';
import { Status } from 'components/core';
import { RequestState } from 'config/constants';
import { getAllOrganizationLocations } from 'modules/settings/redux/settingsActions';

const Company = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(selectSettings);

  const isContentLoading = useMemo(
    () => loading.company === RequestState.PENDING || loading.locations === RequestState.PENDING,
    [loading.company, loading.locations],
  );
  useToolbarTitle({ title: ['Settings', 'Company'] });

  const getLocations = useCallback(() => {
    dispatch(getAllOrganizationLocations());
  }, [dispatch]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  return (
    <Status isLoading={isContentLoading}>
      <ManageCompany />
      <YourLocations />
    </Status>
  );
};

export default Company;
