import { YesNoButtonGroup } from 'components/core';
import type { PendingTimeAway } from 'modules/timeAwayRequests/models/timeAwayRequestsModel';
import classes from './ModalContent.module.scss';
import dayjs from 'dayjs';

type Props = {
  timeAway: PendingTimeAway;
  isLoading: boolean;
  handleYes: (userId: number, timeAwayId: number, timeAwayTypeId?: number) => void;
  handleNo: () => void;
};

const DATE_FORMAT = 'D MMM YYYY';

export const ModalContent = ({ timeAway, handleYes, handleNo, isLoading }: Props) => {
  const { userProfilePhotoPath, firstName, lastName, userId, id, timeAwayType } = timeAway;

  return (
    <div className={classes['c-modal-content']}>
      <div className={classes['c-modal-content__top']}>
        <img
          className={classes['c-modal-content__image']}
          src={userProfilePhotoPath}
          alt="User profile picture"
        />
        <div className={classes['c-modal-content__right']}>
          <div className={classes['c-modal-content__title']}>
            {firstName} {lastName}
          </div>
          <div className={classes['c-modal-content__date']}>
            From: {dayjs(timeAway.startDate).format(DATE_FORMAT)}
          </div>
          <div className={classes['c-modal-content__date']}>
            To: {dayjs(timeAway.endDate).format(DATE_FORMAT)}
          </div>
        </div>
      </div>
      <div className={classes['c-modal-content__bottom']}>
        <YesNoButtonGroup
          width="half"
          isLoading={isLoading}
          handleNo={handleNo}
          handleYes={() => handleYes(userId, id, timeAwayType.id)}
        />
      </div>
    </div>
  );
};
