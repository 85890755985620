import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Role } from 'config/constants';
import { State } from 'state/store';

export type BasicUser = {
  id?: number;
  profilePhotoPath: string;
  role?: Role;
};

export type ToolbarTitle = {
  title: string[];
  subtitle?: string;
};

export type ToolbarState = {
  filterClicked: boolean;
  inputFieldValue?: string;
  currentUser?: BasicUser;
  toolbarTitle: ToolbarTitle;
};

const initialState: ToolbarState = {
  filterClicked: false,
  toolbarTitle: {
    title: [],
  },
};

const slice = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    initializeState: () => initialState,
    initializeFilterAndSearch: (state) => {
      state.filterClicked = initialState.filterClicked;
      state.inputFieldValue = initialState.inputFieldValue;
    },
    toggleFilters: (state) => {
      state.filterClicked = !state.filterClicked;
    },
    setInputValue: (state, action: PayloadAction<string>) => {
      state.inputFieldValue = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setToolbarTitle: (state, action: PayloadAction<ToolbarTitle>) => {
      state.toolbarTitle = action.payload;
    },
    updateProfilePhoto: (state, action) => {
      state.currentUser = { ...state.currentUser, profilePhotoPath: action.payload };
    },
  },
});

export const selectToolbar = (state: State) => state.toolbar;
export const selectToolbarTitle = (state: State) => state.toolbar.toolbarTitle;
export const selectCurrentUser = (state: State) => state.toolbar.currentUser;
export const selectSearchValue = (state: State) => state.toolbar.inputFieldValue;

export const {
  initializeState,
  initializeFilterAndSearch,
  toggleFilters,
  setInputValue,
  setCurrentUser,
  setToolbarTitle,
  updateProfilePhoto,
} = slice.actions;

export default slice.reducer;
