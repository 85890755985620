import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import type { OrganizationCalendar } from 'modules/shared/model';

export const createOrganizationCalendar = async (calendar: OrganizationCalendar) => {
  const { id, ...newCalendar } = calendar;
  const publicHolidays = newCalendar.publicHolidays.map(({ name, date, enabled }) => ({
    name,
    date,
    enabled,
  }));

  const newCalendarWithPublicHolidays = {
    ...newCalendar,
    publicHolidays,
  };

  return api.post<OrganizationCalendar>(
    `organization/${getOrganizationId()}/calendar`,
    newCalendarWithPublicHolidays,
  );
};

export const updateOrganizationCalendar = async (calendar: OrganizationCalendar) => {
  return api.put<OrganizationCalendar>(
    `organization/${getOrganizationId()}/calendar/${calendar.id}`,
    calendar,
  );
};

export const deleteOrganizationCalendar = async (organizationCalendarId: number) => {
  return api.delete<void>(`organization/${getOrganizationId()}/calendar/${organizationCalendarId}`);
};
