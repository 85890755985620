import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import type {
  WorkflowInstance,
  SectionInstance,
  TaskInstance,
  WorkflowInstanceFilter,
  TaskInstanceRequest,
} from '../model/workflowInstance.model';

export const getWorkflowInstances = async (filter: WorkflowInstanceFilter = 'active') => {
  return api.get<WorkflowInstance[]>(
    `/organization/${getOrganizationId()}/organization-workflow-instance`,
    { params: { filter } },
  );
};

export const getWorkflowInstanceById = async (workflowId: number, id: string) => {
  return api.get<WorkflowInstance>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance/${id}`,
  );
};

export const createWorkflowInstance = (workflowId: number, userId: number) => {
  return api.post<WorkflowInstance>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance`,
    { userId },
  );
};

export const updateSectionInstance = async (
  workflowId: number,
  workflowInstanceId: number,
  section?: SectionInstance,
) => {
  return api.put<SectionInstance>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance/${workflowInstanceId}/workflow-section-instance/${section?.id}`,
    {
      name: section?.name,
      taskInstanceDtos: section?.taskInstanceDtos,
    },
  );
};

export const deleteWorkflowInstance = (workflowId: number, id: number) => {
  return api.delete<void>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance/${id}`,
  );
};

export const archiveWorkflowInstance = async (workflowId: number, workflowInstanceId: number) => {
  return api.patch<WorkflowInstance>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance/${workflowInstanceId}/archive`,
  );
};

export const createTaskInstance = async (
  workflowId: number,
  workflowInstanceId: number,
  workflowSectionInstanceId: number,
  name: string,
) => {
  return api.post<TaskInstance>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance/${workflowInstanceId}/workflow-section-instance/${workflowSectionInstanceId}/task-instance`,
    { name },
  );
};

export const updateTaskInstance = async (
  workflowId: number,
  workflowInstanceId: number,
  task: TaskInstanceRequest,
) => {
  return api.put<TaskInstance>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance/${workflowInstanceId}/workflow-section-instance/${
      task.workflowSectionInstanceId
    }/task-instance/${task.id}`,
    { ...task },
  );
};

export const assignUserToTask = async (
  workflowId: number,
  workflowInstanceId: number,
  workflowSectionInstanceId: number,
  taskId: number,
  assignedUserId: number,
) => {
  return api.patch<TaskInstance>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance/${workflowInstanceId}/workflow-section-instance/${workflowSectionInstanceId}/task-instance/${taskId}/user/${assignedUserId}`,
  );
};

export const deleteTaskInstance = (
  workflowId: number,
  workflowInstanceId: number,
  workflowSectionInstanceId: number,
  id: number,
) => {
  return api.delete<void>(
    `/organization/${getOrganizationId()}/workflow/${workflowId}/workflow-instance/${workflowInstanceId}/workflow-section-instance/${workflowSectionInstanceId}/task-instance/${id}`,
  );
};
