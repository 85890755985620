import { useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classes from './SidebarItem.module.scss';
import { Tooltip } from 'components/core';
import { NavigationItem } from 'utils/NavigationItems';
import { ReactComponent as DropdownArrowIcon } from 'assets/RightChevronDark.svg';

type Props = NavigationItem & {
  isSidebarCollapsed: boolean;
};

export const SidebarItem = ({ isSidebarCollapsed, path, label, icon, submenu }: Props) => {
  const [isItemHovering, setIsItemHovering] = useState<boolean>(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(false);

  const { pathname } = useLocation();

  const handleItemHovering = (isHovering: boolean) => {
    setIsItemHovering(isHovering);
  };

  const isActive = path && (pathname === path || (pathname.includes(path) && path !== '/'));
  const hasSubmenu = submenu && submenu?.length > 0;

  const toggleSubmenu = () => setIsSubmenuOpen((prevState) => !prevState);

  const renderItemContent = useCallback(() => {
    return (
      <>
        <span
          className={`${classes['c-sidebar-item__container']} ${classes['c-sidebar-item__container--collapsed']}`}
        >
          <span className={classes['c-sidebar-item__icon']}>{icon}</span>
          {!isSidebarCollapsed && <span className={classes['c-sidebar-item__label']}>{label}</span>}
        </span>
        <Tooltip position="right" isOpen={isSidebarCollapsed && isItemHovering}>
          {label}
        </Tooltip>
      </>
    );
  }, [icon, isItemHovering, isSidebarCollapsed, label]);

  if (!hasSubmenu && path)
    return (
      <div className={classes['c-sidebar-item__wrap']}>
        <NavLink
          className={`
          ${classes['c-sidebar-item']} 
          ${isActive && !submenu ? classes['c-sidebar-item--selected'] : ''} 
          ${isSidebarCollapsed ? classes['c-sidebar-item--collapsed'] : ''}
        `}
          onMouseEnter={() => handleItemHovering(true)}
          onMouseLeave={() => handleItemHovering(false)}
          to={path}
        >
          {renderItemContent()}
        </NavLink>
      </div>
    );

  return (
    <div
      className={`${classes['c-sidebar-item__wrap']} ${
        isSubmenuOpen ? classes['c-sidebar-item__wrap--submenu'] : ''
      }`}
    >
      <button
        className={`${classes['c-sidebar-item']} ${
          isSidebarCollapsed ? classes['c-sidebar-item--collapsed'] : ''
        }`}
        onMouseEnter={() => handleItemHovering(true)}
        onMouseLeave={() => handleItemHovering(false)}
        onClick={toggleSubmenu}
      >
        <>{renderItemContent()}</>
        {!isSidebarCollapsed && (
          <DropdownArrowIcon
            className={`${classes['c-sidebar-item__expand-button']} ${
              isSubmenuOpen ? classes['c-sidebar-item__expand-button--expanded'] : ''
            } ${isItemHovering ? classes['c-sidebar-item__expand-button--hover'] : ''}`}
          />
        )}
      </button>
      {isSubmenuOpen && (
        <ul
          className={`${classes['c-sidebar-item__submenu']} ${
            isSidebarCollapsed ? classes['c-sidebar-item__submenu--collapsed'] : ''
          }`}
        >
          {submenu?.map(({ label, path, icon }) => (
            <li key={path}>
              <SidebarItem
                path={path}
                label={label}
                icon={icon}
                isSidebarCollapsed={isSidebarCollapsed}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
