import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'components/core';
import { getAllProjectsByUser } from 'modules/projects/api/projects.api';
import { getTimesheet, getTimesheetEntries, getTimesheets } from '../api/timesheet.api';
import { TimesheetsFilters } from '../models/timesheet.models';

export const getUserTimesheet = createAsyncThunk(
  'timesheetSlice/getUserTimesheet',
  async (payload: { id: number }) => {
    const { id } = payload;
    const { data } = await getTimesheet(id);
    return data;
  },
);

export const getAllTimesheets = createAsyncThunk(
  'timesheetSlice/getAllTimesheets',
  async (payload?: { timesheetFilters: TimesheetsFilters }) => {
    try {
      const response = await getTimesheets(payload?.timesheetFilters);
      return response.data;
    } catch {
      toast('error', 'Something went wrong while fetching timesheets. Try again.');
    }
  },
);

export const getTimesheetProjects = createAsyncThunk(
  'timesheetSlice/getTimesheetProjects',
  async (payload: { userId: number }) => {
    try {
      const { data } = await getAllProjectsByUser(payload.userId);
      return [...data].map((project) => ({ id: project.id, value: project.name }));
    } catch {
      toast('error', 'Something went wrong while fetching projects. Try again.');
    }
  },
);

export const getAllTimesheetEntries = createAsyncThunk(
  'timesheetSlice/getAllTimesheetEntries',
  async (timesheetId: number) => {
    try {
      const { data } = await getTimesheetEntries(timesheetId);
      return data;
    } catch {
      toast('error', 'Something went wrong. Try again.');
    }
  },
);
