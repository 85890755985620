import { useState, type ComponentProps, type ReactNode } from 'react';
import classes from './IconButton.module.scss';
import { Tooltip } from '../Tooltip';

export type TooltipPosition = 'left' | 'right' | 'top' | 'bottom';
export type IconButtonType =
  | 'primary'
  | 'secondary'
  | 'filter-close'
  | 'warning'
  | 'delete'
  | 'close';
export type IconSize = 'medium' | 'large';

type Props = {
  icon: ReactNode;
  tooltipContent?: string;
  tooltipPosition?: TooltipPosition;
  iconButtonType?: IconButtonType;
  isSelected?: boolean;
  iconSize?: IconSize;
} & ComponentProps<'button'>;

export const IconButton = ({
  tooltipContent,
  icon,
  tooltipPosition = 'top',
  iconButtonType = 'primary',
  isSelected = false,
  iconSize,
  ...props
}: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleTooltipOpen = () => setIsTooltipOpen((isOpen) => !isOpen);

  const classNames = `${classes['c-icon-button']}
    ${iconSize && classes[`c-icon-button--${iconSize}`]}
    ${classes[`c-icon-button--${iconButtonType}`]}
    ${isSelected && classes[`c-icon-button--${iconButtonType}-selected`]}
    `;

  return (
    <div className={tooltipContent ? classes['c-icon-button-wrapper'] : ''}>
      <button
        {...props}
        className={classNames}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipOpen}
      >
        {icon}
      </button>
      {tooltipContent && (
        <Tooltip isOpen={isTooltipOpen} position={tooltipPosition}>
          {tooltipContent}
        </Tooltip>
      )}
    </div>
  );
};
