import { AxiosError, isAxiosError } from 'axios';

type ResponseData = {
  date: string;
  error: string;
  message: string;
  path: string;
  status: number;
};

type ErrorData = Pick<ResponseData, 'message' | 'status'>;

export const mapErrorToErrorData = (error: unknown): ErrorData | null => {
  if (!isAxiosError(error)) return null;

  const axiosError = error as AxiosError<ResponseData>;

  const status = axiosError.response?.status ?? 500;
  const message = axiosError.response?.data.message ?? 'Something went wrong';

  return { status, message };
};
