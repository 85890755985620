import { getMinutesDifference } from 'utils';
import { TimesheetWorkingHours } from '../models/timesheet.models';

export const getTotalHours = (timesheetHours: TimesheetWorkingHours[]) => {
  if (!timesheetHours.length) return 0;

  let total = 0;

  timesheetHours.forEach(({ startDateTime, endDateTime }) => {
    total += getMinutesDifference(new Date(startDateTime), new Date(endDateTime));
  });
  return total || 0;
};

export const getRegularHours = (timesheetHours: TimesheetWorkingHours[]) => {
  if (!timesheetHours.length) return 0;
  const totalHours = getTotalHours(timesheetHours);
  return totalHours <= 8 ? totalHours : 8;
};

export const getOvertimeHours = (timesheetHours: TimesheetWorkingHours[]) => {
  if (!timesheetHours.length) return 0;

  const totalHours = getTotalHours(timesheetHours);

  return totalHours <= 8 ? 0 : +totalHours - 8;
};

export const getDateTime = (value: string, date: Date) => {
  const [hours] = value.split(':');
  const newDate = new Date(date);
  newDate.setHours(+hours);
  newDate.setMinutes(0);

  return newDate;
};
