import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import type { CompanyModel } from 'modules/settings/models/settingsModels';

export const getCompany = async (organizationId: number) => {
  return api.get<CompanyModel>(`/organization/${organizationId}`);
};

export const updateCompany = async (name: string) => {
  return api.patch<CompanyModel>(`/organization/${getOrganizationId()}`, { name });
};

export const uploadCompanyLogo = async (file: File) => {
  const formData = new FormData();

  formData.append('name', file.name);
  formData.append('type', file.type);
  formData.append('file', file);

  return api.post(`/organization/${getOrganizationId()}/logo`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
