import { useCallback, useEffect, useState } from 'react';
import { PopUpModal, Input, Button, toast } from 'components/core';
import { ExportData } from 'pages/ReportsPage/ReportsPage';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { createReportTemplate } from '../../api/reports.api';
import { ReportTemplate } from '../../model/exportDataModels';
import { addReportTemplate } from '../../redux/reportTemplateSlice';
import classes from './SaveTemplateModal.module.scss';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  checkBoxFields: ExportData[];
};

const SaveTemplateModal = ({ isModalOpen, setIsModalOpen, checkBoxFields }: Props) => {
  const dispatch = useAppDispatch();

  const [newTemplate, setNewTemplate] = useState<ReportTemplate>({
    name: '',
    description: '',
    inputFields: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewTemplate({ id: 0, name: '', description: '', inputFields: '' });
  };

  const handleNewTemplateInputs = useCallback((value: string, prop: string) => {
    setNewTemplate((prevNewTemplate) => ({
      ...prevNewTemplate,
      [prop]: value,
    }));
  }, []);

  const getTemplateFromObjectToJSONString = useCallback(() => {
    const templateObj = {};

    checkBoxFields.forEach((checkbox) => {
      if (checkbox.checked) {
        Object.assign(templateObj, {
          [checkbox?.templateValue || '']: checkbox.label,
        });
      }
    });
    return JSON.stringify(templateObj);
  }, [checkBoxFields]);

  const saveTemplate = useCallback(async () => {
    const stringifiedTemplate = getTemplateFromObjectToJSONString();

    setIsLoading(true);

    try {
      const response = await createReportTemplate(newTemplate, stringifiedTemplate);
      dispatch(addReportTemplate(response.data));
      setNewTemplate({ id: 0, name: '', description: '', inputFields: '' });
      setIsModalOpen(false);
      toast('success', 'You have successfully saved template.');
    } catch {
      toast('error', 'Something went wrong while saving template. Try again');
    } finally {
      setIsLoading(false);
    }
  }, [newTemplate, dispatch, getTemplateFromObjectToJSONString, setIsModalOpen]);

  const handleSaveTemplate = useCallback(() => {
    setIsFormSubmitted(true);

    if (!newTemplate.name) return;

    saveTemplate();
  }, [newTemplate, saveTemplate]);

  useEffect(() => {
    setIsFormSubmitted(false);
  }, [isModalOpen]);

  return (
    <PopUpModal
      title="Save Custom Export Template"
      opened={isModalOpen}
      closeModal={handleCloseModal}
      width={30}
    >
      <div className={classes['c-save-template-modal']}>
        <Input
          label="Template Name"
          name="templateName"
          id="templateName"
          handleOnChange={(event) => {
            handleNewTemplateInputs(event.target.value, 'name');
          }}
          autoFocus
          isSubmitted={isFormSubmitted}
        />
        <Input
          label="Template Description"
          name="templateDescription"
          id="templateDescription"
          value={newTemplate.description}
          handleOnChange={(event) => {
            handleNewTemplateInputs(event.target.value, 'description');
          }}
        />
        <div className={classes['c-save-template-modal__save-button']}>
          <Button isLoading={isLoading} onClick={handleSaveTemplate}>
            Save template
          </Button>
        </div>
      </div>
    </PopUpModal>
  );
};

export default SaveTemplateModal;
