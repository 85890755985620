import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'components/core';
import { getOrganizationCalendars } from 'modules/shared/api/calendar.api';

export const getAllOrganizationCalendars = createAsyncThunk(
  'calendar/getAllOrganizationCalendars',
  async () => {
    try {
      const { data } = await getOrganizationCalendars();
      return data;
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  },
);
