import Approvals from './Approvals/Approvals';
import PtoPolicy from './PtoPolicy/PtoPolicy';
import Calendar from './Calendar/Calendar';
import TimeAwayType from './TimeAwayType/TimeAwayType';
import { useToolbarTitle } from 'hooks';
import { Status, toast } from 'components/core';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectPtoPolicies } from 'modules/shared/redux/ptoPolicy/ptoPolicySlice';
import { getAllOrganizationCalendars, selectCalendars } from 'modules/shared/redux/calendar';
import { useCallback, useEffect, useState } from 'react';
import { RequestState } from 'config/constants';
import { getOrganizationPtoPolicies } from 'modules/shared/redux/ptoPolicy/ptoPolicyActions';
import { TimeAwayType as TimeAwayTypeModel } from 'modules/shared/model';
import { getAllTimeAwayTypes, getCompanySettings } from 'modules/settings/api';
import { CompanySettings, TimeAwayCategory } from 'modules/settings/models/settingsModels';

const TimeOff = () => {
  const [timeAwayTypes, setTimeAwayTypes] = useState<TimeAwayTypeModel[]>([]);
  const [categoriesApprovals, setCategoriesApprovals] = useState<TimeAwayCategory[]>([]);
  const [isTimeAwayTypeLoading, setIsTimeAwayTypeLoading] = useState<boolean>(false);
  const [isApprovalsContentLoading, setIsApprovalsContentLoading] = useState<boolean>(false);

  const isPtoPolicyLoading = useAppSelector(selectPtoPolicies).loading;
  const isCalendarLoading = useAppSelector(selectCalendars).loading;

  const dispatch = useAppDispatch();

  const isContentLoading =
    isTimeAwayTypeLoading ||
    isApprovalsContentLoading ||
    isPtoPolicyLoading === RequestState.PENDING ||
    isCalendarLoading === RequestState.PENDING;

  useToolbarTitle({ title: ['Settings', 'Time off'] });

  const getTimeAwayTypes = async () => {
    try {
      setIsTimeAwayTypeLoading(true);

      const { data } = await getAllTimeAwayTypes();
      setTimeAwayTypes(data);
    } catch {
      toast('error', 'Something went wrong while getting the data, please try again.');
    } finally {
      setIsTimeAwayTypeLoading(false);
    }
  };

  const handleCategoriesApprovals = (settings: CompanySettings) => {
    setCategoriesApprovals([
      {
        name: 'Working away',
        approvalRequired: settings.workingAwayApprovalRequired,
      },
      { name: 'Time off', approvalRequired: settings.timeOffApprovalRequired },
    ]);
  };

  const getTimeAwayApprovals = useCallback(async () => {
    try {
      setIsApprovalsContentLoading(true);

      const { data } = await getCompanySettings();
      handleCategoriesApprovals(data);
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    } finally {
      setIsApprovalsContentLoading(false);
    }
  }, []);

  useEffect(() => {
    dispatch(getOrganizationPtoPolicies());
    dispatch(getAllOrganizationCalendars());
    getTimeAwayTypes();
    getTimeAwayApprovals();
  }, [dispatch, getTimeAwayApprovals]);

  return (
    <Status isLoading={isContentLoading}>
      <PtoPolicy />
      <Calendar />
      <TimeAwayType timeAwayTypes={timeAwayTypes} setTimeAwayTypes={setTimeAwayTypes} />
      <Approvals
        categoriesApprovals={categoriesApprovals}
        setCategoriesApprovals={setCategoriesApprovals}
        handleCategoriesApprovals={handleCategoriesApprovals}
      />
    </Status>
  );
};

export default TimeOff;
