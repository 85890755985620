import type { ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SideContent from '../SideContent/SideContent';
import classes from './FormContainer.module.scss';

type Props = {
  children: ReactNode;
};

const FormContainer = ({ children }: Props) => {
  const { pathname } = useLocation();

  return (
    <div className={classes['c-form']}>
      <div className={classes['c-form__content-container']}>
        <SideContent />
      </div>
      <div className={classes['c-form__content-container-right']}>
        {pathname === '/' ? (
          <div className={classes['c-form__form-container']}>{children}</div>
        ) : (
          <div className={classes['c-form__form-container']}>
            <Outlet />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormContainer;
