import { api } from 'lib';
import { getOrganizationId } from 'state/utils';

export const getPendingTimeAways = async (id: number) => {
  return api.get(`organization/${getOrganizationId()}/user/${id}/pending-time-away`);
};

export const getTimeAway = async (userId: string, timeAwayTypeId: string, timeAwayId: string) => {
  return api.get(
    `/organization/${getOrganizationId()}/user/${userId}/time-away-type/${timeAwayTypeId}/time-away/${timeAwayId}`,
  );
};

export const updateTimeAwayApproval = async (
  userId: number,
  timeAwayTypeId: number,
  timeAwayId: number,
  timeAwayApproved: boolean,
) => {
  return api.patch(
    `/organization/${getOrganizationId()}/user/${userId}/time-away-type/${timeAwayTypeId}/time-away/${timeAwayId}`,
    {},
    { params: { timeAwayApproved } },
  );
};
