import { AddActiveMemberValidationSchema } from '../validations/AddActiveMemberValidation.schema';
import { useDispatch } from 'react-redux';
import { DirectReports, NewActiveMember, UserBaseInfo } from 'modules/people/model/User';
import { UserType } from 'config/constants';
import { toast } from 'react-toastify';
import { addNewUser } from 'modules/people/redux/peopleSlice';
import { User } from 'modules/profile/models/profileModels';
import { createActiveUser } from 'modules/people/api/people.api';
import { Location } from '../../redux/peoplePopupSlice';
import { mapErrorToErrorData } from 'utils';

export const useAddActiveMember = () => {
  const dispatch = useDispatch();

  const handleAddActiveMember = async (
    formData: AddActiveMemberValidationSchema,
    selectedLocation: Location | undefined,
    userTypeParam: string | null,
    selectedUser?: number,
    reportingToUser?: UserBaseInfo | undefined,
  ) => {
    const newActiveMember: NewActiveMember = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      userStatus: UserType.ACTIVE_MEMBER,
      email: formData.email,
      jobTitle: formData.jobTitle,
      department: formData.department,
      userReportingToId: formData.reportTo ? selectedUser : undefined,
      startDate: formData.startDate,
      firstWorkingDay: formData.firstWorkingDay,
      organizationLocationDto: selectedLocation,
    };

    try {
      const { data } = await createActiveUser(newActiveMember);

      if (userTypeParam !== UserType.NEW_HIRE) {
        const directReports: DirectReports[] = [];
        let reportingToUserReports: DirectReports | undefined;

        if (reportingToUser) {
          const { id, firstName, lastName, jobTitle, profilePhotoPath } = reportingToUser;

          reportingToUserReports = {
            id,
            firstName,
            lastName,
            jobTitle: jobTitle || '',
            profilePicture: profilePhotoPath || '',
          };
        }

        const newMember: User = {
          ...data,
          placeOfWork: `${data.organizationLocationDto.address}-${data.organizationLocationDto.city}`,
          country: data.organizationLocationDto.country,
          effectiveOn: data.firstWorkingDay,
          directReports,
          reportingToUser: reportingToUserReports,
          userType: UserType.ACTIVE_MEMBER,
          newestAddedUser: true,
        };

        dispatch(addNewUser(newMember));
      }

      toast.success('You have successfully added an active member to your staff.');
      return true;
    } catch (error) {
      const errorData = mapErrorToErrorData(error);

      toast.error(
        errorData?.message || 'Something went wrong while adding active member. Try again.',
      );

      return false;
    }
  };

  return { handleAddActiveMember };
};
