import { useState } from 'react';
import { Button, IconButton } from 'components/core';
import classes from './SidebarActions.module.scss';
import { ReactComponent as Clock } from 'assets/AccountClock.svg';
import { ReactComponent as Calendar } from 'assets/CalendarPlus.svg';
import BookTimeAwayModal from 'modules/timeAway/components/BookTimeAwayModal';
import { EnterHoursModal } from 'modules/shared/components';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectFeatureFlags } from 'modules/featureFlags/redux/featureFlagsSlice';

type ModalType = 'time-away' | 'timesheet';
type ActionModal = Record<ModalType, boolean>;

type Props = {
  isSidebarCollapsed: boolean;
};

const SidebarActions = ({ isSidebarCollapsed }: Props) => {
  const featureFlags = useAppSelector(selectFeatureFlags);
  const [actionModal, setActionModal] = useState<ActionModal>({
    'time-away': false,
    timesheet: false,
  });

  const handleModalOpen = (modalType: ModalType, isOpen: boolean) => {
    setActionModal((prevState) => ({ ...prevState, [modalType]: isOpen }));
  };

  const handleTimeAwayModal = (isOpen: boolean) => handleModalOpen('time-away', isOpen);
  const handleTimesheetHoursModal = (isOpen: boolean) => handleModalOpen('timesheet', isOpen);

  const renderActions = () => {
    if (!isSidebarCollapsed)
      return (
        <>
          <Button
            type="menu-item"
            variant="outlined"
            size="large"
            justifyContent="flex-start"
            iconPadding="spacing-xs-plus"
            textAlign="left"
            leftIcon={<Calendar />}
            onClick={() => handleTimeAwayModal(true)}
          >
            <span className={classes['c-sidebar-actions__button-content']}>Book time away</span>
          </Button>

          {featureFlags?.TIMESHEETS && featureFlags?.PROJECTS && (
            <Button
              type="menu-item"
              variant="outlined"
              size="large"
              justifyContent="flex-start"
              iconPadding="spacing-xs-plus"
              textAlign="left"
              leftIcon={<Clock />}
              onClick={() => handleTimesheetHoursModal(true)}
            >
              <span className={classes['c-sidebar-actions__button-content']}>Enter hours</span>
            </Button>
          )}
        </>
      );

    return (
      <div className={classes['c-sidebar-actions__wrapper']}>
        <div className={classes['c-sidebar-actions__button--collapsed']}>
          <IconButton
            icon={<Calendar />}
            aria-label="Book time away"
            onClick={() => handleTimeAwayModal(true)}
            tooltipContent="Book time away"
            tooltipPosition="right"
          />
        </div>
        {featureFlags?.TIMESHEETS && featureFlags?.PROJECTS && (
          <div className={classes['c-sidebar-actions__button--collapsed']}>
            <IconButton
              icon={<Clock />}
              aria-label="Enter timesheet hours"
              onClick={() => handleTimesheetHoursModal(true)}
              tooltipContent="Enter hours"
              tooltipPosition="right"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`${classes['c-sidebar-actions']} ${
        isSidebarCollapsed ? classes['c-sidebar-actions--collapsed'] : ''
      }`}
    >
      {renderActions()}
      {actionModal['time-away'] && (
        <BookTimeAwayModal opened={actionModal['time-away']} openModal={handleTimeAwayModal} />
      )}
      {actionModal['timesheet'] && (
        <EnterHoursModal opened={actionModal['timesheet']} openModal={handleTimesheetHoursModal} />
      )}
    </div>
  );
};

export default SidebarActions;
