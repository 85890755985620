import { ReactComponent as VisibilityOn } from 'assets/VisibilityOn.svg';
import { ReactComponent as VisibilityOff } from 'assets/VisibilityOff.svg';
import classes from './VisibilityButton.module.scss';

interface VisibilityButtonProps {
  visible: boolean;
  onClick: () => void;
}

const VisibilityButton = ({ visible, onClick }: VisibilityButtonProps) => {
  return (
    <button className={classes['c-visibility-button']} onClick={onClick} tabIndex={-1}>
      {visible ? <VisibilityOff /> : <VisibilityOn />}
    </button>
  );
};

export default VisibilityButton;
