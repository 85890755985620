import { type FC, type Dispatch, type SetStateAction } from 'react';
import dayjs from 'dayjs';
import classes from './DayPicker.module.scss';
import { ReactComponent as ArrLeft } from 'assets/ArrowLeft.svg';
import { ReactComponent as ArrRight } from 'assets/ArrowRight.svg';
import { current } from 'components/core/YearPicker/YearPicker';
import { checkIsToday, getAllDatesInMonth } from 'modules/timeline/utils/dates';
import { IconButton } from 'components/core';

type Props = {
  currentDate: Date | 'Today';
  increaseMonthHandler: () => void;
  subtractMonthHandler: () => void;
  setHoveredDay: Dispatch<SetStateAction<number | null>>;
  hoveredDay: number | null;
  selectedMonth: number | 'Today';
  setSelectedMonth: Dispatch<SetStateAction<number | 'Today'>>;
};

export const DayPicker: FC<Props> = ({
  currentDate,
  increaseMonthHandler,
  subtractMonthHandler,
  setHoveredDay,
  hoveredDay,
  selectedMonth,
  setSelectedMonth,
}) => {
  const allDates = getAllDatesInMonth(currentDate);

  const getClassName = (date: Date) => {
    const mainClass = classes['c-day-picker__day'];

    if (date.getTime() === hoveredDay) {
      return `${mainClass} ${classes['c-day-picker__day--hovered']}`;
    }

    if (date.getDay() === 6 || date.getDay() === 0) {
      return `${mainClass} ${classes['c-day-picker__day--weekend']}`;
    }

    return mainClass;
  };

  const prevMonth = () => {
    if (selectedMonth === current) {
      setSelectedMonth(dayjs(new Date()).subtract(1, 'M').toDate().getMonth());
    }
    subtractMonthHandler();
  };

  const nextMonth = () => {
    if (selectedMonth === current) {
      setSelectedMonth(dayjs(new Date()).add(1, 'M').toDate().getMonth());
    }

    increaseMonthHandler();
  };

  const displayDay = (_date: Date, index: number) => {
    if (checkIsToday(_date, selectedMonth))
      return <span className={classes['c-day-picker__current-date']}>{_date.getDate()}</span>;

    if (selectedMonth !== current) return _date.getDate();

    if (index === 0) return _date.getDate();

    if (_date.getDate() === 1)
      return (
        <span className={classes['c-day-picker__month-text']}>{dayjs(_date).format('MMM')}</span>
      );

    return _date.getDate();
  };

  const displayMonth = () => {
    return dayjs(currentDate === current ? new Date() : currentDate).format('MMMM');
  };

  return (
    <div className={classes['c-day-picker']}>
      <span className={classes['c-day-picker__month']}>{displayMonth()}</span>
      <div className={classes['c-day-picker__picker']}>
        <IconButton
          icon={<ArrLeft className={classes['c-day-picker__icon']} />}
          aria-label="Go to previous month"
          onClick={prevMonth}
        />
        <div className={classes['c-day-picker__days']}>
          {[...allDates].map((date, index) => (
            <div
              key={date.getTime()}
              onMouseEnter={() => setHoveredDay(date.getTime())}
              onMouseLeave={() => setHoveredDay(null)}
              className={getClassName(date)}
            >
              {displayDay(date, index)}
            </div>
          ))}
        </div>
        <IconButton
          icon={<ArrRight className={classes['c-day-picker__icon']} />}
          aria-label="Go to next month"
          onClick={nextMonth}
        />
      </div>
    </div>
  );
};
