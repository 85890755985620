import { Button } from '../Button';
import classes from './YesNoButtonGroup.module.scss';

type Width = 'full' | 'medium' | 'half';

type Props = {
  handleYes: () => void;
  handleNo: () => void;
  width?: Width;
  isLoading?: boolean;
};

export const YesNoButtonGroup = ({
  handleYes,
  handleNo,
  width = 'half',
  isLoading = false,
}: Props) => {
  return (
    <div
      className={`${classes['c-yes-no-button-group']} ${
        classes[`c-yes-no-button-group--${width}`]
      }`}
    >
      <Button type="tertiary" size="large" onClick={handleNo}>
        No
      </Button>
      <Button size="large" isLoading={isLoading} onClick={handleYes}>
        Yes
      </Button>
    </div>
  );
};
