import { array, number, object, string } from 'zod';

export const projectPhaseSchema = object({
  name: string().min(1, { message: 'Please enter a phase name.' }),
  description: string().optional(),
  startDate: string().optional(),
  dueDate: string().optional(),
  userIds: array(number()),
  phaseOrder: number(),
});
