import type { SetStateAction, Dispatch, FC, ChangeEvent } from 'react';
import classes from './DocumentUploadModal.module.scss';
import { type Identification } from 'modules/profile/models/profileModels';
import { Input } from '../../Input';
import { DropDown } from '../../DropDown';
import dayjs from 'dayjs';
import { formatDateForInput } from 'utils/dates';

interface IdentificationModalProps {
  inputs: Identification;
  setInputs?: Dispatch<SetStateAction<Identification>>;
  isFormSubmitted?: boolean;
}

export const IdentificationModal: FC<IdentificationModalProps> = ({
  inputs,
  setInputs,
  isFormSubmitted,
}) => {
  const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
    setInputs?.((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div className={classes['c-document-upload-modal']}>
      <div>
        <label className={classes['c-document-upload-modal__dropdown-label']}>
          Identification type
        </label>
        <DropDown
          displayedOptions={['ID card', 'Driving licence', 'Passport', 'Visa']}
          options={['ID_CARD', 'DRIVING_LICENCE', 'PASSPORT', 'VISA']}
          selectedOption={inputs.identificationType}
          setSelectedOption={(option) => {
            setInputs?.((prev) => ({ ...prev, identificationType: option }));
          }}
          isSubmitted={isFormSubmitted}
          size="medium"
        />
      </div>
      <Input
        id="number"
        name="number"
        label="Number"
        value={inputs.number}
        handleOnChange={(event) => {
          handleInputs(event);
        }}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
      <Input
        id="expiryDate"
        name="expiryDate"
        type="date"
        label="Expiry Date"
        value={formatDateForInput(inputs.expiryDate ?? new Date())}
        setValue={(event) => {
          setInputs?.((prev) => ({
            ...prev,
            expiryDate: dayjs(event).format('YYYY-MM-DD'),
          }));
        }}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
      <Input
        id="countyOfIssue"
        name="countryOfIssue"
        label="Country of issue"
        value={inputs.countryOfIssue}
        handleOnChange={(event) => {
          handleInputs(event);
        }}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
      <Input
        id="note"
        name="note"
        label="Note"
        value={inputs?.note || ''}
        handleOnChange={(event) => {
          handleInputs(event);
        }}
        size="medium"
      />
    </div>
  );
};
