import React from 'react';
import { ReactComponent as Filter } from 'assets/Filter.svg';
import classes from './FiltersButton.module.scss';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectToolbar, toggleFilters } from 'components/layout/Toolbar/redux/toolbarSlice';
import { Button } from '../Button';

const FiltersButton = () => {
  const { filterClicked } = useAppSelector(selectToolbar);

  const dispatch = useAppDispatch();

  return (
    <div className={classes['c-filters-button__wrap']}>
      <Button
        variant="text"
        leftIcon={
          <Filter
            className={`${classes['c-filters-button__icon']} ${
              filterClicked ? classes['c-filters-button__icon--active'] : ''
            }`}
          />
        }
        isActive={filterClicked}
        onClick={() => dispatch(toggleFilters())}
      >
        Filters
      </Button>
    </div>
  );
};

export default FiltersButton;
