import { useState } from 'react';
import { createCompensation } from 'modules/profile/api/profile.api';
import { addNewCompensation } from 'modules/profile/redux/userSlice';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import classes from './AddCompensationModal.module.scss';
import dayjs from 'dayjs';
import { Button, DropDown, Input, toast } from 'components/core';

const compensationTypeOptions = ['SALARY', 'BONUS', 'COMMISSION', 'EQUITY'];
const currencyOptions = ['EUR', 'USD', 'GBP', 'RSD'];
const frequencyOptions = [
  'ANNUAL',
  'QUARTERLY',
  'MONTHLY',
  'BI_WEEKLY',
  'WEEKLY',
  'DAILY',
  'HOURLY',
  'FIXED',
];

export type NewCompensation = {
  compensationType: string;
  amount: number;
  currency: string;
  frequency: string;
  note: string;
  effectiveOn: Date | string;
};

type Props = {
  userId: number;
  employmentId: number;
  setIsModalOpen: (value: boolean) => void;
  setOpenEdit: (value: boolean) => void;
};

const AddCompensationModal = ({ userId, employmentId, setIsModalOpen, setOpenEdit }: Props) => {
  const dispatch = useAppDispatch();
  const [newCompensation, setNewCompensation] = useState<NewCompensation>({
    compensationType: 'SALARY',
    amount: 0,
    currency: 'EUR',
    frequency: 'MONTHLY',
    note: '',
    effectiveOn: dayjs().format('YYYY-MM-DD'),
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormChange = (value: string, prop: string) => {
    setNewCompensation((prevNewCompensation) => ({
      ...prevNewCompensation,
      [prop]: value,
    }));
  };

  const isSubmitButtonDisabled =
    !newCompensation.compensationType ||
    !newCompensation.amount ||
    !newCompensation.currency ||
    !newCompensation.frequency ||
    !newCompensation.effectiveOn;

  const handleAddCompensation = () => {
    setIsFormSubmitted(true);
    if (isSubmitButtonDisabled) return;
    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    setIsLoading(true);
    createCompensation(newCompensation, userId, employmentId)
      .then((res) => {
        dispatch(addNewCompensation({ employmentId, newEmployment: res.data }));
        setIsModalOpen(false);
        setOpenEdit(false);
      })
      .catch(() => {
        toast('error', 'Something went wrong. Please try again later.');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={classes['c-add-compensation-modal']}>
      <div className={classes['c-add-compensation-modal__dropdown-holder']}>
        <label htmlFor="compensationType">Compensation type:</label>
        <DropDown
          options={compensationTypeOptions}
          selectedOption={newCompensation.compensationType}
          displayedOptions={compensationTypeOptions.map(option => option.charAt(0).toUpperCase() + option.slice(1).toLowerCase())}
          setSelectedOption={(option) => {
            handleFormChange(option, 'compensationType');
          }}
          isSubmitted={isFormSubmitted}
          size="medium"
        />
      </div>
      <Input
        name="amount"
        id="amount"
        type="number"
        label="Amount:"
        value={newCompensation.amount ? String(newCompensation.amount) : ''}
        setValue={(event) => {
          handleFormChange(event, 'amount');
        }}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
      <div className={classes['c-add-compensation-modal__dropdown-holder']}>
        <label htmlFor="currency">Currency:</label>
        <DropDown
          options={currencyOptions}
          selectedOption={newCompensation.currency}
          setSelectedOption={(option) => {
            handleFormChange(option, 'currency');
          }}
          currency={true}
          isSubmitted={isFormSubmitted}
          size="medium"
        />
      </div>
      <div className={classes['c-add-compensation-modal__dropdown-holder']}>
        <label htmlFor="frequency">Frequency:</label>
        <DropDown
          options={frequencyOptions}
          displayedOptions={frequencyOptions.map(option => option.charAt(0).toUpperCase() + option.slice(1).toLowerCase())}
          selectedOption={newCompensation.frequency}
          setSelectedOption={(option) => {
            handleFormChange(option, 'frequency');
          }}
          isSubmitted={isFormSubmitted}
          size="medium"
        />
      </div>
      <Input
        name="note"
        id="note"
        label="Note:"
        value={newCompensation.note}
        setValue={(event) => {
          handleFormChange(event, 'note');
        }}
        size="medium"
      />
      <Input
        name="effectiveOnForm"
        id="effectiveOnForm"
        type="date"
        label="Effective on:"
        value={dayjs(newCompensation.effectiveOn).format('YYYY-MM-DD')}
        setValue={(event) => {
          handleFormChange(dayjs(event).format('YYYY-MM-DD'), 'effectiveOn');
        }}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
      <div className={classes['c-add-compensation-modal__button-holder']}>
        <Button size="medium" isLoading={isLoading} onClick={handleAddCompensation}>
          Add compensation
        </Button>
      </div>
    </div>
  );
};

export default AddCompensationModal;
