import { useToolbarTitle } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getPendingTimeAways } from '../../api/timeAwayRequests.api';
import { PendingTimeAway } from '../../models/timeAwayRequestsModel';
import TimeAwayTable from '../Table/TimeAwayTable';

const TimeAwayRequests = () => {
  const [pendingApprovals, setPendingApprovals] = useState<PendingTimeAway[]>([]);
  const [isContentLoading, setIsContentLoading] = useState(false);

  const id = useAppSelector((state) => state.auth.user?.id);

  useToolbarTitle({ title: ['Time away requests'] });

  const retrievePendingTimeAways = useCallback(() => {
    setIsContentLoading(true);

    if (!id) return;

    getPendingTimeAways(id).then((res) => {
      setPendingApprovals(res.data);
      setIsContentLoading(false);
    });
  }, [id]);

  const removeFromPendingTimeAways = (timeAwayId: number) => {
    setPendingApprovals((prev) => prev.filter((item) => item.id !== timeAwayId));
  };

  useEffect(() => {
    retrievePendingTimeAways();
  }, [retrievePendingTimeAways]);

  return (
    <TimeAwayTable
      isContentLoading={isContentLoading}
      pendingApprovals={pendingApprovals}
      removeFromPendingTimeAways={removeFromPendingTimeAways}
      type="MULTY"
    />
  );
};

export default TimeAwayRequests;
