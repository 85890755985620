import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrganizationChartData } from '../model';
import { RequestState } from 'config/constants';
import { getOrganizationChart } from './chartActions';
import { State as AppState } from 'state/store';

type State = {
  data?: OrganizationChartData;
  membersCount: number;
  loading: RequestState;
};

const initialState: State = {
  membersCount: 0,
  loading: RequestState.IDLE,
};

const chartSlice = createSlice({
  name: 'organizationChart',
  initialState,
  reducers: {
    removeOrganizationChartData: (state) => {
      state.data = undefined;
      state.membersCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationChart.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(
        getOrganizationChart.fulfilled,
        (state, action: PayloadAction<OrganizationChartData | undefined>) => {
          state.loading = RequestState.FULFILLED;
          state.data = action.payload;
          state.membersCount = Object.values(state.data ?? {}).reduce(
            (totalCount, nodes) => totalCount + nodes.length,
            0,
          );
        },
      )
      .addCase(getOrganizationChart.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { removeOrganizationChartData } = chartSlice.actions;

export const selectOrganizationChart = (state: AppState) => state.organizationChart;

export default chartSlice.reducer;
