import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import type { NewTimeAwayType, TimeAwayType } from 'modules/shared/model';

export const getAllTimeAwayTypes = async () => {
  return api.get<TimeAwayType[]>(`/organization/${getOrganizationId()}/time-away-type`);
};

export const createTimeAwayType = async (timeAwayType: NewTimeAwayType) => {
  return api.post<TimeAwayType>(`/organization/${getOrganizationId()}/time-away-type`, {
    ...timeAwayType,
  });
};

export const updateTimeAwayType = async (timeAwayType: TimeAwayType) => {
  return api.put<TimeAwayType>(
    `/organization/${getOrganizationId()}/time-away-type/${timeAwayType.id}`,
    { ...timeAwayType },
  );
};

export const deleteTimeAwayType = async (timeAwayTypeId: number) => {
  return api.delete(`/organization/${getOrganizationId()}/time-away-type/${timeAwayTypeId}`);
};
