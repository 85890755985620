import classes from '../PeoplePopup.module.scss';
import { ChangeEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  addNewHireMemberDefaultValues,
  addNewHireMemberValidationSchema,
  AddNewHireMemberValidationSchema,
} from '../validations/AddNewHireMemberValidation.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatDateForInput } from 'utils';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { Input } from 'components/core/Input';
import { AddMemberDropDown } from '../AddMemberDropDown';
import { Button } from 'components/core/Button';
import { useAddNewHireMember } from '../hooks/useAddNewHireMember';
import { useLocation } from 'react-router-dom';

type Props = {
  setIsPopupOpen: (value: boolean) => void;
};

const AddNewHireMemberPopup = ({ setIsPopupOpen }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm<AddNewHireMemberValidationSchema>({
    resolver: zodResolver(addNewHireMemberValidationSchema),
    defaultValues: addNewHireMemberDefaultValues,
  });

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const userTypeParam = params.get('userType');

  const { handleAddNewHireMember } = useAddNewHireMember();
  const { jobTitles } = useAppSelector((state) => state.peoplePopup);

  const handleOnChange =
    (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };

  const handleOnChangeDropdown = (onChange: (value: string) => void, item: string) =>
    onChange(item);

  const handleOnChangeDate = (onChange: (value: string) => void) => (newDate: string) => {
    onChange(formatDateForInput(newDate));
  };

  const handleAddNewHire = async (formData: AddNewHireMemberValidationSchema) => {
    const isSuccess = await handleAddNewHireMember(formData, userTypeParam);

    if (isSuccess) setIsPopupOpen(false);
  };

  return (
    <form className={classes['c-people-popup__form']} onSubmit={handleSubmit(handleAddNewHire)}>
      <div className={classes['c-people-popup__multiple-inputs']}>
        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              id="firstName"
              size="medium"
              name="firstName"
              label="First name"
              value={value}
              handleOnChange={handleOnChange(onChange)}
              errorMessage={errors.firstName?.message}
              isInvalid={!!errors.firstName?.message}
              isSubmitted={isSubmitted}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              id="lastName"
              size="medium"
              name="lastName"
              label="Last name"
              value={value}
              handleOnChange={handleOnChange(onChange)}
              errorMessage={errors.lastName?.message}
              isInvalid={!!errors.lastName?.message}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </div>
      <Controller
        name="personalEmail"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            id="personalEmail"
            size="medium"
            name="personalEmail"
            label="Personal email"
            value={value}
            handleOnChange={handleOnChange(onChange)}
            errorMessage={errors.personalEmail?.message}
            isInvalid={!!errors.personalEmail?.message}
            isSubmitted={isSubmitted}
          />
        )}
      />
      <Controller
        name="jobTitle"
        control={control}
        render={({ field: { onChange, value } }) => (
          <AddMemberDropDown
            label="Job title"
            id="jobTitle"
            selectedValue={value}
            items={jobTitles.map((value) => ({ value }))}
            setValue={(selectedItem: string) => handleOnChangeDropdown(onChange, selectedItem)}
            isSubmitted={isSubmitted}
            errorMessage={errors.jobTitle?.message}
          />
        )}
      />
      <Controller
        name="startDate"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            size="medium"
            name="startDate"
            id="startDate"
            label="Start date"
            value={value}
            type="date"
            setValue={handleOnChangeDate(onChange)}
            isSubmitted={isSubmitted}
            errorMessage={errors.startDate?.message}
            isInvalid={!!errors.startDate?.message}
          />
        )}
      />
      <Button size="medium" htmlType="submit" isLoading={isSubmitting}>
        Add new hire
      </Button>
    </form>
  );
};

export default AddNewHireMemberPopup;
