import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownItem } from 'components/core';
import { RequestState } from 'config/constants';
import { State } from 'state/store';
import { Timesheet, TimesheetEntry } from '../models/timesheet.models';
import {
  getAllTimesheetEntries,
  getTimesheetProjects,
  getAllTimesheets,
} from './timesheetsActions';

type TimesheetsState = {
  timesheets: Timesheet[];
  timesheetEntries: TimesheetEntry[];
  projectOptions: DropdownItem[];
  loading: RequestState;
};

const initialState: TimesheetsState = {
  timesheets: [],
  timesheetEntries: [],
  projectOptions: [],
  loading: RequestState.IDLE,
};

const timesheetsSlice = createSlice({
  name: 'timesheetsSlice',
  initialState,
  reducers: {
    setTimesheets: (state, action: PayloadAction<Timesheet[]>) => {
      state.timesheets = action.payload;
    },
    setProjectOptions: (state, action: PayloadAction<DropdownItem[]>) => {
      state.projectOptions = action.payload;
    },
    setTimesheetEntries: (state, action) => {
      state.timesheetEntries = action.payload;
    },
    updateTimesheetEntries: (state, action: PayloadAction<TimesheetEntry>) => {
      const { payload } = action;

      state.timesheetEntries = [
        ...state.timesheetEntries.map((timesheetEntry) => {
          if (timesheetEntry.id !== payload.id) return { ...timesheetEntry };
          return { ...payload };
        }),
      ];
    },
    addTimesheet: (state, action: PayloadAction<Timesheet>) => {
      const { payload } = action;

      state.timesheets = [...state.timesheets, payload];
    },
    updateTimesheet: (state, action: PayloadAction<Timesheet>) => {
      const { payload } = action;

      state.timesheets = [
        ...state.timesheets.map((timesheet) => {
          if (timesheet.id !== payload.id) return { ...timesheet };
          return { ...payload };
        }),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTimesheets.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getAllTimesheets.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.timesheets = action.payload || [];
      })
      .addCase(getAllTimesheets.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      })
      .addCase(getTimesheetProjects.fulfilled, (state, action) => {
        state.projectOptions = action.payload || [];
      })
      .addCase(getAllTimesheetEntries.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getAllTimesheetEntries.fulfilled, (state, action) => {
        state.timesheetEntries = action.payload || [];
        state.loading = RequestState.FULFILLED;
      })
      .addCase(getAllTimesheetEntries.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const {
  setTimesheets,
  setProjectOptions,
  setTimesheetEntries,
  updateTimesheetEntries,
  addTimesheet,
  updateTimesheet,
} = timesheetsSlice.actions;

export const selectTimesheets = (state: State) => state.timesheets;
export const selectProjectOptions = (state: State) => state.timesheets.projectOptions;

export default timesheetsSlice.reducer;
