import { useQuery, useToolbarTitle } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTimeAway } from 'modules/timeAwayRequests/api/timeAwayRequests.api';
import { PendingTimeAway } from 'modules/timeAwayRequests/models/timeAwayRequestsModel';
import TimeAwayTable from '../Table/TimeAwayTable';

const TimeAwayConfirmation = () => {
  useToolbarTitle({ title: ['Time away Confirmation'] });

  const navigate = useNavigate();
  const organizationId = useQuery().get('organizationId');
  const userId = useQuery().get('userId');
  const timeAwayTypeId = useQuery().get('timeAwayTypeId');
  const timeAwayId = useQuery().get('timeAwayId');

  const [timeAways, setTimeAways] = useState<PendingTimeAway[]>([]);
  const [isContentLoading, setIsContentLoading] = useState(false);

  const retrieveTimeAway = useCallback(
    async (userId: string, timeAwayTypeId: string, timeAwayId: string) => {
      setIsContentLoading(true);

      try {
        const { data } = await getTimeAway(userId, timeAwayTypeId, timeAwayId);
        setTimeAways([data]);
      } catch {
        navigate('/time-away-approvals');
      } finally {
        setIsContentLoading(false);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (!organizationId || !userId || !timeAwayTypeId || !timeAwayId) {
      navigate('/time-away-approvals');
    } else {
      retrieveTimeAway(userId, timeAwayTypeId, timeAwayId);
    }
  }, [organizationId, userId, timeAwayTypeId, timeAwayId, navigate, retrieveTimeAway]);

  return (
    <TimeAwayTable isContentLoading={isContentLoading} pendingApprovals={timeAways} type="SINGLE" />
  );
};

export default TimeAwayConfirmation;
