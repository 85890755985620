import { AddActiveMemberValidationSchema } from '../validations/AddActiveMemberValidation.schema';
import { UserType } from 'config/constants';
import { Location } from '../../redux/peoplePopupSlice';
import { promoteNewHireUser } from 'modules/people/api/people.api';
import { useDispatch } from 'react-redux';
import { promoteUser, removeUser } from 'modules/people/redux/peopleSlice';
import { toast } from 'react-toastify';
import { mapErrorToErrorData } from 'utils';
import { UserBaseInfo } from 'modules/people/model/User';

export const usePromoteMember = () => {
  const dispatch = useDispatch();

  const handlePromoteMember = async (
    formData: AddActiveMemberValidationSchema,
    selectedLocation: Location | undefined,
    userToPromoteId: number,
    selectedUser?: number,
    reportingToUser?: UserBaseInfo | undefined,
  ) => {
    try {
      const { data } = await promoteNewHireUser({
        id: userToPromoteId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        userStatus: UserType.ACTIVE_MEMBER,
        email: formData.email,
        jobTitle: formData.jobTitle,
        department: formData.department,
        organizationLocationDto: selectedLocation,
        userReportingToId: formData.reportTo ? selectedUser : undefined,
        startDate: formData.startDate,
        firstWorkingDay: formData.firstWorkingDay,
      });

      dispatch(removeUser(userToPromoteId));

      const placeOfWork = `${data.organizationLocationDto?.address}-${data.organizationLocationDto?.city}`;
      const country = data.organizationLocationDto?.country;

      dispatch(
        promoteUser({
          ...data,
          placeOfWork,
          reportingToUser,
          country,
          effectiveOn: data.startDate,
          userType: data.userStatus,
        }),
      );

      toast.success('You have successfully promoted user to active member.');
      return true;
    } catch (error) {
      const errorData = mapErrorToErrorData(error);

      toast.error(
        errorData?.message ||
          'Something went wrong while promoting user to active member. Try again.',
      );

      return false;
    }
  };

  return { handlePromoteMember };
};
