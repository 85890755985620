import { Chart, ChartTypeRegistry } from 'chart.js';

export const lineChartBackgroundColor = 'rgba(228, 228, 228, 0.2)';
export const lineChartPointColor = 'rgba(103, 39, 226, 1)';
export const borderColor = 'rgba(123, 133, 140, 1)';
const gridColor = 'rgba(189, 189, 189, 1)';
export const textColor = 'rgba(55, 59, 60, 1)';

export const chartColors = [
  'rgba(249, 199, 79, 1)',
  'rgba(209, 185, 255, 1)',
  'rgba(243, 114, 44, 1)',
  'rgba(53, 149, 126, 1)',
  'rgba(248, 150, 30, 1)',
  'rgba(249, 65, 68, 1)',
  'rgba(162, 213, 155, 255)',
  'rgba(187, 118, 54, 1)',
  'rgba(131, 201, 184, 1)',
];

export const disabledChartColors = ['rgba(228, 228, 228, 1)'];

export const tooltipConfiguration = {
  enabled: true,
  titleFont: {
    family: 'Nunito',
    size: 9,
    fontWeight: '600',
  },
  bodyFont: {
    family: 'Nunito',
    size: 10,
    fontWeight: '700',
  },
  cornerRadius: 10,
  titleMarginBottom: 3,
  padding: 8,
  caretPadding: 10,
  caretSize: 0,
  usePointStyle: true,
  boxPadding: 5,
};

export const titleConfiguration = {
  display: true,
  align: 'start' as const,
  padding: {
    bottom: 35,
  },
  color: textColor,
  font: {
    size: 13,
    fontWeight: '800',
    family: 'Nunito',
  },
};

export const ticksConfiguration = {
  color: textColor,
  font: {
    size: 12,
    fontWeight: '700',
    family: 'Nunito',
  },
};

export const borderConfiguration = {
  display: true,
  color: textColor,
  width: 1,
  dash: [3, 4],
};

export const gridConfiguration = {
  display: true,
  drawTicks: false,
  color: gridColor,
};

export const barConfiguration = {
  barThickness: 18,
  borderRadius: 15,
};

export const hoverLinePlugin = {
  id: 'hoverLinePlugin',
  afterDatasetsDraw(chart: Chart<keyof ChartTypeRegistry>) {
    const {
      ctx,
      tooltip,
      chartArea: { bottom },
      scales: { x, y },
    } = chart;

    if (!tooltip?.getActiveElements().length) return;

    const xCoor = x.getPixelForValue(tooltip.dataPoints[0].parsed.x);
    const yCoor = y.getPixelForValue(tooltip.dataPoints[0].parsed.y);

    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = lineChartPointColor;

    ctx.moveTo(xCoor, yCoor);
    ctx.lineTo(xCoor, bottom);
    ctx.stroke();
    ctx.closePath();
  },
};

export const responsiveConfig = {
  responsive: true,
  maintainAspectRatio: false,
  devicePixelRatio: 2,
};
