import { useCallback, useEffect, useState } from 'react';
import { toast, DropDown, Button } from 'components/core';
import { createWorkflowInstance } from 'modules/workflows/api/workflowInstances.api';
import { Workflow } from 'modules/workflows/model/workflow.model';
import { getAllWorkflows } from 'modules/workflows/redux/workflowTemplatesActions';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import classes from './CreateWorkflowModal.module.scss';
import { UserSearchInputComponent } from 'components/core/Popups/People/UserSearchInputComponent/UserSearchInputComponent';

type Props = {
  workflowTemplates: Workflow[];
};

const CreateWorkflowModal = ({ workflowTemplates }: Props) => {
  const [selectedWorkflowTemplate, setSelectedWorkflowTemplate] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<number>();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCreate = useCallback(async () => {
    setIsFormSubmitted(true);

    const selectedWorkflowTemplateId = workflowTemplates.find(
      (workflow) => workflow.name === selectedWorkflowTemplate,
    )?.id;

    if (!selectedWorkflowTemplateId || !selectedUser) return;

    try {
      const { data } = await createWorkflowInstance(selectedWorkflowTemplateId, selectedUser);
      if (data.id) navigate(`${selectedWorkflowTemplateId}/workflow-instance/${data.id}`);
    } catch {
      toast('error', 'Something went wrong while creating workflow. Try again.');
    }
  }, [navigate, selectedWorkflowTemplate, workflowTemplates, selectedUser]);

  useEffect(() => {
    dispatch(getAllWorkflows({ page: 0, size: 9, sort: 'id' }));
  }, [dispatch]);

  return (
    <div className={classes['c-create-workflow-modal']}>
      <div className={classes['c-create-workflow-modal__form-wrapper']}>
        <span className={classes['c-create-workflow-modal__dropdown-label']}>
          Select workflow template
        </span>
        <DropDown
          options={workflowTemplates.map((workflow) => workflow.name)}
          selectedOption={selectedWorkflowTemplate}
          setSelectedOption={setSelectedWorkflowTemplate}
        />
        <span className={classes['c-create-workflow-modal__error-message']}>
          {isFormSubmitted && !selectedWorkflowTemplate
            ? 'Workflow template must be selected.'
            : ''}
        </span>
        <span className={classes['c-create-workflow-modal__dropdown-label']}>Add person</span>
        <UserSearchInputComponent
          id="add-member"
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
        <span className={classes['c-create-workflow-modal__error-message']}>
          {isFormSubmitted && !selectedUser ? 'Person must be selected.' : ''}
        </span>
      </div>
      <div className={classes['c-create-workflow-modal__btn-container']}>
        <Button size="large" onClick={handleCreate}>
          Create
        </Button>
      </div>
    </div>
  );
};

export default CreateWorkflowModal;
