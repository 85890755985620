import classes from './Spinner.module.scss';
import { MoonLoader } from 'react-spinners';

type Props = {
  size?: number;
  color?: string;
};

export const Spinner = ({ size = 90, color = '#7b858c' }: Props) => {
  return (
    <div className={classes['c-spinner']}>
      <MoonLoader color={color} speedMultiplier={0.5} size={size} />
    </div>
  );
};
