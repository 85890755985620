import { useState, type ReactNode } from 'react';
import avatarPlaceholder from 'assets/AvatarPlaceholder.svg';
import classes from './Avatar.module.scss';
import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';

type Size =
  | 'extra-small'
  | 'small'
  | 'small-plus'
  | 'medium-minus'
  | 'medium'
  | 'large'
  | 'timeline';

type Props = {
  imageUrl?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  icon?: ReactNode;
  horizontal?: boolean;
  size?: Size;
  tooltipContent?: string;
  clickable?: boolean;
  onIconClick?: () => void;
  handleOnClick?: () => void;
};

export const Avatar = ({
  imageUrl,
  title,
  subtitle,
  icon,
  tooltipContent,
  size = 'small',
  horizontal = false,
  clickable = false,
  onIconClick,
  handleOnClick,
}: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleTooltipOpen = () => setIsTooltipOpen((isOpen) => !isOpen);
  const handleOnAvatarClick = () => {
    if (handleOnClick) handleOnClick();
  };
  return (
    <div
      className={`${classes['c-avatar']} ${classes[`c-avatar--${size}`]} ${
        horizontal ? classes[`c-avatar--horizontal`] : ''
      } ${clickable ? classes['c-avatar--clickable'] : ''}`}
      role="button"
      onClick={handleOnAvatarClick}
    >
      <div
        className={classes['c-avatar__profile-photo-container']}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipOpen}
      >
        <img src={imageUrl || avatarPlaceholder} className={classes['c-avatar__profile-photo']} />
        {icon && onIconClick && (
          <div className={classes['c-avatar__icon-button']}>
            <IconButton icon={icon} aria-label="remove" onClick={onIconClick} />
          </div>
        )}
        {tooltipContent && (
          <Tooltip isOpen={isTooltipOpen} position="right">
            {tooltipContent}
          </Tooltip>
        )}
      </div>

      {(title || subtitle) && (
        <div className={classes['c-avatar__title-container']}>
          {title && <div className={`${classes['c-avatar__title']} `}>{title}</div>}
          {subtitle && <div className={classes['c-avatar__subtitle']}>{subtitle}</div>}
        </div>
      )}
    </div>
  );
};
