import { TooltipItem } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { months } from 'config/constants';
import { pascalCase } from 'utils/utilFunctions';
import {
  borderColor,
  borderConfiguration,
  gridConfiguration,
  hoverLinePlugin,
  lineChartBackgroundColor,
  lineChartPointColor,
  responsiveConfig,
  ticksConfiguration,
  titleConfiguration,
  tooltipConfiguration,
} from './chartsConfigurations';
import { TimeAwayDaysByMonth } from 'modules/insights/models';
import { getMaxNumber, getTimeAwaysData } from 'modules/insights/utils';

type Props = {
  timeAwayDaysByMonth: TimeAwayDaysByMonth;
};

export const AwayDaysChart = ({ timeAwayDaysByMonth }: Props) => {
  const timeAwayMonthLabels = Object.keys(timeAwayDaysByMonth).map((item) => pascalCase(item));

  const awayDaysChartData = {
    labels: Object.keys(months).map((month) => month.substring(0, 3)),
    datasets: [
      {
        fill: true,
        data: getTimeAwaysData(timeAwayMonthLabels, timeAwayDaysByMonth),
        lineTension: 0.4,
        borderColor,
        backgroundColor: lineChartBackgroundColor,
        borderWidth: 2,
        pointRadius: 4,
        pointBorderColor: lineChartPointColor,
        pointBackgroundColor: 'white',
        pointHoverBackgroundColor: lineChartPointColor,
        pointHoverRadius: 5,
      },
    ],
  };

  const awayDaysChartOptions = {
    ...responsiveConfig,
    plugins: {
      legend: {
        display: false,
      },
      title: { ...titleConfiguration, text: 'Number of away days per month' },
      tooltip: {
        ...tooltipConfiguration,
        callbacks: {
          title(tooltipItems: TooltipItem<'line'>[]) {
            const itemIndex: number = tooltipItems[0].dataIndex;
            return `${Object.keys(months)[itemIndex]}`;
          },
          label(tooltipItem: TooltipItem<'line'>) {
            return `${tooltipItem.raw} days`;
          },
          labelColor() {
            return {
              borderColor: lineChartPointColor,
              backgroundColor: lineChartPointColor,
            };
          },
        },
      },
    },
    scales: {
      y: {
        max: getMaxNumber(timeAwayDaysByMonth),
        ticks: {
          callback: (value: string | number) => Number(value),
          padding: 10,
          ...ticksConfiguration,
        },
        border: borderConfiguration,
        grid: gridConfiguration,
      },
      x: {
        ticks: {
          padding: 15,
          ...ticksConfiguration,
        },
        border: borderConfiguration,
        grid: gridConfiguration,
      },
    },
  };

  return (
    <Line data={awayDaysChartData} options={awayDaysChartOptions} plugins={[hoverLinePlugin]} />
  );
};
