import { IconButton } from '../IconButton';
import classes from './DropDown.module.scss';
import { DropDownOption } from './DropDownOption';
import { ReactComponent as ArrLeft } from 'assets/ArrowLeft.svg';
import { ReactComponent as ArrRight } from 'assets/ArrowRight.svg';

type Props = {
  options: string[];
  selectMonthHandler: (option: string) => void;
  selectedYear: string;
  selectedMonth?: string;
  handleSelectedYear: (option: number) => void;
};

export const MonthDropDown = ({
  options,
  selectMonthHandler,
  selectedYear,
  handleSelectedYear,
  selectedMonth,
}: Props) => {
  const prevYear = () => handleSelectedYear(Number(selectedYear) - 1);
  const nextYear = () => handleSelectedYear(Number(selectedYear) + 1);

  return (
    <div className={classes['c-drop-down-options']}>
      <DropDownOption
        text={
          <>
            <IconButton
              icon={<ArrLeft className={classes['c-drop-down-options__arrow-icon']} />}
              aria-label="Go to previous year"
              onClick={prevYear}
            />
            {selectedYear}
            <IconButton
              icon={<ArrRight className={classes['c-drop-down-options__arrow-icon']} />}
              aria-label="Go to next year"
              onClick={nextYear}
            />
          </>
        }
        modifier="year"
      />
      {options.map((option) => (
        <DropDownOption
          modifier={selectedMonth === option ? 'selected' : undefined}
          key={option}
          text={option}
          onClick={() => selectMonthHandler(option)}
        />
      ))}
    </div>
  );
};
