import { useEffect, useState, useCallback } from 'react';
import classes from '../styles/AwayUsers.module.scss';
import AwayUser from './AwayUser';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { TimeAway, TimeAwayUser } from '../models/timeAwayModels';
import { searchUsers, sortUsers } from 'utils/searchSortItems';
import AwayUserPlaceholder from './AwayUserPlaceholder';
import { useColorLegend } from 'components/core';

type Props = {
  currentDate: Date | 'Today';
  hoveredDay: number | null;
  setHoveredDay: (hoveredDay: number | null) => void;
};

const AwayUsers = ({ currentDate, hoveredDay, setHoveredDay }: Props) => {
  const { appliedFilters } = useAppSelector((state) => state.filters);
  const { users } = useAppSelector((state) => state.timeline);
  const { inputFieldValue } = useAppSelector((state) => state.toolbar);
  const [timeAways, setTimeAways] = useState<TimeAway[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<TimeAwayUser[]>(users);

  const { filteredTimeAways } = useColorLegend(timeAways);

  const filterUsersByAppliedFilters = useCallback(() => {
    let usersCopy = [...users];

    const { projectNames, sortBy } = appliedFilters;

    if (sortBy.filterValue) {
      usersCopy.sort((x, y) =>
        sortUsers({
          firstNameX: x.firstName.toLowerCase(),
          firstNameY: y.firstName.toLowerCase(),
          lastNameX: x.lastName.toLowerCase(),
          lastNameY: y.lastName.toLowerCase(),
          sortBy: sortBy.filterValue,
        }),
      );
    }

    if (inputFieldValue) {
      usersCopy = usersCopy.filter(({ firstName, lastName }) =>
        searchUsers({ firstName, lastName, searchInput: inputFieldValue.toLowerCase() }),
      );
    }

    const filteredSortedUsers = usersCopy.filter((user) => {
      if (
        projectNames.length &&
        !user.projectNames?.some((project) => projectNames.includes(project))
      )
        return false;

      return true;
    });

    setFilteredUsers(filteredSortedUsers);
  }, [users, appliedFilters, inputFieldValue]);

  useEffect(() => {
    filterUsersByAppliedFilters();
  }, [users, appliedFilters, inputFieldValue, filterUsersByAppliedFilters]);

  useEffect(() => {
    setTimeAways(users.flatMap((user) => user.timeAways.map((timeAway) => timeAway)));
  }, [users]);

  useEffect(() => {
    const filteredTimeAwaysNames = [
      ...new Set(filteredTimeAways.map((item) => item.timeAwayType.name)),
    ];

    const mappedUsers: TimeAwayUser[] = [];

    users.forEach((user) => {
      if (
        user.timeAways.some((timeAway) =>
          filteredTimeAwaysNames.includes(timeAway.timeAwayType.name),
        )
      ) {
        const newUser = { ...user };

        newUser.timeAways = user.timeAways.filter((timeAway) =>
          filteredTimeAwaysNames.includes(timeAway.timeAwayType.name),
        );

        mappedUsers.push(newUser);
      }
    });

    setFilteredUsers(mappedUsers);
  }, [users, filteredTimeAways]);

  const showNoUsersForSelectedFilters = !filteredUsers.length;
  const showNoUsersMessage = !users.length;

  return (
    <div className={classes['c-away-users']}>
      {(showNoUsersMessage || showNoUsersForSelectedFilters) && (
        <div className={classes['c-away-users__empty-page']}>
          {!!showNoUsersMessage && 'There are no people away for this month.'}
          {!!showNoUsersForSelectedFilters &&
            'There are no results for the desired search criteria.'}
        </div>
      )}
      {filteredUsers.map((user, index) => (
        <AwayUser
          key={user.userId}
          awayUser={user}
          searchValue={inputFieldValue ?? ''}
          timeAways={user.timeAways}
          currentDate={currentDate}
          hoveredDay={hoveredDay}
          setHoveredDay={setHoveredDay}
          isFirst={index === 0}
        />
      ))}
      {!!filteredUsers.length && (
        <AwayUserPlaceholder
          isFirst={!filteredUsers.length}
          currentDate={currentDate}
          hoveredDay={hoveredDay}
          setHoveredDay={setHoveredDay}
        />
      )}
    </div>
  );
};

export default AwayUsers;
