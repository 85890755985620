import { api } from 'lib';
import { getOrganizationId, getUserId } from 'state/utils';
import {
  Timesheet,
  TimesheetEntry,
  TimesheetEntryUpdate,
  TimesheetsFilters,
} from '../models/timesheet.models';

export const getTimesheets = async (timesheetFilters?: TimesheetsFilters) => {
  const params = {
    ...timesheetFilters,
    month: timesheetFilters?.month?.toUpperCase(),
  };

  return api.get<Timesheet[]>(`/organization/${getOrganizationId()}/timesheet`, { params });
};

export const getTimesheet = async (id: number) => {
  return api.get<Timesheet>(
    `/organization/${getOrganizationId()}/user/${getUserId()}/timesheet/${id}`,
  );
};

export const createTimesheet = async (timesheet: Timesheet) => {
  return api.post<Timesheet>(`/organization/${getOrganizationId()}/user/${getUserId()}/timesheet`, {
    ...timesheet,
  });
};

export const updateTimesheetEntryHours = async (request: UserProjectTimesheetRequest) => {
  return api.put<TimesheetEntry>(
    `/organization/${getOrganizationId()}/user/${getUserId()}/entry/enter-hours`,
    request,
  );
};

export const updateTimesheetEntries = async (
  timesheetId: number,
  timesheetEntries: TimesheetEntryUpdate,
) => {
  return api.put<TimesheetEntry[]>(
    `/organization/${getOrganizationId()}/user/${getUserId()}/timesheet/${timesheetId}/entry`,
    { updateTimesheetEntries: { ...timesheetEntries } },
  );
};

export const getTimesheetEntries = async (id: number) => {
  return api.get<TimesheetEntry[]>(`/organization/${getOrganizationId()}/timesheet/${id}/entry`);
};
