import { useCallback, useEffect, useState } from 'react';
import classes from '../../../styles/Section.module.scss';
import { ReactComponent as LinkedInSvg } from 'assets/social/linkedin.svg';
import { ReactComponent as TwitterSvg } from 'assets/social/twitter.svg';
import { ReactComponent as InstagramSvg } from 'assets/social/instagram.svg';
import { ReactComponent as FacebookSvg } from 'assets/social/facebook.svg';
import { SectionInfoEdit, SectionActions } from 'modules/shared/components';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { type Social as SocialModel } from 'modules/profile/models/profileModels';
import { updateUserSocial } from 'modules/profile/api/profile.api';
import { selectUserId, updateSocial } from 'modules/profile/redux/userSlice';

const Social = () => {
  const social = useAppSelector((state) => state.profile.user.userSocialDto);
  const userId = useAppSelector(selectUserId);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [socialData, setSocialData] = useState<SocialModel>(social);

  const dispatch = useAppDispatch();

  const handleInputChange = (value: string, prop: string) => {
    setSocialData((prevData) => ({ ...prevData, [prop]: value }));
  };

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    try {
      await updateUserSocial(userId.toString(), socialData)
        .then((res) => {
          dispatch(updateSocial(res.data));
        })
        .finally(() => {
          setIsLoading(false);
          setIsEditMode(false);
        });
    } catch {
      console.log('exception');
    }
  }, [dispatch, socialData, userId]);

  const handleCancel = useCallback(() => {
    setSocialData(social);
    setIsEditMode(false);
  }, [social]);

  useEffect(() => {
    setSocialData(social);
  }, [social]);

  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <h2 className={classes['c-section__title']}>Social</h2>
        <SectionActions
          isEditOpen={isEditMode}
          handleEdit={() => setIsEditMode(true)}
          handleCancel={handleCancel}
          handleSave={handleSave}
          isLoading={isLoading}
        />
      </div>
      <div className={classes['c-section__content']}>
        <SectionInfoEdit
          name="Biography"
          data={socialData.biography}
          setValue={(event) => handleInputChange(event, 'biography')}
          readOnly={!isEditMode}
          inputFieldWidth="full"
          inputValuePosition="left"
        />
        {isEditMode ? (
          <>
            <SectionInfoEdit
              name="LinkedIn link"
              data={socialData.linkedinLink}
              icon={<LinkedInSvg />}
              setValue={(event) => handleInputChange(event, 'linkedinLink')}
              inputFieldWidth="full"
              inputValuePosition="left"
              iconVariant="original"
            />
            <SectionInfoEdit
              name="Facebook link"
              data={socialData.facebookLink}
              icon={<FacebookSvg />}
              setValue={(event) => handleInputChange(event, 'facebookLink')}
              inputFieldWidth="full"
              inputValuePosition="left"
              iconVariant="original"
            />
            <SectionInfoEdit
              name="X link"
              data={socialData.twitterLink}
              icon={<TwitterSvg />}
              setValue={(event) => handleInputChange(event, 'twitterLink')}
              inputFieldWidth="full"
              inputValuePosition="left"
              iconVariant="original"
            />
            <SectionInfoEdit
              name="Instagram link"
              data={socialData.instagramLink}
              icon={<InstagramSvg />}
              setValue={(event) => handleInputChange(event, 'instagramLink')}
              inputFieldWidth="full"
              inputValuePosition="left"
              iconVariant="original"
            />
          </>
        ) : (
          <div
            className={`${classes['c-section__content-column']} ${classes['c-section__content-column--full-width']}`}
          >
            <div className={classes['c-section__social-item']}>
              <span className={classes['c-section__social-item-label']}>Linked accounts</span>
              <div className={classes['c-section__social-item-links']}>
                {social.linkedinLink && (
                  <a
                    className={classes['c-section__social-item--icon']}
                    href={social.linkedinLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInSvg />
                  </a>
                )}
                {social.facebookLink && (
                  <a
                    className={classes['c-section__social-item--icon']}
                    href={social.facebookLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookSvg />
                  </a>
                )}
                {social.instagramLink && (
                  <a
                    className={classes['c-section__social-item--icon']}
                    href={social.instagramLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramSvg />
                  </a>
                )}
                {social.twitterLink && (
                  <a
                    className={classes['c-section__social-item--icon']}
                    href={social.twitterLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterSvg />
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Social;
