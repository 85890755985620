// import CustomReports from './CustomReports/CustomReports';
import ManageCustomFields from './ManageCustomFields/ManageCustomFields';
import { useToolbarTitle } from 'hooks';

const CustomFields = () => {
  useToolbarTitle({ title: ['Settings', 'Custom fields'] });

  return (
    <>
      <ManageCustomFields />
      {/* <CustomReports /> */}
    </>
  );
};

export default CustomFields;
