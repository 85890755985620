import FormContainer from 'modules/auth/components/FormContainer/FormContainer';
import Registration from 'modules/auth/components/Registration/Registration';

const RegistrationContainer = () => {
  return (
    <FormContainer>
      <Registration />
    </FormContainer>
  );
};

export default RegistrationContainer;
