import { lookupViaCity } from 'city-timezones';
import dayjs from 'dayjs';

export const timeBasedOnCity = (city: string, country: string): string => {
  const TIME_FORMAT = 'HH:mm dddd';
  const foundCities = lookupViaCity(city);

  if (!foundCities.length) return '';

  if (foundCities.length === 1) {
    return dayjs().tz(foundCities[0].timezone).format(TIME_FORMAT);
  }

  const foundCity = foundCities.find(
    (city) =>
      city.country === country ||
      city.timezone.includes(country) ||
      city.iso2 === country ||
      city.iso3 === country,
  );

  if (!foundCity) return '';

  return dayjs().tz(foundCity?.timezone).format(TIME_FORMAT);
};
