import { useCallback, useState, useEffect, type MouseEvent } from 'react';

import { IconButton } from 'components/core';
import avatarPlaceholder from 'assets/AvatarPlaceholder.svg';

import classes from './ChartNode.module.scss';
import { ReactComponent as NewTabIcon } from 'assets/NewTabIcon.svg';
import { ReactComponent as ArrowIcon } from 'assets/ChevronDown.svg';
import { DataNode, ForeignObjectProps } from '../../model';
import { isSafariBrowser } from 'modules/organizationChart/utils/common';

type Props = {
  nodeDatum: DataNode;
  foreignObjectProps: ForeignObjectProps;
  membersCount: number;
  searchTerm: string;
  selectedUserId: number | null;
  setSelectedUserId: (id: number) => void;
  toggleNode: () => void;
};

const ChartNode = ({
  nodeDatum,
  foreignObjectProps,
  membersCount,
  searchTerm,
  selectedUserId,
  setSelectedUserId,
  toggleNode,
}: Props) => {
  const [isNodeDisabled, setIsNodeDisabled] = useState<boolean>(false);

  const handleNodeClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      setSelectedUserId(nodeDatum?.attributes?.id as number);
    },
    [setSelectedUserId, nodeDatum],
  );

  const isCompanyNode = nodeDatum?.attributes?.jobTitle === 'Company';
  const companyInfo = membersCount === 1 ? 'member' : 'members';
  const reportsInfo = nodeDatum?.children?.length === 1 ? 'report' : 'reports';
  const isSelected = selectedUserId === nodeDatum?.attributes?.id;

  useEffect(() => {
    if (searchTerm && !nodeDatum.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      setIsNodeDisabled(true);
      return;
    }

    setIsNodeDisabled(false);
  }, [nodeDatum.name, searchTerm]);

  return (
    <foreignObject {...foreignObjectProps}>
      <div
        className={`${classes['c-chart-node']} ${
          isNodeDisabled ? classes['c-chart-node--disabled'] : ''
        } ${classes['c-chart-node']} ${isSelected ? classes['c-chart-node--active'] : ''} ${
          !isSafariBrowser() ? classes['c-chart-node--with-transitions'] : ''
        } ${isCompanyNode ? classes['c-chart-node--main-node'] : ''}`}
        onClick={toggleNode}
      >
        <div className={classes['c-chart-node__banner']}>
          {!isCompanyNode && (
            <div className={classes['c-chart-node__action']}>
              <IconButton
                icon={<NewTabIcon className={classes['c-chart-node__new-tab-icon']} />}
                aria-label={`See details about user ${nodeDatum.name}`}
                onClick={(event) => handleNodeClick(event)}
              />
            </div>
          )}
        </div>
        <div className={classes['c-chart-node__avatar']}>
          <img
            className={classes['c-chart-node__avatar-img']}
            alt="User profile photo"
            src={
              isCompanyNode
                ? (nodeDatum?.attributes?.companyLogoPath as string)
                : (nodeDatum?.attributes?.profilePhotoPath as string) ?? avatarPlaceholder
            }
          />
          <h4 className={classes['c-chart-node__avatar-title']}>{nodeDatum.name}</h4>
        </div>
        <div className={classes['c-chart-node__user-info']}>
          {nodeDatum.attributes?.jobTitle && (
            <div className={classes['c-chart-node__job-title']}>
              {nodeDatum.attributes.jobTitle}
            </div>
          )}
          {nodeDatum?.attributes?.department && (
            <div className={classes['c-chart-node__department']}>
              {nodeDatum.attributes.department}
            </div>
          )}
          {nodeDatum?.children && (
            <div className={classes['c-chart-node__details']}>
              <ArrowIcon className={classes['c-chart-node__details-icon']} />
              {isCompanyNode
                ? `${membersCount} ${companyInfo}`
                : `${nodeDatum?.children?.length} ${reportsInfo}`}
            </div>
          )}
        </div>
      </div>
    </foreignObject>
  );
};

export default ChartNode;
