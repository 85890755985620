import { useState } from 'react';
import { Tooltip } from 'components/core';
import { getShortList, getUserFullName } from 'utils';
import { ProjectUserListItem } from './ProjectUserListItem';
import type { ProjectUser } from 'modules/projects/models';
import classes from './ProjectUserList.module.scss';

type Props = {
  users: ProjectUser[];
};

export const ProjectUserList = ({ users }: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { data: shortListUsers, numberOfShortListedElements: numberOfHiddenUsers } = getShortList(
    users,
    8,
  );

  const handleTooltipOpen = () => setIsTooltipOpen(true);
  const handleTooltipClose = () => setIsTooltipOpen(false);

  const tooltipContent = users.map((user) => (
    <div className={classes['c-project-user-list__tooltip-item']} key={user.id}>
      <ProjectUserListItem
        fullName={getUserFullName(user.firstName, user.lastName)}
        profilePictureUrl={user.profilePicture}
      />
    </div>
  ));

  return (
    <div
      className={classes['c-project-user-list']}
      onMouseEnter={handleTooltipOpen}
      onMouseLeave={handleTooltipClose}
    >
      {shortListUsers.map((user) => (
        <div className={classes['c-project-user-list__item']} key={user.id}>
          <ProjectUserListItem profilePictureUrl={user.profilePicture} />
        </div>
      ))}
      {numberOfHiddenUsers > 0 && (
        <div
          className={`${classes['c-project-user-list__item']} ${classes['c-project-user-list__item--more']}`}
        >
          +{numberOfHiddenUsers}
        </div>
      )}
      <Tooltip isOpen={isTooltipOpen} position="bottom">
        {tooltipContent}
      </Tooltip>
    </div>
  );
};
