import { useRef, type ReactNode, useState, useEffect } from 'react';
import classes from './Tooltip.module.scss';

type Props = {
  children: ReactNode;
  isOpen?: boolean;
  color?: 'black' | 'default';
  position?: 'top' | 'bottom' | 'right' | 'left';
};

export const Tooltip = ({ children, isOpen, color = 'default', position = 'top' }: Props) => {
  const [tooltipPosition, setTooltipPosition] = useState<string>(position);

  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const optionsRect = tooltipRef.current?.getBoundingClientRect();
    const screenWidth = window.innerWidth;

    if (optionsRect && optionsRect.right > screenWidth && position === 'right') {
      setTooltipPosition('left');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      ref={tooltipRef}
      className={`${classes['c-tooltip']}  ${classes[`c-tooltip--${tooltipPosition}`]}
    ${color === 'black' ? classes['c-tooltip--black'] : ''} `}
    >
      {children}
    </div>
  );
};
