import { Avatar, HighlightedText, IconButton, Tooltip } from 'components/core';
import { Timesheet } from 'modules/timesheet/models/timesheet.models';
import { Link } from 'react-router-dom';
import classes from './TimesheetsListItem.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { getListFromString } from 'utils';
import { ReactComponent as DropdownArrowIcon } from 'assets/DropdownArrowIcon.svg';

type Props = {
  timesheet: Timesheet;
};

const TimesheetsListItem = ({
  timesheet: {
    id,
    month,
    year,
    user,
    totalOvertimeHours,
    totalRegularHours,
    projectHours,
    projectNames,
  },
}: Props) => {
  const [projects, setProjects] = useState<string[]>([]);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const handleOpenTooltip = () => setIsTooltipOpen(true);
  const handleCloseTooltip = () => setIsTooltipOpen(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const isLastProject = useCallback((index: number, length: number) => index === length - 1, []);

  const renderProjects = useCallback(() => {
    const displayProjects = projects.length <= 2;
    return (
      <div
        className={classes['c-timesheets-list-item--projects']}
        onMouseEnter={handleOpenTooltip}
        onMouseLeave={handleCloseTooltip}
      >
        {displayProjects
          ? projects.map((project, index) => (
              <React.Fragment key={index}>
                <button className={classes['c-timesheets-list-item--project']}>
                  <HighlightedText isHighligted={false} text={project} />
                </button>
              </React.Fragment>
            ))
          : projects.map((project, index) => (
              <React.Fragment key={index}>
                {index === 0 && (
                  <span
                    className={`${classes['c-timesheets-list-item--project']} ${classes['c-timesheets-list-item--last-project']}`}
                  >
                    ...
                  </span>
                )}
              </React.Fragment>
            ))}

        {!displayProjects && (
          <Tooltip isOpen={isTooltipOpen} position="left">
            {projects &&
              projects.map((project, index) => (
                <span key={index}>
                  {project}
                  {!isLastProject(index, projects.length) && <span>, </span>}
                </span>
              ))}
          </Tooltip>
        )}
      </div>
    );
  }, [isLastProject, isTooltipOpen, projects]);
  useEffect(() => {
    if (!projectNames) return;

    const projectsList = getListFromString(projectNames);

    setProjects(projectsList);
  }, [projectNames]);
  return (
    <>
      <li key={id}>
        <Link className={classes['c-timesheets-list-item']} to={`${id}`}>
          <span
            className={`${classes['c-timesheets-list-item__data']} 
            ${classes['c-timesheets-list-item__data']} 
           `}
          >
            {projectHours && projectHours?.length > 1 && (
              <IconButton
                icon={
                  <DropdownArrowIcon
                    className={`${classes['c-timesheets-list-item__dropdown-icon']} ${
                      isDropdownOpen ? classes['c-timesheets-list-item__dropdown-icon--opened'] : ''
                    }
                    `}
                  />
                }
                onClick={(e) => {
                  e.preventDefault();
                  setIsDropdownOpen(!isDropdownOpen);
                }}
              />
            )}
          </span>

          <Avatar
            imageUrl={user?.profilePhotoPath}
            title={`${user?.firstName} ${user?.lastName}`}
            subtitle={user?.jobTitle}
            size="extra-small"
            horizontal
          />
          <span
            className={`${classes['c-timesheets-list-item__data']} ${classes['c-timesheets-list-item__data--month']}`}
          >
            {month.toLowerCase()}
          </span>
          <span className={classes['c-timesheets-list-item__data']}>{year}</span>
          <span className={classes['c-timesheets-list-item__data']}>
            {!!projects.length && renderProjects()}
          </span>
          <span className={classes['c-timesheets-list-item__data']}>{totalRegularHours}</span>
          <span className={classes['c-timesheets-list-item__data']}>{totalOvertimeHours}</span>
          <span className={classes['c-timesheets-list-item__data']}>
            {(totalRegularHours || 0) + (totalOvertimeHours || 0)}
          </span>
        </Link>
      </li>
      <div
        className={`${
          isDropdownOpen
            ? classes['c-timesheets-list-item--expanded']
            : classes['c-timesheets-list-item--non-expanded']
        }`}
      >
        {projectHours &&
          projectHours?.length > 1 &&
          projectHours?.map((project, index) => (
            <li key={index}>
              <Link className={classes['c-timesheets-list-item']} to={`${id}`}>
                <span
                  className={`${classes['c-timesheets-list-item__data--expanded']} ${classes['c-timesheets-list-item__data--project']}`}
                >
                  <button className={classes['c-timesheets-list-item--project']}>
                    <HighlightedText isHighligted={false} text={project.projectName} />
                  </button>
                </span>
                <span className={classes['c-timesheets-list-item__data--expanded']}>
                  {project.totalRegularHours}
                </span>
                <span className={classes['c-timesheets-list-item__data--expanded']}>
                  {project.totalOvertimeHours}
                </span>
                <span className={classes['c-timesheets-list-item__data--expanded']}>
                  {project.totalOvertimeHours + project.totalRegularHours}
                </span>
              </Link>
            </li>
          ))}
      </div>
    </>
  );
};

export default TimesheetsListItem;
