import { useToolbarTitle } from 'hooks';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectUserEmployment } from '../redux/userSlice';

import DirectReports from './Sections/DirectReports';
import Details from './Sections/SectionClassic/Details';
import Compensation from './Sections/SectionSecondary/Compensation';
import Role from './Sections/SectionSecondary/Role/Role';

const Employment = () => {
  const initialEmployment = useAppSelector(selectUserEmployment);

  useToolbarTitle({ title: ['Profile', 'Employment'] });

  return (
    <>
      <Details employment={initialEmployment} />
      <Role />
      <Compensation />
      <DirectReports />
    </>
  );
};

export default Employment;
