import { useRef, useEffect, useState } from 'react';
import classes from '../styles/AwayUser.module.scss';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import {
  checkIsPublicHoliday,
  checkIsToday,
  getAllDatesInMonth,
  getSplittedAwayClass,
} from '../utils/dates';
import PublicHoliday from './PublicHoliday';

type Props = {
  isFirst: boolean;
  currentDate: Date | 'Today';
  hoveredDay: number | null;
  setHoveredDay: (hoveredDay: number | null) => void;
};

const AwayUserPlaceholder = ({ currentDate, hoveredDay, setHoveredDay, isFirst }: Props) => {
  const { publicHolidays } = useAppSelector((state) => state.timeline);
  const [numberOfLines, setNumberOfLines] = useState<number | null>(null);

  const mainDivRef = useRef<HTMLDivElement | null>(null);

  const ref = useRef<HTMLDivElement | null>(null);

  const allDates = getAllDatesInMonth(currentDate);

  useEffect(() => {
    if (mainDivRef && mainDivRef.current) {
      const calcNumberOfLines = mainDivRef.current.clientHeight / 5;
      setNumberOfLines(calcNumberOfLines > 6 ? Math.round(calcNumberOfLines) : 6);
    }
  }, [mainDivRef]);

  return (
    <div
      className={`${classes['c-away-user']} ${classes['c-away-user--placeholder']}`}
      ref={mainDivRef}
    >
      <div
        className={`${classes['c-away-user__user-info']} ${
          isFirst ? classes['c-away-user__user-info--first'] : ''
        }`}
      />
      <div className={classes['c-away-user__aways']} ref={ref}>
        <div className={classes['c-away-user__splited-away']}>
          {[...allDates].map((date) => (
            <div
              key={date.getTime()}
              className={`${classes['c-away-user__day']} ${
                classes[getSplittedAwayClass(hoveredDay, date)]
              }`}
              onMouseEnter={() => setHoveredDay(date.getTime())}
              onMouseLeave={() => setHoveredDay(null)}
            >
              {checkIsToday(date, currentDate) && (
                <div className={classes['c-away-user__current-day-horizontal']} />
              )}
              {checkIsPublicHoliday(publicHolidays, date) && numberOfLines && (
                <PublicHoliday numberOfLines={numberOfLines} isPlaceHolder />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AwayUserPlaceholder;
