import { useCallback, useEffect, useState } from 'react';
import { searchUsers, sortUsers } from 'utils/searchSortItems';
import { WorkflowInstance } from '../model/workflowInstance.model';
import { AppliedFilters } from 'components/core/Filter/redux/filterSlice';

type UseFilteredWorkflows = {
  filteredWorkflows: WorkflowInstance[];
};

export const useFilteredWorkflows = (
  workflows: WorkflowInstance[],
  appliedFilters: AppliedFilters,
  searchTerm: string,
): UseFilteredWorkflows => {
  const [filteredWorkflowInstances, setFilteredWorkflowInstances] =
    useState<WorkflowInstance[]>(workflows);

  const filterWorkflows = useCallback(() => {
    const { workflowNames, departmentNames, locationNames } = appliedFilters;

    const filteredWorkflows = workflows.filter((workflow) => {
      let shouldIncludeWorkflow = true;

      if (departmentNames.length && !departmentNames.includes(workflow.userDto.department)) {
        shouldIncludeWorkflow = false;
      }

      if (locationNames.length && !locationNames.includes(workflow.userDto.placeOfWork)) {
        shouldIncludeWorkflow = false;
      }

      if (workflowNames.length && !workflowNames.includes(workflow.workflowName)) {
        shouldIncludeWorkflow = false;
      }

      return shouldIncludeWorkflow;
    });

    const sortedWorkflows = [...filteredWorkflows]
      .filter((x) => x.userDto != null)
      .sort((x, y) =>
        sortUsers({
          firstNameX: x.userDto.firstName.toLowerCase(),
          firstNameY: y.userDto.firstName.toLowerCase(),
          lastNameX: x.userDto.lastName.toLowerCase(),
          lastNameY: y.userDto.lastName.toLowerCase(),
          effectiveOnX: x.userDto.effectiveOn.toString(),
          effectiveOnY: y.userDto.effectiveOn.toString(),
          sortBy: appliedFilters.sortBy.filterValue,
        }),
      );

    const searchedWorkflows = sortedWorkflows.filter((workflow) => {
      const { firstName, lastName, placeOfWork, department, jobTitle, reportingToUser } =
        workflow.userDto;

      return searchUsers({
        firstName,
        lastName,
        placeOfWork,
        department,
        jobTitle,
        reportingTo: `${reportingToUser?.firstName} ${reportingToUser?.lastName}`,
        workflowName: workflow.workflowName,
        searchInput: searchTerm,
      });
    });

    setFilteredWorkflowInstances(searchedWorkflows);
  }, [appliedFilters, searchTerm, workflows]);

  useEffect(() => {
    filterWorkflows();
  }, [appliedFilters, searchTerm, workflows, filterWorkflows]);

  return { filteredWorkflows: filteredWorkflowInstances };
};
