import { useState } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/ArrowRight.svg';
import type { CustomField as CustomFieldModel } from 'modules/settings/models/settingsModels';
import classes from './CustomField.module.scss';
import CustomFieldModal from '../CustomFieldModal/CustomFieldModal';
import SectionItem from '../../Section/SectionItem';
import { ActionConfirmationModal, PopUpModal } from 'components/core';

type Props = {
  item: CustomFieldModel;
  onUpdate: (customField: CustomFieldModel) => void;
  onDelete: (customFieldId: number) => void;
  isLoading: boolean;
};

const CustomField = ({ item, onUpdate, onDelete, isLoading }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { id, name } = item;

  const sectionName = item.sectionName.split('_');

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenConfirmationModal = () => setIsConfirmationModalOpen(true);
  const handleCloseConfirmationModal = () => setIsConfirmationModalOpen(false);

  const handleUpdate = (updatedCustomField: CustomFieldModel) => {
    onUpdate(updatedCustomField);
    handleCloseModal();
  };

  const handleDelete = () => {
    onDelete(id);
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <SectionItem
        itemContent={
          <div className={classes['c-custom-field__left-container']}>
            <span className={classes['c-custom-field__text']}>{sectionName[0].toLowerCase()}</span>
            <ArrowIcon />
            <span className={classes['c-custom-field__text']}>{sectionName[1].toLowerCase()}</span>
            <ArrowIcon />
            <span className={classes['c-custom-field__text']}>{name}</span>
          </div>
        }
        onEdit={handleOpenModal}
        onDelete={handleOpenConfirmationModal}
      />
      <PopUpModal
        title="Edit custom field"
        opened={openModal}
        closeModal={handleCloseModal}
        width={30}
      >
        <CustomFieldModal
          type="edit"
          customField={item}
          onUpdate={handleUpdate}
          isLoading={isLoading}
        />
      </PopUpModal>
      <ActionConfirmationModal
        message={`you want to delete custom field "${name}"?`}
        isModalOpen={isConfirmationModalOpen}
        isLoading={isLoading}
        handleNo={handleCloseConfirmationModal}
        handleYes={handleDelete}
        closeModal={handleCloseConfirmationModal}
      />
    </>
  );
};

export default CustomField;
