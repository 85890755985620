import { createSlice } from '@reduxjs/toolkit';
import { RequestState } from 'config/constants';
import { ReportTemplate } from '../model/exportDataModels';
import { getAllReportTemplates } from './reportTemplateActions';

interface ReportSliceState {
  reportTemplates: ReportTemplate[];
  loading: RequestState;
}

const initialState: ReportSliceState = {
  reportTemplates: [],
  loading: RequestState.IDLE,
};

const reportTemplatesSlice = createSlice({
  name: 'reportTemplates',
  initialState,
  reducers: {
    setReportTemplates: (state, action) => {
      state.reportTemplates = action.payload;
    },
    addReportTemplate: (state, action) => {
      state.reportTemplates = [...state.reportTemplates, action.payload];
    },
    deleteReportTemplate: (state, action) => {
      state.reportTemplates = state.reportTemplates.filter(
        (reportTemplate) => reportTemplate.id !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllReportTemplates.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getAllReportTemplates.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.reportTemplates = action.payload;
      })
      .addCase(getAllReportTemplates.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const { setReportTemplates, addReportTemplate, deleteReportTemplate } =
  reportTemplatesSlice.actions;

export default reportTemplatesSlice;
