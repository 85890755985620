import { type ProjectPhase } from './phase.model';

export type ProjectUser = {
  id: number;
  firstName: string;
  lastName: string;
  profilePicture: string;
};

export type Project = {
  id?: number;
  name: string;
  numberOfPeople?: number;
  numberOfPhases?: number;
  finalPhaseDueDate?: string;
  projectPhases: ProjectPhase[];
  users?: ProjectUser[];
  archived: boolean;
};

export enum ComponentType {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export type NewProject = Pick<Project, 'name' | 'projectPhases'>;
export type ProjectStatusFilter = 'active' | 'archived';
