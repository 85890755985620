import { FC } from 'react';
import { Link } from 'react-router-dom';
import classes from './FormLink.module.scss';

type Props = {
  url: string;
  text: string;
};

const FormLink: FC<Props> = ({ text, url }) => (
  <Link to={url} className={classes['c-form-link']}>
    {text}
  </Link>
);

export default FormLink;
