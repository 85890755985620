import { Dispatch, SetStateAction } from 'react';
import Section from '../../Section/Section';
import ApprovalItem from './ApprovalItem/ApprovalItem';
import { CompanySettings, type TimeAwayCategory } from 'modules/settings/models/settingsModels';
import { updateCompanySettings } from 'modules/settings/api/settings.api';
import { toast } from 'components/core';

type Props = {
  categoriesApprovals: TimeAwayCategory[];
  setCategoriesApprovals: Dispatch<SetStateAction<TimeAwayCategory[]>>;
  handleCategoriesApprovals: (settings: CompanySettings) => void;
};

const Approvals = ({
  categoriesApprovals,
  setCategoriesApprovals,
  handleCategoriesApprovals,
}: Props) => {
  const handleApprovalsUpdate = async (value: boolean, name: string) => {
    const updatedCategories = categoriesApprovals.map((category) => {
      if (category.name === name) {
        return {
          ...category,
          approvalRequired: value,
        };
      }
      return category;
    });

    setCategoriesApprovals(updatedCategories);

    const field = name === 'Time off' ? 'timeOffApprovalRequired' : 'workingAwayApprovalRequired';

    let param;

    switch (field) {
      case 'timeOffApprovalRequired':
        param = 'time-off-approval';
        break;
      case 'workingAwayApprovalRequired':
        param = 'working-away-approval';
        break;
      default:
        param = 'mfa-status';
    }

    try {
      const { data } = await updateCompanySettings(field, value, param);

      handleCategoriesApprovals(data);
    } catch {
      toast('error', 'Something went wrong, please try again.');
    }
  };

  return (
    <Section title="Approvals">
      {categoriesApprovals.map((timeAwayCategory) => (
        <ApprovalItem
          key={timeAwayCategory.name}
          name={timeAwayCategory.name}
          approvalRequired={timeAwayCategory.approvalRequired}
          handleApproval={handleApprovalsUpdate}
        />
      ))}
    </Section>
  );
};

export default Approvals;
