const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  hex = hex.replace(/^#/, '');

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

export const isColorDark = (color: string): boolean => {
  if (color === 'transparent') return false;
  const { r, g, b } = hexToRgb(color);

  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
  return brightness < 128;
};
