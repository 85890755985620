import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getProjectById as getProjectByIdFromApi,
  getAllProjects as getAllProjectFromApi,
} from '../api/projects.api';
import { toast } from 'components/core';
import { ProjectStatusFilter } from '../models';

export const getProjectsByStatus = createAsyncThunk(
  'projects/getAllProjects',
  async (payload: { filter: ProjectStatusFilter } | undefined) => {
    try {
      const response = await getAllProjectFromApi(payload?.filter);
      return response?.data;
    } catch {
      toast('error', 'Something went wrong while getting projects. Please try again.');
    }
  },
);

export const getProjectById = createAsyncThunk('projects/getProjectById', async (id: string) => {
  try {
    const response = await getProjectByIdFromApi(id);
    return response?.data;
  } catch {
    toast('error', 'Something went wrong while getting project. Please try again.');
  }
});
