import { useState, type CSSProperties } from 'react';
import classes from './DeleteButton.module.scss';
import { ReactComponent as BinIcon } from 'assets/Delete.svg';
import { Tooltip } from '../Tooltip/Tooltip';

type Props = {
  style?: CSSProperties;
  disabled?: boolean;
  onClick: () => void;
  tooltipContent? : string
};

export const DeleteButton = ({ style, disabled, onClick, tooltipContent}: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const handleTooltipOpen = () => setIsTooltipOpen((isOpen) => !isOpen);

  return (
    <div
    className={classes['c-delete-button-wrapper']}>
    <button
      className={classes['c-delete-button']}
      style={style}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={handleTooltipOpen}
      onMouseLeave={handleTooltipOpen}
    >
      <BinIcon />
    </button>
    {tooltipContent && <Tooltip isOpen={isTooltipOpen} >
    {tooltipContent} 
    </Tooltip>}
    
  </div>
  );
};
