import classes from './ProjectProgress.module.scss';
import { ProgressBar } from 'components/core';
import { colors } from 'styles/variables';

type Props = {
  progress: number;
};

const { secondary, success } = colors;

const ProjectProgress = ({ progress }: Props) => {
  return (
    <div className={classes['c-project-progress']}>
      <ProgressBar
        color={progress === 100 ? success : secondary}
        barHeight={7}
        completed={String(progress)}
      />
    </div>
  );
};

export default ProjectProgress;
