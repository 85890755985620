import type { ReactNode } from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';
import dayjs from 'dayjs';
import { useOnClickOutside } from 'hooks';
import { type DateRangeType } from './models/dateRangePickerModel';
import classes from './DateRangePicker.module.scss';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { createPortal } from 'react-dom';
import { renderDayColor } from './utils';

type Props = {
  dateRange: DateRangeType[];
  openCalendar: boolean;
  rangeColor?: string;
  minDate?: Date;
  maxDate?: Date;
  children?: ReactNode;
  isSubmitted?: boolean;
  position?: 'left' | 'right' | 'center';
  orientation?: 'horizontal' | 'vertical';
  setDateRange: (dateRange: DateRangeType[]) => void;
  onClick: (dateRangePickerState: boolean) => void;
  workingDays?: string[];
};

export const DateRangePicker = ({
  dateRange,
  setDateRange,
  openCalendar,
  onClick,
  rangeColor = '#ffa95a',
  minDate,
  maxDate,
  children,
  isSubmitted,
  position = 'center',
  orientation = 'horizontal',
  workingDays,
}: Props) => {
  const dropdownRef = useOnClickOutside(() => onClick(false));

  const emptyPicker: DateRangeType[] = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ];

  const ranges =
    dateRange[0].endDate === undefined && dateRange[0].startDate === undefined
      ? emptyPicker
      : dateRange;

  const renderDayContent = (day: Date): ReactNode => {
    return (
      <div
        style={{
          color: renderDayColor(day, ranges, workingDays),
        }}
      >
        {day.getDate()}
      </div>
    );
  };

  const handleSelect = (ranges: RangeKeyDict) => {
    const { startDate, endDate } = ranges.selection as DateRangeType;
    if (
      workingDays &&
      (!workingDays.includes(dayjs(startDate).format('dddd').toUpperCase()) ||
        !workingDays.includes(dayjs(endDate).format('dddd').toUpperCase()))
    ) {
      return;
    }
    setDateRange([ranges.selection as DateRangeType]);
  };

  return (
    <div
      className={`${classes['c-date-range-picker']} ${
        orientation === 'horizontal'
          ? classes['c-date-range-picker--horizontal']
          : classes['c-date-range-picker--vertical']
      }`}
      ref={dropdownRef}
    >
      <div className={classes['c-date-range-picker__inputs']}>
        <div className={classes['c-date-range-picker__input-container']}>
          <label className={classes['c-date-range-picker__input-label']}>Start date</label>
          <div
            className={`${classes['c-date-range-picker__input']} ${
              isSubmitted && !dateRange[0].startDate
                ? classes['c-date-range-picker__input--invalid']
                : ''
            }`}
            onClick={() => onClick(!openCalendar)}
          >
            {dateRange[0].startDate
              ? dayjs(dateRange[0].startDate?.toString()).format('DD/MM/YYYY')
              : ''}
          </div>
        </div>
        <div className={classes['c-date-range-picker__input-container']}>
          <label className={classes['c-date-range-picker__input-label']}>End date</label>
          <div
            className={`${classes['c-date-range-picker__input']} ${
              isSubmitted && !dateRange[0].endDate
                ? classes['c-date-range-picker__input--invalid']
                : ''
            }`}
            onClick={() => onClick(!openCalendar)}
          >
            {dateRange[0].endDate
              ? dayjs(dateRange[0].endDate?.toString()).format('DD/MM/YYYY')
              : ''}
          </div>
        </div>
      </div>
      {openCalendar &&
        createPortal(
          <div
            className={`${classes['c-date-range-picker__calendar']} ${
              classes[`c-date-range-picker__calendar--${position}`]
            }`}
          >
            <DateRange
              editableDateInputs={false}
              moveRangeOnFirstSelection={false}
              ranges={ranges}
              months={2}
              direction="horizontal"
              rangeColors={[rangeColor]}
              minDate={minDate}
              maxDate={maxDate}
              showDateDisplay={false}
              onChange={handleSelect}
              {...(workingDays && { dayContentRenderer: renderDayContent })}
            />
            {children}
          </div>,
          dropdownRef?.current as Element,
        )}
    </div>
  );
};
