import type { TimeAway } from 'modules/timeline/models/timeAwayModels';
import type { ColorLegendItem } from '../models';
import type { TimeAwayType } from 'modules/shared/model';

export const getFilteredTimeAways = (
  timeAways: TimeAway[],
  colorLegendItems: ColorLegendItem[],
): TimeAway[] => {
  const selectedLegendItems = colorLegendItems.filter((legendItem) => legendItem.isSelected);

  if (!selectedLegendItems.length) return timeAways;

  return timeAways.filter((timeAway) =>
    colorLegendItems.find(
      (legendItem) => legendItem.label === timeAway.timeAwayType.name && legendItem.isSelected,
    ),
  );
};

export const mapTimeAwayTypesToColorLegendItems = (
  timeAwayTypes: TimeAwayType[],
): ColorLegendItem[] => {
  return timeAwayTypes.map(({ name, color }) => ({
    label: name,
    color,
    isSelected: false,
  }));
};
