import { type ChangeEvent, useCallback, useState, useEffect } from 'react';
import SectionItem from 'modules/settings/components/Section/SectionItem';
import classes from './ApprovalItem.module.scss';
import { InputRadio } from 'components/core';

type Props = {
  name: string;
  approvalRequired: boolean;
  handleApproval: (value: boolean, name: string) => void;
};

const ApprovalItem = ({ name, approvalRequired, handleApproval }: Props) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [isApprovalRequired, setIsApprovalRequired] = useState<boolean>(approvalRequired);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsApprovalRequired(event.target.value === 'approval');
  }, []);

  const handleCancel = () => {
    setIsApprovalRequired(approvalRequired);
    setOpenEdit(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    await handleApproval(isApprovalRequired, name);
    setIsLoading(false);
    setOpenEdit(false);
  };

  useEffect(() => {
    setIsApprovalRequired(approvalRequired);
  }, [approvalRequired]);

  return (
    <div className={classes['c-approval-item']}>
      <SectionItem
        itemContent={name}
        itemOption={approvalRequired ? 'approval required' : 'no approval required'}
        onEdit={() => setOpenEdit(true)}
        onCancel={handleCancel}
        onSave={handleSave}
        isLoading={isLoading}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
      />
      {openEdit && (
        <div className={classes['c-approval-item__options-container']}>
          <div className={classes['c-approval-item__option']}>
            <div className={classes['c-approval-item__input-wrap']}>
              <InputRadio
                id="noApproval"
                value="noApproval"
                label=""
                name={name}
                checked={!isApprovalRequired}
                onChange={handleOnChange}
              />
              <label className={classes['c-approval-item__label']}>No approval required.</label>
            </div>
            <span className={classes['c-approval-item__description']}>
              Employees can freely register time working away. Managers will still be notified via
              email.
            </span>
          </div>
          <div className={classes['c-approval-item__option']}>
            <div className={classes['c-approval-item__input-wrap']}>
              <InputRadio
                id="approval"
                value="approval"
                label=""
                name={name}
                checked={isApprovalRequired}
                onChange={handleOnChange}
              />
              <label className={classes['c-approval-item__label']}>Approval required.</label>
            </div>
            <span className={classes['c-approval-item__description']}>
              Managers will need to approve time working away requests from their reports.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApprovalItem;
