import { Role } from 'config/constants';

export const useUserRole = (role?: Role) => {
  const isRegularUser = role === Role.USER;
  const isAdmin = role === Role.ADMINISTRATOR;
  const isSuperAdmin = role === Role.SUPER_ADMINISTRATOR;
  const isAdminOrSuperAdmin = isAdmin || isSuperAdmin;

  return {
    isRegularUser,
    isAdmin,
    isSuperAdmin,
    isAdminOrSuperAdmin,
  };
};
