import { type ChangeEvent } from 'react';
import classes from './InputRadio.module.scss';

type Props = {
  id: string;
  name: string;
  value: string;
  label: string;
  checked?: boolean;
  size?: 'small' | 'medium';
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const InputRadio = ({ id, name, value, label, checked, size, onChange }: Props) => {
  return (
    <div className={`${classes['c-input-radio']} ${classes[`c-input-radio--${size}`]}`}>
      <input
        type="radio"
        id={id}
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        className={classes['c-input-radio__input']}
      />
      <label htmlFor={id} className={classes['c-input-radio__label']}>
        {label}
      </label>
    </div>
  );
};
