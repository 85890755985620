import { useCallback, useEffect, useMemo, useState } from 'react';
import classes from '../../../styles/Section.module.scss';
import { ReactComponent as TypeSvg } from 'assets/blue-svgs/Type-blue.svg';
import { ReactComponent as People } from 'assets/People.svg';
import { ReactComponent as Mail } from 'assets/Mail.svg';
import { ReactComponent as LocationIcon } from 'assets/LocationIcon.svg';
import { ReactComponent as AttachmentSvg } from 'assets/blue-svgs/Attachment-blue.svg';
import { SectionInfoEdit, SectionActions } from 'modules/shared/components';
import SectionInfoReadOnly from '../../SectionInfoReadOnly/SectionInfoReadOnly';
import { type Identification } from 'modules/profile/models/profileModels';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import {
  updateIdentification as updateIdentificationAction,
  deleteIdentification as deleteIdentificationAction,
} from 'modules/profile/redux/userSlice';
import toast from 'utils/notifications/CustomToast';
import {
  deleteIdentification,
  downloadIdentification,
  updateIdentification,
} from 'modules/profile/api/profile.api';
import { Role } from 'config/constants';
import dayjs from 'dayjs';
import { ActionConfirmationModal, DropDown } from 'components/core';

type Props = {
  identification: Identification;
  userAuthId?: number;
  userRole?: Role;
};

type RequiredErrors = {
  identificationType: boolean;
  number: boolean;
  expiryDate: boolean;
  countryOfIssue: boolean;
};

const IdentificationTable = ({ identification }: Props) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formInputs, setFormInputs] = useState<Identification>({
    ...identification,
  });
  const [requiredFields, setRequiredFields] = useState<RequiredErrors>({
    identificationType: false,
    number: false,
    expiryDate: false,
    countryOfIssue: false,
  });
  const [file, setFile] = useState<File | undefined>(
    new File([], identification.originalFileName || ''),
  );

  const dispatch = useAppDispatch();

  const formattedIdentificationType = useMemo(() => {
    if (identification.identificationType === 'ID_CARD') return 'ID card';
    return identification.identificationType.toLocaleLowerCase().replace('_', ' ');
  }, [identification.identificationType]);

  const changeAttributeHandler = (value: string, changeAttribute: string) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [changeAttribute]: value,
    }));
  };

  const requiredFieldsMissing = () => {
    const errors = { ...requiredFields };
    Object.entries(errors).forEach(([key]) => {
      const prop = key as keyof Identification;
      const errorProp = key as keyof RequiredErrors;

      errors[errorProp] = !formInputs[prop]?.toString().length;
    });
    setRequiredFields(errors);
    return Object.entries(errors).some(([, val]) => val);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFile(e.target.files[0]);
  };

  const updateIdentificationHandler = async () => {
    setIsSubmitted(true);

    if (requiredFieldsMissing()) {
      return;
    }
    if (!file) {
      toast.error('Please add a document file.');
      return;
    }

    const updatedIdentification = {
      id: identification.id,
      identificationType: formInputs.identificationType,
      number: formInputs.number,
      expiryDate: dayjs(formInputs.expiryDate).format('YYYY-MM-DD'),
      countryOfIssue: formInputs.countryOfIssue,
      note: formInputs.note,
      userId: identification.userId,
    };
    setIsLoading(true);
    try {
      const { data } = await updateIdentification(updatedIdentification, file);

      toast.success('You have successfully updated identification.');
      setOpenForm(false);
      dispatch(updateIdentificationAction(data));
    } catch {
      toast.error('Something went wrong while saving. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = useCallback(() => {
    setOpenForm(true);
    setFormInputs(identification);
  }, [identification]);

  const handlePreview = () => {
    downloadIdentification(identification.userId, identification.id)
      .then((res) => {
        const href = URL.createObjectURL(res.data);
        window.open(href, '_blank');

        URL.revokeObjectURL(href);
      })
      .catch(() => toast.error('Something went wrong. Try again.'));
  };

  const handleCancel = useCallback(() => {
    setIsSubmitted(false);
    setOpenForm(false);
    setFile(new File([], identification.originalFileName || ''));
    setFormInputs(identification);
  }, [identification]);

  const handleDownload = async () => {
    setIsLoading(true);

    try {
      const { data } = await downloadIdentification(identification.userId, identification.id);
      const href = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', identification.originalFileName || 'identification-doc');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch {
      toast.error('Something went wrong while downloading identification file. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      await deleteIdentification(identification.userId, identification.id);
      dispatch(deleteIdentificationAction(identification.id));
      setOpenDialog(false);
      toast.success('You have successfully deleted identification.');
    } catch {
      toast.error('Something went wrong while deleting identification. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFormInputs(identification);
  }, [identification]);

  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <h2 className={classes['c-section__title']}>{formattedIdentificationType}</h2>
        <SectionActions
          isEditOpen={openForm}
          handleDownload={handleDownload}
          handlePreview={handlePreview}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          handleSave={updateIdentificationHandler}
          handleDelete={() => setOpenDialog(true)}
          isLoading={isLoading}
        />
      </div>
      <div className={classes['c-section__content']}>
        <>
          <SectionInfoEdit
            name="Number"
            data={formInputs.number}
            icon={<People />}
            isInvalid={requiredFields.number}
            isSubmitted={isSubmitted}
            setValue={(event) => {
              changeAttributeHandler(event, 'number');
            }}
            readOnly={!openForm}
          />
          <SectionInfoEdit
            name="Expiry date"
            data={formInputs.expiryDate && dayjs(formInputs.expiryDate).format('YYYY-MM-DD')}
            icon={<People />}
            isInvalid={requiredFields.expiryDate}
            isSubmitted={isSubmitted}
            inputType="date"
            setValue={(event) => {
              changeAttributeHandler(event, 'expiryDate');
            }}
            readOnly={!openForm}
          />
          <SectionInfoEdit
            name="Country of issue"
            data={formInputs.countryOfIssue}
            icon={<LocationIcon />}
            isInvalid={requiredFields.countryOfIssue}
            isSubmitted={isSubmitted}
            setValue={(event) => {
              changeAttributeHandler(event, 'countryOfIssue');
            }}
            readOnly={!openForm}
          />
          <SectionInfoEdit
            name="Note"
            data={formInputs.note || ''}
            icon={<Mail />}
            setValue={(event) => {
              changeAttributeHandler(event, 'note');
            }}
            readOnly={!openForm}
          />
          {openForm ? (
            <div className={classes['c-section__section-item']}>
              <div className={classes['c-section__input-container']}>
                <label className={classes['c-section__input-label']}>
                  <AttachmentSvg className={classes['c-section__label-icon']} />
                  <span className={classes['c-section__input-label-span']}>File</span>
                </label>
                <label className={classes['c-section__input-file-label']} htmlFor="file-upload">
                  {file?.name || 'Add document'}
                </label>
                <input id="file-upload" type="file" multiple={false} onChange={handleFileChange} />
              </div>
            </div>
          ) : (
            <SectionInfoReadOnly
              name="File"
              icon={<AttachmentSvg />}
              type="file"
              data={formInputs.originalFileName || ''}
              handlePreview={handlePreview}
              handleDownload={handleDownload}
            />
          )}
          {openForm && (
            <div className={classes['c-section__input-container']}>
              <label className={classes['c-section__input-label']}>
                <TypeSvg className={classes['c-section__label-icon']} />
                <span className={classes['c-section__input-label-span']}>Type</span>
              </label>
              <div className={classes['c-section__dropdown-container']}>
                <DropDown
                  displayedOptions={['ID card', 'Driving licence', 'Passport', 'Visa']}
                  options={['ID_CARD', 'DRIVING_LICENCE', 'PASSPORT', 'VISA']}
                  selectedOption={formInputs.identificationType}
                  setSelectedOption={(option) => {
                    setFormInputs((prev) => ({
                      ...prev,
                      identificationType: option,
                    }));
                  }}
                  selectedValueAlign="right"
                />
              </div>
            </div>
          )}
        </>
      </div>
      <ActionConfirmationModal
        message={`you want to delete identification "${formattedIdentificationType}"?`}
        isModalOpen={openDialog}
        closeModal={() => setOpenDialog(false)}
        isLoading={isLoading}
        handleYes={handleDelete}
        handleNo={() => setOpenDialog(false)}
      />
    </div>
  );
};

export default IdentificationTable;
