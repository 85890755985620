import { api } from 'lib';
import { ExportModel, ReportTemplate } from '../model/exportDataModels';
import { getOrganizationId } from 'state/utils';

export const createReport = async (exportFields: ExportModel) => {
  return api.post(`/organization/${getOrganizationId()}/basic-report`, exportFields, {
    responseType: 'blob',
  });
};

export const createReportTemplate = async (newTemplate: ReportTemplate, inputFields: string) => {
  return api.post(`/organization/${getOrganizationId()}/report-template`, {
    ...newTemplate,
    inputFields,
  });
};

export const deleteReportTemplate = async (templateId: number) => {
  return api.delete(`/organization/${getOrganizationId()}/report-template/${templateId}`);
};
