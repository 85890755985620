import type { ReactNode } from 'react';
import {
  flexAlign,
  flexGap,
  flexJustify,
  type Align,
  type Gap,
  type Justify,
  type Properties,
} from './utils';

type Props = {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justify?: Justify;
  align?: Align;
  gap?: Gap;
  children: ReactNode;
};

export const Container = ({
  direction = 'row',
  justify = 'start',
  align = 'center',
  gap = 's',
  children,
}: Props) => {
  const style: Properties = {
    display: 'flex',
    flexDirection: direction,
    justifyContent: flexJustify[justify],
    alignItems: flexAlign[align],
    gap: flexGap[gap],
  };

  return <div style={style}>{children}</div>;
};
