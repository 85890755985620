import { api } from 'lib';
import { type NewAccount } from 'components/core/Popups/Profile/Banking/BankingPopup';
import { type NewCompensation } from '../components/Modals/Compensation/AddCompensationModal';
import { type NewEquipment } from '../components/Modals/Equipment/EquipmentModal';
import type {
  Equipment,
  Compensation,
  Employment,
  BankAccount,
  Social,
  Health,
  Identification,
  UserDocument,
  OffboardingModel,
  EmergencyContact,
  PtoPolicyUpdateData,
  UserPtoPolicyData,
  UserCustomField,
} from '../models/profileModels';
import { getOrganizationId } from 'state/utils/organization';
import { formatDateForInput } from 'utils';
import { CustomField } from 'modules/settings/models/settingsModels';

export type UpdateUser = {
  id: number;
  firstName: string;
  lastName: string;
  userStatus: string;
  gender: string;
  personalEmail: string;
  email: string;
  address: string;
  nationalities: string;
  dateOfBirth: Date | string;
  personalPhone: string;
  basicCustomFields: UserCustomField[];
};

export const updateUser = async (user: UpdateUser) => {
  return api.put(`/organization/${getOrganizationId()}/user/${user.id}`, user);
};

export const updateEmployment = async (userId: string, employment: Employment) => {
  return api.put(
    `/organization/${getOrganizationId()}/user/${userId}/employment/${employment.id}`,
    {
      id: employment.id,
      userId: employment.userId,
      employeeId: employment.employeeId,
      taxId: employment.taxId,
      taxCode: employment.taxCode,
      contractType: employment.contractType,
      payrollProvider: employment.payrollProvider,
      organizationLocationId: employment.organizationLocationId,
      startDate: employment.startDate,
      probationEndsAt: employment.probationEndsAt,
      firstWorkingDay: employment.firstWorkingDay,
      workingDays: employment.workingDays,
      customFields: employment.customFields,
    },
  );
};

export const updateRole = async (userId: string, employment: Employment, updateData: any) => {
  const roleId = employment.employmentRole.id;

  return api.put(
    `/organization/${getOrganizationId()}/user/${userId}/employment/${
      employment.id
    }/employment-role/${roleId}`,
    updateData,
  );
};

export const updateUserSocial = async (userId: string, social: Social) => {
  return api.put(`/organization/${getOrganizationId()}/user/${userId}/user-social`, social);
};

export const updateCompensation = async (
  userId: string,
  compensation: Compensation,
  employmentId: number,
) => {
  return api.put(
    `/organization/${getOrganizationId()}/user/${userId}/employment/${employmentId}/compensation/${compensation?.id}`,
    {
      ...compensation,
    },
  );
};

export const updateHealth = async (health: Health, userId: string) => {
  return api.patch(`/organization/${getOrganizationId()}/user/${userId}/health`, health);
};

export const updateEquipment = async (equipment: Equipment) => {
  return api.put(`/organization/${getOrganizationId()}/equipment-piece/${equipment.id}`, equipment);
};

export const updateUserPtoPolicy = (userId: number, data: PtoPolicyUpdateData) => {
  return api.put<UserPtoPolicyData>(
    `/organization/${getOrganizationId()}/user/${userId}/pto-policy`,
    data,
  );
};

export const deleteEmergencyContact = async (emergencyContactId: number, userId: number) => {
  return api.delete(
    `/organization/${getOrganizationId()}/user/${userId}/emergency-contact/${emergencyContactId}`,
  );
};

export const updateEmergencyContact = async (userId: number, updatedContact: EmergencyContact) => {
  return api.put(
    `/organization/${getOrganizationId()}/user/${userId}/emergency-contact/${updatedContact.id}`,
    updatedContact,
  );
};

export const createEquipment = async (equipment: NewEquipment) => {
  return api.post(`/organization/${getOrganizationId()}/equipment-piece`, equipment);
};

export const createCompensation = async (
  compensation: NewCompensation,
  userId: number,
  employmentId: number,
) => {
  return api.post(
    `/organization/${getOrganizationId()}/user/${userId}/employment/${employmentId}/compensation`,
    compensation,
  );
};

export const updateBankAccount = async (userId: number, account: BankAccount) => {
  return api.put(`/organization/${getOrganizationId()}/user/${userId}/bank-account/${account.id}`, {
    ...account,
  });
};

export const createBankAccount = async (account: NewAccount, userId: string) => {
  return api.post(`/organization/${getOrganizationId()}/user/${userId}/bank-account`, {
    ...account,
  });
};

export const deleteBankAccount = async (accountId: number, userId: string) => {
  return api.delete(
    `/organization/${getOrganizationId()}/user/${userId}/bank-account/${accountId}`,
  );
};

export const createIdentification = async (identification: Identification, file: File) => {
  const formData = new FormData();

  formData.append('id', String(identification.id));
  formData.append('identificationType', identification.identificationType);
  formData.append('number', identification.number);
  formData.append('expiryDate', formatDateForInput(identification.expiryDate));
  formData.append('note', identification?.note || '');
  formData.append('countryOfIssue', identification.countryOfIssue);
  formData.append('userId', String(identification.userId));
  formData.append('originalFileName', file.name);
  formData.append('multipartFile', file);

  return api.post(
    `/organization/${getOrganizationId()}/user/${identification.userId}/identification`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
};

export const updateIdentification = async (identification: Identification, file: File) => {
  const formData = new FormData();

  Object.entries(identification).forEach(([key, value]) => {
    formData.append(key, String(value));
  });

  formData.append('originalFileName', file.name);
  formData.append('multipartFile', file);

  return api.put(
    `/organization/${getOrganizationId()}/user/${identification.userId}/identification/${
      identification.id
    }`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
};

export const deleteIdentification = async (userId: number, id: number) => {
  return api.delete(`/organization/${getOrganizationId()}/user/${userId}/identification/${id}`);
};

export const createUserDocument = async (document: UserDocument, id: string, file: File) => {
  const formData = new FormData();

  formData.append('name', document.name);
  formData.append('type', document.type);
  formData.append('multipartFile', file);

  return api.post(`/organization/${getOrganizationId()}/user/${id}/user-document`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateUserDocument = async (document: UserDocument, id: string, file: File) => {
  const formData = new FormData();

  Object.entries(document).forEach(([key, value]) => {
    formData.append(key, String(value));
  });

  formData.append('multipartFile', file);

  return api.put(
    `/organization/${getOrganizationId()}/user/${id}/user-document/${document.id}`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
};

export const downloadUserDocument = async (userId: number, documentId: number) => {
  const response = await api.get(
    `/organization/${getOrganizationId()}/user/${userId}/user-document/${documentId}`,
    {
      responseType: 'blob',
    },
  );

  return response;
};

export const calculateRemainingDays = async (
  userId: number,
  daysToRemove: number,
  daysToAdd: number,
  ptoPolicyId: number,
) => {
  const url = `/organization/${getOrganizationId()}/user/${userId}/pto-policy/update-preview`;
  const queryParams = { daysToRemove, daysToAdd, ptoPolicyId };
  const response = await api.get(url, { params: queryParams });
  return response.data;
};

export const downloadIdentification = async (userId: number, identificationId: number) => {
  const response = await api.get(
    `/organization/${getOrganizationId()}/user/${userId}/identification/${identificationId}/download`,
    {
      responseType: 'blob',
    },
  );

  return response;
};

export const deleteUserDocument = async (userId: number, id: number) => {
  return api.delete(`/organization/${getOrganizationId()}/user/${userId}/user-document/${id}`);
};

export const deleteCompensation = async (
  userId: string,
  compensationId: string,
  employmentId: number,
) => {
  return api.delete(
    `/organization/${getOrganizationId()}/user/${userId}/employment/${employmentId}/compensation/${compensationId}`,
  );
};

export const createOffboarding = async (offboarding: OffboardingModel, userId: string) => {
  return api.post(`/organization/${getOrganizationId()}/user/${userId}/offboard`, {
    ...offboarding,
  });
};

export const deleteOffboarding = async (offboardingId: number, userId: string) => {
  return api.delete(
    `/organization/${getOrganizationId()}/user/${userId}/offboard/${offboardingId}`,
  );
};

export const updateDirectReports = async (reportingUserIds: number[], userId: string) => {
  return api.put(
    `/organization/${getOrganizationId()}/user/${userId}/set-direct-reporting-users`,
    reportingUserIds,
  );
};

export const createEmergencyContact = (newEmergencyContact: EmergencyContact, userId: number) => {
  return api.post(
    `/organization/${getOrganizationId()}/user/${userId}/emergency-contact`,
    newEmergencyContact,
  );
};

export const uploadProfilePicture = async (userId: string, file: File) => {
  const formData = new FormData();

  formData.append('name', file.name);
  formData.append('type', file.type);
  formData.append('file', file);

  return api.post(`/organization/${getOrganizationId()}/user/${userId}/profile-photo`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getCustomFieldById = async (customFieldId: number) => {
  return api.get<CustomField>(`/organization/${getOrganizationId()}/custom-field/${customFieldId}`);
};

export const resendVerificationToken = async (email: string) => {
  return api.post('/resend-registration-link', { email });
};
