import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { AddMemberDropDown, Button, DropDown, DropdownItem } from 'components/core';
import { months } from 'config/constants';
import { TimesheetsFilters } from 'modules/timesheet/models/timesheet.models';
import classes from './TimesheetsOverviewHeader.module.scss';
import { getAllTimesheets, getTimesheetProjects } from 'modules/timesheet/redux/timesheetsActions';
import { getProjectsByStatus } from 'modules/projects/redux/projectActions';
import { selectProjects } from 'modules/projects/redux/projectSlice';
import { getUserId } from 'state/utils';
import { selectProjectOptions } from 'modules/timesheet/redux/timesheetsSlice';
import { UserSearchInputComponent } from 'components/core/Popups/People/UserSearchInputComponent/UserSearchInputComponent';

type Props = {
  isAdminOrSuperAdmin: boolean;
  handleOpenModal: () => void;
};

const TimesheetsOverviewHeader = ({ isAdminOrSuperAdmin, handleOpenModal }: Props) => {
  const [timesheetFilters, setTimesheetFilters] = useState<TimesheetsFilters>({});
  const [projectsOptions, setProjectsOptions] = useState<DropdownItem[]>([]);

  const { projects } = useAppSelector(selectProjects);
  const userProjectOptions = useAppSelector(selectProjectOptions);
  const userId = getUserId();

  const dispatch = useAppDispatch();
  const monthOptions = Object.keys(months);

  const yearOptions = useMemo(() => {
    const years = [];

    for (let i = -1; i <= 10; i++) {
      const newYear = new Date().getFullYear() - i;

      years.push(newYear.toString());
    }

    return years;
  }, []);

  const handleFiltersChange = useCallback((value: number | string, name: string) => {
    setTimesheetFilters((prevState) => {
      return { ...prevState, [name]: value };
    });
  }, []);
  useEffect(() => {
    if (isAdminOrSuperAdmin) {
      setProjectsOptions(projects.map((project) => ({ id: project.id, value: project.name })));
      return;
    }

    setProjectsOptions(userProjectOptions);
  }, [isAdminOrSuperAdmin, projects, userProjectOptions]);

  useEffect(() => {
    if (isAdminOrSuperAdmin) {
      dispatch(getAllTimesheets({ timesheetFilters }));
      return;
    }

    dispatch(getAllTimesheets({ timesheetFilters: { ...timesheetFilters, userId } }));
  }, [timesheetFilters, dispatch, isAdminOrSuperAdmin, userId]);

  useEffect(() => {
    if (isAdminOrSuperAdmin) {
      dispatch(getProjectsByStatus());

      return;
    }

    if (!userId) return;

    dispatch(getTimesheetProjects({ userId }));
  }, [dispatch, isAdminOrSuperAdmin, userId]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    if (timesheetFilters[id as keyof TimesheetsFilters] !== undefined) {
      setTimesheetFilters((prevState) => ({ ...prevState, [id]: undefined }));
    }
  };
  return (
    <div className={classes['c-timesheets-overview-header']}>
      <div className={classes['c-timesheets-overview-header__filters']}>
        {isAdminOrSuperAdmin && (
          <div className={classes['c-timesheets-overview-header__filter-wrap']}>
            <label>User</label>
            <UserSearchInputComponent
              id="userId"
              selectedUser={timesheetFilters.userId || undefined}
              setSelectedUser={(value: number) => handleFiltersChange(value, 'userId')}
              handleOnChange={handleOnChange}
            />
          </div>
        )}
        <div className={classes['c-timesheets-overview-header__filter-wrap']}>
          <label>Month</label>
          <DropDown
            options={monthOptions}
            selectedOption={timesheetFilters.month || undefined}
            setSelectedOption={(value: string) => handleFiltersChange(value, 'month')}
          />
        </div>
        <div className={classes['c-timesheets-overview-header__filter-wrap']}>
          <label>Year</label>
          <DropDown
            options={yearOptions}
            selectedOption={timesheetFilters.year?.toString() || ''}
            setSelectedOption={(value: string) => handleFiltersChange(+value, 'year')}
          />
        </div>
        <div className={classes['c-timesheets-overview-header__filter-wrap']}>
          <label>Project</label>
          <AddMemberDropDown
            id="projectId"
            items={projectsOptions}
            setSelectedItemId={(projectId: number) => handleFiltersChange?.(projectId, 'projectId')}
            selectedValue={
              projectsOptions.find(({ id }) => timesheetFilters.projectId === id)?.value
            }
            isAddNewDisabled
            handleOnChange={handleOnChange}
          />
        </div>
      </div>
      <Button variant="outlined" size="small" onClick={handleOpenModal}>
        Create new
      </Button>
    </div>
  );
};

export default TimesheetsOverviewHeader;
