import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';

export const setupDayjs = (): void => {
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(localeData);
};
