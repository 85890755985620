import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Status, toast } from 'components/core';
import { RequestState, regularWorkingMinutes } from 'config/constants';
import classes from './Timesheet.module.scss';
import { SectionActions } from 'modules/shared/components';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { TimesheetEntry, UserProjectTimesheetRequest } from '../../models/timesheet.models';
import { selectTimesheets } from '../../redux/timesheetsSlice';
import { formatDate } from 'utils';
import {
  getAllTimesheetEntries,
  getTimesheetProjects,
} from 'modules/timesheet/redux/timesheetsActions';
import { updateTimesheetEntries } from 'modules/timesheet/api/timesheet.api';
import TimesheetItem from './TimesheetItem/TimesheetItem';
import { getUserId } from 'state/utils';

type TimesheetEntryUpdate = { [key: string]: UserProjectTimesheetRequest[] };

const Timesheet = () => {
  const { timesheetEntries, loading } = useAppSelector(selectTimesheets);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [entries, setEntries] = useState<TimesheetEntry[]>([]);

  const { id } = useParams();
  const dispatch = useAppDispatch();

  const userId = getUserId();
  const calculateRegularMinutes = (duration: number) => {
    if (duration <= regularWorkingMinutes) return duration;
    return regularWorkingMinutes;
  };

  const calculateOvertimeMinutes = (duration: number) => {
    if (duration <= regularWorkingMinutes) return 0;
    return duration - regularWorkingMinutes;
  };

  const handleSave = async () => {
    if (!id || !entries.length) return;
    try {
      const updatedTimesheetEntries = entries.reduce((result, entry) => {
        result[String(entry.id)] = entry.userProjectTimesheets.map((projectEntry) => ({
          id: entry.userProjectTimesheets[0].id,
          projectId: projectEntry.projectId || 0,
          startDateTime: formatDate(projectEntry.startDateTime, 'YYYY-MM-DD HH:mm:ss'),
          regularMinutes: calculateRegularMinutes(projectEntry.durationInMinutes),
          overtimeMinutes: calculateOvertimeMinutes(projectEntry.durationInMinutes),
        }));
        return result;
      }, {} as TimesheetEntryUpdate);
      await updateTimesheetEntries(+id, updatedTimesheetEntries);

      setIsEditMode(false);
    } catch {
      toast('error', 'Something went wrong while creating timesheet. Try again.');
    }
  };

  const handleCancel = () => {
    setEntries(timesheetEntries);
    setIsEditMode(false);
  };

  useEffect(() => {
    setEntries(timesheetEntries);
  }, [timesheetEntries]);

  useEffect(() => {
    if (id) dispatch(getAllTimesheetEntries(+id));
  }, [dispatch, id]);

  useEffect(() => {
    if (userId) {
      dispatch(getTimesheetProjects({ userId }));
    }
  }, [dispatch, userId]);

  return (
    <div className={classes['c-timesheet']}>
      <div className={classes['c-timesheet__header']}>
        <SectionActions
          isEditOpen={isEditMode}
          isLoading={false}
          handleEdit={() => setIsEditMode(true)}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      </div>
      <Status isLoading={loading === RequestState.PENDING}>
        <table className={classes['c-timesheet__table']}>
          <thead>
            <tr>
              <th className={classes['c-timesheet__table-header-cell']}>Date</th>
              <th className={classes['c-timesheet__table-header-cell']}>Day</th>
              <th className={classes['c-timesheet__table-header-cell']}>Project</th>
              <th className={classes['c-timesheet__table-header-cell']}>Start time</th>
              {isEditMode && (
                <th className={classes['c-timesheet__table-header-cell']}>Duration</th>
              )}
              <th className={classes['c-timesheet__table-header-cell']}>End time</th>
              <th className={classes['c-timesheet__table-header-cell']}>Regular hours</th>
              <th className={classes['c-timesheet__table-header-cell']}>Overtime</th>
              <th className={classes['c-timesheet__table-header-cell']}>Total</th>
            </tr>
          </thead>
          <tbody className={classes['c-timesheet__table-body']}>
            {entries.map((item, index) => (
              <TimesheetItem
                key={item.id}
                index={index}
                timesheetEntry={item}
                isEditMode={isEditMode}
                setEntries={setEntries}
              />
            ))}
          </tbody>
        </table>
      </Status>
    </div>
  );
};

export default Timesheet;
