import { toast } from 'react-toastify';
import classes from './customToast.module.scss';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import { ReactComponent as Invalid } from 'assets/InvalidData.svg';
import { ReactComponent as Success } from 'assets/Success.svg';

toast.success = (message) => {
  return toast(
    <div>
      <div className={classes['c-notification__top-container']}>
        <Success />
        <span className={classes['c-notification__title']}>Success!</span>
      </div>
      <div className={classes['c-notification__message']}>{String(message)}</div>
    </div>,
    {
      className: classes['c-notification--success'],
      icon: null,
    },
  );
};

toast.info = (message, title?) => {
  return toast(
    <div>
      <div className={classes['c-notification__top-container']}>
        <InfoIcon />
        <span className={classes['c-notification__title']}>{title ? String(title) : 'Info!'}</span>
      </div>
      <div className={classes['c-notification__message']}>{String(message)}</div>
    </div>,
    {
      className: classes['c-notification--info'],
      icon: null,
    },
  );
};

toast.warning = (message, title?) => {
  return toast(
    <div>
      <div className={classes['c-notification__top-container']}>
        <Invalid className={classes['c-notification__warrning']} />
        <span className={classes['c-notification__title']}>
          {title ? String(title) : 'Warning!'}
        </span>
      </div>
      <div className={classes['c-notification__message']}>{String(message)}</div>
    </div>,
    {
      className: classes['c-notification--warning'],
      icon: null,
    },
  );
};

toast.error = (message, options) => {
  return toast(
    <div>
      <div className={classes['c-notification__top-container']}>
        <Invalid />
        <span className={classes['c-notification__title']}>Error!</span>
      </div>
      <div className={classes['c-notification__message']}>{String(message)}</div>
    </div>,
    {
      ...options,
      className: classes['c-notification--error'],
      icon: null,
    },
  );
};

export default toast;
