import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserWorkflowSection from './UserWorkflowSection/UserWorkflowSection';
import classes from './UserWorkflowOverviewPage.module.scss';
import UserWorkflowTaskDetails from './UserWorkflowTaskDetails/UserWorkflowTaskDetails';
import {
  closestCorners,
  DndContext,
  type DragOverEvent,
  type DragStartEvent,
  PointerSensor,
  useSensor,
  DragOverlay,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import {
  type SectionInstance,
  type TaskInstance,
} from 'modules/workflows/model/workflowInstance.model';
import { getWorkflowInstance } from 'modules/workflows/redux/workflowInstanceAction';
import { type User } from 'modules/people/model/User';
import {
  archiveWorkflowInstance,
  createTaskInstance,
  deleteWorkflowInstance,
  updateSectionInstance,
} from 'modules/workflows/api/workflowInstances.api';
import {
  addNewSectionTask,
  selectWorkflowInstance,
  setWorkflowInstance,
} from 'modules/workflows/redux/workflowInstanceSlice';
import { RequestState } from 'config/constants';
import { UserInfo } from 'modules/shared/components';
import {
  ActionConfirmationModal,
  Badge,
  Container,
  Status,
  toast,
  ArchiveActionButtons,
} from 'components/core';
import { checkIfWorkflowIsCompleted } from 'modules/workflows/utils';

type ActionType = 'delete' | 'archive';

const UserWorkflowOverviewPage = () => {
  const { workflowName, archived, workflowSectionInstanceDtos, userDto } =
    useAppSelector(selectWorkflowInstance);
  const { loading } = useAppSelector((state) => state.workflowInstances);
  const [user, setUser] = useState<User>(userDto);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [sections, setSections] = useState<SectionInstance[]>(workflowSectionInstanceDtos);
  const [selectedTask, setSelectedTask] = useState<TaskInstance | null>(null);
  const [draggingSection, setDraggingSection] = useState<SectionInstance | null>(null);
  const [areTaskDetailsOpen, setAreTaskDetailsOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ActionType>('delete');
  const [isUserInfoOpen, setIsUserInfoOpen] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const sensor = useSensor(PointerSensor);
  const { id, workflowId } = useParams();

  const areAllTasksCompleted = useMemo(() => checkIfWorkflowIsCompleted(sections), [sections]);

  const handleCloseConfirmationModal = () => setIsConfirmationModalOpen(false);
  const handleOpenConfirmationModal = (actionType: ActionType) => {
    setActionType(actionType);
    setIsConfirmationModalOpen(true);
  };

  const addNewTask = async (workflowInstanceId: number, sectionId: number, taskName: string) => {
    try {
      if (!workflowId) return;

      const { data } = await createTaskInstance(
        +workflowId,
        workflowInstanceId,
        sectionId,
        taskName,
      );
      dispatch(addNewSectionTask({ sectionId, newTask: data }));
      toast('success', 'You have successfully created new task.');
    } catch {
      toast('error', 'Something went wrong while creating new task. Try again.');
    }
  };

  const handleOpenTaskDetails = (taskId: number, sectionId: number) => {
    setSelectedTask(
      sections
        .find((section) => section.id === sectionId)
        ?.taskInstanceDtos.find((task) => task.id === taskId) ?? null,
    );
    setAreTaskDetailsOpen(true);
    setIsUserInfoOpen(false);
  };

  const handleSectionDragStart = (event: DragStartEvent) => {
    setDraggingSection(sections.find((section) => section.id === event.active.id) ?? null);
  };

  const handleSectionDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (active.id === over?.id) return;

    setSections((prevSections) => {
      const activeIndex = prevSections.findIndex((section) => section.id === active.id);
      const overIndex = prevSections.findIndex((section) => section.id === over?.id);
      const updatedSections = arrayMove(prevSections, activeIndex, overIndex).map(
        (section, index) => ({
          ...section,
          order: index + 1,
        }),
      );
      return updatedSections.sort((a, b) => a.order - b.order);
    });
  };

  const handleSectionDragEnd = async (event: DragEndEvent) => {
    setDraggingSection(null);

    const updatedSection = sections.find((section) => section.id === event.active.id);

    if (!updatedSection || !id) return;

    try {
      await updateSectionInstance(+id, updatedSection.workflowInstanceId, updatedSection);
      toast('success', 'You have successfully updated section order.');
    } catch {
      toast('error', 'Something went wrong while changing section order. Try again.');
    }
  };

  const handleTaskDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (active.id === over?.id) return;

    setSections((prevSections) => {
      const sectionId = active.data.current?.sortable.containerId;

      return prevSections.map((section) => {
        if (section.id.toString() === sectionId) {
          const oldIndex = section.taskInstanceDtos.findIndex((item) => item.id === active.id);
          const newIndex = section.taskInstanceDtos.findIndex((item) => item.id === over?.id);

          const updatedTaskInstanceDtos = arrayMove(
            section.taskInstanceDtos,
            oldIndex,
            newIndex,
          ).map((task, index) => ({
            ...task,
            order: index + 1,
          }));
          return {
            ...section,
            taskInstanceDtos: [...updatedTaskInstanceDtos].sort((a, b) => a.order - b.order),
          };
        }
        return section;
      });
    });
  };

  const handleDeleteWorkflow = useCallback(async () => {
    if (!id || !workflowId) return;

    if (!areAllTasksCompleted) {
      setIsConfirmationModalOpen(false);
      toast('warning', 'All tasks must be completed before you delete workflow.');
      return;
    }

    try {
      setIsLoading(true);
      await deleteWorkflowInstance(+workflowId, +id);

      navigate('/workflows');
      toast('success', 'You have successfully deleted workflow.');
    } catch {
      toast('error', 'Something went wrong while deleting workflow. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [id, workflowId, areAllTasksCompleted, navigate]);

  const handleArchiveWorkflow = useCallback(async () => {
    if (!id || !workflowId) return;

    if (!areAllTasksCompleted) {
      toast('warning', 'All tasks must be completed before you archive workflow.');
      handleCloseConfirmationModal();
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await archiveWorkflowInstance(+workflowId, +id);
      dispatch(setWorkflowInstance(data));
      toast('success', 'You have successfully archived workflow.');
    } catch {
      toast('error', 'Something went wrong while archiving workflow. Please try again.');
    } finally {
      setIsLoading(false);
      handleCloseConfirmationModal();
    }
  }, [areAllTasksCompleted, id, workflowId, dispatch]);

  useEffect(() => {
    if (!workflowId || !id) return;
    dispatch(getWorkflowInstance({ workflowId: +workflowId, id }));
  }, [dispatch, workflowId, id]);

  useEffect(() => {
    setSections(workflowSectionInstanceDtos);
    setSelectedUserId(userDto.id);
    setUser(userDto);
  }, [workflowSectionInstanceDtos, userDto]);

  useEffect(() => {
    setSelectedTask(
      (prevState) =>
        sections
          .find((section) => section.id === prevState?.workflowSectionInstanceId)
          ?.taskInstanceDtos.find((task) => task.id === prevState?.id) ?? null,
    );
  }, [sections]);

  const handleCloseModal = () => {
    setAreTaskDetailsOpen(false);
    setIsUserInfoOpen(true);
  };
  const confirmationModalHandler =
    actionType === 'delete' ? handleDeleteWorkflow : handleArchiveWorkflow;

  return (
    <Status isLoading={loading === RequestState.PENDING}>
      <div className={classes['c-user-workflow-overview']}>
        <div className={classes['c-user-workflow-overview__workflow-content']}>
          <div className={classes['c-user-workflow-overview__header']}>
            <Container gap="xs">
              <h3 className={classes['c-user-workflow-overview__header-text']}>{workflowName}</h3>
              {archived && (
                <Badge size="medium" color="blue">
                  Archived
                </Badge>
              )}
            </Container>
            {!archived && (
              <ArchiveActionButtons
                isArchiveDisabled={!areAllTasksCompleted}
                isDeleteDisabled={!areAllTasksCompleted}
                tooltipContent="You can archive or delete workflow only when all tasks are finished"
                handleConfirmationModal={handleOpenConfirmationModal}
              />
            )}
          </div>
          <div className={classes['c-user-workflow-overview__sections-list']}>
            <DndContext
              sensors={[sensor]}
              collisionDetection={closestCorners}
              onDragStart={handleSectionDragStart}
              onDragOver={handleSectionDragOver}
              onDragEnd={handleSectionDragEnd}
            >
              <SortableContext items={sections} strategy={verticalListSortingStrategy}>
                {sections.map((section) => (
                  <div
                    key={section.id}
                    className={classes['c-user-workflow-overview__sections-list-item']}
                  >
                    <UserWorkflowSection
                      section={section}
                      isViewOnly={archived}
                      handleOpenTaskDetails={handleOpenTaskDetails}
                      addNewTask={addNewTask}
                      handleTaskDragOver={handleTaskDragOver}
                    />
                  </div>
                ))}
              </SortableContext>
              <DragOverlay>
                {draggingSection && (
                  <UserWorkflowSection
                    handleOpenTaskDetails={handleOpenTaskDetails}
                    section={draggingSection}
                    addNewTask={addNewTask}
                  />
                )}
              </DragOverlay>
            </DndContext>
          </div>
          {areTaskDetailsOpen && selectedTask && (
            <UserWorkflowTaskDetails
              task={selectedTask}
              user={user}
              setAreTaskDetailsOpen={handleCloseModal}
              workflowId={Number(workflowId)}
              workflowInstanceId={Number(id)}
              isViewOnly={archived}
            />
          )}
          {isUserInfoOpen && (
            <UserInfo
              id={selectedUserId}
              setSelectedId={setSelectedUserId}
              isOpen={isUserInfoOpen}
            />
          )}
        </div>
        <ActionConfirmationModal
          message={`you want to ${actionType} "${workflowName}" workflow?`}
          isModalOpen={isConfirmationModalOpen}
          isLoading={isLoading}
          handleNo={handleCloseConfirmationModal}
          handleYes={confirmationModalHandler}
          closeModal={handleCloseConfirmationModal}
        />
      </div>
    </Status>
  );
};

export default UserWorkflowOverviewPage;
