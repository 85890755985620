import { useCallback, useEffect, useMemo, useState } from 'react';
import classes from '../../../styles/Section.module.scss';
import { ReactComponent as FileIcon } from 'assets/File.svg';
import { ReactComponent as StartIcon } from 'assets/StartIcon.svg';
import { ReactComponent as EndIcon } from 'assets/RevertStartIcon.svg';
import { ReactComponent as AddressSvg } from 'assets/LocationIcon.svg';
import { ReactComponent as CalendarSvg } from 'assets/CalendarIcon.svg';
import { ReactComponent as PayrollIcon } from 'assets/DollarClock.svg';
import { SectionInfoEdit, SectionActions } from 'modules/shared/components';
import type { Employment } from 'modules/profile/models/profileModels';
import { updateEmployment } from 'modules/profile/api/profile.api';
import { updateEmploymentDetails } from 'modules/profile/redux/userSlice';
import { useAppDispatch } from 'state/redux-hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import toast from 'utils/notifications/CustomToast';
import { WeekDaysSelection, DropDown, Input } from 'components/core';
import { getLocations } from 'modules/settings/api/location.api';
import { type LocationModel } from 'modules/settings/models/settingsModels';
import { pascalCase, replaceUnderscoreWithBlankSpaceInString } from 'utils/utilFunctions';
import { formatDateForInput, isDateAfter, isDateBefore, mapErrorToErrorData } from 'utils';
import { getCustomFieldsByPosition, updateCustomFieldValue } from 'modules/profile/utils';
import { CustomFieldItem } from '../../CustomFieldItem/CustomFieldItem';
import { useUserCustomFields } from 'modules/profile/hooks';
import { ReactComponent as WarningIcon } from 'assets/WarningIcon.svg';

type RequiredErrors = {
  organizationLocationId: boolean;
};

type Props = {
  employment: Employment;
};

type DateErrors = {
  startDate: boolean;
  firstWorkingDay: boolean;
  probationEndsAt: boolean;
};

const initialDateErrors: DateErrors = {
  startDate: false,
  firstWorkingDay: false,
  probationEndsAt: false,
};

const Details = ({ employment }: Props) => {
  const [openForm, setOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState(employment);
  const [requiredFields, setRequiredFields] = useState<RequiredErrors>({
    organizationLocationId: false,
  });
  const [locationOptions, setLocationOptions] = useState<LocationModel[]>([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [dateErrors, setDateErrors] = useState<DateErrors>(initialDateErrors);

  const contractOptions = ['Full time', 'Part time', 'Internship', 'Contractor'];

  const { customFields } = useUserCustomFields(employment.customFields, openForm);

  const { id } = useParams();

  const dispatch = useAppDispatch();

  const fetchLocations = async () => {
    const { data } = await getLocations();
    setLocationOptions(data);
  };

  const getCustomFieldValue = useCallback(
    (customFieldId: number) =>
      details?.customFields.find((field) => field.customFieldId === customFieldId)?.fieldValue ??
      '',
    [details],
  );

  const handleCustomFieldChange = useCallback((value: string, customFieldId: number) => {
    setDetails((previous) => ({
      ...previous,
      customFields: updateCustomFieldValue(previous.customFields, customFieldId, value),
    }));
  }, []);

  const areRequiredFieldsMissing = useMemo(() => {
    const errors = { ...requiredFields };

    Object.entries(errors).forEach(([key]) => {
      const prop = key as keyof Employment;
      const errorProp = key as keyof RequiredErrors;

      errors[errorProp] = !details[prop];
    });

    setRequiredFields(errors);

    return Object.entries(errors).some(([, val]) => val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const changeAttributeHandler = (value: string | string[], changeAttribute: string) => {
    setDetails((prev) => ({
      ...prev,
      [changeAttribute]:
        changeAttribute === 'startDate' ||
        changeAttribute === 'probationEndsAt' ||
        changeAttribute === 'firstWorkingDay'
          ? formatDateForInput(value as string)
          : value,
    }));
  };

  const handleSelectedOption = useCallback(
    (option: string) => {
      const placeOfWork = locationOptions.find((location) => location.id === +option)?.city || '';
      setDetails((prev) => ({
        ...prev,
        placeOfWork,
        organizationLocationId: +option,
      }));
    },
    [locationOptions],
  );

  const handleEdit = useCallback(() => {
    setOpenForm(true);
    fetchLocations();
  }, []);

  const validateStartDate = useCallback(() => {
    const hasChanged = employment.startDate !== details.startDate;
    const isValid =
      (details.firstWorkingDay && isDateAfter(details.startDate, details.firstWorkingDay)) ||
      (details.probationEndsAt && isDateAfter(details.startDate, details.probationEndsAt));

    return hasChanged && !!isValid;
  }, [details.firstWorkingDay, details.probationEndsAt, details.startDate, employment.startDate]);

  const validateFirstWorkingDay = useCallback(() => {
    const hasChanged = employment.firstWorkingDay !== details.firstWorkingDay;
    const isValid =
      (details.probationEndsAt && isDateAfter(details.firstWorkingDay, details.probationEndsAt)) ||
      isDateBefore(details.firstWorkingDay, details.startDate);

    return hasChanged && isValid;
  }, [
    details.firstWorkingDay,
    details.probationEndsAt,
    details.startDate,
    employment.firstWorkingDay,
  ]);

  const validateProbationEnds = useCallback(() => {
    const hasChanged = employment.probationEndsAt !== details.probationEndsAt;
    const isValid =
      (details.firstWorkingDay && isDateBefore(details.probationEndsAt, details.firstWorkingDay)) ||
      isDateBefore(details.probationEndsAt, details.startDate);

    return hasChanged && isValid;
  }, [
    details.firstWorkingDay,
    details.probationEndsAt,
    details.startDate,
    employment.probationEndsAt,
  ]);

  const validateWorkingDays = useCallback(() => {
    if (validateStartDate()) {
      setDateErrors((prev) => ({ ...prev, startDate: true }));
      return false;
    }

    if (validateFirstWorkingDay()) {
      setDateErrors((prev) => ({ ...prev, firstWorkingDay: true }));
      return false;
    }

    if (details.probationEndsAt && validateProbationEnds()) {
      setDateErrors((prev) => ({ ...prev, probationEndsAt: true }));
      return false;
    }
    return true;
  }, [details.probationEndsAt, validateFirstWorkingDay, validateProbationEnds, validateStartDate]);

  const handleSave = useCallback(async () => {
    setIsFormSubmitted(true);

    if (areRequiredFieldsMissing || !validateWorkingDays() || !id) return;

    setIsLoading(true);

    try {
      const { data } = await updateEmployment(id, details);
      const placeOfWork = locationOptions.find(
        (location) => location.id === data.organizationLocationId,
      )?.city;
      const response = { ...data, placeOfWork };
      dispatch(updateEmploymentDetails(response));
      setOpenForm(false);
      toast.success('You have successfully updated user details.');
    } catch (error) {
      const errorData = mapErrorToErrorData(error);
      toast.error(errorData?.message || 'Something went wrong while saving. Try again.');
    } finally {
      setIsLoading(false);
      setDateErrors(initialDateErrors);
    }
  }, [areRequiredFieldsMissing, validateWorkingDays, id, details, locationOptions, dispatch]);

  const handleCancel = useCallback(() => {
    setIsFormSubmitted(false);
    setDetails(employment);
    setOpenForm(false);
    setDateErrors(initialDateErrors);
  }, [employment]);

  const renderLocationField = useCallback(() => {
    const { placeOfWork } = details;
    if (placeOfWork)
      return (
        <Input
          id="placeOfWork"
          value={placeOfWork}
          inputValuePosition="right"
          readOnly
          size="small"
        />
      );

    return <WarningIcon className={classes['c-section__content-warning-icon']} />;
  }, [details]);

  useEffect(() => {
    setDetails(employment);
  }, [employment]);

  useEffect(() => {
    const locationId = locationOptions.find(
      (location) => location.city.toLowerCase() === employment.placeOfWork?.toLowerCase(),
    )?.id;
    if (locationId) {
      setDetails({ ...employment, organizationLocationId: locationId });
    }
  }, [locationOptions, employment]);

  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <h2 className={classes['c-section__title']}>Details</h2>
        <SectionActions
          isEditOpen={openForm}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          handleSave={handleSave}
          isLoading={isLoading}
        />
      </div>
      <div className={classes['c-section__content--columns']}>
        <div className={classes['c-section__content-column']}>
          <SectionInfoEdit
            name="Employment start"
            data={details.startDate && formatDateForInput(details.startDate)}
            icon={<StartIcon />}
            inputType="date"
            setValue={(event) => changeAttributeHandler(event, 'startDate')}
            readOnly={!openForm}
            isInvalid={!!dateErrors.startDate}
            isSubmitted={isFormSubmitted}
            errorMessage={
              dateErrors.startDate
                ? 'Employment start should be before first working day and probation ends.'
                : ''
            }
          />
          <SectionInfoEdit
            name="First woking day"
            data={details.firstWorkingDay && formatDateForInput(details.firstWorkingDay)}
            icon={<StartIcon />}
            inputType="date"
            setValue={(event) => changeAttributeHandler(event, 'firstWorkingDay')}
            readOnly={!openForm}
            isInvalid={!!dateErrors.firstWorkingDay}
            isSubmitted={isFormSubmitted && !!details.firstWorkingDay}
            errorMessage={
              dateErrors.firstWorkingDay
                ? 'First working day should be between employment start and probation ends.'
                : ''
            }
          />
          <SectionInfoEdit
            name="Probation ends at"
            data={details.probationEndsAt && formatDateForInput(details.probationEndsAt)}
            icon={<EndIcon />}
            inputType="date"
            setValue={(event) => changeAttributeHandler(event, 'probationEndsAt')}
            readOnly={!openForm}
            hideWarningIcon
            isInvalid={!!dateErrors.probationEndsAt}
            isSubmitted={isFormSubmitted && !!details.probationEndsAt}
            errorMessage={
              dateErrors.probationEndsAt
                ? 'Probation ends should be after employment start and first working day.'
                : ''
            }
          />
          <div className={classes['c-section__section-item']}>
            <div className={classes['c-section__input-container']}>
              <div className={classes['c-section__input-label']}>
                <CalendarSvg className={classes['c-section__label-icon']} />
                <span className={classes['c-section__input-label-span']}>Working days</span>
              </div>
              <WeekDaysSelection
                workingDays={details.workingDays}
                setSelectedDays={(selectedDays) =>
                  changeAttributeHandler(selectedDays, 'workingDays')
                }
                readOnly={!openForm}
              />
            </div>
          </div>
          <SectionInfoEdit
            name="Employee ID"
            data={details.employeeId}
            icon={<FileIcon />}
            setValue={(event) => changeAttributeHandler(event, 'employeeId')}
            readOnly={!openForm}
          />
          <SectionInfoEdit
            name="Tax ID"
            data={details.taxId}
            icon={<FileIcon />}
            setValue={(event) => changeAttributeHandler(event, 'taxId')}
            readOnly={!openForm}
            hideWarningIcon
          />
          {getCustomFieldsByPosition(customFields, 'odd').map((customField) => (
            <CustomFieldItem
              customField={customField}
              key={customField.customFieldId}
              readonly={!openForm}
              selectedOption={getCustomFieldValue(customField.customFieldId)}
              onChange={handleCustomFieldChange}
            />
          ))}
        </div>
        <div className={classes['c-section__content-column']}>
          <SectionInfoEdit
            name="Tax code/Tax number"
            data={details.taxCode}
            icon={<FileIcon />}
            setValue={(event) => changeAttributeHandler(event, 'taxCode')}
            readOnly={!openForm}
          />

          <div className={classes['c-section__section-item']}>
            <div className={classes['c-section__input-container']}>
              <div className={classes['c-section__input-label']}>
                <FileIcon className={classes['c-section__label-icon']} />
                <span className={`${classes['c-section__input-label-span']} `}>Contract type</span>
              </div>
              <div className={classes['c-section__dropdown-container']}>
                <DropDown
                  options={contractOptions}
                  displayedOptions={contractOptions}
                  selectedOption={contractOptions.find(
                    (option) => option.toUpperCase().replace(' ', '_') === details.contractType,
                  )}
                  setSelectedOption={(selectedOption) => {
                    setDetails((prev) => ({
                      ...prev,
                      contractType: selectedOption.toUpperCase().replace(' ', '_'),
                    }));
                  }}
                  defaultValue={pascalCase(
                    replaceUnderscoreWithBlankSpaceInString(details.contractType),
                  )}
                  selectedValueAlign="right"
                  readOnly={!openForm}
                />
              </div>
            </div>
          </div>
          <SectionInfoEdit
            name="Payroll provider"
            data={details.payrollProvider}
            icon={<PayrollIcon />}
            setValue={(event) => changeAttributeHandler(event, 'payrollProvider')}
            pascalCaseState={true}
            readOnly={!openForm}
          />
          <div className={classes['c-section__section-item']}>
            <div className={classes['c-section__input-container']}>
              <div className={classes['c-section__input-label']}>
                <AddressSvg className={classes['c-section__label-icon']} />
                <span
                  className={`${classes['c-section__input-label-span']} ${
                    openForm ? classes['c-section__input-label-span--required'] : ''
                  }`}
                >
                  Place of work
                </span>
              </div>
              {openForm ? (
                <DropDown
                  options={locationOptions.map((option) => String(option.id))}
                  displayedOptions={locationOptions.map(
                    (location) =>
                      `${location.address ? `${location.address},` : ''} ${location.city}`,
                  )}
                  selectedOption={
                    locationOptions.find(
                      (location) => location.id === details.organizationLocationId,
                    )?.city
                  }
                  setSelectedOption={handleSelectedOption}
                  fetchOptions={fetchLocations}
                  inputFieldIncluded={true}
                  defaultValue={details.placeOfWork}
                  readOnly={!openForm}
                  selectedValueAlign="right"
                  size="small"
                  isSubmitted={isFormSubmitted && requiredFields.organizationLocationId}
                />
              ) : (
                renderLocationField()
              )}
            </div>
          </div>
          {getCustomFieldsByPosition(customFields, 'even').map((customField) => (
            <CustomFieldItem
              customField={customField}
              key={customField.customFieldId}
              readonly={!openForm}
              selectedOption={getCustomFieldValue(customField.customFieldId)}
              onChange={handleCustomFieldChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Details;
