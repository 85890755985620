import { useCallback, useContext } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';

export const useOAuth = () => {
  const { loginInProgress, idToken, error, login, logOut } = useContext(AuthContext);

  const handleLogin = useCallback(() => login(), [login]);
  const handleLogout = useCallback(() => logOut(), [logOut]);

  return {
    loginInProgress,
    idToken,
    error,
    handleLogin,
    handleLogout,
  };
};
