import { useLocation } from 'react-router-dom';
import { settingsSidebarNavigationItems } from 'utils/NavigationItems';
import classes from './Sidebar.module.scss';
import SidebarItem from './SidebarItem/SidebarItem';

const Sidebar = () => {
  const { pathname } = useLocation();

  return (
    <ul className={classes['c-sidebar']}>
      {settingsSidebarNavigationItems.map(({ label, path, icon }) => (
        <SidebarItem
          key={label}
          label={label}
          path={path ?? ''}
          icon={icon}
          isActive={pathname === `/settings/${path}`}
        />
      ))}
    </ul>
  );
};

export default Sidebar;
