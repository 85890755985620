import { getAllPtoPolicies } from '../../api/PtoPolicy.api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'components/core';

export const getOrganizationPtoPolicies = createAsyncThunk(
  'ptoPolicy/getOrganizationPtoPolicies',
  async () => {
    try {
      const { data } = await getAllPtoPolicies();
      return data;
    } catch {
      toast('error', 'Something went wrong. Please try again later.');
    }
  },
);
