import { useCallback, useEffect, useState } from 'react';
import classes from '../styles/Tasks.module.scss';
import TaskTable from './TaskTable';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { Task } from '../model';
import { initializeAppliedFilters } from 'components/core/Filter/redux/filterSlice';
import { initializeFilterAndSearch } from 'components/layout/Toolbar/redux/toolbarSlice';
import { getTasksForUser } from '../redux/taskActions';
import { initializeState } from 'modules/projects/redux/projectSlice';
import { selectTasks } from '../redux/tasksSlice';
import { RequestState } from 'config/constants';
import TaskFilters, { TaskFilter } from './TaskFilters';
import tasksImage from 'assets/empty/Tasks.svg';
import { Status, Empty } from 'components/core';
import { useToolbarTitle } from 'hooks';

const Tasks = () => {
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);

  const { appliedFilters } = useAppSelector((state) => state.filters);
  const { inputFieldValue } = useAppSelector((state) => state.toolbar);
  const { tasks, loading } = useAppSelector(selectTasks);

  useToolbarTitle({ title: ['My tasks'], subtitle: `${tasks.length} tasks` }, [tasks.length]);

  const dispatch = useAppDispatch();

  const filterTasks = useCallback(
    (filterParam: string) => {
      setFilteredTasks(
        tasks.filter(
          ({ name, workflowName, workflowSectionInstanceName }) =>
            name.toLowerCase().includes(filterParam.toLowerCase()) ||
            name.toLowerCase() === filterParam.toLowerCase() ||
            workflowName.toLowerCase().includes(filterParam.toLowerCase()) ||
            workflowName.toLowerCase() === filterParam.toLowerCase() ||
            workflowSectionInstanceName.toLowerCase().includes(filterParam.toLowerCase()) ||
            workflowSectionInstanceName.toLowerCase() === filterParam.toLowerCase(),
        ),
      );
    },
    [tasks],
  );

  const filterTasksByStatus = useCallback(
    (filter: TaskFilter) => {
      if (filter === 'ALL') {
        setFilteredTasks(tasks);
        return;
      }

      setFilteredTasks(tasks.filter(({ taskStatus }) => taskStatus === filter));
    },
    [tasks],
  );

  useEffect(() => {
    Promise.all([
      dispatch(initializeAppliedFilters()),
      dispatch(initializeFilterAndSearch()),
      dispatch(initializeState()),
      dispatch(getTasksForUser()),
    ]);
  }, [dispatch]);

  useEffect(() => {
    setFilteredTasks(tasks);
  }, [tasks]);

  useEffect(() => {
    filterTasks(inputFieldValue ?? '');
  }, [appliedFilters, inputFieldValue, filterTasks]);

  return (
    <Status isLoading={loading === RequestState.PENDING}>
      <div className={classes['c-tasks']}>
        {tasks?.length ? (
          <>
            <TaskFilters onClick={filterTasksByStatus} />
            <TaskTable tasks={filteredTasks} searchValue={inputFieldValue} />
          </>
        ) : (
          <Empty
            title="See what needs to be done"
            info="Organize yours and your team's work. Quickly create, assign and filter tasks to keep up to date with necessary work, quickly and with zero pressure."
            img={tasksImage}
          />
        )}
      </div>
    </Status>
  );
};

export default Tasks;
