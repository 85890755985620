import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { ReactComponent as ArrowIcon } from 'assets/ChevronRight.svg';
import { updateTimeAwayApproval } from '../../api/timeAwayRequests.api';
import { PendingTimeAway } from '../../models/timeAwayRequestsModel';
import { ModalContent } from 'modules/shared/components';
import { Button, Avatar, HighlightedText, PopUpModal, Status, toast } from 'components/core';
import { useToolbarTitle } from 'hooks';
import { Nullable } from 'modules/shared/model';
import classes from '../TimeAwayRequests/TimeAwayRequests.module.scss';

type Props = {
  type: 'SINGLE' | 'MULTY';
  isContentLoading: boolean;
  pendingApprovals: PendingTimeAway[];
  removeFromPendingTimeAways?: (id: number) => void;
};

const TimeAwayTable = ({
  isContentLoading,
  pendingApprovals,
  type,
  removeFromPendingTimeAways,
}: Props) => {
  const [pendingApprovalsCopy, setPendingApprovalsCopy] = useState<PendingTimeAway[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTimeAwayApproved, setIsTimeAwayApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimeAway, setSelectedTimeAway] = useState<Nullable<PendingTimeAway>>(null);
  const navigate = useNavigate();

  const id = useAppSelector((state) => state.auth.user?.id);
  const { inputFieldValue } = useAppSelector((state) => state.toolbar);

  useToolbarTitle({ title: ['Time away requests'] });

  const closeModal = useCallback(() => {
    setSelectedTimeAway(null);
    setIsTimeAwayApproved(false);
    setIsModalOpen(false);
  }, []);

  const handleApproval = useCallback(
    async (userId: number, timeAwayId: number, timeAwayTypeId?: number) => {
      setIsLoading(true);

      if (!id || !timeAwayTypeId) return;

      try {
        await updateTimeAwayApproval(userId, timeAwayTypeId, timeAwayId, isTimeAwayApproved);
        closeModal();
        toast(
          'success',
          `You have successfully ${
            isTimeAwayApproved ? 'approved' : 'declined'
          } time away request.`,
        );

        if (type === 'MULTY' && removeFromPendingTimeAways) {
          removeFromPendingTimeAways(timeAwayId);
        } else {
          navigate('/time-away-approvals');
        }
      } catch {
        toast('error', 'Something went wrong. Please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [id, isTimeAwayApproved, closeModal],
  );

  const handleOnClick = (isApproved: boolean, pendingApproval: PendingTimeAway) => {
    setIsTimeAwayApproved(isApproved);
    setSelectedTimeAway(pendingApproval);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!inputFieldValue) {
      setPendingApprovalsCopy(pendingApprovals);
      return;
    }

    const newPendingApprovals = pendingApprovals.filter((item) =>
      `${item.firstName} ${item.lastName}`.toLowerCase().includes(inputFieldValue.toLowerCase()),
    );
    setPendingApprovalsCopy(newPendingApprovals);
  }, [inputFieldValue, pendingApprovals]);

  return (
    <div className={classes['c-time-away-approvals']}>
      <div className={classes['c-time-away-approvals__list']}>
        <div className={classes['c-time-away-approvals__list-header']}>
          <span>Person</span>
          <div
            className={`${classes['c-time-away-approvals__date-range']} ${classes['c-time-away-approvals__date-range--header']}`}
          >
            <span>Start date</span>
            <span>End date</span>
          </div>
          <span>Reason</span>
          <span>Note</span>
        </div>
        {pendingApprovalsCopy.length || isContentLoading ? (
          <Status isLoading={isContentLoading}>
            <div className={classes['c-time-away-approvals__items-container']}>
              {pendingApprovalsCopy.map((approval) => (
                <div className={classes['c-time-away-approvals__item-container']} key={approval.id}>
                  <Link
                    className={`${classes['c-time-away-approvals__item']} ${classes['c-time-away-approvals__item--link']}`}
                    to={`/user/${approval.userId}/about`}
                  >
                    <Avatar
                      horizontal
                      size="small"
                      imageUrl={approval.userProfilePhotoPath}
                      title={
                        <HighlightedText
                          text={`${approval.firstName} ${approval.lastName}`}
                          highlight={inputFieldValue}
                        />
                      }
                    />
                  </Link>
                  <div className={classes['c-time-away-approvals__date-range']}>
                    <span className={classes['c-time-away-approvals__item']}>
                      {approval.startDate}
                    </span>
                    <ArrowIcon />
                    <span className={classes['c-time-away-approvals__item']}>
                      {approval.endDate}
                    </span>
                  </div>
                  <span className={classes['c-time-away-approvals__item']}>
                    {approval.timeAwayType.name}
                  </span>
                  <span
                    className={`${classes['c-time-away-approvals__item']} ${
                      approval.note ? '' : classes['c-time-away-approvals__item--missing-data']
                    }`}
                  >
                    {approval.note || 'N/A'}
                  </span>
                  <div className={classes['c-time-away-approvals__button-container']}>
                    <Button type="tertiary" onClick={() => handleOnClick(false, approval)}>
                      Decline
                    </Button>
                    <Button type="success" onClick={() => handleOnClick(true, approval)}>
                      Approve
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </Status>
        ) : (
          <span className={classes['c-time-away-approvals__item-container--missing-data']}>
            There are no pending time away requests.
          </span>
        )}
      </div>
      {selectedTimeAway && (
        <PopUpModal
          title={`Are you sure that you want to ${
            isTimeAwayApproved ? 'approve' : 'decline'
          } time away?`}
          opened={isModalOpen}
          closeModal={closeModal}
          width={25}
        >
          <ModalContent
            timeAway={selectedTimeAway}
            isLoading={isLoading}
            handleNo={closeModal}
            handleYes={handleApproval}
          />
        </PopUpModal>
      )}
    </div>
  );
};

export default TimeAwayTable;
