import type { CSSProperties } from 'react';

export type Properties = Pick<
  CSSProperties,
  'display' | 'flexDirection' | 'justifyContent' | 'alignItems' | 'gap'
>;

export type Gap =
  | 'xxs'
  | 'xxsPlus'
  | 'xsMinus'
  | 'xs'
  | 'xsPlus'
  | 'sMinus'
  | 'sMedium'
  | 's'
  | 'm'
  | 'mPlus'
  | 'lMinus'
  | 'l'
  | 'lPlus'
  | 'xlMinus'
  | 'xl'
  | 'xxl';
export type Justify = 'start' | 'end' | 'center' | 'space-between' | 'space-around';
export type Align = 'start' | 'end' | 'center' | 'baseline' | 'stretch';

export const flexJustify: Record<Justify, string> = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  'space-between': 'space-between',
  'space-around': 'space-around',
};

export const flexAlign: Record<Align, string> = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  baseline: 'baseline',
  stretch: 'stretch',
};

export const flexGap: Record<Gap, string> = {
  xxs: '0.25rem', // 4px
  xxsPlus: '0.313rem', // 5px
  xsMinus: '0.375rem', // 6px
  xs: '0.5rem', // 8px
  xsPlus: '0.625rem', // 10px
  sMinus: '0.75rem', // 12px
  sMedium: '0.875rem', // 14px
  s: '1rem', // 16px
  m: '1.25rem', // 20px
  mPlus: '1.5rem', // 24px
  lMinus: '1.75rem', // 28px
  l: '2rem', // 32px
  lPlus: '2.5rem', // 40px
  xlMinus: '3rem', // 48px
  xl: '3.75rem', // 60px
  xxl: '5rem', // 80px
};
