import { useCallback } from 'react';
import classes from '../styles/TaskTable.module.scss';
import Task from './Task';
import type { Task as TaskModel } from '../model';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectTasks, updateTask } from '../redux/tasksSlice';
import { RequestState, TaskStatus } from 'config/constants';
import { completeTask } from '../api/tasks.api';
import { toast, Status, Empty } from 'components/core';
import { sortArrayByPropertyOrder } from 'utils/array';
import tasksImage from 'assets/empty/Tasks.svg';

type Props = {
  tasks: TaskModel[];
  searchValue?: string;
};

const TaskTable = ({ tasks, searchValue }: Props) => {
  const { loading } = useAppSelector(selectTasks);

  const dispatch = useAppDispatch();

  const handleComplete = useCallback(
    async (task: TaskModel) => {
      if (task.taskStatus === TaskStatus.COMPLETED) return;

      try {
        const updatedTask = { ...task, taskStatus: TaskStatus.COMPLETED };

        await completeTask(updatedTask);
        dispatch(updateTask(updatedTask));
        toast('success', 'Task completed successfully.');
      } catch {
        toast('error', 'Something went wrong. Please try again later.');
      }
    },
    [dispatch],
  );

  const renderTasksContent = useCallback(() => {
    return (
      <>
        <div className={classes['c-task-table__content-header']}>
          <p className={classes['c-task-table__content-header-item']}>Personal tasks</p>
          <p className={classes['c-task-table__content-header-item']}>Workflow name</p>
          <p className={classes['c-task-table__content-header-item']}>Workflow section</p>
          <p className={classes['c-task-table__content-header-item']}>Due date</p>
          <p className={classes['c-task-table__content-header-item']}>Trigger date</p>
          <p className={classes['c-task-table__content-header-item']}>Order</p>
          <p className={classes['c-task-table__content-header-item']}>Action</p>
        </div>
        <div className={classes['c-task-table__content']}>
          <Status isLoading={loading === RequestState.PENDING}>
            {sortArrayByPropertyOrder([...tasks]).map((task) => (
              <Task
                key={task.id}
                task={task}
                searchValue={searchValue}
                onComplete={handleComplete}
              />
            ))}
          </Status>
        </div>
      </>
    );
  }, [handleComplete, loading, searchValue, tasks]);

  return (
    <div className={classes['c-task-table']}>
      <div className={classes['c-task-table__body']}>
        {tasks.length ? (
          renderTasksContent()
        ) : (
          <Empty
            title="See what needs to be done"
            info="Organize yours and your team's work. Quickly create, assign and filter tasks to keep up to date with necessary work, quickly and with zero pressure."
            img={tasksImage}
          />
        )}
      </div>
    </div>
  );
};

export default TaskTable;
