import { type ChangeEvent, useState, useEffect, useCallback, Fragment } from 'react';
import classes from './PtoPolicyForm.module.scss';
import { ActionConfirmationModal, Checkbox } from 'components/core';
import FormContainer from 'modules/settings/components/FormContainer/FormContainer';
import type { PtoPolicy } from 'modules/shared/model';
import { formatDateForInput } from 'utils/dates';
import { SectionInfoEdit } from 'modules/shared/components';

type Props = {
  ptoPolicy: PtoPolicy;
  displayMode?: 'settings-section' | 'modal';
  onChange: (ptoPolicy: PtoPolicy) => void;
  onSave?: () => void;
  setIsConfirmationModalOpen?: (isConfirmationModal: boolean) => void;
  isConfirmationModalOpen?: boolean;
};

const PtoPolicyForm = ({
  ptoPolicy,
  displayMode,
  isConfirmationModalOpen,
  onChange,
  onSave,
  setIsConfirmationModalOpen,
}: Props) => {
  const [formData, setFormData] = useState<PtoPolicy>(ptoPolicy);
  const {
    name,
    yearStartDate,
    annualDays,
    carryOverDays,
    carryOverEndDate,
    extraDaysPerYear,
    default: isDefault,
  } = formData;

  const handleOnInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData],
  );

  const handleOnDateChange = useCallback(
    (name: string, value: string) => {
      setFormData({
        ...formData,
        [name]: formatDateForInput(value),
      });
    },
    [formData],
  );

  const openConfirmationModal = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && setIsConfirmationModalOpen) setIsConfirmationModalOpen(true);
      else
        setFormData({
          ...formData,
          default: event.target.checked,
        });
    },
    [formData],
  );

  const handleCheckboxChange = (isChecked: boolean) => {
    setFormData({
      ...formData,
      default: isChecked,
    });
    if (setIsConfirmationModalOpen) setIsConfirmationModalOpen(false);
  };

  useEffect(() => {
    onChange(formData);
  }, [formData, onChange]);

  return (
    <>
      <FormContainer onSave={onSave} displayMode={displayMode}>
        <Fragment>
          <SectionInfoEdit
            name="name"
            label="Policy name"
            data={name}
            handleChange={handleOnInputChange}
            displayMode={displayMode}
          />
          <SectionInfoEdit
            name="yearStartDate"
            label="PTO year start"
            inputType="date"
            data={yearStartDate}
            min={new Date(new Date().getFullYear(), 0, 1)}
            max={new Date(new Date().getFullYear(), 11, 31)}
            setValue={(value) => handleOnDateChange('yearStartDate', formatDateForInput(value))}
            displayMode={displayMode}
          />
          <SectionInfoEdit
            name="annualDays"
            label="Annual PTO allocation (days)"
            data={String(annualDays)}
            handleChange={handleOnInputChange}
            displayMode={displayMode}
          />
        </Fragment>
        <Fragment>
          <SectionInfoEdit
            name="carryOverDays"
            label="Annual carry over allocation (days)"
            data={String(carryOverDays)}
            handleChange={handleOnInputChange}
            displayMode={displayMode}
          />
          <SectionInfoEdit
            name="carryOverEndDate"
            label="Carry over end date"
            inputType="date"
            data={carryOverEndDate || ''}
            min={new Date(yearStartDate)}
            setValue={(value) => handleOnDateChange('carryOverEndDate', formatDateForInput(value))}
            displayMode={displayMode}
          />
          <SectionInfoEdit
            name="extraDaysPerYear"
            label="Annual extra days"
            data={String(extraDaysPerYear)}
            handleChange={handleOnInputChange}
            displayMode={displayMode}
          />
        </Fragment>
        <div className={classes['c-pto-policy-form__checkbox-wrap']}>
          <Checkbox
            id="defaultPolicy"
            label="Default policy"
            checked={isDefault}
            size={displayMode === 'modal' ? 'medium' : 'small'}
            handleCheckedItem={openConfirmationModal}
          />
        </div>
      </FormContainer>
      <ActionConfirmationModal
        message="Are you sure you want to change the default policy?"
        isModalOpen={isConfirmationModalOpen ?? false}
        handleNo={() => handleCheckboxChange(false)}
        handleYes={() => handleCheckboxChange(true)}
        shouldClose={true}
        closeModal={() => handleCheckboxChange(false)}
      />
    </>
  );
};

export default PtoPolicyForm;
