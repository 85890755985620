import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getTimeAwayTypes, resetSelectedItems, selectColorLegend } from '../redux';
import type { TimeAway } from 'modules/timeline/models/timeAwayModels';
import { getFilteredTimeAways } from '../utils';

type UseColorLegend = {
  filteredTimeAways: TimeAway[];
};

export const useColorLegend = (timeAways: TimeAway[]): UseColorLegend => {
  const [filteredTimeAways, setFilteredTimeAways] = useState<TimeAway[]>([]);

  const { colorLegendItems } = useAppSelector(selectColorLegend);

  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTimeAwayTypes());
  }, [dispatch]);

  useEffect(() => {
    setFilteredTimeAways(getFilteredTimeAways(timeAways, colorLegendItems));
  }, [colorLegendItems, timeAways]);

  useEffect(() => {
    dispatch(resetSelectedItems());
  }, [location, dispatch]);

  return { filteredTimeAways };
};
