import { type SetStateAction, useState } from 'react';
import dayjs from 'dayjs';
import classes from './OffboardingModal.module.scss';
import { Input, DropDown, Checkbox, Button } from 'components/core';
import { createOffboarding } from 'modules/profile/api/profile.api';
import toast from 'utils/notifications/CustomToast';
import { useNavigate, useParams } from 'react-router-dom';
import { type OffboardingModel } from 'modules/profile/models/profileModels';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectUser, updateOffboarding, updateUserStatus } from 'modules/profile/redux/userSlice';

const reasonOptions = ['RESIGNED', 'REDUNDANCY', 'DISMISSED', 'OTHER'];

interface OffboardingModalProps {
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

const OffboardingModal = ({ setIsModalOpen }: OffboardingModalProps) => {
  const { offboardingDto } = useAppSelector(selectUser);
  const [offboardingData, setOffboardingData] = useState<OffboardingModel>(
    offboardingDto || {
      id: 0,
      employmentEndDate: '',
      lastWorkingDay: '',
      reason: '',
      note: '',
    },
  );
  const [isChecked, setIsChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changeOffboardingHandler = (value: string, prop: string) => {
    setOffboardingData((prevOffboarding) => ({
      ...prevOffboarding,
      [prop]: value,
    }));
  };

  const isButtonDisabled = () => {
    return (
      !offboardingData.employmentEndDate ||
      !offboardingData.reason ||
      (!isChecked && !offboardingData.lastWorkingDay)
    );
  };

  const handleOffboarding = () => {
    const updatedData = isChecked
      ? {
          ...offboardingData,
          lastWorkingDay: offboardingData.employmentEndDate,
        }
      : { ...offboardingData };

    setIsLoading(true);

    id &&
      createOffboarding(updatedData, id)
        .then((res) => {
          navigate(`/user/${id}/offboarding`);
          dispatch(updateUserStatus('OFFBOARDING_SCHEDULED'));
          dispatch(updateOffboarding(res.data));
          setIsModalOpen(false);
          toast.success('You have successfully started offboarding process of this user.');
        })
        .catch(() => toast.error('Something went wrong while saving. Try again.'))
        .finally(() => setIsLoading(false));
  };

  return (
    <div className={classes['c-offboarding-modal']}>
      <Input
        name="employmentEndDate"
        id="employmentEndDate"
        type="date"
        label="Employment end date"
        value={offboardingData.employmentEndDate}
        min={new Date()}
        setValue={(event) =>
          changeOffboardingHandler(dayjs(event).format('YYYY-MM-DD'), 'employmentEndDate')
        }
        size="medium"
      />
      {!isChecked && (
        <Input
          name="lastWorkingDay"
          id="lastWorkingDay"
          type="date"
          label="Last working day"
          value={offboardingData.lastWorkingDay}
          min={new Date()}
          max={new Date(offboardingData.employmentEndDate)}
          setValue={(event) =>
            changeOffboardingHandler(dayjs(event).format('YYYY-MM-DD'), 'lastWorkingDay')
          }
          size="medium"
        />
      )}
      <div
        className={`${classes['c-offboarding-modal__field-holder']} ${classes['c-offboarding-modal__field-holder--checkbox']}`}
      >
        <Checkbox
          checked={isChecked}
          id="offboardingCheckbox"
          label="Last working day same as employment end date"
          handleCheckedItem={(event: React.ChangeEvent<HTMLInputElement>) =>
            setIsChecked(event?.target.checked)
          }
          size="medium"
        />
      </div>
      <div className={classes['c-offboarding-modal__field-holder']}>
        <label htmlFor="reason">Reason:</label>
        <DropDown
          options={reasonOptions}
          selectedOption={offboardingData.reason}
          setSelectedOption={(event) => changeOffboardingHandler(event, 'reason')}
          size="medium"
        />
      </div>
      <Input
        name="note"
        id="note"
        label="Note"
        setValue={(event) => changeOffboardingHandler(event, 'note')}
        size="medium"
      />
      <div className={classes['c-offboarding-modal__button-holder']}>
        <Button
          size="medium"
          onClick={handleOffboarding}
          isLoading={isLoading}
          disabled={isButtonDisabled()}
        >
          Offboard
        </Button>
      </div>
    </div>
  );
};

export default OffboardingModal;
