import { jwtDecode, type JwtDecodeOptions } from 'jwt-decode';
import type { TokenPayload } from './tokenModel';

const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';

export const decodeToken = (token: string, options?: JwtDecodeOptions) => {
  return jwtDecode<TokenPayload>(token, options);
};

export const isTokenValid = (token: string): boolean => {
  try {
    decodeToken(token);
    return true;
  } catch {
    return false;
  }
};

export const setLocalStorageTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const clearLocalStorageTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const getTokenPayload = (): TokenPayload | null => {
  const token = localStorage.getItem(ACCESS_TOKEN);

  return token ? decodeToken(token) : null;
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(REFRESH_TOKEN);
};
