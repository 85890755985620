import { Nullable } from 'modules/shared/model';
import { z } from 'zod';

export const enterHoursSchema = z.object({
  selectedDate: z.date({
    required_error: 'Date is required.',
  }),
  projectId: z
    .number()
    .nullable()
    .refine((value) => value !== null, { message: 'Please select project.' }),
  startTime: z.string(),
  regularHours: z
    .number()
    .min(0, { message: 'Regular hours must be at least 0.' })
    .max(8, { message: 'Regular hours must be less than or equal to 8.' }),
  overtimeHours: z
    .number()
    .min(0, { message: 'Overtime hours must be at least 0.' })
    .max(8, { message: 'Overtime hours must be less than or equal to 8.' }),
});

export type EnterHoursSchema = {
  selectedDate: Date;
  projectId: Nullable<number>;
  startTime: string;
  regularHours: number;
  overtimeHours: number;
};

export const defaultValues: EnterHoursSchema = {
  selectedDate: new Date(),
  startTime: '09:00',
  projectId: null,
  regularHours: 8,
  overtimeHours: 0,
};
