import { useCallback, useEffect, useState } from 'react';

import { Status } from 'components/core';
import { UserInfo } from 'modules/shared/components';

import { RequestState, UserType } from 'config/constants';

import { getAllUsersFromOrganization } from 'modules/people/redux/usersActions';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';

import { getOrganizationChart } from '../redux/chartActions';

import classes from './OrganizationChart.module.scss';
import { selectOrganizationChart } from '../redux/chartSlice';
import ChartContent from './ChartContent/ChartContent';
import { useToolbarTitle } from 'hooks';

const OrganizationChart = () => {
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);

  const dispatch = useAppDispatch();

  const company = useAppSelector((state) => state.settings.company);
  const { inputFieldValue: searchTerm } = useAppSelector((state) => state.toolbar);
  const {
    data: organizationChartData,
    membersCount,
    loading,
  } = useAppSelector(selectOrganizationChart);

  useToolbarTitle({ title: ['Organization chart'], subtitle: `${membersCount} members` }, [
    membersCount,
  ]);

  const handleSelectedUser = useCallback((id: number) => {
    setSelectedUserId(id);
    setIsUserInfoOpen(true);
  }, []);

  useEffect(() => {
    dispatch(getOrganizationChart());
    dispatch(getAllUsersFromOrganization(UserType.ACTIVE_MEMBER));
  }, [dispatch]);

  return (
    <Status isLoading={loading === RequestState.PENDING}>
      <div className={classes['c-organization-chart-wrapper']}>
        <div className={classes['c-organization-chart']}>
          <div id="treeWrapper" className={classes['c-organization-chart__content']}>
            {organizationChartData && company && (
              <ChartContent
                companyName={company.name}
                organizationChartData={organizationChartData}
                company={company}
                handleSelectedUser={handleSelectedUser}
                selectedUserId={selectedUserId}
                membersCount={membersCount}
                searchTerm={searchTerm ?? ''}
              />
            )}
          </div>
        </div>
      </div>
      <UserInfo
        id={selectedUserId}
        isOpen={isUserInfoOpen}
        setIsOpen={setIsUserInfoOpen}
        setSelectedId={setSelectedUserId}
      />
    </Status>
  );
};

export default OrganizationChart;
