import classes from './FilterButton.module.scss';
import { IconButton } from 'components/core/IconButton';
import { useEffect, useState } from 'react';
import { isColorDark } from '../utils/checkColorDarkness';
import { ReactComponent as CloseSvg } from 'assets/CloseIcon.svg';

type ButtonType = 'fixed' | 'selective';

type Props = {
  label: string;
  selected?: boolean;
  highlightMode?: 'dark' | 'light';
  type?: ButtonType;
  onClick: () => void;
  color?: string;
};

export const FilterButton = ({
  label,
  selected,
  highlightMode = 'dark',
  type = 'fixed',
  onClick,
  color,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [borderColor, setBorderColor] = useState('transparent');
  const [fontColor, setFontColor] = useState('#373b3c');

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    let newBackgroundColor = 'transparent';
    let newBorderColor = 'transparent';

    if (isHovered || selected) {
      if (color) {
        newBackgroundColor = color;
        newBorderColor = color;
      } else {
        newBackgroundColor = highlightMode === 'dark' ? '#e6e6e6' : '#fff';
      }
    } else if (color) {
      newBorderColor = color;
    }

    setBackgroundColor(newBackgroundColor);
    setBorderColor(newBorderColor);
    setFontColor(isColorDark(newBackgroundColor) ? '#fff' : '#373b3c');
  }, [isHovered, selected, color, highlightMode]);

  const style = {
    border: `1px solid ${borderColor}`,
    backgroundColor,
    color: fontColor,
  };

  if (type === 'fixed')
    return (
      <button
        style={style}
        className={`${classes['c-filter-button']}  ${
          selected ? classes['c-filter-button--selected'] : ''
        } ${classes[`c-filter-button__highlight-mode--${highlightMode}`]}`}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {label}
      </button>
    );

  return (
    <div className={classes['c-filter-button__selective-button-wrap']}>
      <div
        className={`${classes['c-filter-button']} ${classes['c-filter-button--selected']} ${
          classes[`c-filter-button__highlight-mode--${highlightMode}`]
        }`}
      >
        {label}
      </div>
      <IconButton
        icon={<CloseSvg className={classes['c-filter-button__close-icon']} />}
        iconButtonType="filter-close"
        onClick={onClick}
      />
    </div>
  );
};
