import FormContainer from 'modules/auth/components/FormContainer/FormContainer';
import SignIn from 'modules/auth/components/SignIn/SignIn';

const LoginContainer = () => {
  return (
    <FormContainer>
      <SignIn />
    </FormContainer>
  );
};

export default LoginContainer;
