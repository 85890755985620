import { months } from 'config/constants';
import { TimeAwayDaysByMonth } from '../models';

export const getTimeAwaysData = (
  timeAwayMonthLabels: string[],
  timeAwayDaysByMonth: TimeAwayDaysByMonth,
) => {
  return Object.keys(months).map((month) =>
    timeAwayMonthLabels.includes(month) ? timeAwayDaysByMonth[month.toUpperCase()] : 0,
  );
};

export const getMaxNumber = (timeAwayDaysByMonth: TimeAwayDaysByMonth) => {
  return Math.max(...Object.values(timeAwayDaysByMonth).map(Number)) + 3;
};
