import { Location } from '../../redux/peoplePopupSlice';

export const formatLocationsForDropDown = (locations: Location[]) => {
  return locations.map((location) => ({
    id: location.id,
    location: `${location.city}${location.address ? `, ${location.address}` : ''}`,
  }));
};

export const formatSelectedLocation = (selectedLocationId: string, locations: Location[]) => {
  const location = locations.find(({ id }) => id === Number(selectedLocationId));

  if (!location) return;

  return `${location.city}${location.address ? `, ${location.address}` : ''}`;
};
