import React from 'react';
import { Outlet } from 'react-router-dom';
import classes from './WorkflowPage.module.scss';

const WorkflowPage = () => {
  return (
    <div className={classes['c-workflow-page']}>
      <Outlet />
    </div>
  );
};

export default WorkflowPage;
