import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'lib';
import { UserType } from 'config/constants';
import { getOrganizationId } from 'state/utils';

export const getAllUsersFromOrganization = createAsyncThunk(
  'people/getAllUsersFromOrganization',
  async (userType: UserType) => {
    const response = await api.get(`/organization/${getOrganizationId()}/user/list`, {
      params: { userType },
    });
    return response.data;
  },
);

export const getUsers = createAsyncThunk('people/getUsers', async (queryString: string) => {
  const response = await api.get(`/organization/${getOrganizationId()}/user${queryString}`);
  return response.data;
});
