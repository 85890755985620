import { type ReactElement, type ChangeEvent, type InputHTMLAttributes } from 'react';
import classes from './SectionInfoEdit.module.scss';
import { DropDown, IconButton, Input } from 'components/core';
import { ReactComponent as WarningIcon } from 'assets/WarningIcon.svg';

type Props = {
  icon?: ReactElement;
  name: string;
  label?: string;
  data: string;
  inputType?: InputHTMLAttributes<HTMLInputElement>['type'];
  isInvalid?: boolean;
  required?: boolean;
  readOnly?: boolean;
  min?: Date;
  max?: Date;
  displayMode?: 'regular-section' | 'settings-section' | 'modal';
  setValue?: (value: string) => void;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDropdownChange?: (option: string) => void;
  errorMessage?: string;
  autoFocus?: boolean;
  pascalCaseState?: boolean;
  isSubmitted?: boolean;
  inputFieldWidth?: 'full';
  inputValuePosition?: 'left' | 'right';
  iconVariant?: 'primary' | 'original';
  showAsDropdown?: boolean;
  dropdownOptions?: string[];
  dropdownDisplayedOptions?: string[];
  selectedOption?: string;
  hideWarningIcon?: boolean;
};

export const SectionInfoEdit = ({
  icon,
  name,
  label,
  data,
  inputType,
  isInvalid,
  required,
  readOnly,
  min,
  max,
  displayMode,
  setValue,
  handleChange,
  handleDropdownChange,
  errorMessage,
  autoFocus,
  pascalCaseState,
  isSubmitted,
  inputFieldWidth,
  inputValuePosition = 'right',
  iconVariant = 'primary',
  showAsDropdown,
  dropdownOptions = [],
  dropdownDisplayedOptions = [],
  selectedOption,
  hideWarningIcon,
}: Props) => {
  const sortedDropdownOptions = [...dropdownOptions].sort((a, b) => a.localeCompare(b));
  const sortedDropdownDisplayedOptions = [...dropdownDisplayedOptions].sort((a, b) =>
    a.localeCompare(b),
  );

  const input = showAsDropdown ? (
    <DropDown
      options={sortedDropdownOptions}
      displayedOptions={sortedDropdownDisplayedOptions}
      selectedOption={selectedOption}
      setSelectedOption={handleDropdownChange}
      readOnly={readOnly}
      selectedValueAlign="right"
    />
  ) : (
    <Input
      name={name}
      id="name"
      size={displayMode === 'modal' ? 'medium' : 'small'}
      type={inputType}
      value={data}
      setValue={setValue}
      inputValuePosition={displayMode === 'modal' ? 'left' : inputValuePosition}
      autoFocus={autoFocus}
      isSubmitted={isSubmitted}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      handleOnChange={handleChange}
      pascalCaseState={pascalCaseState}
      readOnly={readOnly}
      min={min}
      max={max}
    />
  );

  return (
    <div
      className={`${classes['c-section-info-edit']} ${
        classes[`c-section-info-edit--${displayMode}`]
      }`}
    >
      <div
        className={`${classes['c-section-info-edit__data-container']} ${
          inputFieldWidth === 'full'
            ? classes['c-section-info-edit__data-container--full-input']
            : ''
        }`}
      >
        <div
          className={`${classes['c-section-info-edit__data']} ${
            errorMessage ? classes['c-section-info-edit__data--extended'] : ''
          }`}
        >
          <div className={classes['c-section-info-edit__label-wrap']}>
            <div className={classes[`c-section-info-edit__label-icon--${iconVariant}`]}>{icon}</div>
            <span
              className={`${classes['c-section-info-edit__label']} ${
                icon ? classes['c-section-info-edit__label--margin'] : ''
              } ${!readOnly && required ? classes['c-section-info-edit__label--required'] : ''}`}
            >
              {label ?? name}
            </span>
          </div>
        </div>
        <div
          className={`${classes['c-section-info-edit__data']} ${classes['c-section-info-edit__data--secondary']}`}
        >
          {readOnly && !hideWarningIcon && !data ? (
            <div className={classes['c-section-info-edit__data--missing']}>
              <IconButton
                icon={<WarningIcon className={classes['c-section-actions__icon']} />}
                tooltipContent="Missing info"
                tooltipPosition="bottom"
                iconButtonType="warning"
              />
            </div>
          ) : (
            input
          )}
        </div>
      </div>
    </div>
  );
};
