import { useState } from 'react';
import { Button, Checkbox, Input, toast } from 'components/core';
import { createEquipment } from 'modules/profile/api/profile.api';
import { UserCustomField } from 'modules/profile/models/profileModels';
import { addNewEquipment as addNewEquipmentAction } from 'modules/profile/redux/userSlice';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectOrganization } from 'state/shared/organization';
import classes from './EquipmentModal.module.scss';
import { formatDateForInput } from 'utils';

type Props = {
  userId: number;
  closeModal: (isClosed: boolean) => void;
};

export type NewEquipment = {
  name: string;
  equipmentType: string;
  serialNumber: string;
  cost: string;
  currency: string;
  note: string;
  issueDate: Date | string;
  receivedWhenIssued: boolean;
  collected: boolean;
  userId: number;
  organizationId: number | null;
  customFields: UserCustomField[];
};

const EquipmentModal = ({ userId, closeModal }: Props) => {
  const { organization } = useAppSelector(selectOrganization);

  const [newEquipment, setNewEquipment] = useState<NewEquipment>({
    name: '',
    equipmentType: '',
    serialNumber: '',
    cost: '',
    currency: '',
    note: '',
    issueDate: formatDateForInput(new Date()),
    receivedWhenIssued: false,
    collected: false,
    customFields: [],
    userId,
    organizationId: organization?.id ?? null,
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleChangeEquipment = (value: string, changeAttribute: string) => {
    setNewEquipment((prevEquipment) => ({
      ...prevEquipment,
      [changeAttribute]: value,
    }));
  };

  const handleCheckbox = (passedProp: number | string) => {
    setNewEquipment((prevEquipment) => ({
      ...prevEquipment,
      [passedProp]: !prevEquipment[passedProp as keyof NewEquipment],
    }));
  };

  const isButtonDisabled =
    !newEquipment.name || !newEquipment.equipmentType || !newEquipment.serialNumber;

  const handleSubmit = () => {
    setIsFormSubmitted(true);
    !isButtonDisabled && addNewEquipment();
  };

  const addNewEquipment = async () => {
    setIsLoading(true);

    try {
      const { data } = await createEquipment(newEquipment);
      dispatch(addNewEquipmentAction(data));
      closeModal(false);
      toast('success', 'You have successfully added a new equipment.');
    } catch {
      toast('error', 'Something went wrong while saving. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes['c-equipment-modal']}>
      <Input
        label="Name"
        name="name"
        id="name"
        value={newEquipment.name}
        setValue={(event) => handleChangeEquipment(event, 'name')}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
      <Input
        label="Equipment type"
        name="equipmentType"
        id="equipmentType"
        value={newEquipment.equipmentType}
        setValue={(event) => handleChangeEquipment(event, 'equipmentType')}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
      <Input
        label="Serial number"
        name="serialNumber"
        id="serialNumber"
        value={newEquipment.serialNumber}
        setValue={(event) => handleChangeEquipment(event, 'serialNumber')}
        isSubmitted={isFormSubmitted}
        size="medium"
      />
      <div className={classes['c-equipment-modal__multiple-inputs']}>
        <Input
          label="Cost"
          name="cost"
          id="cost"
          setValue={(event) => handleChangeEquipment(event, 'cost')}
          size="medium"
        />
        <Input
          label="Currency"
          name="currency"
          id="currency"
          setValue={(event) => handleChangeEquipment(event, 'currency')}
          size="medium"
        />
      </div>
      <Input
        label="Note"
        name="note"
        id="note"
        setValue={(event) => handleChangeEquipment(event, 'note')}
        size="medium"
      />
      <Input
        label="Issue date"
        name="issueDate"
        id="issueDate"
        type="date"
        setValue={(event) => handleChangeEquipment(formatDateForInput(event), 'issueDate')}
        value={formatDateForInput(newEquipment.issueDate)}
        size="medium"
      />
      <div
        className={`${classes['c-equipment-modal__multiple-inputs']} ${classes['c-equipment-modal__multiple-inputs--checkbox']}`}
      >
        <Checkbox
          checked={newEquipment.receivedWhenIssued}
          id="receivedWhenIssued"
          label="Received when issued"
          checkHandler={handleCheckbox}
          size="medium"
        />
        <Checkbox
          checked={newEquipment.collected}
          id="collected"
          label="Collected"
          checkHandler={handleCheckbox}
          size="medium"
        />
      </div>
      <Button size="medium" onClick={handleSubmit} isLoading={isLoading}>
        Add equipment
      </Button>
    </div>
  );
};

export default EquipmentModal;
