import { useCallback, useEffect, useMemo, useState } from 'react';
import classes from '../../../../styles/SectionSecondary.module.scss';
import { Input, Avatar, AddMemberDropDown } from 'components/core';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { updateRole as updatedRoleApi } from 'modules/profile/api/profile.api';
import { selectUserEmployment, updateEmploymentRole } from 'modules/profile/redux/userSlice';
import { useParams } from 'react-router-dom';
import toast from 'utils/notifications/CustomToast';
import { selectFilters } from 'components/core/Filter/redux/filterSlice';
import { getAllJobTitles } from 'components/core/Popups/redux/peoplePopupActions';
import { getAllFilters } from 'components/core/Filter/redux/filterActions';
import { SectionActions } from 'modules/shared/components';
import { formatDate, formatDateForInput } from 'utils';
import { UserSearchInputComponent } from 'components/core/Popups/People/UserSearchInputComponent/UserSearchInputComponent';
import { UserBaseInfo } from 'modules/people/model/User';

type UpdatedUser = {
  jobTitle: string;
  department: string;
  effectiveOn: string;
  employmentId?: number;
  dedicatedHrDto?: UserBaseInfo;
  reportingToUserDto?: UserBaseInfo;
};

const Role = () => {
  const employment = useAppSelector(selectUserEmployment);
  const { departmentNames } = useAppSelector(selectFilters).fetchedFilters;
  const { jobTitles } = useAppSelector((state) => state.peoplePopup);
  const { user } = useAppSelector((state) => state.auth);

  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [employmentData, setEmploymentData] = useState(employment);

  const [selectedUser, setSelectedUser] = useState<number>();
  const [selectedHRUser, setSelectedHRUser] = useState<number>();

  const [updateData, setUpdateData] = useState<UpdatedUser>({
    jobTitle: '',
    department: '',
    effectiveOn: '',
    employmentId: 0,
  });

  const [departmentListDropDown, setDepartmentListDropDown] = useState<string[]>(
    departmentNames.filter((department) => department !== null),
  );
  const [jobTitleListDropDown, setJobTitleListDropDown] = useState<string[]>(jobTitles);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isCurrentUserActive = id ? +id === user?.id : false;

  const reportingToUserName = useMemo(() => {
    const { reportingToUserDto } = updateData;

    if (!reportingToUserDto) return '';

    return `${reportingToUserDto.firstName} ${reportingToUserDto.lastName}`;
  }, [updateData]);

  const dedicatedHrName = useMemo(() => {
    const { dedicatedHrDto } = updateData;
    if (!dedicatedHrDto) return '';
    return `${dedicatedHrDto.firstName} ${dedicatedHrDto.lastName}`;
  }, [updateData]);

  const defaultEmploymentRoleValues = useCallback(() => {
    const { employmentRole, id } = employment;

    changeRole(employmentRole.jobTitle, 'jobTitle');
    changeRole(employmentRole.department, 'department');
    changeRole(formatDateForInput(employmentRole.effectiveOn), 'effectiveOn');
    changeRole(id.toString(), 'employmentId');

    setUpdateData((prevData) => ({
      ...prevData,
      reportingToUserDto: employmentRole.reportingToUserDto,
      dedicatedHrDto: employmentRole.dedicatedHrDto,
    }));
  }, [employment]);

  const handleSave = useCallback(() => {
    if (!id) return;

    setIsLoading(true);

    updatedRoleApi(id, employment, updateData)
      .then((res) => {
        dispatch(updateEmploymentRole(res.data));
        setEmploymentData((prevState) => ({ ...prevState, employmentRole: res.data }));
        setIsEditEnabled(false);
        toast.success('You have successfully updated role.');
      })
      .catch(() => {
        toast.error('Something went wrong while saving. Try again.');
      })
      .finally(() => setIsLoading(false));
  }, [id, employment, updateData, dispatch]);

  const handleCancel = useCallback(() => {
    defaultEmploymentRoleValues();
    setIsEditEnabled(false);
  }, [defaultEmploymentRoleValues]);

  const changeRole = (value: string, prop: string) => {
    setUpdateData((prevData) => ({ ...prevData, [prop]: value }));
  };

  const updateUsersList = (value: number) => {
    setSelectedUser(value);
  };

  const updateDedicatedHRList = (value: number) => {
    setSelectedHRUser(value);
  };

  useEffect(() => {
    if (!isEditEnabled) return;
    !jobTitleListDropDown.length && dispatch(getAllJobTitles());
    !departmentNames.length && dispatch(getAllFilters());
  }, [departmentNames.length, jobTitleListDropDown.length, isEditEnabled, dispatch]);

  const onClickEditButton = () => {
    setIsEditEnabled(true);
  };
  useEffect(() => {
    defaultEmploymentRoleValues();
  }, [defaultEmploymentRoleValues, employment, employmentData]);
  const updateUser = (value: UserBaseInfo, name: string) => {
    setUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <div className={classes['c-section-secondary']}>
      <div className={classes['c-section-secondary__header']}>
        <h2 className={classes['c-section-secondary__title']}>Role</h2>
        <SectionActions
          isEditOpen={isEditEnabled}
          handleEdit={onClickEditButton}
          handleCancel={handleCancel}
          handleSave={handleSave}
          isLoading={isLoading}
        />
      </div>
      <div className={classes['c-section-secondary__content']}>
        <div
          className={`${classes['c-section-secondary__info-header']} ${classes['c-section-secondary__info-header--small']}`}
        >
          <span className={classes['c-section-secondary__info-header-title']}>Title</span>
          <span className={classes['c-section-secondary__info-header-title']}>Department</span>
          <span className={classes['c-section-secondary__info-header-title']}>Effective date</span>
          <span className={classes['c-section-secondary__info-header-title']}>Reporting to</span>
          <span className={classes['c-section-secondary__info-header-title']}>Dedicated HR</span>
        </div>
        {isEditEnabled ? (
          <div
            className={`${classes['c-section-secondary__info-data']} ${classes['c-section-secondary__info-data--small']} ${classes['c-section-secondary__info-data--edit-mode']}`}
          >
            <AddMemberDropDown
              items={jobTitleListDropDown.map((value) => ({ value }))}
              id="jobTitle"
              setValue={(event) => changeRole(event, 'jobTitle')}
              selectedValue={updateData.jobTitle}
              handleOnClick={(text: string) =>
                setJobTitleListDropDown((prevState) => [...prevState, text])
              }
            />
            <AddMemberDropDown
              items={departmentListDropDown.map((value) => ({ value }))}
              id="department"
              setValue={(event) => changeRole(event, 'department')}
              selectedValue={updateData.department}
              handleOnClick={(text: string) =>
                setDepartmentListDropDown((prevState) => [...prevState, text])
              }
            />
            <Input
              value={updateData.effectiveOn && formatDateForInput(updateData.effectiveOn)}
              name="effectiveOn"
              id="effectiveOn"
              type="date"
              setValue={(event) => changeRole(formatDateForInput(event), 'effectiveOn')}
            />
            <UserSearchInputComponent
              id="reportTo"
              selectedUser={selectedUser}
              setSelectedUser={(value) => updateUsersList(value)}
              setUser={(value) => updateUser(value, 'reportingToUserDto')}
              selectedValue={reportingToUserName}
              currentUserToExclude={isCurrentUserActive ? user?.id : undefined}
            />
            <UserSearchInputComponent
              id="dedicatedHrDto"
              selectedUser={selectedHRUser}
              setSelectedUser={(event) => updateDedicatedHRList(event)}
              setUser={(value) => updateUser(value, 'dedicatedHrDto')}
              selectedValue={dedicatedHrName}
              jobTitle="HR"
              currentUserToExclude={isCurrentUserActive ? user?.id : undefined}
            />
          </div>
        ) : (
          <div
            className={`${classes['c-section-secondary__info-data']} ${classes['c-section-secondary__info-data--small']} ${classes['c-section-secondary__info-data--last']}`}
          >
            <div className={classes['c-section-secondary__data']}>{updateData.jobTitle}</div>
            <div className={classes['c-section-secondary__data']}>{updateData.department}</div>
            <div className={classes['c-section-secondary__data']}>
              {updateData.effectiveOn ? (
                formatDate(updateData.effectiveOn, 'DD MMM YYYY')
              ) : (
                <span className={classes['c-section-secondary__data--missing']}>N/A</span>
              )}
            </div>
            <div className={classes['c-section-secondary__user']}>
              {reportingToUserName ? (
                <Avatar
                  size="extra-small"
                  imageUrl={updateData.reportingToUserDto?.profilePhotoPath || ''}
                  title={reportingToUserName}
                  horizontal
                />
              ) : (
                <span className={classes['c-section-secondary__data--missing']}>N/A</span>
              )}
            </div>
            <div className={classes['c-section-secondary__user']}>
              {dedicatedHrName ? (
                <Avatar
                  size="extra-small"
                  imageUrl={updateData.dedicatedHrDto?.profilePhotoPath || ''}
                  title={dedicatedHrName}
                  horizontal
                />
              ) : (
                <span className={classes['c-section-secondary__data--missing']}>N/A</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Role;
