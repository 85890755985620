import { toast } from 'components/core';
import { isLeapYear, isDateBefore } from 'utils';
import type { PtoPolicy } from 'modules/shared/model';

export const isFormValid = (ptoPolicy: PtoPolicy) => {
  const { name, carryOverDays, annualDays, extraDaysPerYear, carryOverEndDate, yearStartDate } =
    ptoPolicy;

  if (
    !String(carryOverDays).length ||
    !String(annualDays).length ||
    !String(extraDaysPerYear).length
  ) {
    toast('warning', 'Please fill all required fields.');
    return false;
  }

  if (!name.length) {
    toast('warning', 'PTO policy name can not be blank.');
    return false;
  }

  if ((isLeapYear(new Date(yearStartDate)) && annualDays > 366) || annualDays > 365) {
    toast('warning', 'Annual days can not be greater than total days in year.');
    return false;
  }

  if (carryOverDays > annualDays) {
    toast('warning', 'Carry over days can not be greater than annual days.');
    return false;
  }

  if (extraDaysPerYear > annualDays) {
    toast('warning', 'Extra days can not be greater than annual days.');
    return false;
  }

  if (isDateBefore(carryOverEndDate, yearStartDate)) {
    toast('warning', 'Carry over end date can not be before year start date.');
    return false;
  }

  return true;
};

export const ptoPolicyNameExists = (newPtoPolicy: PtoPolicy, ptoPolicies: PtoPolicy[]) => {
  const foundPtoPolicy = ptoPolicies
    .filter((policy) => policy.id !== newPtoPolicy.id)
    .find((ptoPolicy) => ptoPolicy.name.toLowerCase() === newPtoPolicy.name.toLowerCase());

  if (foundPtoPolicy) {
    toast('warning', 'PTO policy name already exists.');
    return true;
  }

  return false;
};
