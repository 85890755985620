import { z } from 'zod';

export const organizationValidationSchema = z
  .object({
    firstName: z
      .string()
      .refine((value) => value !== '', { message: 'Please enter your first name.' }),
    lastName: z
      .string()
      .refine((value) => value !== '', { message: 'Please enter your last name.' }),
    jobTitle: z
      .string()
      .refine((value) => value !== '', { message: 'Please enter your job title.' }),
    organizationName: z
      .string()
      .refine((value) => value !== '', { message: 'Please enter your organization title.' }),
    city: z.string().refine((value) => value !== '', { message: 'Please select city.' }),
    country: z.string().refine((value) => value !== '', { message: 'Please select country.' }),
    address: z.string().refine((value) => value !== '', { message: 'Please enter your address.' }),
    password: z.string().refine((value) => value.length >= 8, {
      message: 'Password must be at least 8 characters long.',
    }),
    repeatPassword: z.string(),
    email: z.string().nullable(),
    startDate: z.string().refine((value) => value !== '', { message: 'Please select start date.' }),
    firstWorkingDay: z
      .string()
      .refine((value) => value !== '', { message: 'Please select first working day.' }),
  })
  .refine((data) => data.repeatPassword && data.password && data.password === data.repeatPassword, {
    message: "Passwords don't match, please try again.",
    path: ['repeatPassword'],
  })
  .refine((data) => new Date(data.firstWorkingDay) >= new Date(data.startDate), {
    message: 'First working day cannot be before the start date.',
    path: ['firstWorkingDay'],
  });

export type OrganizationValidationSchema = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  organizationName: string;
  city: string;
  country: string;
  address: string;
  password: string;
  repeatPassword: string;
  email: string;
  startDate?: string;
  firstWorkingDay?: string;
};

export const organizationDefaultValues: OrganizationValidationSchema = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  organizationName: '',
  city: '',
  country: '',
  address: '',
  password: '',
  repeatPassword: '',
  email: '',
  startDate: '',
  firstWorkingDay: '',
};
