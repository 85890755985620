import classes from './ColorSelector.module.scss';

type Props = {
  color: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onSelectColor: () => void;
};

const ColorSelector = ({ color, isSelected, isDisabled, onSelectColor }: Props) => {
  return (
    <button
      aria-label={`Select color ${color}`}
      className={`${classes['c-color-selector']} ${
        isSelected || isDisabled ? classes['c-color-selector--selected'] : ''
      }`}
      style={{ backgroundColor: isDisabled ? '#bdbdbd' : color }}
      disabled={isDisabled}
      onClick={onSelectColor}
    />
  );
};

export default ColorSelector;
