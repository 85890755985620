import { useDispatch } from 'react-redux';
import { AddNewHireMemberValidationSchema } from '../validations/AddNewHireMemberValidation.schema';
import { createNewHire } from 'modules/people/api/people.api';
import { UserType } from 'config/constants';
import { addNewUser } from 'modules/people/redux/peopleSlice';
import { toast } from 'react-toastify';
import { mapErrorToErrorData } from 'utils';

export const useAddNewHireMember = () => {
  const dispatch = useDispatch();

  const handleAddNewHireMember = async (
    formData: AddNewHireMemberValidationSchema,
    userTypeParam: string | null,
  ) => {
    try {
      const { data } = await createNewHire(
        formData.firstName,
        formData.lastName,
        formData.personalEmail,
        formData.jobTitle,
        formData.startDate,
      );

      if (userTypeParam !== UserType.ACTIVE_MEMBER) {
        const newMember = {
          ...data,
          email: data.personalEmail,
          userType: UserType.NEW_HIRE,
          effectiveOn: data.startDate,
          newestAddedUser: true,
        };

        dispatch(addNewUser(newMember));
      }

      toast.success('You have successfully added a new member to your staff.');
      return true;
    } catch (error) {
      const errorData = mapErrorToErrorData(error);

      toast.error(
        errorData?.message || 'Something went wrong while adding a new member. Try again.',
      );

      return false;
    }
  };

  return { handleAddNewHireMember };
};
