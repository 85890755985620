import { AddMemberDropDown, toast } from 'components/core';
import { UserType } from 'config/constants';
import { getUserBySearch } from 'modules/people/api/people.api';
import { UserBaseInfo } from 'modules/people/model/User';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

type UserJobTitle = 'HR' | 'Manager';
export type Props = {
  id: string;
  setSelectedUser?: (id: number) => void;
  selectedUser?: number;
  setUser?: (user: UserBaseInfo) => void;
  selectedValue?: string;
  label?: any;
  jobTitle?: UserJobTitle;
  currentUserToExclude?: number;
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const UserSearchInputComponent = ({
  id,
  setSelectedUser,
  setUser,
  selectedValue,
  selectedUser,
  handleOnChange,
  label,
  jobTitle,
  currentUserToExclude,
}: Props) => {
  const timerRef = useRef<any>(null);
  const [usersList, setUsersList] = useState<UserBaseInfo[]>([]);
  const [message, setMessage] = useState<string>();

  const fetchUsersList = async (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(undefined);

    const { value } = event.target;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (handleOnChange) handleOnChange(event);
    timerRef.current = setTimeout(async () => {
      if (value.length < 3) return;
      if (value.length >= 3) {
        try {
          setUsersList([]);
          const response = await getUserBySearch(value, UserType.ACTIVE_MEMBER);
          let users = response.data;
          if (jobTitle) users = users.filter((user) => user.jobTitle?.includes(jobTitle));
          if (currentUserToExclude)
            users = users.filter((user) => user.id !== currentUserToExclude);
          setUsersList(response.data);
          if (!users.length) setMessage('There is no user found');
          setUsersList(users);
        } catch {
          toast('error', 'Something went wrong. Try again.');
        }
      }
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  const selectedUserFullName = useMemo(() => {
    if (!selectedUser) return '';
    const user = usersList.find((user) => user.id === selectedUser);
    return `${user?.firstName} ${user?.lastName}`;
  }, [usersList, selectedUser]);

  return (
    <>
      <AddMemberDropDown
        items={usersList.map((user) => ({
          value: `${user.firstName} ${user.lastName}`,
          profilePhotoPath: user.profilePhotoPath,
          id: user.id,
          jobTitle: user.jobTitle,
        }))}
        id={id}
        setSelectedItemId={setSelectedUser}
        isAddNewDisabled
        handleOnChange={fetchUsersList}
        placeholder="Search by name, job title"
        setUser={setUser}
        selectedValue={selectedValue ? selectedValue : selectedUserFullName}
        label={label}
        userMessage={message}
      />
    </>
  );
};
