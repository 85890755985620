import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Nullable } from 'modules/shared/model';
import { State as AppState } from 'state/store';
import { Organization } from 'utils/auth/tokenModel';

type State = {
  organization: Nullable<Organization>;
};

const initialState: State = {
  organization: null,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    initializeOrganization: () => initialState,
    setOrganization: (state, action: PayloadAction<Organization>) => {
      state.organization = action.payload;
    },
    removeOrganization: (state) => {
      state.organization = null;
    },
  },
});

export const { initializeOrganization, setOrganization, removeOrganization } =
  organizationSlice.actions;

export default organizationSlice.reducer;

export const selectOrganization = (state: AppState) => state.organization;
