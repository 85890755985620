import { api } from 'lib';
import { LocationModel } from '../models/settingsModels';
import { getOrganizationId } from 'state/utils/organization';

export const getLocations = async () => {
  return api.get<LocationModel[]>(`organization/${getOrganizationId()}/organization-location`);
};

export const createLocation = async (location: LocationModel) => {
  return api.post<LocationModel>(`/organization/${getOrganizationId()}/organization-location`, {
    ...location,
  });
};

export const updateLocation = async (location: LocationModel) => {
  return api.put<LocationModel>(
    `/organization/${getOrganizationId()}/organization-location/${location.id}`,
    {
      ...location,
    },
  );
};

export const deleteLocation = async (locationId: number) => {
  return api.delete(`/organization/${getOrganizationId()}/organization-location/${locationId}`);
};
