import { Fragment, useCallback, useEffect, useState } from 'react';
import type { Equipment as EquipmentModel } from 'modules/profile/models/profileModels';
import classes from '../../../styles/Section.module.scss';
import { SectionInfoEdit, SectionActions } from 'modules/shared/components';
import { ReactComponent as FileIcon } from 'assets/File.svg';
import { updateEquipment } from 'modules/profile/api/profile.api';
import { PopUpModal, Tab } from 'components/core';
import EquipmentModal from '../../Modals/Equipment/EquipmentModal';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import toast from 'utils/notifications/CustomToast';
import { useDispatch } from 'react-redux';
import { selectUserId, updateEquipmentInSlice } from 'modules/profile/redux/userSlice';
import { useUserRole } from 'hooks';
import { selectAuthUser } from 'modules/auth/redux/authSlice';
import { formatDateForInput } from 'utils/dates';
import { getCustomFieldsByPosition, updateCustomFieldValue } from 'modules/profile/utils';
import { CustomFieldItem } from '../../CustomFieldItem/CustomFieldItem';
import { useUserCustomFields } from 'modules/profile/hooks';

type Props = {
  equipments: EquipmentModel[];
};

const Equipment = ({ equipments }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [equipmentsData, setEquipmentsData] = useState<EquipmentModel[]>(equipments);
  const [equipmentToUpdate, setEquipmentToUpdate] = useState<Set<number>>(new Set());
  const [activeTab, setActiveTab] = useState<number>(equipments[0]?.id);

  const userId = useAppSelector(selectUserId);
  const { user } = useAppSelector(selectAuthUser);
  const { isAdminOrSuperAdmin } = useUserRole(user?.role);

  const dispatch = useDispatch();

  const { customFields } = useUserCustomFields(equipments[0]?.customFields ?? [], isEditMode);

  const isContentAvailable = !!equipmentsData.length;

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleEdit = () => setIsEditMode(true);

  const getCustomFieldValue = useCallback(
    (customFieldId: number, equipmentId: number) => {
      const customField = equipmentsData
        .find((equipment) => equipment.id === equipmentId)
        ?.customFields.find((field) => field.customFieldId === customFieldId);

      return customField?.fieldValue ?? '';
    },
    [equipmentsData],
  );

  const changeEquipmentHandler = (value: string, prop: string, equipmentId: number) => {
    setEquipmentToUpdate((prevSet) => new Set([...prevSet, equipmentId]));
    setEquipmentsData((prevEquipments) => [
      ...prevEquipments.map((equipment) => {
        if (equipment.id === equipmentId) {
          return { ...equipment, [prop]: value };
        }
        return equipment;
      }),
    ]);
  };

  const handleCustomFieldChange = useCallback(
    (value: string, customFieldId: number) => {
      setEquipmentToUpdate((previousSet) => new Set([...previousSet, activeTab]));

      setEquipmentsData((previousEquipmentData) => {
        return previousEquipmentData.map((previousEquipment) => {
          if (previousEquipment.id !== activeTab) return previousEquipment;

          return {
            ...previousEquipment,
            customFields: updateCustomFieldValue(
              previousEquipment.customFields,
              customFieldId,
              value,
            ),
          };
        });
      });
    },
    [activeTab],
  );

  const requiredFieldsMissing = useCallback(() => {
    const checkRequiredFields = equipmentsData.map(
      (equipment) => !equipment.name || !equipment.equipmentType || !equipment.serialNumber,
    );

    return checkRequiredFields.includes(true);
  }, [equipmentsData]);

  const submitSaveHandler = useCallback(async () => {
    if (requiredFieldsMissing()) return;

    try {
      setIsLoading(true);

      for (const id of equipmentToUpdate) {
        const equipmentToUpdate = equipmentsData.find((equipment) => equipment.id === id);

        if (
          equipmentToUpdate?.name &&
          equipmentToUpdate.equipmentType &&
          equipmentToUpdate.serialNumber
        ) {
          const res = await updateEquipment(equipmentToUpdate);

          dispatch(updateEquipmentInSlice(res.data));
          toast.success('You have successfully updated equipment info');
        }
      }
    } catch {
      toast.error('An error occurred while updating equipment info. Try again.');
    } finally {
      setEquipmentToUpdate(new Set());
      setIsLoading(false);
      setIsEditMode(false);
    }
  }, [dispatch, equipmentToUpdate, equipmentsData, requiredFieldsMissing]);

  const handleCancel = useCallback(() => {
    setEquipmentsData(equipments);
    setEquipmentToUpdate(new Set());
    setIsEditMode(false);
  }, [equipments]);

  const renderContent = useCallback(() => {
    if (!equipmentsData?.length) {
      return (
        <span className={classes['c-section__no-content-data-message']}>
          No equipment data available.
        </span>
      );
    }

    return (
      <div className={classes['c-section__content--columns']}>
        {equipmentsData.map(
          ({ id, name, serialNumber, cost, currency, equipmentType, issueDate, note }) => {
            if (id !== activeTab) return;

            return (
              <Fragment key={id}>
                <div className={classes['c-section__content-column']}>
                  <SectionInfoEdit
                    name="Name"
                    data={name}
                    icon={<FileIcon />}
                    setValue={(value) => changeEquipmentHandler(value, 'name', id)}
                    isInvalid={!name.toString().length}
                    readOnly={!isEditMode}
                  />
                  <SectionInfoEdit
                    name="Serial number"
                    data={serialNumber}
                    icon={<FileIcon />}
                    setValue={(value) => changeEquipmentHandler(value, 'serialNumber', id)}
                    isInvalid={!serialNumber.toString().length}
                    readOnly={!isEditMode}
                  />
                  <SectionInfoEdit
                    name="Cost"
                    data={isEditMode ? cost : `${cost} ${currency}`}
                    icon={<FileIcon />}
                    setValue={(value) => changeEquipmentHandler(value, 'cost', id)}
                    readOnly={!isEditMode}
                    inputType={!isEditMode ? 'text' : 'number'}
                  />
                  {isEditMode && (
                    <SectionInfoEdit
                      showAsDropdown
                      name="Currency"
                      data={currency}
                      icon={<FileIcon />}
                      dropdownOptions={['EUR', 'USD', 'GBP', 'RSD']}
                      dropdownDisplayedOptions={['EUR', 'USD', 'GBP', 'RSD']}
                      handleDropdownChange={(option) =>
                        changeEquipmentHandler(option, 'currency', id)
                      }
                      selectedOption={currency}
                      readOnly={!isEditMode}
                    />
                  )}
                  {getCustomFieldsByPosition(customFields, 'even').map((customField) => (
                    <CustomFieldItem
                      customField={customField}
                      key={customField.customFieldId}
                      readonly={!isEditMode}
                      selectedOption={getCustomFieldValue(customField.customFieldId, id)}
                      onChange={handleCustomFieldChange}
                    />
                  ))}
                </div>
                <div className={classes['c-section__content-column']}>
                  <SectionInfoEdit
                    name="Type"
                    data={equipmentType}
                    icon={<FileIcon />}
                    setValue={(value) => changeEquipmentHandler(value, 'equipmentType', id)}
                    isInvalid={!equipmentType.toString().length}
                    readOnly={!isEditMode}
                  />
                  <SectionInfoEdit
                    name="Issue date"
                    data={issueDate && formatDateForInput(issueDate)}
                    icon={<FileIcon />}
                    inputType="date"
                    setValue={(value) =>
                      changeEquipmentHandler(formatDateForInput(value), 'issueDate', id)
                    }
                    readOnly={!isEditMode}
                  />
                  <SectionInfoEdit
                    name="Note"
                    data={note}
                    icon={<FileIcon />}
                    setValue={(value) => changeEquipmentHandler(value, 'note', id)}
                    readOnly={!isEditMode}
                  />
                  {getCustomFieldsByPosition(customFields, 'odd').map((customField) => (
                    <CustomFieldItem
                      customField={customField}
                      key={customField.customFieldId}
                      readonly={!isEditMode}
                      selectedOption={getCustomFieldValue(customField.customFieldId, id)}
                      onChange={handleCustomFieldChange}
                    />
                  ))}
                </div>
              </Fragment>
            );
          },
        )}
      </div>
    );
  }, [
    activeTab,
    equipmentsData,
    isEditMode,
    customFields,
    getCustomFieldValue,
    handleCustomFieldChange,
  ]);

  useEffect(() => {
    if (!equipments?.length) setEquipmentsData([]);
    else if (!activeTab || !equipments.some((equipment) => equipment.id === activeTab))
      setActiveTab(equipments[0].id);

    setEquipmentsData(equipments);
  }, [equipments, activeTab]);

  return (
    <div className={classes['c-section']}>
      <div className={classes['c-section__header']}>
        <div className={classes['c-section__header__title-and-add']}>
          <h2 className={classes['c-section__title']}>Equipment</h2>
        </div>
        {isAdminOrSuperAdmin && (
          <SectionActions
            isEditOpen={isEditMode}
            handleEdit={handleEdit}
            handleCancel={handleCancel}
            handleSave={submitSaveHandler}
            handleAdd={handleOpenModal}
            isLoading={isLoading}
            isContentAvailable={isContentAvailable}
          />
        )}
      </div>
      <div className={classes['c-section__tabs']}>
        {equipments.map(({ id, name }) => (
          <Tab key={id} text={name} itemId={id} activeTab={activeTab} setActiveTab={setActiveTab} />
        ))}
      </div>
      <div className={classes['c-section__section-content-container']}>{renderContent()}</div>
      <PopUpModal
        title="Add equipment"
        opened={isModalOpen}
        closeModal={handleCloseModal}
        width={25}
      >
        <EquipmentModal userId={userId} closeModal={setIsModalOpen} />
      </PopUpModal>
    </div>
  );
};

export default Equipment;
