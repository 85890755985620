import { AppliedFilters } from 'components/core/Filter/redux/filterSlice';
import {
  countAppliedFilters,
  getSingleAppliedFilterValue,
  isSortByFilterApplied,
} from 'components/core/Filter/utils';
import { PaginationData } from 'modules/shared/model';

export const getToolbarSubtitle = (
  appliedFilters: AppliedFilters,
  paginationData: PaginationData,
  itemsName: string,
) => {
  const { totalFilteredElements, totalElements } = paginationData;
  const numberOfAppliedFilters = countAppliedFilters(appliedFilters);

  if (numberOfAppliedFilters === 0) return `${totalElements} members`;

  const appliedFilter = getSingleAppliedFilterValue(appliedFilters);
  const isSortApplied = isSortByFilterApplied(appliedFilters.sortBy);

  if (numberOfAppliedFilters === 1) {
    return isSortApplied
      ? `${totalElements} ${itemsName} - ${appliedFilter}`
      : `${totalFilteredElements} out of ${totalElements} ${itemsName} in ${appliedFilter}`;
  }

  if (numberOfAppliedFilters > 1)
    return `${totalFilteredElements} out of ${totalElements} ${itemsName} - ${numberOfAppliedFilters} filters applied`;
};
