import { useNavigate } from 'react-router-dom';
import error404Image from 'assets/errors/error404.png';
import classes from './NotFound.module.scss';
import { Empty } from 'components/core';

export const NotFound = () => {
  const navigate = useNavigate();
  const goToHome = () => navigate('/', { replace: true });

  return (
    <div className={classes['c-error-page']}>
      <div className={classes['c-error-page__content']}>
        <Empty
          title="Oops! It looks like you've wandered off the beaten path. Let's get you back on track."
          info="All the changes you made are still waiting for you, just go to home page."
          btnText="Return to home page"
          img={error404Image}
          handleOnClick={goToHome}
        />
      </div>
    </div>
  );
};
