import { useState } from 'react';
import { Chart, ChartEvent, ChartTypeRegistry, TooltipItem } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {
  chartColors,
  disabledChartColors,
  responsiveConfig,
  textColor,
  titleConfiguration,
  tooltipConfiguration,
} from './chartsConfigurations';
import { ActiveElement } from 'chart.js/dist/plugins/plugin.tooltip';
import { NumberOfPeoplePerDepartment } from 'modules/insights/models';
import { sortNumberOfPeoplePerDepartment } from 'modules/insights/utils';

type Props = {
  numberOfPeoplePerDepartment: NumberOfPeoplePerDepartment[];
};

export const PeoplePerDepartmentsChart = ({ numberOfPeoplePerDepartment }: Props) => {
  const [hoveringElement, setHoveringElement] = useState<number>();

  const sortedNumOfPeoplePerDepartment = sortNumberOfPeoplePerDepartment(
    numberOfPeoplePerDepartment,
  );

  const peoplePerDepartmentLabels = sortedNumOfPeoplePerDepartment.map((item) => item.department);

  const peoplePerDepartmentData = {
    labels: peoplePerDepartmentLabels,
    datasets: [
      {
        data: sortedNumOfPeoplePerDepartment.map((item) => item.numberOfPeople),
        backgroundColor: hoveringElement !== undefined ? disabledChartColors : chartColors,
        borderColor: hoveringElement !== undefined ? ['rgb(255,255,255)'] : chartColors,
        borderWidth: 1,
        hoverOffset: 15,
      },
    ],
  };

  const peoplePerDepartmentOptions = {
    ...responsiveConfig,
    aspectRatio: 2,
    cutout: '60%',
    radius: '90%',
    plugins: {
      legend: {
        position: 'left' as const,
        align: 'center' as const,
        onClick: (e: ChartEvent) => {
          if (e.native) {
            e.native.stopPropagation();
          }
        },
        labels: {
          textAlign: 'left' as const,
          font: {
            size: 12,
            family: 'Nunito',
            fontWeight: '600',
            color: textColor,
          },
          usePointStyle: true,
          padding: 20,
          boxHeight: 5,
          boxWidth: 5,
          generateLabels: (chart: Chart<keyof ChartTypeRegistry>) => {
            const { datasets, labels } = chart.data;

            return datasets[0].data.map((_, i: number) => ({
              text: `${labels?.[i]}`,
              fillStyle: chartColors[i % chartColors.length],
              lineWidth: 0,
              index: i,
            }));
          },
        },
      },
      title: {
        ...titleConfiguration,
        text: 'People per departments',
      },
      tooltip: {
        ...tooltipConfiguration,
        callbacks: {
          title(tooltipItems: TooltipItem<'doughnut'>[]) {
            const itemIndex: number = tooltipItems[0].dataIndex;
            return `${peoplePerDepartmentLabels[itemIndex]}`;
          },
          label(tooltipItem: TooltipItem<'doughnut'>) {
            return `${tooltipItem.raw} people`;
          },
          labelColor(tooltipItem: TooltipItem<'doughnut'>) {
            return {
              borderColor: chartColors[tooltipItem.dataIndex],
              backgroundColor: chartColors[tooltipItem.dataIndex],
            };
          },
        },
      },
    },
    hoverBackgroundColor: chartColors,
    hoverBorderColor: chartColors,
    onHover(_e: ChartEvent, item: ActiveElement[]) {
      if (!item.length) {
        setHoveringElement(undefined);
        return;
      }
      setHoveringElement(item[0].index);
    },
  };

  return <Doughnut data={peoplePerDepartmentData} options={peoplePerDepartmentOptions} />;
};
