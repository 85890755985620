import { useEffect, useState } from 'react';
import classes from './ManageTimeAway.module.scss';
import TimeAwayHeader from '../TimeAwayHeader/TimeAwayHeader';
import { Status, YearCalendar, useColorLegend } from 'components/core';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getTimeAwaysByUser } from '../../../redux/timeAwaysActions';
import TimeAwaysList from '../TimeAwaysList/TimeAwaysList';
import { selectUserTimeAways } from 'modules/profile/redux/timeAwaysSlice';
import TimeAwayActions, { OverviewType } from '../TimeAwayActions/TimeAwayActions';
import { selectCalendars } from 'modules/shared/redux/calendar';
import { selectAuthUser } from 'modules/auth/redux/authSlice';
import { selectPtoPolicies } from 'modules/shared/redux/ptoPolicy/ptoPolicySlice';
import { selectUser } from 'modules/profile/redux/userSlice';
import { useToolbarTitle, useUserRole } from 'hooks';
import { RequestState } from 'config/constants';

const ManageTimeAway = () => {
  const { timeAways, loading } = useAppSelector(selectUserTimeAways);
  const [overviewType, setOverviewType] = useState<OverviewType>(OverviewType.CALENDAR);

  const { userPtoPolicyDto, organizationCalendarDto } = useAppSelector(selectUser);

  const { calendars } = useAppSelector(selectCalendars);
  const { ptoPolicies } = useAppSelector(selectPtoPolicies);
  const { user } = useAppSelector(selectAuthUser);

  const { filteredTimeAways } = useColorLegend(timeAways);

  const dispatch = useAppDispatch();

  useToolbarTitle({ title: ['Profile', 'Time away'] });

  const { id } = useParams();
  const { isAdminOrSuperAdmin } = useUserRole(user?.role);

  useEffect(() => {
    if (!id) return;

    dispatch(getTimeAwaysByUser(id));
  }, [dispatch, id]);

  const headerActions = (
    <TimeAwayActions overviewType={overviewType} setOverviewType={setOverviewType} />
  );

  return (
    <Status isLoading={loading === RequestState.PENDING}>
      <TimeAwayHeader
        userId={Number(id)}
        isEditAllowed={isAdminOrSuperAdmin}
        calendars={calendars}
        ptoPolicies={ptoPolicies}
        userPtoPolicy={userPtoPolicyDto}
        userCalendar={organizationCalendarDto}
      />
      <div className={classes['c-manage-time-away__overview']}>
        {overviewType === OverviewType.CALENDAR ? (
          <YearCalendar
            timeAways={filteredTimeAways}
            numOfMonths={1}
            isFullYearAllowed
            headerItem={headerActions}
          />
        ) : (
          <TimeAwaysList timeAways={filteredTimeAways} headerActions={headerActions} />
        )}
      </div>
    </Status>
  );
};

export default ManageTimeAway;
