import { useState, useEffect, useCallback, type ChangeEvent, useMemo } from 'react';
import dayjs from 'dayjs';
import FormContainer from 'modules/settings/components/FormContainer/FormContainer';
import classes from './CalendarForm.module.scss';
import { formatDateForInput } from 'utils/dates';
import type { OrganizationCalendar, PublicHoliday } from 'modules/shared/model';
import { SectionInfoEdit } from 'modules/shared/components';
import { IconButton, Divider, DeleteButton, Checkbox } from 'components/core';
import { ReactComponent as AddIcon } from 'assets/AddIcon.svg';

type Props = {
  calendar: OrganizationCalendar;
  displayMode?: 'settings-section' | 'modal';
  onChange: (calendar: OrganizationCalendar) => void;
  onSave?: () => void;
};

const CalendarForm = ({ calendar, displayMode, onChange, onSave }: Props) => {
  const [formData, setFormData] = useState<OrganizationCalendar>(calendar);

  const { name, country, default: isDefault, publicHolidays } = formData;

  const sortedHolidays = useMemo(
    () =>
      [...publicHolidays].sort((a, b) => {
        if (dayjs(a.date).isBefore(b.date)) return -1;

        return 1;
      }),
    [publicHolidays],
  );

  const updateHolidays = useCallback(
    (id: number | null, name: string, value: string | boolean) => {
      const updatedHolidays = [...publicHolidays];
      const index = updatedHolidays.findIndex((holiday) => holiday.id === id);
      updatedHolidays[index] = { ...updatedHolidays[index], [name]: value };

      return updatedHolidays;
    },
    [publicHolidays],
  );

  const handleOnCalendarInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleOnHolidayInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, id: number | null) => {
      const { name, value } = event.target;

      setFormData((prev) => ({
        ...prev,
        publicHolidays: updateHolidays(id, name, value),
      }));
    },
    [updateHolidays],
  );

  const handleOnDateChange = useCallback(
    (value: string, id: number | null) => {
      setFormData((prev) => ({
        ...prev,
        publicHolidays: updateHolidays(id, 'date', formatDateForInput(value)),
      }));
    },
    [updateHolidays],
  );

  const handleOnCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      default: event.target.checked,
    }));
  }, []);

  const handleOnHolidayCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, id: number | null) => {
      const { checked } = event.target;

      setFormData((prev) => ({
        ...prev,
        publicHolidays: updateHolidays(id, 'enabled', checked),
      }));
    },
    [updateHolidays],
  );

  const handleOnDeleteHoliday = useCallback((id: number | null) => {
    setFormData((prev) => ({
      ...prev,
      publicHolidays: prev.publicHolidays.filter((holiday) => holiday.id !== id),
    }));
  }, []);

  const handleOnAddNewHoliday = useCallback(() => {
    const newPublicHoliday: PublicHoliday = {
      id: null,
      name: '',
      date: '',
      enabled: true,
    };

    setFormData((prev) => ({
      ...prev,
      publicHolidays: [...prev.publicHolidays, newPublicHoliday],
    }));
  }, []);

  useEffect(() => {
    onChange(formData);
  }, [formData, onChange]);

  return (
    <FormContainer onSave={onSave} displayMode={displayMode}>
      <div
        className={`${classes['c-calendar-form__item-inputs-wrap']} ${
          displayMode === 'modal' ? classes['c-calendar-form__item-inputs-wrap--modal'] : ''
        }`}
      >
        <SectionInfoEdit
          name="name"
          data={name}
          handleChange={handleOnCalendarInputChange}
          displayMode={displayMode}
        />
        <SectionInfoEdit
          name="country"
          label="Country"
          data={country}
          handleChange={handleOnCalendarInputChange}
          displayMode={displayMode}
        />
        <div className={classes['c-calendar-form__checkbox-holder']}>
          <Checkbox
            id="defaultCalendar"
            label="Default calendar"
            checked={isDefault}
            handleCheckedItem={handleOnCheckboxChange}
            size={displayMode === 'modal' ? 'medium' : 'small'}
          />
        </div>
      </div>
      <div className={classes['c-calendar-form__public-holidays']}>
        <div
          className={`${classes['c-calendar-form__public-holidays-header']} ${
            displayMode === 'modal' ? classes['c-calendar-form__public-holidays-header--modal'] : ''
          }`}
        >
          <h3 className={classes['c-calendar-form__public-holidays-heading']}>Public holidays</h3>
          {displayMode !== 'modal' ? <Divider type="light" /> : ''}
          <IconButton
            icon={<AddIcon className={classes['c-calendar-form__add-public-holiday-icon']} />}
            aria-label="Add public holiday"
            onClick={handleOnAddNewHoliday}
          />
        </div>
        <div
          className={`${classes['c-calendar-form__public-holidays-content']} ${
            displayMode === 'modal'
              ? classes['c-calendar-form__public-holidays-content--modal']
              : ''
          }`}
        >
          {!publicHolidays.length && (
            <span className={classes['c-calendar-form__missing-content-message']}>
              No public holidays
            </span>
          )}
          {sortedHolidays.map(({ id, name, enabled, date }) => (
            <div key={id} className={classes['c-calendar-form__public-holiday-item']}>
              <div className={classes['c-calendar-form__item-inputs-wrap']}>
                <SectionInfoEdit
                  name="name"
                  data={name}
                  handleChange={(event) => handleOnHolidayInputChange(event, id)}
                  displayMode={displayMode}
                />
                <SectionInfoEdit
                  inputType="date"
                  name="date"
                  label="Date"
                  data={date}
                  setValue={(value) => handleOnDateChange(formatDateForInput(value), id)}
                  displayMode={displayMode}
                />

                {displayMode !== 'modal' && (
                  <Checkbox
                    label="Enabled"
                    id={String(id)}
                    checked={enabled}
                    handleCheckedItem={(event) => handleOnHolidayCheckboxChange(event, id)}
                  />
                )}
                <DeleteButton onClick={() => handleOnDeleteHoliday(id)} />
              </div>
              {displayMode === 'modal' && (
                <Checkbox
                  label="Enabled"
                  id={String(id)}
                  checked={enabled}
                  handleCheckedItem={(event) => handleOnHolidayCheckboxChange(event, id)}
                  size="medium"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </FormContainer>
  );
};

export default CalendarForm;
