import { useEffect, useState } from 'react';
import classes from './WeekDaysSelection.module.scss';

type Option = {
  name: string;
  selected: boolean;
};

type Props = {
  workingDays: string[];
  setSelectedDays: (selectedDays: string[]) => void;
  readOnly?: boolean;
};

export const WeekDaysSelection = ({ setSelectedDays, workingDays, readOnly }: Props) => {
  const [options, setOptions] = useState<Option[]>([
    { name: 'MONDAY', selected: true },
    { name: 'TUESDAY', selected: true },
    { name: 'WEDNESDAY', selected: true },
    { name: 'THURSDAY', selected: true },
    { name: 'FRIDAY', selected: true },
    { name: 'SATURDAY', selected: false },
    { name: 'SUNDAY', selected: false },
  ]);

  const handleToggleOption = (toggledOption: Option) => {
    if (readOnly) return;

    const updatedOptions: Option[] = options.map((option) => {
      if (option.name !== toggledOption.name) {
        return option;
      }
      return { name: toggledOption.name, selected: !toggledOption.selected };
    });

    setOptions(updatedOptions);

    setSelectedDays(
      updatedOptions.filter((option) => option.selected).map((option) => option.name),
    );
  };

  useEffect(() => {
    if (!workingDays) return;

    const updatedOptions = options.map((item) =>
      workingDays.includes(item.name) ? { ...item, selected: true } : { ...item, selected: false },
    );
    setOptions(updatedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingDays]);

  return (
    <div className={classes['c-week-days-selection']}>
      {options.map((option, index) => (
        <button
          key={index}
          className={`${classes['c-week-days-selection__option']} ${
            readOnly ? classes['c-week-days-selection__option--unclickable'] : ''
          } ${option.selected ? classes['c-week-days-selection__option--selected'] : ''}`}
          onClick={() => handleToggleOption(option)}
        >
          {option.name.charAt(0)}
        </button>
      ))}
    </div>
  );
};
