import { useState } from 'react';
import classes from '../styles/Section.module.scss';
import { DocumentDropSection } from 'components/core';
import IdentificationTable from './Sections/SectionClassic/IdentificationTable';
import { type Identification as IdentificationModel } from '../models/profileModels';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import { selectUser } from '../redux/userSlice';
import classesIdentification from './Identification.module.scss';
import { useToolbarTitle } from 'hooks';

const Identification = () => {
  const { identifications } = useAppSelector(selectUser);
  const { user } = useAppSelector((state) => state.auth);

  const { id } = useParams();

  useToolbarTitle({ title: ['Profile', 'Identification'] });

  const [inputs, setInputs] = useState<IdentificationModel>({
    id: 0,
    identificationType: '',
    number: '',
    expiryDate: new Date(),
    countryOfIssue: '',
    note: '',
    userId: Number(id),
  });

  return (
    <div className={classes['c-section__wrapper']}>
      <div className={classesIdentification['c-identification-section']}>
        {identifications.map((identification) => (
          <IdentificationTable
            key={identification.id}
            identification={identification}
            userAuthId={user?.id}
            userRole={user?.role}
          />
        ))}
        <DocumentDropSection
          title="Create identification"
          userId={user?.id}
          documentType="identification"
          identificationInputs={inputs}
          handleIdentificationInputs={setInputs}
        />
      </div>
    </div>
  );
};

export default Identification;
