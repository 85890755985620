import { useCallback, useState } from 'react';
import { FilterButton } from 'components/core';
import classes from '../styles/TaskFilters.module.scss';

const taskFilters = ['ALL', 'COMPLETED', 'TO_DO'] as const;

export type TaskFilter = (typeof taskFilters)[number];

const taskFilterLabel: Record<TaskFilter, string> = {
  ALL: 'All',
  COMPLETED: 'Completed',
  TO_DO: 'To Do',
};

type Props = {
  onClick: (selectedFilter: TaskFilter) => void;
};

const TaskFilters = ({ onClick }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<TaskFilter>('ALL');

  const handleOnClick = useCallback(
    (filter: TaskFilter) => {
      setSelectedFilter(filter);
      onClick(filter);
    },
    [onClick],
  );

  return (
    <div className={classes['c-task-filters']}>
      {taskFilters.map((filter) => (
        <FilterButton
          key={filter}
          label={taskFilterLabel[filter]}
          selected={filter === selectedFilter}
          onClick={() => handleOnClick(filter)}
        />
      ))}
    </div>
  );
};

export default TaskFilters;
