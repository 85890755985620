import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomFieldWithSingleChoiceOptions, UserCustomField } from '../models/profileModels';
import { getSingleChoiceCustomFields } from '../utils';
import { getCustomFieldById } from '../api/profile.api';
import { toast } from 'components/core';

type UseUserCustomFields = {
  customFields: CustomFieldWithSingleChoiceOptions[];
};

export const useUserCustomFields = (
  userCustomFields: UserCustomField[],
  shouldGetSingleChoiceValues: boolean,
): UseUserCustomFields => {
  const [customFields, setCustomFields] = useState<CustomFieldWithSingleChoiceOptions[]>([]);

  const shouldGetSingleChoiceOptions = useMemo(
    () =>
      shouldGetSingleChoiceValues &&
      !customFields.some((customField) => customField.singleChoiceString?.length),
    [customFields, shouldGetSingleChoiceValues],
  );

  const getOptionsForSingleChoiceCustomFields = useCallback(async () => {
    const singleChoiceCustomFieldsId = getSingleChoiceCustomFields(userCustomFields).map(
      (customField) => customField.customFieldId,
    );

    if (!singleChoiceCustomFieldsId.length) return;

    try {
      for (const id of singleChoiceCustomFieldsId) {
        const { data } = await getCustomFieldById(id);

        setCustomFields((previous) =>
          previous.map((customField) => {
            return customField.customFieldId === id
              ? { ...customField, singleChoiceString: data.singleChoiceString }
              : customField;
          }),
        );
      }
    } catch {
      toast('error', 'Something went wrong, please try again later');
    }
  }, [userCustomFields]);

  useEffect(() => {
    if (!userCustomFields.length) return;

    setCustomFields(userCustomFields);
  }, [userCustomFields]);

  useEffect(() => {
    if (shouldGetSingleChoiceOptions) {
      getOptionsForSingleChoiceCustomFields();
    }
  }, [
    shouldGetSingleChoiceOptions,
    shouldGetSingleChoiceValues,
    getOptionsForSingleChoiceCustomFields,
  ]);

  return {
    customFields: [...customFields].sort((a, b) =>
      a.customFieldName.toLowerCase() < b.customFieldName.toLowerCase() ? -1 : 1,
    ),
  };
};
