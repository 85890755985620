import { replaceDashWithCommaSpace } from 'utils/utilFunctions';
import { AppliedFilters, SortByFilter } from '../redux/filterSlice';

export const isSortByFilterApplied = (sortByFilter: SortByFilter) => {
  return sortByFilter.id !== -1;
};

export const isLocationFilter = (appliedFilters: AppliedFilters, filter: string) => {
  return appliedFilters.locationNames.includes(filter);
};

export const countAppliedFilters = (appliedFilters: AppliedFilters): number => {
  const filterArrays = [
    appliedFilters.departmentNames,
    appliedFilters.locationNames,
    appliedFilters.projectNames,
    appliedFilters.workflowNames,
  ];

  return (
    filterArrays.reduce((count, filterArray) => count + filterArray.length, 0) +
    (appliedFilters.sortBy.filterValue !== '' ? 1 : 0)
  );
};

export const getSingleAppliedFilterValue = (appliedFilters: AppliedFilters) => {
  const appliedValues = [
    ...appliedFilters.departmentNames,
    ...appliedFilters.locationNames,
    ...appliedFilters.projectNames,
    ...appliedFilters.workflowNames,
  ];

  if (isSortByFilterApplied(appliedFilters.sortBy) && countAppliedFilters(appliedFilters) === 1)
    return appliedFilters.sortBy.filterValue;

  if (appliedFilters.locationNames.length === 1) return replaceDashWithCommaSpace(appliedValues[0]);

  if (appliedValues.length === 1) return appliedValues[0];
};
