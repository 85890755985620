import { type KeyboardEvent, useState } from 'react';
import classes from './CreateWorkflowModal.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'components/core';

const CreateWorkflowModal = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [name, setName] = useState<string>('');

  const handleEnterKey = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') continueEditingHandler();
  };

  const continueEditingHandler = () => {
    if (name === '' || name === undefined) {
      setError('Please enter the workflow template name');
      return;
    }
    navigate(`/workflow-templates/new-template/${name}`);
  };

  return (
    <div className={classes['c-new-worklfow-modal']}>
      <div className={classes['c-new-worklfow-modal__input-wrap']}>
        <Input
          name="new-worklofw"
          id="new-workflow"
          label="Template name"
          setValue={setName}
          autoFocus
          size="medium"
          handleOnKeyDown={handleEnterKey}
        />
        <div className={classes['c-new-worklfow-modal__error-message']}>
          <span>{error}</span>
        </div>
      </div>
      <Button size="medium" onClick={continueEditingHandler}>
        Continue editing
      </Button>
    </div>
  );
};

export default CreateWorkflowModal;
