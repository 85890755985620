import classes from './ErrorMessage.module.scss';

type Props = { isInputInvalid: boolean; invalidMessage?: string };

export const ErrorMessage = ({ invalidMessage, isInputInvalid }: Props) => (
  <>
    {isInputInvalid && invalidMessage && (
      <p className={classes['c-form-error-message']}>{isInputInvalid && invalidMessage}</p>
    )}
  </>
);
