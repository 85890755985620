import type { TAuthConfig } from 'react-oauth2-code-pkce';

export type OAuthProvider = 'google' | 'microsoft';

const sharedConfig: Pick<TAuthConfig, 'decodeToken' | 'autoLogin' | 'clearURL'> = {
  decodeToken: false,
  autoLogin: false,
};

const MICROSOFT_DIRECTORY_ID = process.env.REACT_APP_PEOPLE_MICROSOFT_DIRECTORY_ID;

export const O_AUTH_CONFIG: Record<OAuthProvider, TAuthConfig> = {
  google: {
    ...sharedConfig,
    redirectUri: `${window.location.origin}/oauth2/redirect/google`,
    clientId: process.env.REACT_APP_PEOPLE_GOOGLE_CLIENT_ID ?? '',
    extraTokenParameters: {
      client_secret: process.env.REACT_APP_PEOPLE_GOOGLE_CLIENT_SECRET ?? '',
    },
    scope: 'https://www.googleapis.com/auth/userinfo.email openid',
    authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
    tokenEndpoint: 'https://oauth2.googleapis.com/token',
  },
  microsoft: {
    ...sharedConfig,
    redirectUri: `${window.location.origin}/oauth2/redirect/microsoft`,
    clientId: process.env.REACT_APP_PEOPLE_MICROSOFT_CLIENT_ID ?? '',
    scope: 'openid profile email',
    authorizationEndpoint: `https://login.microsoftonline.com/${MICROSOFT_DIRECTORY_ID}/oauth2/v2.0/authorize`,
    tokenEndpoint: `https://login.microsoftonline.com/${MICROSOFT_DIRECTORY_ID}/oauth2/v2.0/token`,
  },
};
