export const pascalCase = (text?: string): string => {
  if (!text) return '';

  return text.replace(/\w+/g, (word) => word[0].toUpperCase() + word.slice(1).toLowerCase());
};

export const replaceUnderscoreWithBlankSpaceInString = (text?: string): string => {
  if (!text) return '';

  return text.replace('_', ' ');
};

export const replaceDashWithCommaSpace = (text?: string): string => {
  if (!text) return '';
  return text.replace('-', ', ');
};
