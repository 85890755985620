import classes from './AppliedFilters.module.scss';
import { ReactComponent as ClearFilter } from 'assets/ClearFilter.svg';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { removeFilter, clearAppliedFilters } from './../redux/filterSlice';
import { Button, FilterButton } from 'components/core';
import { selectToolbar } from 'components/layout/Toolbar/redux/toolbarSlice';
import { isLocationFilter } from '../utils';
import { replaceDashWithCommaSpace } from 'utils/utilFunctions';

export const AppliedFilters = () => {
  const { appliedFilters } = useAppSelector((state) => state.filters);
  const { filterClicked } = useAppSelector(selectToolbar);

  const dispatch = useAppDispatch();

  const getFilterLabel = (filter: string) => {
    if (isLocationFilter(appliedFilters, filter)) return replaceDashWithCommaSpace(filter);
    return filter;
  };

  const appliedFiltersList = Object.entries(appliedFilters).reduce<string[]>(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        return acc.concat(value);
      }
      if (key === 'sortBy' && value.filterValue !== '') {
        return acc.concat(value.filterValue);
      }
      return acc;
    },
    [],
  );

  return (
    <div
      className={`${classes['c-applied-filters']} ${
        filterClicked ? classes['c-applied-filters--opened-filters'] : ''
      }`}
    >
      <div className={classes['c-applied-filters__list']}>
        {appliedFiltersList.map((filter, index) => (
          <FilterButton
            key={index}
            label={getFilterLabel(filter)}
            type="selective"
            onClick={() => dispatch(removeFilter(filter))}
          />
        ))}
      </div>
      {!!appliedFiltersList.length && (
        <div className={classes['c-applied-filters__clear-filters']}>
          <Button
            variant="text"
            leftIcon={<ClearFilter className={classes['c-applied-filters__clear-filters--icon']} />}
            onClick={() => dispatch(clearAppliedFilters())}
          >
            Clear filters
          </Button>
        </div>
      )}
    </div>
  );
};
