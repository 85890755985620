import { api } from 'lib';
import { getOrganizationId } from 'state/utils/organization';
import type { PtoPolicy } from 'modules/shared/model';

export const createPtoPolicy = async (ptoPolicy: PtoPolicy) => {
  return api.post<PtoPolicy>(`/organization/${getOrganizationId()}/pto-policy`, { ...ptoPolicy });
};

export const updatePtoPolicy = async (ptoPolicy: PtoPolicy) => {
  return api.put<PtoPolicy>(`/organization/${getOrganizationId()}/pto-policy/${ptoPolicy.id}`, {
    ...ptoPolicy,
  });
};

export const deletePtoPolicy = async (ptoPolicyId: number) => {
  return api.delete<void>(`/organization/${getOrganizationId()}/pto-policy/${ptoPolicyId}`);
};
