import { AwayBarData } from '../components/AwayBar';

const radius = '15px';

const awayBarDimensions = (
  width: string,
  left: string,
  backgroundColor: string,
  {
    borderBottomLeftRadius = '0',
    borderBottomRightRadius = '0',
    borderTopLeftRadius = '0',
    borderTopRightRadius = '0',
  }: {
    borderTopLeftRadius?: string;
    borderBottomLeftRadius?: string;
    borderTopRightRadius?: string;
    borderBottomRightRadius?: string;
  },
): AwayBarData => {
  return {
    width,
    left,
    borderTopLeftRadius,
    borderBottomLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    backgroundColor,
  };
};

export const roundedBar = (width: string, left: string, backgroundColor: string) => {
  return awayBarDimensions(width, left, backgroundColor, {
    borderTopLeftRadius: radius,
    borderBottomLeftRadius: radius,
    borderTopRightRadius: radius,
    borderBottomRightRadius: radius,
  });
};

export const roundedLeftBar = (width: string, left: string, backgroundColor: string) => {
  return awayBarDimensions(width, left, backgroundColor, {
    borderTopLeftRadius: radius,
    borderBottomLeftRadius: radius,
  });
};

export const roundedRightBar = (width: string, left: string, backgroundColor: string) => {
  return awayBarDimensions(width, left, backgroundColor, {
    borderTopRightRadius: radius,
    borderBottomRightRadius: radius,
  });
};

export const wholeMonthBar = (backgroundColor: string) => {
  return awayBarDimensions('100%', '0', backgroundColor, {});
};
