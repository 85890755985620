import { useCallback, useEffect, useState } from 'react';
import classes from '../../../styles/SectionSecondary.module.scss';
import { type Compensation as CompensationModel } from 'modules/profile/models/profileModels';
import { Input, PopUpModal, DropDown, ActionConfirmationModal } from 'components/core';
import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import { deleteCompensation, updateCompensation } from 'modules/profile/api/profile.api';
import {
  deleteCompensation as deleteCompensationAction,
  selectUserEmployment,
  updateCompensation as updateCompensationAction,
} from 'modules/profile/redux/userSlice';
import AddCompensationModal from '../../Modals/Compensation/AddCompensationModal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import toast from 'utils/notifications/CustomToast';
import { SectionActions } from 'modules/shared/components';
import dayjs from 'dayjs';
import { formatDateForDisplay } from 'utils/dates';

const Compensation = () => {
  const employment = useAppSelector(selectUserEmployment);
  const compensations = useAppSelector((state) => state.profile.user.employment.compensations);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const [compensationsToUpdate, setCompensationsToUpdate] = useState<Set<number>>(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [compensationsData, setCompensationsData] = useState<CompensationModel[]>(
    compensations || [],
  );

  const [compensationData, setCompensationData] = useState<CompensationModel>();
  const compensationTypeOptions = ['SALARY', 'BONUS', 'COMMISSION', 'EQUITY'];
  const currencyOptions = ['EUR', 'USD', 'GBP', 'RSD'];
  const frequencyOptions = [
    'ANNUAL',
    'QUARTERLY',
    'MONTHLY',
    'BI_WEEKLY',
    'WEEKLY',
    'DAILY',
    'HOURLY',
    'FIXED',
  ];
  const dispatch = useDispatch();

  const { id } = useParams();

  const isContentAvailable = !!compensationsData.length;
  const handleCloseConfirmationModal = () => setIsConfirmationModalOpen(false);
  const changeCompensation = (value: string, id: number, prop: string) => {
    setCompensationsToUpdate((prevSet) => new Set([...prevSet, id]));

    setCompensationsData((prevCompensation) => [
      ...prevCompensation.map((compensation) => {
        if (compensation.id === id) {
          return {
            ...compensation,
            [prop]: prop === 'effectiveOn' ? dayjs(value).format('YYYY-MM-DD') : value,
          };
        }
        return compensation;
      }),
    ]);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      if (!id) return;
      if (!compensationData) return;
      await deleteCompensation(id, compensationData?.id.toString(), employment.id);
      dispatch(deleteCompensationAction(compensationData?.id));
      toast.success('You have successfully deleted compensation.');
      setIsConfirmationModalOpen(false);
    } catch {
      toast.error('Something went wrong while deleting document. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true);

      for (const compensationId of compensationsToUpdate) {
        const compensationToUpdate = compensationsData.find(
          (compensation) => compensation.id === compensationId,
        );

        if (compensationToUpdate && id) {
          await updateCompensation(id, compensationToUpdate, employment.id);
          dispatch(
            updateCompensationAction({
              id: employment.id,
              compensationToUpdate,
            }),
          );
          toast.success('You have successfully updated compensation.');
          setOpenEdit(false);
          setCompensationsToUpdate(new Set());
        }
      }
    } catch {
      toast.error('Something went wrong while saving. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [compensationsData, compensationsToUpdate, dispatch, employment, id]);

  const handleCancel = useCallback(() => {
    setCompensationsToUpdate(new Set());
    setOpenEdit(false);
    setCompensationsData([...compensations]);
  }, [compensations]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!compensations.length) {
      return;
    }
    setCompensationsData(compensations);
  }, [compensations]);

  const handleOnClickDelete = (currentCompensation: CompensationModel) => {
    setCompensationData(currentCompensation);
    if (currentCompensation) {
      const activeCompensationsData = compensationsData.filter(
        (compensation) => compensation.id !== currentCompensation.id,
      );
      setCompensationsData(activeCompensationsData);
      setIsConfirmationModalOpen(true);
    }
  };

  return (
    <div className={classes['c-section-secondary']}>
      <div className={classes['c-section-secondary__header']}>
        <h2 className={classes['c-section-secondary__title']}>Compensation</h2>
        <SectionActions
          isEditOpen={openEdit}
          handleEdit={() => setOpenEdit(true)}
          handleCancel={handleCancel}
          handleSave={handleSave}
          handleAdd={handleOpenModal}
          isLoading={isLoading}
          isContentAvailable={isContentAvailable}
        />
      </div>
      <div className={classes['c-section-secondary__content']}>
        <div
          className={`${classes['c-section-secondary__info-header']} ${
            openEdit
              ? classes['c-section-secondary__info-header--large']
              : classes['c-section-secondary__info-header--large']
          }`}
        >
          <div className={classes['c-section-secondary__info-header-title']}>Type</div>
          <div className={classes['c-section-secondary__info-header-title']}>Amount</div>
          {openEdit && (
            <div className={classes['c-section-secondary__info-header-title']}>Currency</div>
          )}
          <div className={classes['c-section-secondary__info-header-title']}>Effective date</div>
          <div className={classes['c-section-secondary__info-header-title']}>Note</div>
          <div className={classes['c-section-secondary__info-header-title']}>Frequency</div>
          <div className={classes['c-section-secondary__info-header-title']}></div>
        </div>
        {openEdit
          ? compensationsData.map((compensation) => (
              <div
                key={compensation.id}
                className={`${classes['c-section-secondary__info-data']} ${classes['c-section-secondary__info-data--large']} ${classes['c-section-secondary__info-data--edit-mode']}`}
              >
                <DropDown
                  options={compensationTypeOptions}
                  displayedOptions={compensationTypeOptions.map(
                    (option) => option.charAt(0).toUpperCase() + option.slice(1).toLowerCase(),
                  )}
                  selectedOption={compensation.compensationType}
                  setSelectedOption={(option) => {
                    changeCompensation(option, compensation.id, 'compensationType');
                  }}
                  size="small"
                />
                <Input
                  name={compensation.amount.toString()}
                  id={compensation.amount.toString()}
                  value={compensation.amount.toString()}
                  type="number"
                  setValue={(event) => {
                    changeCompensation(event, compensation.id, 'amount');
                  }}
                  size="small"
                />
                <DropDown
                  options={currencyOptions}
                  selectedOption={compensation.currency}
                  setSelectedOption={(option) => {
                    changeCompensation(option, compensation.id, 'currency');
                  }}
                  currency={true}
                  size="small"
                  readOnly={compensation.compensationType === 'EQUITY'}
                />
                <Input
                  value={
                    compensation.effectiveOn && dayjs(compensation.effectiveOn).format('YYYY-MM-DD')
                  }
                  id="effectiveOn"
                  name="effectiveOn"
                  type="date"
                  setValue={(event) => {
                    changeCompensation(event, compensation.id, 'effectiveOn');
                  }}
                  size="small"
                />
                <Input
                  value={compensation.note}
                  name={compensation.note}
                  id={compensation.note}
                  setValue={(event) => {
                    changeCompensation(event, compensation.id, 'note');
                  }}
                  size="small"
                />
                <DropDown
                  options={frequencyOptions}
                  displayedOptions={frequencyOptions.map(
                    (option) => option.charAt(0).toUpperCase() + option.slice(1).toLowerCase(),
                  )}
                  selectedOption={compensation.frequency}
                  setSelectedOption={(option) => {
                    changeCompensation(option, compensation.id, 'frequency');
                  }}
                  size="small"
                  readOnly={compensation.compensationType === 'EQUITY'}
                />
              </div>
            ))
          : compensations.map((compensation) => (
              <div
                key={compensation.id}
                className={`${classes['c-section-secondary__info-data']} ${classes['c-section-secondary__info-data--large']} `}
              >
                <span className={classes['c-section-secondary__data']}>
                  {compensation.compensationType.charAt(0).toUpperCase() +
                    compensation.compensationType.slice(1).toLowerCase()}
                </span>
                <span className={classes['c-section-secondary__data']}>
                  {compensation.amount} {compensation.currency}
                </span>
                <span className={classes['c-section-secondary__data']}>
                  {formatDateForDisplay(compensation.effectiveOn)}
                </span>
                <span className={classes['c-section-secondary__data']}>
                  {compensation.note || (
                    <span className={classes['c-section-secondary__data--missing']}>N/A</span>
                  )}
                </span>
                <div className={classes['c-section-secondary__data']}>
                  {compensation.frequency &&
                    compensation.frequency.charAt(0).toUpperCase() +
                      compensation.frequency.slice(1).toLowerCase()}
                </div>
                <span>
                  <SectionActions
                    isEditOpen={false}
                    handleDelete={() => handleOnClickDelete(compensation)}
                    isLoading={isLoading}
                  />
                </span>
              </div>
            ))}
        {!compensations.length && (
          <div className={classes['c-section-secondary__missing-data-wrap']}>
            <span className={classes['c-section-secondary__data--missing']}>
              No compensation data available.
            </span>
          </div>
        )}
      </div>
      <PopUpModal
        title="Add compensation"
        opened={isModalOpen}
        closeModal={handleCloseModal}
        width={25}
      >
        <AddCompensationModal
          userId={employment.userId}
          employmentId={employment.id}
          setIsModalOpen={setIsModalOpen}
          setOpenEdit={setOpenEdit}
        />
      </PopUpModal>
      <ActionConfirmationModal
        message={`you want to delete compensation "${compensationData?.compensationType}"?`}
        isModalOpen={isConfirmationModalOpen}
        isLoading={isLoading}
        closeModal={handleCloseConfirmationModal}
        handleNo={handleCloseConfirmationModal}
        handleYes={handleDelete}
      />
    </div>
  );
};

export default Compensation;
